<template>
  <div class="card-header">
    <h3>我的合同</h3>
    <br />
  <ul class="nav nav-pills">
    <li class="nav-item">
      <router-link :to="{ name: 'ContractListOngoing'}" class="nav-link" :class="status == 1 ? 'active' : ''">进行中</router-link>
    </li>
    <li class="nav-item" v-if="authLevel(2)">
      <router-link :to="{ name: 'ContractListHistory'}" class="nav-link" :class="status == 2 ? 'active' : ''">历史合同</router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'ContractListInvalid'}" class="nav-link" :class="status == 3 ? 'active' : ''">作废合同</router-link>
    </li>
  </ul>
</div>
</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  props: [
    'status'
  ],
  mixins: [ storeUtil ]
}
</script>
