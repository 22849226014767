<template>
  <b-card>
    <b-card-header :header-bg-variant="randomColor()">
    <h3 v-if="type.length > 0">{{ type }}</h3>
    <b-form-input v-else v-model="customType" v-on:keyup.enter="addTodoTypeToRedis()" placeholder="自定义卡片标题，回车确认"></b-form-input>
    <div style="text-align: right">
      <b-button variant="light" v-if="type.length > 0" @click="customATodo()">添加</b-button>
    </div>
  </b-card-header>
  <b-card-text>
    <draggable class="list-group" :list="todos" :animation="200" ghost-class="ghost-card" group="tasks" @change="change">
      <div class="list-group-item" v-for="(todo, index) in todos" :key="todo._id">
        <div style="text-align: right">
        <a class="fa fa-times" @click="removeTodo(todo._id, index)"></a>
      </div>
        <br />
        <b>{{ todo.context }}</b>
        <br />
        {{ todo.owner }}
        <br />
        {{ getYearMonthDate(todo.date) }}

        <h5 style="text-align:right"><b-badge variant="primary">{{ todo.priority }}</b-badge></h5>
      </div>
    </draggable>

      <br />
      <b-card-text v-if="customTodo">
        <b-form-input v-model="customTodo.context"></b-form-input>
        <rlabel label="日期"></rlabel>
        <b-form-datepicker v-model="customTodo.date" locale="zh" calendar-width="500px"></b-form-datepicker>
        <rlabel label="优先级"></rlabel>
        <b-form-select v-model="customTodo.priority" :options="[0, 1, 2]"></b-form-select>
        <b-button variant="primary" @click="saveTodo()">保存</b-button>
      </b-card-text>
  </b-card-text>
</b-card>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import formatTime from '@/js/formatTime';
import colorUtil from '@/js/colorUtil';

export default {
  props: [
    'projectId',
    'type',
    'score'
  ],
  data() {
    return {
      todos: [],
      customType: '',
      customTodo: null
    }
  },
  mixins: [
    storeUtil, formatTime, colorUtil
  ],
  created() {
    this.fetchTodos();
  },
  watch: {
    todos(newTodo, oldTodo) {
      console.log(this.type + ' newtodo ' + JSON.stringify(newTodo));
    }
  },
  methods: {
    addTodoTypeToRedis() {
      var query = {
        value: this.customType,
        score: this.score
      };
      this.axios.post(`redis/zadd/${this.projectId}TodoType`, query).then(response => {
        this.type = this.customType;
      });
    },
    customATodo() {
      if (!this.customTodo) {
        this.customTodo = {};
      } else {
        this.customTodo = null;
      }
    },
    saveTodo() {
      this.customTodo.owner = this.getUser.name;
      this.customTodo.projectId = this.projectId;
      this.customTodo.type = this.type;
      this.axios.post(`todo/add`, this.customTodo).then(response => {
        this.customTodo = {};
        this.fetchTodos();
      });
    },
    fetchTodos() {
      this.axios.post(`todo/queryAll`, {
        projectId: this.projectId,
        type: this.type
      }).then(response => {
        this.todos = response.data;
      })
    },
    removeTodo(todoId, index) {
      this.axios.get(`todo/delete/${todoId}`).then(response => {
        this.todos.splice(index, 1);
        alert(response.data);
      });
    },
    change({ added }) {
      if (added) {
        var element = added.element;
        element.type = this.type;
        this.axios.post(`todo/update/${element._id}`, element).then(response => {
        });
      }
    }
  }
}
</script>
