<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>付款申请</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="uploadAccountImg">
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">合同编号</th>
                <td>{{ contract._id }}</td>
              </tr>
              <tr>
                <th scope="row">盖章合同</th>
                <td><a href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">{{ contract.stampedFilename }}</a></td>
              </tr>
              <tr>
                <th scope="row">合同总金额</th>
                <td>{{ contract.amount }}</td>
              </tr>
              <tr>
                <th scope="row">已付金额</th>
                <td>{{ contract.paidAmount }}</td>
              </tr>
              <tr>
                <th scope="row">供应商</th>
                <td>{{ supplier }}</td>
              </tr>
            </tbody>
          </table>

          <div class="form-group" id="priceAlert">
            <rlabel label="付款金额"></rlabel>
            <input type="number" step="0.01" class="form-control" v-model="payment.amount" :placeholder="(contract.amount - contract.paidAmount).toFixed(2)" required="required">
            <div v-if="showPriceAlert" class="alert alert-danger">
              付款金额应在0~{{ (contract.amount - contract.paidAmount).toFixed(2) }}之间
            </div>
          </div>
          <b-row>
            <b-col>
              <div class="form-group">
                <rlabel label="付款方式"></rlabel>
                <select class="form-control" v-model="payment.method" required="required">
                  <option disabled selected value>-----请选择-----</option>
                  <option v-for="method in paymentMethods" :key="method">{{ method }}</option>
                </select>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <rlabel label="税率"></rlabel>
                <select class="form-control" v-model="payment.taxPercentage" required="required">
                  <option disabled selected value>-----请选择-----</option>
                  <option v-for="tax in taxPercentages" :key="tax">{{ tax }}</option>
                </select>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="form-group">
                <rlabel label="付款公司"></rlabel>
                <select class="form-control" v-model="payment.company" required="required">
                  <option disabled selected value>-----请选择-----</option>
                  <option v-if="getLocation == 'xian'">陕西晟航</option>
                  <option v-if="getLocation == 'xian'">陕西双友</option>
                  <option v-if="getLocation == 'xian'">其他</option>
                  <option v-if="getLocation == 'shanghai'">上海晟航</option>
                  <option v-if="getLocation == 'shanghai'">上海微宇</option>
                </select>
              </div>
            </b-col>
            <b-col>
              <div class="form-group" id="dateAlert">
                <rlabel label="付款日期"></rlabel>
                <b-form-datepicker v-model="expectedPaymentDate" locale="zh" calendar-width="450px"></b-form-datepicker>
                <div v-if="showDateAlert" class="alert alert-danger">
                  付款日期还未填写
                </div>
              </div>
            </b-col>
          </b-row>

          <FileUploadBox
          label="上传付款信息"
          :notes="['支持拖拽; 重新选择或拖拽文件即可替换原先上传文件']"
          @setFiles="files = $event"
          :required="true"
          :supportMultiFiles="false"
          :filenameCheck="false">
          </FileUploadBox>

          <br /><br />

          <div>
            <b>历史付款信息   （上传与勾选历史付款信息二选一，后期将通过付款信息使用次数优化推荐）</b>
            <rise-loader :loading="loading" class="text-center"></rise-loader>
            <ul style="list-style: none;" v-for="acc in accountInfoSuggestion">
              <li>
                <input type="radio" :value="acc" v-model="paymentAcc">
                <a href="#" @click="viewFile(acc.accountImgId, acc.accountImgFilename)">
                  {{ acc.accountImgFilename }}
                </a>
              </li>
            </ul>
          </div>

        <div class="form-group">
          <label>备注:</label>
          <textarea type="text" class="form-control" v-model="payment.note" rows="5"/>
        </div>
        <div class="form-group">
          <input type="submit" class="btn btn-primary" value="付款申请" :disabled="waiting">
        </div>
        <Uploading :waiting="waiting"></Uploading>

      </form>
    </div>
  </div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      contract: {},
      payment: {},
      taxPercentages: [],
      paymentMethods: [],
      showDateAlert: false,
      expectedPaymentDate: '',
      supplier: '',
      accountInfoSuggestion: [],
      paymentAcc: null
    }
  },
  mixins: [
    formatTime, fileIO, storeUtil, loaderUtil
  ],
  created() {
    this.getRedisSelection();
    this.fetchContract();
    this.fetchItems();
    this.fetchAccountInfoSuggestion();
  },
  computed : {
    showPriceAlert: function() {
      return (this.payment.amount <= 0 || this.payment.amount > this.contract.amount - this.contract.paidAmount);
    }
  },
  methods: {
    getRedisSelection() {
      this.axios.get(`redis/members/taxPercentage`).then((response) => {
        this.taxPercentages = response.data[0];
      });
      this.axios.get(`redis/members/paymentMethod`).then((response) => {
        this.paymentMethods = response.data[0];
      });
    },
    fetchContract() {
      this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
        _id: this.$route.params.id
      }).then(response => {
        if (this.getUser.name == response.data[0].owner) {
          this.contract = response.data[0];
        } else {
          alert('您暂无权限查看此页');
          this.$router.go(-1);
        }
      }).catch(() => {
        alert('您暂无权限查看此页');
        this.$router.go(-1);
      });
    },
    fetchItems() {
      this.axios.post(`items/${this.getLocation}/queryAll`, {
        contractId: this.$route.params.id
      }).then((response) => {
        this.supplier = response.data.length > 0 ? response.data[0].supplier : '?';
      });
    },
    uploadAccountImg() {
      this.waiting = true;
      if (this.expectedPaymentDate == '') {
        this.showDateAlert = true;
        document.getElementById("dateAlert").scrollIntoView();
        this.waiting = false;
        return;
      }
      this.payment.date = this.formatDatePicker(this.expectedPaymentDate);

      if(this.showPriceAlert) {
        document.getElementById("priceAlert").scrollIntoView();
        this.waiting = false;
        return;
      }

      if (this.files.length > 0) {
        this.handleFiles(this.addPayment, false);
      } else {
        this.addPayment();
      }
    },
    addPayment(id, filename) {
      if (id != null && filename != null) {
        this.payment.accountImgId = id;
        this.payment.accountImgFilename = filename;
      } else if (this.paymentAcc != null) {
        this.payment.accountImgId = this.paymentAcc.accountImgId;
        this.payment.accountImgFilename = this.paymentAcc.accountImgFilename;
      } else {
        this.$router.push({name: 'ErrorPage'});
        return;
      }

      this.payment.procedure = this.contract.owner;
      this.payment.contractId = this.contract._id;
      this.payment.procedureNote = '';

      this.axios.post(`payments/${this.getLocation}/add`, this.payment).then((response) => {
        var paymentId = response.data._id;
        this.axios.post(`payments/${this.getLocation}/update/procedure/${paymentId}`, {}).then((response) => {
          alert(response.data);
          this.waiting = false;
          this.$router.push({name: 'ContractInfo', params: {id: this.$route.params.id}});
        }).catch(() => {
          this.errDeletePayment(paymentId);
        });
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    errDeletePayment(paymentId) {
      this.axios.get(`payments/${this.getLocation}/delete/${paymentId}`).then(() => {
        alert('付款申请失败，请重新提交');
        this.waiting = false;
        this.$router.push({name: 'ContractInfo', params: {id: this.$route.params.id}});
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchAccountInfoSuggestion() {
      this.axios.post(`payments/${this.getLocation}/queryAll`, {
        contractId: this.$route.params.id
      }).then(response => {
        if (response.data.length > 0) {
          this.accountInfoSuggestion = response.data;
          this.loading = false;
        } else {
          this.fetchPayments();
        }
      });
    },
    fetchPayments() {
      this.axios.post(`payments/${this.getLocation}/queryAll`, {
        procedure: '完成',
        accountImgId: { $exists: true },
        accountImgFilename: { $exists: true }
      }).then(async response => {
        var payments = response.data;

        for (var i = 0; i < payments.length; i++) {
          var response = await this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
            _id: payments[i].contractId,
            category: '采购合同'
          });

          if (response.data.length > 1) {
            this.$route.push({name: 'ErrorPage'});
          }
          var contract = response.data[0];
          if (contract.supplier == this.supplier) {
            this.accountInfoSuggestion.push(payments[i]);
          }

        }
        this.loading = false;
        this.$forceUpdate();
      });
    }
  }
}
</script>
