<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>收到发票</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="uploadReceipt">
          <div class="form-group">
            <label>合同编号</label>
            {{contract._id}}
          </div>
          <div class="form-group">
            <label>合同总金额</label>
            {{contract.amount}}
          </div>
          <div class="form-group" id="priceAlert">
            <rlabel label="此次收到发票金额"></rlabel>
            <input type="number" step=".01" class="form-control" v-model="receipt.amount" max="contract.amount" required="required">
            <div v-if="showPriceAlert" class="alert alert-danger">
              开票金额应在0~{{ contract.amount - contract.receiptAmount }}之间
            </div>
          </div>

          <FileUploadBox
          label="上传发票"
          :notes="['支持拖拽; 重新选择或拖拽文件即可替换原先上传文件']"
          @setFiles="files = $event"
          :required="true"
          :supportMultiFiles="false"
          :filenameCheck="false">
          </FileUploadBox>

          <br />
          <div class="form-group">
            <label>预计收到发票日期</label>
            <b-form-datepicker v-model="expectedReceiptDate" locale="zh" calendar-width="450px"></b-form-datepicker>
            <p>* 如已收到发票，此处可以不填写</p>
          </div>
          <br />
          <div class="form-group">
            <label>备注</label>
            <textarea type="text" class="form-control" v-model="receipt.note" rows="5"/>
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
          </div>
          <Uploading :waiting="waiting"></Uploading>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import formatTime from '@/js/formatTime';
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';
import moment from 'moment';

export default {
  data() {
    return {
      contract: {},
      receipt: {},
      expectedReceiptDate: ''
    }
  },
  mixins: [
    fileIO, formatTime, storeUtil, loaderUtil
  ],
  created() {
    this.fetchContract();
  },
  computed : {
    showPriceAlert: function() {
      return (this.receipt.amount <= 0 || this.receipt.amount > this.contract.amount - this.contract.receiptAmount);
    }
  },
  methods: {
    fetchContract() {
      if (this.authLevel(4)) {
        this.axios.post(`contracts/${this.getLocation}/query`, {
          _id: this.$route.params.id
        }).then((response) => {
          this.contract = response.data;
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
      } else {
        alert('您暂无权限查看此页');
      }
    },
    uploadReceipt() {
      this.waiting = true;
      if (this.showPriceAlert) {
        document.getElementById('priceAlert').scrollIntoView();
        this.waiting = false;
        return;
      }
      this.handleFiles(this.addReceiptUpdateContract, false);
    },
    addReceiptUpdateContract(id, filename) {
      this.receipt.contractId = this.contract._id;
      this.receipt.fileId = id;
      this.receipt.filename = filename;
      this.receipt.date = this.expectedReceiptDate == '' ? moment() : this.formatDatePicker(this.expectedReceiptDate);

      this.axios.post(`receipts/${this.getLocation}/add`, this.receipt).then(() => {
        this.$router.push({name: 'UpdatePaymentReceipt'});
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });

      this.contract.receiptAmount = Number(this.contract.receiptAmount) + Number(this.receipt.amount);
      this.axios.post(`contracts/${this.getLocation}/update/${this.receipt.contractId}`, this.contract).then(() => {
          alert('成功更新合同发票数据');
          this.$router.push({name: 'UpdatePaymentReceipt'});
          this.waiting = false;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    clearData() {
      this.receipt = {};
    }
  }
}
</script>
