import moment from 'moment';

export default {
  methods: {
    sortByProcedureTimestamp(results) {
      return results.filter(row => {
        var comparison = this.determineProcedureTimestamp(row.procedureTimestamp);
        return new Date(comparison) > moment().add(-3, 'months').toDate();
      }).sort((a, b) => {
        var aComparison = this.determineProcedureTimestamp(a.procedureTimestamp);
        var bComparison = this.determineProcedureTimestamp(b.procedureTimestamp);

        if (!aComparison && !bComparison) {
          return 0;
        } else if (!aComparison && bComparison) {
          return 1;
        } else if (!bComparison && aComparison) {
          return -1;
        } else {
          return new Date(bComparison) - new Date(aComparison);
        }
      });
    },
    determineProcedureTimestamp(timestamp) {
      var comparison = null;
      if (timestamp != null) {
        for (var role of this.getUser.role.split('/')) {
          if (timestamp[role] != null) {
            comparison = timestamp[role];
            break;
          } else if (timestamp[role+'初审'] != null) {
            comparison = timestamp[role+'初审'];
            break;
          }
        }
      }
      return comparison;
    },
    inProcedureArray(procedure) {
      var inProcedure = false;
      while(procedure.length > 0) {
        var p = procedure.shift();
        if (this.getUser.role.split('/').includes(p)) {
          inProcedure = true;
          break;
        }
      }

      if (inProcedure) {
        procedure.push('完成');
      }
      return procedure;
    }
  }
}
