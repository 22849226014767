<template>
  <div class="container">
  <div class="card">

    <FileLoading :showGifImg="showGifImg"></FileLoading>
    <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

    <TheHeader status="complete"></TheHeader>

    <div class="card-body">
      <button class="btn btn-info" style="float: right;" @click="determineAllProjectStatus(projects)">
        更新项目状态
      </button>
      
      <div class="form-check">
        <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
        <p class="form-check-label">模糊查询</p>
      </div>
      <Search @updateData='updateData'></Search>

      <br />
      <b v-show="projects.length > 0">共{{ projects.length }}个已完成的项目</b>
      <br />

      <rise-loader :loading="loading" class="text-center"></rise-loader>

      <div v-if="!loading && projects.length > 0" style='overflow-x:auto'>
        <table class="table table-hover">
          <thead>
            <tr>
              <Header header="甲方" @sort="sort('customer')"></Header>
              <th class="text-center align-middle" style="min-width: 80px">名称</th>
              <th class="text-center align-middle" style="min-width: 100px">销售合同</th>
              <th class="text-center align-middle" style="min-width: 210px">项目人员</th>
              <th class="text-center" style="min-width: 100px"></th>
              <th v-show="authLevel(2)"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(project, index) in sortedProjects" :key="project._id">

              <td v-if="currentSort != 'customer' || projectCustomerCount[project.customer] == 1" class="text-center align-middle">
                {{ project.customer }}
              </td>
              <td v-else-if="index % pageSize == 0 || (index - 1 >= 0 && sortedProjects[index - 1].customer != project.customer)"
                class="text-center align-middle"
                :rowspan="projectCustomerCount[project.customer] >= pageSize - index % pageSize
                ? pageSize - index % pageSize
                : projectCustomerCount[project.customer]">
                {{ project.customer }}
              </td>


              <td class="text-center align-middle">{{ project.title }}</td>
              <td class="text-center align-middle">
                <a href="#" v-on:click="viewContractByContractId(project.contractId)">{{ project.filename }}</a>
              </td>
              <ul class="list-group" v-for="p in project.people" :key="p._id">
                <li class="list-group-item">{{ p.name }} : {{p.role}}</li>
              </ul>

              <td class="text-center align-middle">
                <router-link :to="{name: 'ProjectInfo', params: { id: project._id }}" class="btn btn-success">详情</router-link>
              </td>

              <td class="align-middle" v-show="authLevel(2)">
                <b-button-toolbar>
                  <b-dropdown text="更多操作">
                    <b-dropdown-item @click="changeProjectStatus(project, '历史项目')">
                      移到历史项目
                    </b-dropdown-item>
                  </b-dropdown>
                </b-button-toolbar>
              </td>

            </tr>
          </tbody>
        </table>

        <Pagination
        :array="projects"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @setCurrentPage="setCurrentPage"
        @incrementCurrentPage="currentPage++"
        @minusCurrentPage="currentPage--">
      </Pagination>
    </div>

    <Empty v-else-if="!loading && projects.length == 0"></Empty>
  </div>
</div>
</div>
</template>

<script>
import ProjectListUtil from './ProjectListUtil';

export default {
  data() {
    return {
      status: '已完成'
    }
  },
  mixins: [
    ProjectListUtil
  ]
}
</script>
