<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>我的项目</h3>
        <br />
        <ul class="nav nav-pills">
          <li class="nav-item">
            <router-link :to="{ name: 'ProjectListOverall', params: { status: 'ongoing' } }" class="nav-link" :class="$route.params.status == 'ongoing' ? 'active' : ''">进行中</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'ProjectListOverall', params: { status: 'complete' } }" class="nav-link" :class="$route.params.status == 'complete' ? 'active' : ''">已完成</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'ProjectListOverall', params: { status: 'history' } }" class="nav-link" :class="$route.params.status == 'history' ? 'active' : ''">历史项目</router-link>
          </li>
        </ul>
        <router-link :to="projectListFilename" class="btn-primary btn" style="float: right;">简明版</router-link>
      </div>

      <div class="card-body">
        <ProjectListOverallTemplate :projectStatus='projectStatusTranslate'></ProjectListOverallTemplate>
      </div>
    </div>
  </div>
</template>

<script>
import TheNavPills from './TheNavPills';
import ProjectListOverallTemplate from './ProjectListOverallTemplate';

export default {
  data() {
    return {
      status: this.$route.params.status,
      chineseStatus: ''
    }
  },
  components: {
    TheNavPills,
    ProjectListOverallTemplate
  },
  computed: {
    projectListFilename() {
      return {
        name: `ProjectList${this.status[0].toUpperCase()}${this.status.substring(1)}`
      };
    },
    projectStatusTranslate() {
      switch(this.$route.params.status) {
        case 'ongoing':
        this.chineseStatus = '进行中';
        break;
        case 'complete':
        this.chineseStatus = '已完成';
        break;
        case 'history':
        this.chineseStatus = '历史项目';
        break;
      }
      return this.chineseStatus;
    }
  }
}
</script>
