<template>
<div>
  <div :id="timelineId">
    <i class="fa fa-info" aria-hidden="true"></i>
  </div>
  <b-popover :target="timelineId" placement="right" triggers="hover">
    <ul v-for="key in sortedKeys" style="list-style: none;">
      <li>{{ key }} - {{ getFormattedDate(timestamps[key]) }}</li>
    </ul>
  </b-popover>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';

export default {
  mixins: [
    formatTime
  ],
  props: [
    'timelineId',
    'timestamps'
  ],
  computed: {
    sortedKeys() {
      if (!this.timestamps) {
        return [];
      }
      var _this = this;
      return Object.keys(this.timestamps).sort(function (a, b) {
        return new Date(_this.timestamps[a]) - new Date(_this.timestamps[b]);
      });
    }
  }
}
</script>
