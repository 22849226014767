<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>加班记录</h3>
      </div>
      <div class="card-body">

        <b-alert show dismissible>
          <h4 class="alert-heading">说明</h4>
          <hr>
          可补交一个月内的加班记录
        </b-alert>

        <form v-on:submit.prevent="addOvertime">

          <div class="form-row" id="dateAlert">
            <div class="form-group col-md-3">
              <rlabel label="开始日期"></rlabel>
              <b-form-datepicker v-model="startDate" locale="zh" calendar-width="450px" :date-disabled-fn="disableBeforeOneMonth"></b-form-datepicker>
            </div>
            <div class="form-group col-md-4">
              <rlabel label="开始时间"></rlabel>
              <b-form-timepicker v-model="startTime" locale="zh"></b-form-timepicker>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <rlabel label="结束日期"></rlabel>
              <b-form-datepicker v-model="endDate" locale="zh" calendar-width="450px" :date-disabled-fn="disableBeforeOneMonth"></b-form-datepicker>
            </div>
            <div class="form-group col-md-4">
              <rlabel label="结束时间"></rlabel>
              <b-form-timepicker v-model="endTime" locale="zh"></b-form-timepicker>
            </div>
          </div>

          <div v-if="showDateAlert && !startDate" class="alert alert-danger">
            开始日期还未填写
          </div>
          <div v-else-if="showDateAlert && !endDate" class="alert alert-danger">
            结束日期还未填写
          </div>
          <div v-else-if="showDateAlert" class="alert alert-danger">
            开始时间大于结束时间, 请重新填写
          </div>

          <div class="form-group">
            <rlabel label="备注"></rlabel>
            <textarea type="text" class="form-control" rows="5" v-model="overtime.note" required />
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传">
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import formatTime from '@/js/formatTime.js';
import loaderUtil from '@/js/loaderUtil.js';
import storeUtil from '@/js/storeUtil.js';

export default {
  data() {
    return {
      overtime: {},
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      showDateAlert: false
    }
  },
  mixins: [ formatTime, loaderUtil, storeUtil ],
  methods: {
    addOvertime() {
      this.overtime.startTime = this.formatDateTimePicker(this.startDate, this.startTime);
      this.overtime.endTime = this.formatDateTimePicker(this.endDate, this.endTime);

      if (this.overtime.startTime >= this.overtime.endTime) {
        this.showDateAlert = true;
        document.getElementById('dateAlert').scrollIntoView();
        this.waiting = false;
        return;
      }
      this.overtime.procedure = this.getUser.name;

      let baseUri = `people/${this.getLocation}`;
      let addUri = `${baseUri}/add/overtime/${this.getUser.name}`;
      let updateProcedureUri = `${baseUri}/update/procedure/overtime/${this.getUser.name}`;

      this.axios.post(addUri, this.overtime).then(response => {
        var overtimeId = response.data;
        this.axios.post(updateProcedureUri, {id: overtimeId}).then(response => {
          alert(response.data);
        }).catch(err => {
          alert(err.response.data);
        });
      }).catch(err => {
        alert(err.response.data);
      });

    }
  }
}
</script>
