<template>
  <div>
    <div v-for="category in categorys" :key="!field ? category : category._id" style="width: 20%; text-align: center; display: inline-block;">
      <div class="align-middle" @click="$emit('clickFolder', category)">
        <i class="fa fa-folder fa-4x text-info"></i>
        <br />
        {{ !field ? category : category[field] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'categorys',
    'field'
  ]
}
</script>
