<template>
  <div class="container">

    <rise-loader :loading="loading" class="center"></rise-loader>

    <div class="card-deck">
      <div class="card">
        <div class="card-body bg-warning" style='overflow-x:auto'>
          <ComparisonTable :item="item" :highlightedParam="highlightedParam" :isOriginal="true"></ComparisonTable>
        </div>
      </div>

      <div v-for="item in sortedRefItems" :key="item._id" class="card">
        <div class="card-body" @click="clickedRefItem = item" style='overflow-x:auto'>
          <ComparisonTable :item="item" :isOriginal="false"></ComparisonTable>
        </div>
      </div>
    </div>

    <br />

    <Pagination
    :array="refItems"
    :currentPage=currentPage
    :pageSize="pageSize"
    @setCurrentPage="setCurrentPage"
    @incrementCurrentPage="currentPage++"
    @minusCurrentPage="currentPage--">
  </Pagination>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import loaderUtil from '@/js/loaderUtil';
import ComparisonTable from './ComparisonTable';

export default {
  data() {
    return {
      item: {},
      refItems: [],
      clickedRefItem: null,
      currentSort: 'paramSimilarity',
      currentSortDir: 'desc',
      pageSize: 2
    }
  },
  mixins: [
    storeUtil, sortUtil, loaderUtil
  ],
  components: {
    ComparisonTable
  },
  created() {
    this.getItem();
  },
  computed: {
    sortedRefItems() {
      return this.sortArray(this.refItems);
    },
    highlightedParam() {
      if (!this.clickedRefItem) {
        return;
      }

      var slashChars = /[+*().-]/ig;
      var param = this.item.parameters;
      var diff = this.clickedRefItem.diff;
      var position = 0;

      for (var [status, chars] of diff) {
        if (status == 1) {
          if (chars.match(slashChars) != null) {
            chars = chars.replace(slashChars, '\\$&');
          }

          var regex = new RegExp(`${chars}`, 'i');
          param = param.substring(0, position) + param.substring(position).replace(regex, function(matchedTxt, offset, string) {
            var replacedStr = '<span class=\'highlight\' style=\'background-color: white\'>' + matchedTxt + '</span>';
            position += offset + replacedStr.length;
            return replacedStr;
          });
        }
      }
      return param;
    }
  },
  methods: {
    getItem() {
      this.axios.post(`items/${this.getLocation}/queryAll`, {
        _id: this.$route.params.id
      }).then(response => {
        this.item = response.data[0];
        this.item.highlightedParam = this.item.parameters;
        this.fetchRefItems();
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchRefItems() {
      this.axios.post(`items/${this.getLocation}/comparison`, this.item).then(async response => {
        this.refItems = await this.fetchRefItemParamSimilarity(response.data);
        this.loading = false;
      });
    },
    async fetchRefItemParamSimilarity(refItems) {
      let compareUri = `items/${this.getLocation}/diff/parameters`;
      for (var i = 0; i < refItems.length; i++) {
        var refItem = refItems[i];
        var response = await this.axios.post(compareUri, {
          refParameters: refItem.parameters,
          parameters: this.item.parameters
        });
        var diff = response.data;
        refItems[i]['diff'] = diff;
        refItems[i]['parameters'] = this.highlightSimilarity(diff, refItem.parameters);
        refItems[i]['paramSimilarity'] = this.similarity(diff, refItem.parameters, this.item.parameters)
      }
      return refItems;
    },
    highlightSimilarity(diff, str) {
      var slashChars = /[+*()]/ig;

      for (var [status, chars] of diff) {
        if (chars.match(slashChars) != null) {
          chars = chars.replace(slashChars, '\\$&');
        }
        if (status == 0) {
          var regex = new RegExp(`${chars}`, 'i');
          str = str.toLowerCase().replace(regex, function(matchedTxt) {
            return '<span class=\'highlight\'>' + matchedTxt + '</span>';
          });
        }
      }
      return str;
    },
    similarity(diff, str1, str2) {
      var regex = /[,*+?^${}()|[\]\\，。：；、（）\n]/g;
      str1 = str1.toLowerCase().replace(regex, '');
      str2 = str2.toLowerCase().replace(regex, '');
      var commonStr = '';
      for (var [status, str] of diff) {
        str = str.replace(regex, '');
        if (status == 0) {
          commonStr += str;
        }
      }
      return (commonStr.length / str2.length * 100).toFixed(2);
    }
  }
}
</script>
