<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>供应商列表</h3>
        <router-link :to="{ name: 'SupplierForm' }" class="btn btn-primary" style="float: right;">添加供应商</router-link>
      </div>
      <div class="card-body">
        <input type="text" class="trigger" placeholder="搜索" v-model="searchKey" /><i class="fa fa-search" @click="fetchSuppliers()"></i>

        <div class="card" v-if="suppliers.length > 0">
          <table class="table table-hover table-sm">
            <thead class="text-center">
              <tr>
                <th class="align-middle">序号</th>
                <th class="align-middle" style="width: 200px">名称</th>
                <th class="align-middle" style="width: 100px">标签</th>
                <th class="align-middle" style="width: 100px">联系人列表</th>
                <Header header="信誉"></Header>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(supplier, index) in suppliers" :key="supplier._id">
                <td class="text-center align-middle"><b>{{ index + 1 }}</b></td>
                <td class="text-center align-middle">{{ supplier.name }}</td>
                <td class="text-center align-middle">
                  <div v-for="tag in supplier.tags" :key="tag">
                  <b-badge pill>{{ tag }}</b-badge>
                </div>
                </td>
                <td class="align-middle">
                  <b-list-group v-for="contact in supplier.contacts">
                    <b-list-group-item v-show="contact.name">
                      <b>联系人</b><b-badge variant="info">{{ contact.name }}</b-badge>
                    </b-list-group-item>
                    <b-list-group-item v-show="contact.phoneNumber">
                      <b>联系方式</b><b-badge variant="info">{{ contact.phoneNumber }}</b-badge>
                    </b-list-group-item>
                    <b-list-group-item v-show="contact.wechatID">
                      <b>微信</b><b-badge variant="info">{{ contact.wechatID }}</b-badge>
                    </b-list-group-item>
                    <b-list-group-item v-show="contact.email">
                      <b>邮箱</b><b-badge variant="info">{{ contact.email }}</b-badge>
                    </b-list-group-item>
                    <b-list-group-item v-show="contact.role">
                      <b>职位</b><b-badge variant="info">{{ contact.role }}</b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </td>
                <td class="text-center align-middle">{{ supplier.credibility }}</td>
                <td class="text-center align-middle">
                  <router-link :to="{ name: 'SupplierForm', params: { id: supplier._id } }" class="btn btn-primary btn-sm">编辑</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Empty v-else></Empty>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: '',
      suppliers: [],
      allSuppliers: []
    }
  },
  created() {
    this.axios.post(`suppliers/aggregate`, [
      {
        $match: {}
      }
    ]).then(response => {
      this.allSuppliers = response.data;
      this.suppliers = response.data;
    });
  },
  methods: {
    fetchSuppliers() {
      this.axios.post(`suppliers/aggregate`, [
        {
          $match: {
            name: { $regex: `.*${this.searchKey}.*` }
          }
        }
      ]).then(response => {
        this.suppliers = response.data;
      });
    }
  }
}
</script>
