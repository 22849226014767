export default {
  data() {
    return {
      datasetLabels: ['应收', '到期应收', '本月应收', '应付', '到期应付', '本月应付', '保证金', '到期保证金', '本月应收保证金']
    }
  },
  methods: {
    datasets(toReceive, dueReceive, monthDueReceive, toPay, duePay, monthDuePay, margin, dueMargin, monthMargin) {
      return [
        {
          label: this.datasetLabels[0],
          data: toReceive,
          backgroundColor: "rgb(255, 99, 132, 0.2)",
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 1
        },
        {
          label: this.datasetLabels[1],
          data: dueReceive,
          backgroundColor: "rgb(255, 99, 132, 0.5)",
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 1
        },
        {
          label: this.datasetLabels[2],
          data: monthDueReceive,
          backgroundColor: "rgb(255, 99, 132, 0.8)",
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 1
        },
        {
          label: this.datasetLabels[3],
          data: toPay,
          backgroundColor: "rgb(75, 192, 192, 0.2)",
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 1
        },
        {
          label: this.datasetLabels[4],
          data: duePay,
          backgroundColor: "rgb(75, 192, 192, 0.5)",
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 1
        },
        {
          label: this.datasetLabels[5],
          data: monthDuePay,
          backgroundColor: "rgb(75, 192, 192, 0.8)",
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 1
        },
        {
          label: this.datasetLabels[6],
          data: margin,
          backgroundColor: "rgb(255, 205, 86, 0.2)",
          borderColor: "rgb(255, 205, 86)",
          borderWidth: 1
        },
        {
          label: this.datasetLabels[7],
          data: dueMargin,
          backgroundColor: "rgb(255, 205, 86, 0.5)",
          borderColor: "rgb(255, 205, 86)",
          borderWidth: 1
        },
        {
          label: this.datasetLabels[8],
          data: monthMargin,
          backgroundColor: "rgb(255, 205, 86, 0.8)",
          borderColor: "rgb(255, 205, 86)",
          borderWidth: 1
        }
      ];
    },
  }
}
