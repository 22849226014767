<template>
  <div style="overflow-x: auto">

    <rise-loader :loading="loading" class="text-center align-middle"></rise-loader>

  <table class="table table-hover" v-if="!loading">
    <thead>
      <tr class="text-center">
        <th class="align-middle">序号</th>
        <th class="align-middle" style="min-width: 100px">项目名称</th>
        <th class="align-middle" style="min-width: 120px">费用责任人</th>
        <th class="align-middle" style="min-width: 80px">类别</th>
        <th class="align-middle" style="min-width: 120px">备注</th>
        <th class="align-middle" style="min-width: 100px">实报金额</th>
        <th class="align-middle" style="min-width: 120px">报销完成时间</th>
        <th></th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(r, index) in reimbursements" :key="r._id" class="text-center">
        <td class="align-middle"><b>{{ index + 1 }}</b></td>
        <td class="align-middle">{{ r.projectTitle }}</td>
        <td class="align-middle">{{ r.owner }}</td>
        <td class="align-middle">{{ r.category }}</td>
        <td style="width: 35%">{{ r.note }}</td>
        <td class="align-middle">{{ r[amountField] }}</td>
        <td class="align-middle">{{ getFormattedDate(r.completeTime) }}</td>
        <td class="align-middle" v-if="r.procedureTimestamp != null">
          <TimelineHover :timelineId="r._id" :timestamps="r.procedureTimestamp"></TimelineHover>
        </td>
        <td v-else></td>

        <td class="align-middle" v-if="r.procedureNote != null && r.procedureNote != ''">
          <ProcedureNote :id="r._id.toString() + 'ProcedureNote'" :note="r.procedureNote"></ProcedureNote>
        </td>
        <td v-else></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="align-middle"><b>
          {{ total.toFixed(2) }}
        </b></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>

</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import accountingUtil from '@/js/accountingUtil';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      reimbursements: []
    }
  },
  props:[
    'matchQuery',
    'amountField'
  ],
  mixins: [
    formatTime, accountingUtil, storeUtil, loaderUtil
  ],
  created() {
    this.fetchCompletedReimbursements();
  },
  watch: {
    matchQuery(newMatchQuery) {
      this.reimbursements = [];
      this.loading = true;
      this.fetchCompletedReimbursements();
    }
  },
  computed: {
    total() {
      var calculation = this.getTotal(this.reimbursements, this.amountField);
      this.$emit('setReimbursementTotal', calculation);
      return calculation;
    }
  },
  methods: {
    fetchCompletedReimbursements() {
      var query = [
        { $match: this.matchQuery },
        { $sort: { completeTime: -1 } }
      ];
      this.axios.post(`overall/${this.getLocation}/reimbursements/project/title`, query).then((response) => {
        this.reimbursements = response.data;
        this.loading = false;
      }).catch(() => {
        this.$router.push({ name: 'ErrorPage' });
      });
    }
  }
}
</script>
