<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>休假申请</h3>
      </div>
      <div class="card-body">

        <b>{{ year }}年度剩余调休时间——{{ workingHours }}小时</b>

        <br />
        <br />
        <br />

        <form v-on:submit.prevent="addVacation">

          <div class="form-row" id="dateAlert">
            <div class="form-group col-md-3">
              <rlabel label="开始日期"></rlabel>
              <b-form-datepicker v-model="startDate" locale="zh" calendar-width="450px" :date-disabled-fn="disableDatesBeforeToday"></b-form-datepicker>
            </div>
            <div class="form-group col-md-4">
              <rlabel label="开始时间"></rlabel>
              <b-form-timepicker v-model="startTime" locale="zh"></b-form-timepicker>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <rlabel label="结束日期"></rlabel>
              <b-form-datepicker v-model="endDate" locale="zh" calendar-width="450px" :date-disabled-fn="disableDatesBeforeToday"></b-form-datepicker>
            </div>
            <div class="form-group col-md-4">
              <rlabel label="结束时间"></rlabel>
              <b-form-timepicker v-model="endTime" locale="zh"></b-form-timepicker>
            </div>
          </div>


          <br />
          <b>
            此次休假时长共计{{ duration }}小时
          </b>
          <br />
          <br />
          <br />

          <div id="dateAlert">
            <div v-if="!startDate || !startTime" class="alert alert-danger">
              开始日期还未填写
            </div>
            <div v-if="!endDate || !endTime" class="alert alert-danger">
              结束日期还未填写
            </div>
            <div v-if="showStartGreaterEndDateAlert" class="alert alert-danger">
              开始时间大于结束时间, 请重新填写
            </div>
            <div v-if="showStartEndDateAlert" class="alert alert-warning">
              休假时长超过可调休时长
            </div>
          </div>

          <div class="form-group">
            <rlabel label="备注"></rlabel>
            <textarea type="text" class="form-control" rows="5" v-model="vacation.note" required />
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传">
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import formatTime from '@/js/formatTime.js';
import storeUtil from '@/js/storeUtil.js';
import moment from 'moment';

export default {
  data() {
    return {
      vacation: {},
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      year: moment().year(),
      workingHours: 0
    }
  },
  mixins: [ storeUtil, formatTime ],
  created() {
    this.computeWorkingHours();
  },
  computed: {
    duration() {
      if(this.startDate != '' && this.startTime != '' && this.endDate != '' && this.endTime != '') {
        return moment(`${this.endDate} ${this.endTime}`, "YYYY-MM-DD HH:mm").diff(moment(`${this.startDate} ${this.startTime}`, "YYYY-MM-DD HH:mm"), 'hours', true);
      } else {
        return 0;
      }
    },
    showStartGreaterEndDateAlert() {
      return this.duration < 0;
    },
    showStartEndDateAlert() {
      return this.duration > this.workingHours;
    }
  },
  methods: {
    addVacation() {
      if (this.showStartGreaterEndDateAlert) {
        document.getElementById('dateAlert').scrollIntoView();
        this.waiting = false;
        return;
      }
      this.vacation.startTime = this.formatDateTimePicker(this.startDate, this.startTime);
      this.vacation.endTime = this.formatDateTimePicker(this.endDate, this.endTime);
      this.vacation.procedure = this.getUser.name;

      let baseUri = `people/${this.getLocation}`;
      let addUri = `${baseUri}/add/vacation/${this.getUser.name}`;
      let updateProcedureUri = `${baseUri}/update/procedure/vacation/${this.getUser.name}`;

      this.axios.post(addUri, this.vacation).then(response => {
        var vacationId = response.data;
        this.axios.post(updateProcedureUri, {id: vacationId}).then(response => {
          alert(response.data);
        }).catch(err => {
          console.log(err);
        });
      }).catch(err => {
        console.log(err);
      });
    },
    computeWorkingHours() {
      let uri = `people/${this.getLocation}/query`;
      this.axios.post(uri, {name: this.getUser.name}).then(response => {
        var overtime = response.data.overtime;
        var vacation = response.data.vacation;
        var totalOvertime = 0;
        var totalVacation = 0;
        for (var o of overtime) {
          totalOvertime += Number(moment(o.endTime).from(o.startTime, true).split(' ')[0]);
        }

        for (var v of vacation) {
          totalVacation += Number(moment(v.endTime).from(v.startTime, true).split(' ')[0]);
        }

        this.workingHours = totalOvertime - totalVacation;
      });
    }
  }
}
</script>
