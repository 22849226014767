<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>批量添加采购记录</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="importItemsToDB()">
          <div class="form-group">
            <rlabel label="项目名称"></rlabel>
            <select class="form-control" v-model="projectTitle" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="project in projects" :key="project._id" :value="project.title">
                {{ project.customer }} - {{ project.title }}
              </option>
            </select>
          </div>

          <br />

          <div class="alert alert-warning">
            批量添加采购<a href="#" @click="viewTemplate('61581a653451c552b21540ea')">模板</a>下载
          </div>

          <FileUploadBox
          label="上传EXCEL文件"
          :notes="[
          '支持拖拽; 重新选择或拖拽文件即可替换原先上传文件',
          '可导入的列名有——名称、品牌、型号、单价、数量、供应商、送货日期（必填）、参数',
          '如采购条目中有一列内容未填，系统将自动忽略此采购条目',
          '送货日期需为EXCEL日期格式']"
          @setFiles="files = $event"
          :required="false"
          :supportMultiFiles="false"
          :filenameCheck="false">
        </FileUploadBox>

          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="批量导入" :disabled="waiting">
          </div>
          <Uploading :waiting="waiting"></Uploading>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import formatTime from '@/js/formatTime';
import peopleUtil from '@/js/peopleUtil';
import storeUtil from '@/js/storeUtil';
import fileIO from '@/js/fileIO';
import projectUtil from '@/js/projectUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      projectTitle: ''
    }
  },
  mixins: [
    formatTime, peopleUtil, storeUtil, fileIO, projectUtil, loaderUtil
  ],
  methods: {
    importItemsToDB() {
      this.waiting = true;
      if(this.files.length == 0) {
        this.waiting = false;
        return;
      }

      var filenameSplitByDot = this.files[0].name.split('.');
      var type = filenameSplitByDot[filenameSplitByDot.length - 1];
      if (type != 'xlsx' && type != 'xls') {
        alert('请通过EXCEL文件批量导入');
        this.files = [];
        this.waiting = false;
        return;
      }

      let formData = new FormData();
      let header = {
        'Content-Type': 'multipart/form-data'
      };

      formData.append('file', this.files[0]);
      formData.append('owner', this.getUser.name);
      this.axios.post(`projects/${this.getLocation}/query`,
        {
          title: this.projectTitle
        }
      ).then((response) => {
        if (!response.data) {
          this.$router.push({name: 'ErrorPage'});
          return;
        }
        formData.append('projectId', response.data._id);

        this.axios.post(`items/${this.getLocation}/add/csv`, formData, { headers: header }).then((response) => {
          this.waiting = false;
          alert(response.data);
          this.$router.push({name: 'ItemListOngoing'});
        });

      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });

    }
  }
}
</script>
