<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3 v-if="!this.$route.params.id">费用申请</h3>
        <h3 v-else>费用申请——上传发票</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="uploadReimbursement">
          <div class="form-group">
            <rlabel label="项目名称"></rlabel>
            <select class="form-control" v-model="reimbursement.projectId" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="p in projects" :key="p._id" :value="p._id">
                {{ p.customer }} - {{ p.title }}
              </option>
              <option :value="null">日常报销</option>
            </select>
          </div>
          <div class="form-group">
            <rlabel label="金额"></rlabel>
            <input type="number" step=".01" class="form-control" v-model="reimbursement.amount" required="required">
          </div>
          <div class="form-group">
            <rlabel label="类别"></rlabel>
            <div v-if="this.$route.params.id != null">{{ reimbursement.category }}</div>
            <select class="form-control" v-model="reimbursement.category" required="required" v-else>
              <option disabled selected value>-----请选择-----</option>
              <option v-for="c in reimbursementCategory" :key="c" :value="c">{{ c }}</option>
            </select>
          </div>

          <div class="form-group">
            <rlabel label="说明"></rlabel>
            <textarea type="text" class="form-control" rows="5" v-model="reimbursement.note" required="required" />
          </div>

          <div class="form-group">
            <rlabel label="付款方式"></rlabel>
            <br />
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="method" value="company" v-model="reimbursement.method" required>
              <label class="form-check-label">公司</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="method" value="individual" v-model="reimbursement.method">
              <label class="form-check-label">个人</label>
            </div>

            <FileUploadBox v-show="reimbursement.method == 'individual'"
            label="上传发票"
            :notes="['支持拖拽; 重新选择或拖拽文件即可替换原先上传文件']"
            @setFiles="files = $event"
            :required="!this.$route.params.id"
            :supportMultiFiles="false"
            :filenameCheck="false">
          </FileUploadBox>

          <div v-show="reimbursement.method == 'company'">

            <FileUploadBox
            label="上传付款信息"
            :notes="['支持拖拽; 重新选择或拖拽文件即可替换原先上传文件']"
            @setFiles="files = $event"
            :required="true"
            :supportMultiFiles="false"
            :filenameCheck="false">
          </FileUploadBox>

          <br /><br />

          <div>
            * 如费用涉及外聘人员，请点击
            <a href="#" @click="getLocation == 'shanghai' ? viewTemplate('5e67b47b22e3296146fb7622') : viewTemplate('')">此处</a>
            下载模板填写后，在付款信息处上传。
          </div>

        </div>
      </div>

      <div class="form-group">
        <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
      </div>
      <Uploading :waiting="waiting"></Uploading>

    </form>
  </div>
</div>
</div>
</template>

<script>
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import translateUtil from '@/js/translateUtil';
// import projectUtil from '@/js/projectUtil';
import loaderUtil from '@/js/loaderUtil';
import moment from 'moment';

export default {
  data() {
    return {
      projects: [],
      allPeople: [],
      reimbursement: {},
      reimbursementProjectTitle: '',
      reimbursementCategory: [],
      receiptNote: ''
    }
  },
  mixins: [
    fileIO, storeUtil, translateUtil, loaderUtil
  ],
  created: function() {
    this.fetchOpportunitiesOrOngoingProjects();
    this.fetchAllPeople();
    if (!this.$route.params.id) {
      this.fetchReimbursementCategory();
    } else {
      this.fetchExistingReimbursement();
    }
  },
  methods: {
    fetchOpportunitiesOrOngoingProjects() {
      this.axios.post(`projects/${this.getLocation}/queryAll`, {
        'people.name': this.getUser.name,
        status: {
          $in: [ '机会', '进行中' ]
        }
      }).then((response) => {
        if (response.data != null) {
          this.projects = response.data;
        }
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchAllPeople() {
      this.axios.post(`people/${this.getLocation}/aggregate`, [
        { $match:
          { name: { $ne: this.getUser.name } }
        }
      ]).then(response => {
        this.allPeople = response.data;
      });
    },
    fetchReimbursementCategory() {
      this.axios.get(`redis/members/reimbursementCategory`).then((response) => {
        this.reimbursementCategory = response.data[0];
      });
    },
    fetchExistingReimbursement() {
      this.axios.post(`reimbursements/${this.getLocation}/query`, {
        _id: this.$route.params.id
      }).then(response => {
        if (!response.data) {
          this.$router.push({name: 'ErrorPage'});
          return;
        }

        this.reimbursement = response.data;

        this.axios.post(`projects/${this.getLocation}/query`, {
          _id: this.reimbursement.projectId
        }).then((response) => {
          this.reimbursementProjectTitle = !response.data ? '日常报销' : response.data.title;
        });
      });
    },
    uploadReimbursement() {
      this.waiting = true;

      if (!this.$route.params.id) {
        switch(this.reimbursement.method) {
          case 'individual':
          this.handleFiles(this.addReimbursementApplicationWithReceipt, false);
          break;
          case 'company':
          this.handleFiles(this.addReimbursementApplicationWithAccount, false);
          break;
        }
      } else {
        this.handleFiles(this.addReimbursement, false);
      }
    },
    addReimbursementApplicationWithReceipt(id, filename) {
      this.reimbursement.fileId = id;
      this.reimbursement.filename = filename;
      this.addReimbursementApplication();
    },
    addReimbursementApplicationWithAccount(id, filename) {
      this.reimbursement.accountImgId = id;
      this.reimbursement.accountImgFilename = filename;
      this.addReimbursementApplication();
    },
    async addReimbursementApplication() {
      this.reimbursement.owner = this.getUser.name;
      this.reimbursement.procedure = this.reimbursement.owner;

      // var updateQuery = {};

      this.axios.post(`reimbursements/${this.getLocation}/add`, this.reimbursement).then((response) => {
        this.axios.post(`reimbursements/${this.getLocation}/update/procedure/${response.data._id}`, {}).then((response) => {
          this.finalStep(response);
        });
      });
    },
    addReimbursement(id, filename) {
      if (this.reimbursement.procedure == this.reimbursement.owner) {
        // 编辑
        this.reimbursement.fileId = !id ? this.reimbursement.fileId : id;
        this.reimbursement.filename = !filename ? this.reimbursement.filename : filename;
        this.axios.post(`reimbursements/${this.getLocation}/update/${this.$route.params.id}`, this.reimbursement).then((response) => {
          this.axios.post(`reimbursements/${this.getLocation}/update/procedure/${this.$route.params.id}`, {}).then((response) => {
            this.finalStep(response);
          });
        });
      } else {
        // 上传发票
        this.reimbursement.fileId = id;
        this.reimbursement.filename = filename;
        if(this.receiptNote.length > 0) {
          this.reimbursement.note += this.receiptNote;
        }
        if (this.reimbursement.method == 'company') {
          this.reimbursement.completeTime = moment();
        }

        this.axios.post(`reimbursements/${this.getLocation}/update/${this.$route.params.id}`, this.reimbursement).then(async (response) => {
          this.finalStep(response);
        });
      }
    },
    finalStep(response) {
      this.waiting = false;
      alert(response.data);
      this.$router.push({name: 'ReimbursementListIndividual'});
    },
    // async determineProcedureByCategory() {
    //   var response = await this.axios.get(`redis/hgetall/firstReimbursementProcedure`);
    //   var rProcedure = response.data[0];
    //   for (var p of Object.keys(rProcedure)) {
    //     var categoryArray = rProcedure[p].split(',');
    //     if (categoryArray.indexOf(this.reimbursement.category) > -1) {
    //       return p;
    //     }
    //   }
    // }
  }
}
</script>
