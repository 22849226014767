<template>
  <div class="container">
    <FileLoading :showGifImg="showGifImg"></FileLoading>

    <div class="card">
      <div class="card-header">
        <h3>共享文件</h3>

        <Search @updateData='updateData'></Search>
      </div>

      <div class="card-body" v-if="filters.length > 0">
        <SharedFilesListTemplate :templates="filteredTemplates"></SharedFilesListTemplate>
      </div>

      <div class="card-body" v-if="filters.length == 0">

        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#" @click="depth=1">首页</a></li>
            <li class="breadcrumb-item">{{ category }}</li>
          </ol>
        </nav>

        <div v-if="depth == 1">
          <Folder v-if="templates.length > 0"
            :categorys="distinctTemplateCategory"
            @clickFolder="category = $event, depth++">
          </Folder>
          <Empty v-else></Empty>
        </div>

        <div v-if="depth == 2">
          <i class="fa fa-backspace fa-2x" @click="depth--"></i>
          <br /><br />
          <SharedFilesListTemplate :templates="categorizedTemplates"></SharedFilesListTemplate>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import fileIO from '@/js/fileIO';
import Folder from '@/components/FolderTemplate';
import SharedFilesListTemplate from '@/components/sharedFiles/SharedFilesListTemplate';

export default {
  data() {
    return {
      templates: [],
      filters: [],
      filteredTemplates: [],
      distinctTemplateCategory: [],
      category: '',
      depth: 1,
      searchLogicOr: false
    }
  },
  mixins: [
    storeUtil, sortUtil, fileIO
  ],
  components: {
    Folder, SharedFilesListTemplate
  },
  computed: {
    categorizedTemplates() {
      return this.templates.filter(row => {
        return row.category == this.category
      });
    }
  },
  created() {
    this.axios.post(`template/queryAll`, {}).then(response => {
      this.templates = response.data;
    });
    this.axios.post(`template/distinct`, {
      field: 'category'
    }).then(response => {
      this.distinctTemplateCategory = response.data;
    }).catch(err => {
      console.log(err);
    });
  },
  methods: {
    updateData(filters) {
      this.filters = filters;
      this.filteredTemplates = filters.length == 0 ? this.templates :
      !this.searchLogicOr ? this.filterKeywordsAnd(this.templates, filters) : this.filterKeywords(this.templates, filters);
    }
  }
}
</script>
