<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>添加采购记录</h3>
        <router-link :to="{ name: 'ItemExcelImport' }" class="btn btn-outline-info" style="float: right;">EXCEL批量导入 <i class="far fa-file-excel"></i></router-link>
      </div>
      <div class="card-body">
        <form>
          <b-row>
            <b-col class="form-group">
              <rlabel label="项目名称"></rlabel>
              <select class="form-control" v-model="item.projectId" required="required">
                <option disabled selected value>-----请选择-----</option>
                <option v-for="project in projects" :key="project._id" :value="project._id">
                  {{ project.customer }} - {{ project.title }}
                </option>
              </select>
              <footer v-show="projects.length  == 0" class="alert alert-warning">
                尚未有可选择的项目名称。请确认你是某项目的项目负责人或技术员，否则你没有权限为此项目添加采购记录。
              </footer>
            </b-col>
            <b-col class="form-group">
              <rlabel label="名称"></rlabel>
              <input type="text" class="form-control" v-model="item.name" required="required">
            </b-col>

          </b-row>
          <b-row>
            <b-col class="form-group">
              <rlabel label="品牌" v-if="!item.purchaser"></rlabel>
              <label v-else>品牌</label>
              <input type="text" class="form-control" v-model="item.brand" v-if="!item.purchaser" required="required">
              <input type="text" class="form-control" v-model="item.brand" v-else>
            </b-col>
            <b-col class="form-group">
              <rlabel label="型号" v-if="!item.purchaser"></rlabel>
              <label v-else>型号</label>
              <input type="text" class="form-control" v-model="item.series" v-if="!item.purchaser" required="required">
              <input type="text" class="form-control" v-model="item.series" v-else>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="form-group">
              <rlabel label="供应商"></rlabel>
              <i class="fa fa-search" @click="$bvModal.show('search-supplier-modal')"></i>
              {{ item.supplier }}
            </b-col>
            <b-col class="form-group" id="dateAlert">
              <rlabel label="到货日期"></rlabel>
              <b-form-datepicker v-model="item.date" locale="zh" calendar-width="450px"></b-form-datepicker>
              <div v-show="showDateAlert" class="alert alert-danger">
                到货日期还未填写
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="form-group">
              <rlabel label="数量"></rlabel>
              <input type="text" class="form-control" v-model="item.quantity" required="required">
            </b-col>
            <b-col class="form-group">
              <rlabel label="单价"></rlabel>
              <input type="text" class="form-control" v-model="item.price">
            </b-col>
          </b-row>

          <div class="form-group">
            <rlabel label="参数"></rlabel>
            <textarea type="text" class="form-control" rows="5" v-model="item.parameters" required="required"/>
          </div>
          <div class="form-group">
            <label><b>备注</b></label>
            <textarea type="text" class="form-control" rows="5" v-model="item.note"/>
          </div>
          <div class="form-group">
            <b-button v-b-modal.info-modal variant="info">保存</b-button>
            <b-button v-b-modal.submit-modal variant="primary">上传</b-button>
          </div>
        </form>

        <b-modal
        id="search-supplier-modal"
        ok-title="确认"
        cancel-title="取消"
        title="搜索供应商"
        @ok="$bvModal.hide('search-supplier-modal')"
        @hidden="$bvModal.hide('search-supplier-modal')">
        <rlabel label="供应商"></rlabel>
        <b-form-select
        v-model="item.supplier"
        :options="supplierList"
        class="mb-3"
        value-field="name"
        text-field="name"
        disabled-field="notEnabled"
        ></b-form-select>
        <br />
        <b-alert show>
          如未在这里搜索到供应商或该供应商为新供应商，请前往<router-link :to="{ name: 'SupplierList' }">供应商列表</router-link>添加供应商信息后再添加采购信息
        </b-alert>
      </b-modal>

      <b-modal
      id="info-modal"
      ok-title="确认"
      cancel-title="取消"
      title="采购详情"
      scrollable
      centered
      @ok="addItem()">
      <span v-html="itemString()"></span>
    </b-modal>

    <b-modal
    id="submit-modal"
    ok-title="确认"
    cancel-title="取消"
    title="采购上传"
    @show="$bvModal.hide('submit-modal')"
    @hidden="$bvModal.hide('submit-modal')"
    @ok="startProcedure = true, addItem(), $bvModal.hide('submit-modal')">
    确定要上传此项采购吗？
  </b-modal>

</div>
</div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import peopleUtil from '@/js/peopleUtil';
import storeUtil from '@/js/storeUtil';
import projectUtil from '@/js/projectUtil';
import translateUtil from '@/js/translateUtil';
import moment from 'moment';

export default {
  data() {
    return {
      item: {},
      showDateAlert: false,
      expectedArrvialDate: '',
      supplierList: [],
      startProcedure: false
    }
  },
  mixins: [
    formatTime, peopleUtil, storeUtil, projectUtil, translateUtil
  ],
  created() {
    this.fetchSupplierList();
    if (this.$route.params.id) {
      this.axios.post(`items/${this.getLocation}/queryAll`, {_id: this.$route.params.id}).then(response => {
        this.item = response.data[0];
      });
    }
  },
  methods: {
    addItem() {
      if (!this.item.date) {
        this.showDateAlert = true;
        document.getElementById("dateAlert").scrollIntoView();
        return;
      }
      this.item.owner = this.getUser.name;

      var url = '';
      if (this.$route.params.id) {
        url = `items/${this.getLocation}/update/${this.$route.params.id}`;
      } else {
        url = `items/${this.getLocation}/add`;
      }

      this.axios.post(url, this.item).then(response => {
        if (this.startProcedure) {
          var itemId = this.$route.params.id ? this.$route.params.id : response.data._id;
          this.updateProcedure(itemId);
        } else {
          alert('采购已保存');
          this.$router.push({name: 'ItemListOngoing'});
        }
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    updateProcedure(itemId) {
      this.axios.post(`items/${this.getLocation}/update/procedure/${itemId}`, {}).then((response) => {
        alert(response.data);
        this.$router.push({name: 'ItemListOngoing'});
      });
    },
    fetchSupplierList() {
      this.axios.post(`suppliers/queryAll`, {}).then(response => {
        this.supplierList = response.data;
      });
    },
    itemString() {
      var str = '';
      for (let [key, value] of Object.entries(this.item)) {
        str += `${this.translateAttribute(key)} - ${value}<br />`;
      }
      return str;
    },
    // canStartProcedure() {
    //   return !(!item.name || !item.brand || !item.series || !item.price ||
    //   !item.quantity || !item.supplier || !item.owner || !item.date ||
    //   !item.parameters);
    // }
  }
}
</script>
