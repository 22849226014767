import jwt from 'jsonwebtoken';
import moment from 'moment';

export default {
  data() {
    return {
      showDateAlert: false
    }
  },
  computed: {
    greeting() {
      var hour = moment().hour();
      if (hour > 6 && hour < 12) {
        return '早上好';
      } else if (hour >= 12 && hour < 18) {
        return '下午好';
      } else if (hour >= 18 || hour <= 6) {
        return '晚上好';
      } else {
        return '您好';
      }
    },
    today() {
      return `${moment().year()}年${moment().month()+1}月${moment().date()}日`;
    }
  },
  methods: {
    getYearMonthDate(date) {
      return !date ? '' : moment(date).format('YYYY-MM-DD');
    },
    getFormattedDate(date) {
      return !date ? '' : moment(date).format('YYYY-MM-DD H:mm');
    },
    getCurrentTime() {
      moment.locale('zh-cn');
      return moment().format('LLLL');
    },
    checkTokenExpired(token) {
      try {
        // var decoded = jwt.verify(token, 'secret');
        jwt.verify(token, 'secret');
      } catch (err) {
        return true;
      }
      return false;
    },
    checkDate(date) {
      if (!date) {
        this.showDateAlert = true;
        document.getElementById('dateAlert').scrollIntoView();
      }
    },
    getFormattedYearMonthDateTime(yearMonthDate, hour, minute) {
      return new Date(yearMonthDate.getFullYear(), yearMonthDate.getMonth(), yearMonthDate.getDate(), hour, minute);
    },
    disableDatesBeforeToday(ymd, date) {
      // Return `true` if the date should be disabled
      // Use for bootstrap-vue datepicker
      return date < moment().add(-1, 'days').toDate();
    },
    disableBeforeOneMonth(ymd, date) {
      var min = moment().add(-1, 'months');
      return date <= min.toDate();
    },
    formatDateTimePicker(date, time) {
      var dateArray = date.split('-');
      var timeArray = time.split(':');
      return new Date(dateArray[0], dateArray[1] - 1, dateArray[2], timeArray[0], timeArray[1]);
    },
    formatDatePicker(date) {
      var dateArray = date.split('-');
      return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    },
    timeDiff(startTime, endTime) {
      moment.locale('zh-cn');
      return moment(endTime).from(startTime, true);
    },
    yearDateMap(dateArray) {
      return dateArray.map(num => {
        if (num.indexOf('-') < 0)
        return num;

        var year = num.split('-')[0];
        var month = num.split('-')[1];
        return `${year}年${month * 1 + 1}月`;
      });
    }
  }
}
