<template>
  <div class="container">
    <div class="card">

      <FileLoading :showGifImg="showGifImg"></FileLoading>

      <div style="overflow-x:auto">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="text-center align-middle" style="min-width: 60px">序号</th>
              <th class="text-center align-middle" style="min-width: 100px">项目甲方</th>
              <th class="text-center align-middle" style="min-width: 100px">项目名称</th>
              <th class="text-center align-middle" style="min-width: 100px">合同名称</th>
              <th class="text-center align-middle" style="min-width: 80px">负责人</th>
              <th class="text-center align-middle" style="min-width: 100px;">
                <a v-if="category == '采购合同'">应付金额</a>
                <a v-else-if="category == '销售合同'">应收金额</a>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(contract, index) in contracts" :key="contract._id">
              <td class="text-center align-middle"><b>{{ index + 1 }}</b></td>


              <td v-if="projectCustomerCount[contract.projectCustomer] == 1" class="text-center align-middle">
                {{ contract.projectCustomer }}
              </td>
              <td v-else-if="index == 0 || (index - 1 >= 0 && contracts[index - 1].projectCustomer != contract.projectCustomer)"
                class="text-center align-middle"
                :rowspan="projectCustomerCount[contract.projectCustomer]">
                {{ contract.projectCustomer }}
              </td>


              <td class="text-center align-middle">{{ contract.projectTitle }}</td>
              <td class="text-center align-middle">
                <a v-if="!contract.stampedFileId && !contract.stampedFilename" href="#" @click="viewFile(contract.fileId, contract.filename)">
                  {{ contract.filename }}
                </a>
                <a v-else href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">
                  {{ contract.stampedFilename }}
                </a>
              </td>
              <td class="text-center align-middle">{{ contract.owner }}</td>
              <td class="text-center align-middle">
                {{ contract.dueAmount }}
              </td>

              <td class="text-center align-middle" v-if="contract.procedureNote != null && contract.procedureNote != ''">
                <ProcedureNote :id="contract._id" :note="contract.procedureNote"></ProcedureNote>
              </td>
              <td v-else></td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-center align-middle">
                <b>{{ getTotal(contracts, 'dueAmount') }}</b>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import fileIO from '@/js/fileIO';
import sortUtil from '@/js/sortUtil';
import accountingUtil from '@/js/accountingUtil';

export default {
  data() {
    return {
      currentSort: 'projectCustomer',
      pageSize: this.contracts.length
    }
  },
  props: [
    'contracts'
  ],
  computed: {
    projectCustomerCount() {
      var count = new Object();
      for (var contract of this.contracts) {
        count[contract.projectCustomer] = !count[contract.projectCustomer] ? 1 : count[contract.projectCustomer] + 1;
      }
      return count;
    },
    category() {
      if (this.contracts.length > 0) {
        return this.contracts[0].category;
      }
    }
  },
  mixins: [
    fileIO, sortUtil, accountingUtil
  ]
}
</script>
