<template>
  <div class="card-body">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
      <p class="form-check-label">模糊查询</p>
    </div>
    <Search @updateData="updateData"></Search>

    <br />
    <p><b v-show="items.length > 0">共{{ items.length }}条采购记录</b></p>
    <br />

    <rise-loader :loading="loading" class="center"></rise-loader>

    <div v-if="!loading && sortedItems.length > 0" style='overflow-x:auto'>
      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th class="text-center align-middle" style="min-width: 60px">序号</th>
            <Header header="项目名称" v-show="columns.includes('projectTitle')"></Header>
            <Header header="名称" @sort="sort('name')"></Header>
            <Header header="品牌" @sort="sort('brand')"></Header>
            <Header header="型号" @sort="sort('series')"></Header>
            <Header header="单价" @sort="sort('price')"></Header>
            <Header header="数量" @sort="sort('quantity')"></Header>
            <Header header="供应商" @sort="sort('supplier')"></Header>
            <Header header="采购责任人" @sort="sort('owner')"></Header>
            <Header header="审批进度" @sort="sort('procedure')" v-show="columns.includes('procedure')"></Header>
            <th style="min-width: 60px"></th>
            <th style="min-width: 60px" v-show="columns.includes('editButton')"></th>
            <th style="min-width: 60px" v-show="columns.includes('comparisonButton')"></th>
            <th style="min-width: 60px" v-if="authLevel(2)" v-show="columns.includes('operationButton')"></th>
            <!-- <th style="min-width: 60px"></th> -->
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in sortedItems" :key="item._id" :id="item._id" ref="items">
            <th class="text-center align-middle"><b>{{ index + 1 }}</b></th>
            <td class="text-center align-middle" v-show="columns.includes('projectTitle')">{{ item.projectTitle }}</td>
            <td class="text-center align-middle">{{ item.name }}</td>
            <td class="text-center align-middle">{{ item.brand }}</td>
            <td class="text-center align-middle">{{ item.series }}</td>
            <td class="text-center align-middle">{{ item.price }}</td>
            <td class="text-center align-middle">{{ item.quantity }}</td>
            <td class="text-center align-middle">{{ item.supplier }}</td>
            <td class="text-center align-middle">{{ item.owner }}</td>
            <td class="text-center align-middle" v-show="columns.includes('procedure')">
              {{ item.procedure }}
              <WhoIsWho
              :id="item._id"
              :role="item.procedure"
              v-if="item.procedure != null && item.procedure != '完成' && item.procedure != item.owner && item.procedure != item.purchaser">
            </WhoIsWho>
            </td>

            <td class="text-center align-middle">
              <router-link :to="{ name: 'ItemDetails', params: {id: item._id} }" class="btn btn-success btn-sm">
                详情
              </router-link>
            </td>

            <td class="text-center align-middle" v-show="columns.includes('editButton')">
              <router-link
              :to="{name: 'ItemForm', params: { id: item._id }}" class="btn btn-primary btn-sm"
              v-if="item.isEditable">
              编辑
            </router-link>
            <button
            class="btn btn-primary btn-sm" v-else-if="isCallbackable(item)"
            @click="recallItem = item, $bvModal.show('recall-item-procedure')">
            撤回
          </button>
        </td>

        <td class="text-center align-middle" v-show="columns.includes('comparisonButton')">
          <router-link :to="{ name: 'ItemComparison', params: { id: item._id } }" class="btn btn-warning btn-sm">
            比价
          </router-link>
        </td>

        <td class="align-middle" v-if="authLevel(2)" v-show="columns.includes('operationButton')">
          <DropdownOperations :item="item"></DropdownOperations>
        </td>

        <!-- <td v-if="item.procedureTimestamp != null" class="align-middle">
          <TimelineHover :timelineId="item._id + 'Timeline'" :timestamps="item.procedureTimestamp"></TimelineHover>
        </td>
        <td v-else></td> -->
      </tr>
    </tbody>
  </table>

  <Pagination
  :array="items"
  :currentPage=currentPage
  :pageSize="pageSize"
  @setCurrentPage="setCurrentPage"
  @incrementCurrentPage="currentPage++"
  @minusCurrentPage="currentPage--">
</Pagination>
</div>

<Empty v-else-if="!loading && items.length == 0"></Empty>

<b-modal
id="recall-item-procedure"
ok-title="确认"
cancel-title="取消"
title="撤回采购审批"
@show="$bvModal.hide('recall-item-procedure')"
@hidden="$bvModal.hide('recall-item-procedure')"
@ok="recallItemProcedure(recallItem), $bvModal.hide('recall-item-procedure')">
  确定要撤回采购审批吗？确认后可重新编辑采购上传
</b-modal>

</div>
</template>

<script>
import ItemListUtil from './ItemListUtil';
import DropdownOperations from './DropdownOperations';

export default {
  data() {
    return {
      recallItem: {}
    }
  },
  props: [
    'query',
    'columns',
    'allData'
  ],
  mixins: [
    ItemListUtil
  ],
  components: {
    DropdownOperations
  },
  computed: {
    url() {
      return this.allData ? `items/${this.getLocation}/queryAllData` : `items/${this.getLocation}/queryAll`;
    }
  },
  methods: {
    recallItemProcedure(recallItem) {
      recallItem.procedure = recallItem.owner;
      recallItem.managerConfirmation = false;
      this.updateItem(recallItem, '已成功撤回采购审批，请编辑后重新上传');
    },
    updateItem(item, msg) {
      this.axios.post(`items/${this.getLocation}/update/${item._id}`, item).then(response => {
        alert(msg);
        this.$forceUpdate();
      }).catch(err => {
        alert(err.response.data);
      });
    },
  }
}
</script>
