import JSZip from 'jszip';
import FileSaver from 'file-saver';
import storeUtil from './storeUtil.js';
import XLSX from 'xlsx';

export default {
  data() {
    return {
      files: [],
      showGifImg: false,
      similarFilenames: [],
      showCheckDuplicatedFilenameMsg: false,
      ifCheckDuplicatedFilename: true,
      viewFileAttempt: 0
    }
  },
  mixins: [
    storeUtil
  ],
  methods: {
    xlsxExport(tableId, filename) {
      // export json to Worksheet of Excel
      var tbl = document.getElementById(tableId);

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.table_to_book(tbl);

      // export Excel file
      XLSX.writeFile(wb, `${filename}.xlsx`);
    },
    async onFileChange(e) {
      this.similarFilenames = [];
      this.showCheckDuplicatedFilenameMsg = true;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      } else {
        if (this.ifCheckDuplicatedFilename) {
          for (var i = 0; i < files.length; i++) {
            var similarFilenames = await this.checkDuplicatedFilename(files[i].name);
            if (similarFilenames.length > 0) {
              this.similarFilenames = similarFilenames;
              this.showCheckDuplicatedFilenameMsg = false;
              return;
            }
          }
        }
        this.files = files;
        this.showCheckDuplicatedFilenameMsg = false;
      }
    },
    onFileChangeWithoutCheck(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      } else {
        this.files = files;
      }
    },
    handleFiles(addModelToDB, supportMultiple) {
      if (this.files.length == 0) {
        alert('请上传文件');
        return;
      }
      if (!supportMultiple && this.files.length > 1) {
        alert('请分别上传各文件');
        return;
      }

      for (var i = 0; i < this.files.length; i++) {
        this.uploadFile(this.files[i], addModelToDB);
      }
    },
    uploadFile(file, addModelToDB) {
      if(!file) {
        return;
      }

      let formData = new FormData();
      let header = {
        'Content-Type': 'multipart/form-data'
      };
      formData.append('file', file);
      this.axios.post(`files/upload`, formData, {
        headers: header
      }).then((response) => {
        addModelToDB(response.data.id, response.data.filename);
      }).catch(err => {
        console.log(err);
        this.$router.push({name: 'ErrorPage'});
      });
    },
    viewFile(id, filename) {
      this.showGifImg = true;
      this.axios.get(`files/view/${id}`, {
        responseType: 'blob'
      }).then(response => {
        console.log('viewfile response is - ');
        console.log(response);
        try {
          this.viewFileAttempt = 0;
          this.previewDoc(response, filename);
        } catch(err) {
          try {
            this.viewFileAttempt = 0;
            FileSaver.saveAs(response.data, filename);
            this.showGifImg = false;
          } catch(err) {
            this.anotherAttempt(id, filename);
          }
        }
      }).catch(err => {
        console.log(err);
        this.anotherAttempt(id, filename);
      });
    },
    async downloadZip(tickedFiles) {
      var zip = new JSZip();
      for (var i = 0; i < tickedFiles.length; i++) {
        var fileId = tickedFiles[i].fileId;
        var filename = tickedFiles[i].filename;
        var response = await this.axios.get(`files/view/${fileId}`, { responseType: 'blob' });
        var blob = new Blob([response.data], {type: response.data.type});
        zip.file(filename, blob);
      }
      zip.generateAsync({ type: 'blob' }).then((content) => {
        if (content) {
          FileSaver.saveAs(content, tickedFiles[0].filename + '等附件.zip');
        }
      });
    },
    anotherAttempt(id, filename) {
      this.viewFileAttempt += 1;
      if (this.viewFileAttempt <= 10) {
        this.viewFile(id, filename);
      } else {
        this.showGifImg = false;
        this.viewFileAttempt = 0;
        alert('暂时无法预览此文件');
      }
    },
    viewContractByContractId(id) {
      this.showGifImg = true;
      this.axios.get(`contracts/${this.getLocation}/view/contractId/${id}`, {
        responseType: 'blob'
      }).then((response) => {
        try {
          this.previewDoc(response, '文件.pdf');
        } catch(err) {
          try {
            FileSaver.saveAs(response.data);
          } catch (err) {
            this.viewFileAttempt = 0;
            alert('暂时无法预览此文件');
          } finally {
            this.showGifImg = false;
          }
        }
      }).catch(() => {
        this.showGifImg = false;
        alert('暂时无法预览此文件');
      });
    },
    previewDoc(response, filename) {
      try {
        var blob = new Blob([response.data], {type: response.data.type});
        this.preview(blob, filename);
      } catch (err) {
        this.showGifImg = false;
        alert('暂时无法预览此文件');
      }
    },
    preview(objectToCreateURL) {
      var fileURL = URL.createObjectURL(objectToCreateURL);
      var downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.href = fileURL;
      if (this.isSafari()) {
        downloadLink.download = filename;
      }
      downloadLink.click();
      this.showGifImg = false;
    },
    viewTemplate(templateId) {
      this.axios.post(`template/query`, {
        _id: templateId
      }).then(response => {
        var fileId = response.data.fileId;
        var filename = response.data.filename;
        this.viewFile(fileId, filename);
      });
    },
    deleteFile(fileId, filename) {
      this.axios.post(`files/delete`, {
        fileId: fileId,
        filename: filename
      }).then(response => {
        alert(response.data);
        this.$forceUpdate();
      });
    },
    isSafari() {
      return /constructor/i.test(window.HTMLElement) ||
      (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] ||
      (typeof safari !== 'undefined' && safari.pushNotification));
    },
    isFirefox() {
      return typeof InstallTrigger !== 'undefined';
    },
    isChrome() {
      return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    },
    async checkDuplicatedFilename(name) {
      var response = await this.axios.get(`files/duplicate/filename/${name}`);
      return response.data;
    }
  }
}
