<template>
  <b-modal id="infoModal">
  <table class="table table-hover text-center">
    <thead>
      <tr>
        <th class="align-middle">奖金金额</th>
        <th class="align-middle">审批</th>
        <th class="align-middle">审批备注</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-for="bonusPayment in bonusPayments" :key="bonusPayment._id">
      <tr>
        <td class="align-middle">{{ bonusPayment.amount }}</td>
        <td class="align-middle">{{ bonusPayment.procedure }}</td>
        <td class="align-middle">{{ bonusPayment.procedureNote }}</td>
        <td class="align-middle">
          <TimelineHover :timelineId="bonusPayment._id" :timestamps="bonusPayment.procedureTimestamp"></TimelineHover>
        </td>
        </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      bonusPayments: []
    }
  },
  props: [
    'bonus'
  ],
  watch: {
    bonus(newB, oldB) {
      if (!newB._id) {
        return;
      }

      this.axios.post(`bonusPayment/queryAll/timeline`, {
        bonusId: newB._id
      }).then(response => {
        this.bonusPayments = response.data;
      });
    }
  }
}
</script>
