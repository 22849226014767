<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>保证金管理</h3>
      </div>
      <div class="card-body">
        <rise-loader :loading="loading" class="text-center align-middle"></rise-loader>

        <div class="form-check">
          <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
          <p class="form-check-label">模糊查询</p>
        </div>
        <Search @updateData='updateData'></Search>
        <br />
        <p><b v-show="reimbursements.length > 0">共{{ reimbursements.length }}条保证金费用</b></p>
        <br />

        <RTemp v-if="!loading"
        :list="['projectTitle', 'estimatedAmount', 'amount', 'filename', 'method', 'completeTime', 'returnDate', 'returnButton']"
        :reimbursements="reimbursements"
        :pagination="true"
        @deleteReimbursement="">
        </RTemp>
    </div>
  </div>
</div>
</template>

<script>
import ReimbursementListUtil from './ReimbursementListUtil';
import moment from 'moment';

export default {
  mixins: [
    ReimbursementListUtil
  ],
  methods: {
    fetchReimbursements() {
      var query = {
        category: { $regex: '.*金' },
        $or :
        [
          { amount: { $gt: 0 } },
          { amount: { $exists: false } }
        ]
      };
      this.fetchReimbursementsWithQuery(query);
    },
    clickHandler(event) {
      this.enableReturnDateEdit = !this.enableReturnDateEdit;
    }
  }
}
</script>
