<template>
<div>
  <canvas id="bar" width="100%"></canvas>
  <br /><br /><br />
  <div class="card-body" style='overflow-x:auto'>
    <CTemp :contractDisplay="contractDisplay"></CTemp>
    <RTemp
    :list="['projectTitle', 'estimatedAmount', 'amount', 'filename', 'method', 'completeTime', 'returnDate']"
    :reimbursements="reimbursementDisplay"
    :pagination="false"
    :displayEmpty="false"
    @deleteReimbursement="">
  </RTemp>
</div>
</div>
</template>

<script>
import Chart from 'chart.js';
import accountingUtil from '@/js/accountingUtil';
import queryUtil from '@/js/queryUtil';
import sortUtil from '@/js/sortUtil';
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';
import currentFinanceBarUtil from './currentFinanceBarUtil';
import moment from 'moment';

export default {
  data() {
    return {
      personalFinance: new Object(),
      contractDisplay: [],
      reimbursementDisplay: [],
    }
  },
  created() {
    this.createBar();
  },
  mixins: [
    accountingUtil, queryUtil, sortUtil, formatTime, currentFinanceBarUtil, storeUtil
  ],
  methods: {
    generateToReceive() {
      return new Promise((resolve, reject) => {
        this.axios.post(`contracts/${this.getLocation}/aggregate`, this.contractAggregate('销售合同')).then(response => {
          for (var row of response.data) {
            if (row.due > 0) {
              this.personalFinance[row._id] = !this.personalFinance[row._id] ? new Object() : this.personalFinance[row._id];
              this.personalFinance[row._id]['toReceive'] = row.due;
            }
          }
          resolve();
        });
      });
    },
    generateToPay() {
      return new Promise((resolve, reject) => {
        this.axios.post(`contracts/${this.getLocation}/aggregate`, this.contractAggregate('采购合同')).then(response => {
          for (var row of response.data) {
            if (row.due > 0) {
              this.personalFinance[row._id] = !this.personalFinance[row._id] ? new Object() : this.personalFinance[row._id];
              this.personalFinance[row._id]['toPay'] = row.due;
            }
          }
          resolve();
        });
      });
    },
    generateMargin() {
      return new Promise((resolve, reject) => {
        this.axios.post(`reimbursements/${this.getLocation}/aggregate`, [
          { $match: this.marginQuery },
          {
            $group: {
              _id: '$owner',
              totalMargin: { $sum: '$estimatedAmount' }

            }
          }
        ]).then(aggregateResponse => {
          for (var row of aggregateResponse.data) {
            if (row.totalMargin > 0) {
              this.personalFinance[row._id] = !this.personalFinance[row._id] ? new Object() : this.personalFinance[row._id];
              this.personalFinance[row._id]['margin'] = row.totalMargin;
            }
          }

          this.axios.post(`overall/${this.getLocation}/reimbursements/project/title`, [
            { $match: this.marginQuery }
          ]).then(response => {
            resolve(response.data);
          });

        });
      });
    },
    generateMonthMargin() {
      return new Promise((resolve, reject) => {
        this.axios.post(`reimbursements/${this.getLocation}/queryAll`, this.marginQuery).then(response => {
          for (var r of response.data) {
            if (moment(r.returnDate).isBefore(moment().endOf('month'))) {
              this.setFinance(this.personalFinance, r.owner, '本月保证金', r.estimatedAmount);
            }
          }

          resolve();
        });
      });
    },
    dueMargin() {
      return new Promise((resolve, reject) => {
        this.axios.post(`reimbursements/${this.getLocation}/queryAll`, this.marginQuery).then(response => {
          for (var r of response.data) {
            if (moment(r.returnDate).isBefore(moment())) {
              this.setFinance(this.personalFinance, r.owner, '到期保证金', r.estimatedAmount);
            }
          }
          resolve();
        });
      });
    },
    createBar() {
      Promise.all([
        this.generateToReceive(),
        this.dueReceive(),
        this.generateToPay(),
        this.duePay(),
        this.generateMargin(),
        this.generateMonthMargin(),
        this.dueMargin()
      ]).then(results => {
        console.log(this.personalFinance);
        var labels = Object.keys(this.personalFinance).sort((a,b) => {
          return a.localeCompare(b, 'zh-CN');
        });
        var toReceive = [];
        var dueReceive = [];
        var monthDueReceive = [];
        var toPay = [];
        var duePay = [];
        var monthDuePay = [];
        var margin = [];
        var dueMargin = [];
        var monthMargin = [];

        for (var person of labels) {
          toReceive.push((this.personalFinance[person]['toReceive'] / 10000).toFixed(4));
          dueReceive.push((this.personalFinance[person]['到期应收'] / 10000).toFixed(4));
          monthDueReceive.push((this.personalFinance[person]['本月应收'] / 10000).toFixed(4));
          toPay.push((this.personalFinance[person]['toPay'] / 10000).toFixed(4));
          duePay.push((this.personalFinance[person]['到期应付'] / 10000).toFixed(4));
          monthDuePay.push((this.personalFinance[person]['本月应付'] / 10000).toFixed(4));
          margin.push((this.personalFinance[person]['margin'] / 10000).toFixed(4));
          dueMargin.push(this.personalFinance[person]['到期保证金'] / 10000).toFixed(4);
          monthMargin.push(this.personalFinance[person]['本月保证金'] / 10000).toFixed(4);
        }

        var _this = this;
        var ctx = document.getElementById('bar');
        var bar = new Chart(ctx, {
          type: 'bar',
          data: {
            datasets: this.datasets(toReceive, dueReceive, monthDueReceive, toPay, duePay, monthDuePay, margin, dueMargin, monthMargin),
            labels: labels
          },
          options: {
            animation: this.animation,
            scales: {
              xAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: '人员'
                },
                gridLines: {
                  offset: false
                }
              }],
              yAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: '金额(万元)'
                }
              }]
            },
            onClick: function(event) {
              var element = bar.getElementAtEvent(event)[0];
              if (!element)
              return;

              _this.contractDisplay = [];
              _this.reimbursementDisplay = [];

              switch(element._datasetIndex) {
                case 0:
                break;
                case 1:
                case 2:
                // When clicking 应收
                _this.contractDisplay = results[1].filter(row => {
                  return row.owner == element._model.label && row[element._model.datasetLabel] != null;
                });
                break;
                case 3:
                break;
                case 4:
                case 5:
                // When clicking 应付
                _this.contractDisplay = results[3].filter(row => {
                  return row.owner == element._model.label && row[element._model.datasetLabel] != null;
                });
                break;
                case 6:
                _this.reimbursementDisplay = results[4].filter(row => {
                  return row.owner == element._model.label;
                });
                break;
                case 7:
                _this.reimbursementDisplay = results[4].filter(row => {
                  return row.owner == element._model.label && moment(row.returnDate).isBefore(moment());
                });
                break;
                case 8:
                _this.reimbursementDisplay = results[4].filter(row => {
                  return row.owner == element._model.label && moment(row.returnDate).isBefore(moment().endOf('month'));
                });
                break;
              }

            }
          }
        });
      });
    },
    dueReceive() {
      return new Promise((resolve, reject) => {
        this.axios.post(`overall/${this.getLocation}/contracts/project/title`, this.contractQuery('销售合同')).then(async response => {
          var contracts = response.data;
          var relatedContracts = [];
          for (var contract of contracts) {
            contract = this.dueCalculation(contract, '到期应收', moment());
            contract = this.dueCalculation(contract, '本月应收', moment().endOf('month'));

            if (contract['到期应收'] != null || contract['本月应收'] != null) {
              relatedContracts.push(contract);
            }
          }
          resolve(relatedContracts);
        });
      });
    },
    duePay() {
      return new Promise((resolve, reject) => {
        this.axios.post(`overall/${this.getLocation}/contracts/project/title`, this.contractQuery('采购合同')).then(async response => {
          var contracts = response.data;
          var relatedContracts = [];
          for (var contract of contracts) {
            contract = this.dueCalculation(contract, '到期应付', moment());
            contract = this.dueCalculation(contract, '本月应付', moment().endOf('month'));

            if (contract['到期应付'] != null || contract['本月应付'] != null) {
              relatedContracts.push(contract);
            }
          }
          resolve(relatedContracts);
        });
      });
    },
    dueCalculation(contract, key, time) {
      var percentage = this.expectedPaymentPercentage(contract, time) - this.actualPaymentPercentage(contract);
      if (percentage > 0) {
        contract[key] = percentage;
        this.setFinance(this.personalFinance, contract.owner, key, percentage / 100 * contract.amount);
      }
      return contract;
    },
    setFinance(object, field, key, amount) {
      object[field] = !object[field] ? new Object() : object[field];
      object[field][key] = !object[field][key] ? 0 : object[field][key];
      object[field][key] += amount;
    },
  }
}
</script>
