<template>
  <a>
    <b-modal
    id="change-item-owner"
    ok-title="确认"
    cancel-title="取消"
    title="修改采购负责人"
    @show="$bvModal.hide('change-item-owner')"
    @hidden="$bvModal.hide('change-item-owner')"
    @ok="updateItemOwner(), $bvModal.hide('change-item-owner')"
    >

    <div class="form-group">
      <select class="form-control" v-model="updatedOwner" required="required">
        <option disabled selected value>-----请选择-----</option>
        <option v-for="person in item.projectPeople" :key="person._id" :value="person.name">
          {{ person.name }}
        </option>
      </select>
    </div>

    </b-modal>

    <b-modal
    :id="deleteModalId"
    ok-title="确认"
    cancel-title="取消"
    title="删除采购记录"
    @show="$bvModal.hide(deleteModalId)"
    @hidden="$bvModal.hide(deleteModalId)"
    @ok="deleteItem(), $bvModal.hide(deleteModalId)">
        确定要删除此项采购记录吗？
    </b-modal>

  <b-button-toolbar v-if="authLevel(2)">
    <b-dropdown variant="info" size="sm" text="操作" dropleft>
      <b-dropdown-item @click="fetchProjectPeople()">
        修改责任人
      </b-dropdown-item>
      <b-dropdown-item :to="{ name: 'ItemComparison', params: { id: item._id } }">
        比价
      </b-dropdown-item>
      <b-dropdown-item @click="moveToHistory()">
        归为历史记录
      </b-dropdown-item>
      <b-dropdown-item @click="$bvModal.show(deleteModalId)">
        删除
      </b-dropdown-item>
    </b-dropdown>
  </b-button-toolbar>
</a>
</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  props: [
    'item'
  ],
  data() {
    return {
      updatedOwner: '',
      deleteModalId: 'delete-item-' + this.item._id
    }
  },
  mixins: [
    storeUtil
  ],
  methods: {
    fetchProjectPeople() {
      this.axios.post(`projects/${this.getLocation}/query`, { _id: this.item.projectId }).then(response => {
        this.item.projectPeople = response.data.people;
        this.$forceUpdate();
        this.$bvModal.show('change-item-owner');
      });
    },
    updateItemOwner() {
      this.item.owner = this.updatedOwner;
      this.updateItem('成功修改责任人');
      this.updatedOwner = '';
    },
    moveToHistory() {
      this.item.procedure = null;
      this.item.note += `采购负责人为${this.item.owner}，原归属于${this.item.projectTitle}项目`;
      this.item.projectId = null;
      this.item.owner = null;
      this.updateItem('已将采购归到历史记录');
    },
    updateItem(msg) {
      this.axios.post(`items/${this.getLocation}/update/${this.item._id}`, this.item).then(response => {
        alert(msg);
        this.$forceUpdate();
      }).catch(err => {
        alert(err.response.data);
      });
    },
    deleteItem() {
      this.axios.get(`items/${this.getLocation}/delete/${this.item._id}`).then(response => {
        alert(response.data);
        this.$forceUpdate();
      }).catch(() => {
        this.$router.push({name:'ErrorPage'});
      });
    }
  }
}
</script>
