import moment from 'moment';

export default {
  data() {
    return {
      monthlyFinance: new Object(),
      contractTimelineDisplay: [],
      reimbursementTimelineDisplay: [],
      personalTimelineBar: null,
      animation: {
        animateScale: true,
        animateRotate: true
      },
      scales: {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: '时间'
          },
          stacked: true
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: '金额(万元)'
          },
          stacked: true
        }]
      }
    }
  },
  methods: {
    monthlyDueReceive(query) {
      return new Promise((resolve, reject) => {
        this.axios.post(`overall/${this.getLocation}/contracts/project/title`, query).then(response => {
          var contractDisplay = this.monthlyDue(response, 'toReceive');
          resolve(contractDisplay);
        });
      });
    },
    monthlyDuePay(query) {
      return new Promise((resolve, reject) => {
        this.axios.post(`overall/${this.getLocation}/contracts/project/title`, query).then(response => {
          var contractDisplay = this.monthlyDue(response, 'toPay');
          resolve(contractDisplay);
        });
      });
    },
    monthlyDue(response, key) {
      var contractTimelineDisplay = new Object();

      var year = moment().year();
      var m = moment().month();

      for (var contract of response.data) {
        var percentage = this.expectedPaymentPercentage(contract) - this.actualPaymentPercentage(contract);
        if (percentage > 0) {
          this.setFinance(this.monthlyFinance, '历史', key, percentage / 100 * contract.amount);
          this.setContract(contractTimelineDisplay, '历史', contract);
        }
      }

      while (year <= moment().year() + 5) {
        while(m < 12) {
          var startOfMonth = moment().set({'year': year, 'month': m, 'date': 1});
          var endOfMonth = moment().set({'year': year, 'month': m, 'date': 1}).endOf('month');
          for (var contract of response.data) {
            var percentage = this.expectedMonthlyPaymentPercentage(contract, startOfMonth, endOfMonth);
            if (percentage > 0) {
              this.setFinance(this.monthlyFinance, `${year}-${m}`, key, percentage / 100 * contract.amount);
              this.setContract(contractTimelineDisplay, `${year}年${m + 1}月`, contract);
            }
          }
          m++;
        }
        m = 0;
        year++;
      }

      return contractTimelineDisplay;
    },
    monthlyDueReturn(query) {
      return new Promise((resolve, reject) => {
        this.axios.post(`overall/${this.getLocation}/reimbursements/project/title`, [
          { $match: query }
        ]).then(response => {
          var reimbursementTimelineDisplay = new Object();
          for (var r of response.data) {
            this.setFinance(this.monthlyFinance, `${moment(r.returnDate).year()}-${moment(r.returnDate).month()}`, 'toReturn', r.estimatedAmount);
            this.setContract(reimbursementTimelineDisplay, `${moment(r.returnDate).year()}年${moment(r.returnDate).month() + 1}月`, r);
          }
          resolve(reimbursementTimelineDisplay);
        });
      });
    },
    setFinance(object, field, key, amount) {
      object[field] = !object[field] ? new Object() : object[field];
      object[field][key] = !object[field][key] ? 0 : object[field][key];
      object[field][key] += amount;
    },
    setContract(object, field, element) {
      object[field] = !object[field] ? [] : object[field];
      object[field].push(element);
    },
    createTimelineBar(results, timelineBarId) {
      var labels = this.sortDateWithHistory(Object.keys(this.monthlyFinance));
      var dateLabels = this.yearDateMap(this.sortDateWithHistory(Object.keys(this.monthlyFinance)));

      var toReceive = [];
      var toPay = [];
      var toReturn = [];
      for (var month of labels) {
        toReceive.push((this.monthlyFinance[month]['toReceive'] / 10000).toFixed(4));
        toPay.push((this.monthlyFinance[month]['toPay'] / 10000 * -1).toFixed(4));
        toReturn.push((this.monthlyFinance[month]['toReturn'] / 10000).toFixed(4));
      }

      var _this = this;
      var ctx = document.getElementById(timelineBarId);
      this.personalTimelineBar = new Chart(ctx, {
        data: {
          type: 'bar',
          labels: dateLabels,
          datasets: [
            {
              type: 'bar',
              label: '应收',
              data: toReceive,
              backgroundColor: "rgb(255, 99, 132, 0.2)",
              borderColor: "rgb(255, 99, 132)",
              borderWidth: 1
            },
            {
              type: 'bar',
              label: '应付',
              data: toPay,
              backgroundColor: "rgb(75, 192, 192, 0.2)",
              borderColor: "rgb(75, 192, 192)",
              borderWidth: 1
            },
            {
              type: 'bar',
              label: '保证金',
              data: toReturn,
              backgroundColor: "rgb(255, 205, 86, 0.2)",
              borderColor: "rgb(255, 205, 86)",
              borderWidth: 1
            }
          ]
        },
        options: {
          animation: this.animation,
          scales: this.scales,
          onClick: function(event) {
            var element = _this.personalTimelineBar.getElementAtEvent(event)[0];
            if (!element)
            return;

            switch(element._datasetIndex) {
              case 0:
              _this.contractTimelineDisplay = results[0][element._model.label];
              break;
              case 1:
              _this.contractTimelineDisplay = results[1][element._model.label];
              break;
              case 2:
              _this.reimbursementTimelineDisplay = results[2][element._model.label];
            }
          }
        }
      });
    }
  }
}
