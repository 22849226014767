<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>上传合同</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="uploadContract">
          <div class="form-group">
            <rlabel label="合同类别"></rlabel>
            <select class="form-control" v-model="contract.category" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option>中标通知书</option>
              <option>销售合同</option>
              <option>采购合同</option>
            </select>
          </div>

          <div class="card" v-if="contract.category == '采购合同'">
            <div class="card-body form-group">
              <rlabel label="项目名称"></rlabel>
              <select class="form-control" v-model="projectTitle" required="required" @change="fetchItems()">
                <option disabled selected value>-----请选择-----</option>
                <option v-for="project in projects" :key="project._id" :value="project.title">
                  {{ project.customer }} - {{ project.title }}
                </option>
              </select>
            </div>
            <div class="card-body" v-if="items && items.length > 0">
              <table class="table table-hover table-sm">
                <thead>
                  <tr class="text-center">
                    <th></th>
                    <th class="align-middle">名称</th>
                    <th class="align-middle">品牌</th>
                    <th class="align-middle">型号</th>
                    <th class="align-middle">单价</th>
                    <th class="align-middle">数量</th>
                    <th class="align-middle">供应商</th>
                    <th class="align-middle">采购责任人</th>
                    <th class="align-middle">采购员</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in items" :key="item._id" class="text-center">
                    <td class="align-middle"><input type="checkbox" :value="item" v-model="itemContractLink"></td>
                    <td class="align-middle">{{ item.name }}</td>
                    <td class="align-middle">{{ item.brand }}</td>
                    <td class="align-middle">{{ item.series }}</td>
                    <td class="align-middle">{{ item.price }}</td>
                    <td class="align-middle">{{ item.quantity }}</td>
                    <td class="align-middle">{{ item.supplier }}</td>
                    <td class="align-middle">{{ item.owner }}</td>
                    <td class="align-middle">{{ item.purchaser }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <FileUploadBox
          label="上传合同"
          :notes="generateFileUploadBoxNotes()"
          @setFiles="files = $event"
          :required="true"
          :supportMultiFiles="false"
          :filenameCheck="true">
          </FileUploadBox>

          <br /><br />

          <div class="form-group">
            <rlabel label="合同金额"></rlabel>
            <span v-if="contract.category == '采购合同'"><br />{{ contractAmount }}</span>
            <input v-else type="text" class="form-control" v-model="contract.amount" required="required">
          </div>
          <div class="form-group">
            <rlabel label="质保期"></rlabel>
            <div class="input-group">
              <input type="number" class="form-control" v-model="contract.warranty" placeholder="仅支持阿拉伯数字" required="required">
              <div class="input-group-append"><span class="btn">年</span></div>
            </div>
          </div>
          <div class="form-group">
            <rlabel label="付款方式"></rlabel>
            <div class="input-group">
              <input type="text" class="form-control" v-model="contract.paymentMethod" required="required">
            </div>
          </div>
          <div v-if="checkContractPaymentMethodValidity(contract.paymentMethod)" class="alert alert-danger">
            如一次性支付全款，请输入100；如三七付款，请输入30-70；如三六一付款，请输入30-60-10；以此类推。
          </div>
          <div class="form-group" v-if="isNotNull(contract.paymentMethod)">
            <rlabel label="付款时间节点"></rlabel>
            <div v-for="index in paymentScheduleCount">
              <b-form-datepicker v-model="contract.paymentSchedule.schedule[index]" locale="zh" calendar-width="450px" required="required"></b-form-datepicker>
            </div>
          </div>
          <div class="form-group" v-if="isNotNull(contract.paymentMethod)">
            <label>付款时间节点说明</label>
            <div class="input-group">
              <input type="text" class="form-control" v-model="contract.paymentSchedule.note">
            </div>
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
          </div>
          <Uploading :waiting="waiting"></Uploading>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';
import projectUtil from '@/js/projectUtil';
import checkFormValidity from '@/js/checkFormValidity';

export default {
  data() {
    return {
      projects: [],
      projectTitle: '',
      items: [],
      itemContractLink: [],
      contract: {
        paymentSchedule: {}
      }
    }
  },
  mixins: [
    fileIO, storeUtil, loaderUtil, projectUtil, checkFormValidity
  ],
  created() {
    // Fetched projects in projectUtil
    if (this.$route.params.id)
    this.getExistingContract();
  },
  computed : {
    contractAmount() {
      var amount = 0;
      for (var i = 0; i < this.itemContractLink.length; i++) {
        var item = this.itemContractLink[i];
        amount += item.price * item.quantity;
      }
      return amount.toFixed(2);
    },
    paymentScheduleCount() {
      if (!this.contract.paymentMethod)
      return [];

      var length = this.contract.paymentMethod.split('-').length;
      var array = [];
      var index = 0;
      while (index < length) {
        if (index == 0) {
          this.contract.paymentSchedule.schedule = [];
        }
        array.push(index);
        index++;
      }
      return array;
    }
  },
  methods: {
    getExistingContract() {
      this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
        _id: this.$route.params.id
      }).then(response => {
        var contract = response.data[0];
        if (contract.owner == this.getUser.name) {
          this.contract = contract;
          this.projectTitle = this.contract.projectTitle;

          this.axios.post(`items/${this.getLocation}/queryAll`, {
            contractId: this.$route.params.id
          }).then(response => {
            this.itemContractLink = response.data;
            this.items = response.data;
            this.$forceUpdate();
          });
        } else {
          alert('您暂无权查看此页');
          this.$router.go(-1);
        }
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchItems() {
      this.axios.post(`projects/${this.getLocation}/query`, {
        title: this.projectTitle,
        'people.name': this.getUser.name,
        status: '进行中'
      }).then((response) => {
        if (response.data) {
          var purchasingProject = response.data;
          this.axios.post(`items/${this.getLocation}/queryAll`, {
              projectId: purchasingProject._id,
              price: { $exists: true },
              contractId: null,
              procedure: '完成'
            }).then((response) => {
              if (response.data.length == 0) {
                alert('还没有任何采购记录可以选！');
                this.clearData();
              } else {
                this.items = response.data;
              }
            });
          }
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
      },
      uploadContract() {
        this.waiting = true;

        if (this.checkContractPaymentMethodValidity(this.contract.paymentMethod)) {
          alert('请按照正确格式填写付款方式');
          this.waiting = false;
          return;
        }

        if (this.$route.params.id != null && this.files.length == 0) {
          if (this.contract.fileId != null) {
            // When one does not need resubmit contract in editing
            this.addContract(this.contract.fileId, this.contract.filename);
          } else {
            alert('请上传合同');
            this.waiting = false;
            return;
          }
        } else {
          // Upload contract for the first time or upload a new contract in editing
          this.handleFiles(this.addContract, false);
        }
      },
      addContract(id, filename) {
        this.contract.owner = this.getUser.name;
        this.contract.fileId = id;
        this.contract.filename = filename;
        this.contract.uploadTime = moment();
        if (this.contract.category == '采购合同') {
          this.contract.amount = this.contractAmount;
        }
        this.contract.paidAmount = 0;
        this.contract.receiptAmount = 0;
        this.contract.lock = false;
        this.contract.procedure = this.contract.owner;
        this.contract.procedureNote = '';

        var appendUri = this.$route.params.id ? `update/${this.$route.params.id}` : 'add';
        var uri = `contracts/${this.getLocation}/${appendUri}`;

        this.axios.post(uri, this.contract).then(async (response) => {
          await this.updateProcedure(response.data._id);
          if (this.contract.category == '采购合同') {
            await this.linkItemContract(response.data._id);
          }
          this.waiting = false;
          this.$router.push({name: 'ContractListOngoing'});
        }).catch(err => {
          alert(err.response.data);
        });
      },
      async linkItemContract(contractId) {
        for (var i = 0; i < this.itemContractLink.length; i++) {
          this.itemContractLink[i].contractId = contractId;
          await this.axios.post(`items/${this.getLocation}/update/${this.itemContractLink[i]._id}`, this.itemContractLink[i]);
        }
      },
      async updateProcedure(id) {
        var response = await this.axios.post(`contracts/${this.getLocation}/update/procedure/${id}`, {});
        alert(response.data);
      },
      clearData() {
        this.projects = [];
        this.projectTitle = '';
        this.items = [];
        this.itemContractLink = [];
        this.file = {};
        this.contract = {};
      },
      generateFileUploadBoxNotes() {
        var notes = ['支持拖拽; 重新选择或拖拽文件即可替换原先上传文件'];
        if (this.$route.params.id != null) {
          notes.push('编辑合同时，如果不需要替换旧合同，此处可不用上传任何文件');
        }
        return notes;
      },
      isNotNull(element) {
        return element != null && element != '';
      }
    }
  }
  </script>
