<template>
  <div>
    <div style='overflow-x:auto' v-if="reimbursements.length > 0">
      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th class="text-center align-middle" style="min-width: 60px">序号</th>
            <Header header="责任人" @sort="sort('owner')"></Header>
            <Header header="项目名称" @sort="sort('projectTitle')" v-show="list.includes('projectTitle')"></Header>
            <Header header="金额" @sort="sort('amount')" v-show="list.includes('amount')"></Header>
            <Header header="类别" @sort="sort('category')"></Header>
            <Header header="发票" @sort="sort('filename')" v-show="list.includes('filename')"></Header>
            <Header header="审批进度" @sort="sort('procedure')" v-show="list.includes('procedure')"></Header>
            <th class="text-center align-middle" style="min-width: 200px">说明</th>
            <Header header="付款方式" @sort="sort('method')" v-show="list.includes('method')"></Header>
            <Header header="完成时间" @sort="sort('completeTime')" v-show="list.includes('completeTime')"></Header>
            <Header header="预计退回时间" @sort="sort('returnDate')" v-show="list.includes('returnDate')" style="min-width: 160px"></Header>
            <th></th>
            <th></th>
            <th style="min-width: 100px" v-show="showButton()"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(r, index) in sortedReimbursements" :key="r._id" class="text-center">
            <td class="align-middle"><b>{{ index + 1 }}</b></td>
            <td class="align-middle">{{ r.owner }}</td>
            <td class="align-middle" v-show="list.includes('projectTitle')">{{ r.projectTitle }}</td>
            <td class="align-middle" v-show="list.includes('amount')">{{ r.amount }}</td>
            <td class="align-middle">{{ r.category }}</td>
            <td class="align-middle" v-show="list.includes('filename')">
              <a href="#" v-on:click="viewFile(r.fileId, r.filename)">{{ r.filename }}</a>
            </td>
            <td class="align-middle" v-show="list.includes('procedure')">
              {{ r.procedure }}
              <WhoIsWho :id="r._id + 'WhoIsWho'" :role="r.procedure" v-if="r.procedure != '完成' && r.procedure != r.owner"></WhoIsWho>
            </td>
            <td class="text-left align-middle" v-html="!r.note ? '' : r.note.replace(/\n/, '<br /><br />')"></td>
            <td class="align-middle" v-show="list.includes('method')">
              <a v-if="r.method == 'company' && r.accountImgId != null">
                {{ translateReimbursementMethod(r.method) }}
                <a href="#" @click="viewFile(r.accountImgId, r.accountImgFilename)">
                  <i class="fa fa-sticky-note" aria-hidden="true"></i>
                </a>
              </a>
              <a v-else>{{ translateReimbursementMethod(r.method) }}</a>
            </td>
            <td class="align-middle" v-show="list.includes('completeTime')">{{ getFormattedDate(r.completeTime) }}</td>
            <MarginReturnDate :reimbursement="r" v-show="list.includes('returnDate')"></MarginReturnDate>

            <td v-if="r.procedureTimestamp != null" class="text-center align-middle">
              <TimelineHover :timelineId="r._id" :timestamps="r.procedureTimestamp"></TimelineHover>
            </td>
            <td v-else></td>

            <td class="align-middle" v-if="r.procedureNote != null && r.procedureNote != ''">
              <ProcedureNote :id="r._id.toString() + 'ProcedureNote'" :note="r.procedureNote"></ProcedureNote>
            </td>
            <td v-else></td>

            <td class="align-middle" v-if="soleButton(r)">
              <router-link :to="{ name: 'ReimbursementForm', params: { id: r._id } }" class="btn btn-primary btn-sm"
              v-show="list.includes('editButton') && r.procedure == r.owner && getUser.name == r.owner">
              编辑
            </router-link>
            <router-link :to="{ name: 'ReimbursementForm', params: { id: r._id } }" class="btn btn-primary btn-sm"
            v-show="list.includes('uploadButton') && r.procedure == '完成' && getUser.name == r.owner">
            上传发票
          </router-link>
          <button class="btn btn-primary btn-sm"
          v-show="list.includes('callbackButton') && r.owner == getUser.name"
          @click="callbackReimbursementProcedure(r)">
          撤回
        </button>
        <button class="btn btn-danger btn-sm" v-show="list.includes('deleteButton')" @click="$emit('deleteReimbursement', r._id, index)">
          删除
        </button>
        <button class="btn btn-primary btn-sm" v-show="list.includes('returnButton')" @click="updateMargin(r)">已退回</button>
      </td>

      <td class="align-middle" v-else-if="showButton(r)">
        <b-dropdown text="操作" variant="primary" size="sm">
          <b-dropdown-item-button v-show="list.includes('editButton') && getUser.name == r.owner" :disabled="r.procedure != r.owner">
            <router-link :to="{ name: 'ReimbursementForm', params: { id: r._id } }">编辑</router-link>
          </b-dropdown-item-button>
          <b-dropdown-item-button v-show="list.includes('uploadButton') && getUser.name == r.owner" :disabled="r.procedure != '完成'">
            <router-link :to="{ name: 'ReimbursementForm', params: { id: r._id } }">上传发票</router-link>
          </b-dropdown-item-button>
          <b-dropdown-item-button v-show="list.includes('callbackButton') && r.owner == getUser.name" :disabled="r.procedure == r.owner" @click="callbackReimbursementProcedure(r)">
            撤回
          </b-dropdown-item-button>
          <b-dropdown-item-button v-show="list.includes('deleteButton') && authLevel(2)" @click="$emit('deleteReimbursement', r._id, index)">
            删除
          </b-dropdown-item-button>
          <b-dropdown-item-button v-show="list.includes('returnButton')" @click="updateMargin(r)">
            已退回
          </b-dropdown-item-button>
        </b-dropdown>
      </td>
    </tr>
  </tbody>
</table>

<Pagination v-show="pagination"
:array="reimbursements"
:currentPage="currentPage"
:pageSize="pageSize"
@setCurrentPage="setCurrentPage"
@incrementCurrentPage="currentPage++"
@minusCurrentPage="currentPage--">
</Pagination>

</div>
<Empty v-else-if="reimbursements.length == 0 && displayEmpty"></Empty>
</div>
</template>

<script>
import translateUtil from '@/js/translateUtil';
import storeUtil from '@/js/storeUtil';
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import fileIO from '@/js/fileIO';
import MarginReturnDate from './MarginReturnDate';
import moment from 'moment';

export default {
  props: [
    'list',
    'reimbursements',
    'pagination',
    'displayEmpty'
  ],
  data() {
    return {
      currentSort: 'owner'
    }
  },
  mixins: [
    translateUtil, storeUtil, formatTime, sortUtil, fileIO
  ],
  components: {
    MarginReturnDate
  },
  computed : {
    sortedReimbursements:function() {
      return this.pagination ? this.sortArray(this.reimbursements) : this.sortArrayWithoutPagination(this.reimbursements);
    }
  },
  methods: {
    callbackReimbursementProcedure(callbackReimbursement) {
      callbackReimbursement.procedure = callbackReimbursement.owner;
      this.axios.post(`reimbursements/${this.getLocation}/update/${callbackReimbursement._id}`, callbackReimbursement).then(response => {
        alert('已成功撤回费用审批，请编辑后重新上传');
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    showButton() {
      return this.list.includes('editButton') || this.list.includes('uploadButton') || this.list.includes('callbackButton') || this.list.includes('deleteButton') || this.list.includes('returnButton');
    },
    soleButton(r) {
      var enableEdit = this.list.includes('editButton') && r.procedure == r.owner && this.getUser.name == r.owner;
      var enableUpload = this.list.includes('uploadButton') && r.procedure == '完成' && this.getUser.name == r.owner;
      var enableCallback = this.list.includes('callbackButton') && r.owner == this.getUser.name;
      var enableDelete = this.list.includes('deleteButton') && this.authLevel(2);
      var enableReturn = this.list.includes('returnButton');
      var count = enableEdit + enableUpload + enableCallback + enableDelete + enableReturn;
      return count == 1;
    },
    updateMargin(margin) {
      margin.completeTime = moment();
      margin.amount = 0;
      margin.procedure = '已退回';
      this.axios.post(`reimbursements/${this.getLocation}/update/${margin._id}`, margin).then(response => {
        alert(response.data);
      });
    }
  }
}
</script>
