<template>
  <div>
    <div class="card" id="completedItems">
      <div class="card-header">
        <h3>我审批的采购</h3>
        <b>* 此处显示近三个月来审批的采购。</b>
      </div>
      <div class="card-body">
        <rise-loader :loading="loading" class="text-center"></rise-loader>
        <div v-if="!loading && sortedItems.length > 0" style="overflow-x: auto">
          <table class="table table-hover table-sm">
            <thead>
              <tr class="text-center">
                <Header header="名称" @sort="sort('name')"></Header>
                <Header header="品牌" @sort="sort('brand')"></Header>
                <Header header="型号" @sort="sort('series')"></Header>
                <Header header="单价" @sort="sort('price')"></Header>
                <Header header="数量" @sort="sort('quantity')"></Header>
                <Header header="供应商" @sort="sort('supplier')"></Header>
                <Header header="采购责任人" @sort="sort('owner')"></Header>
                <Header header="采购员" @sort="sort('purchaser')"></Header>
                <Header header="审批进度" @sort="sort('procedure')"></Header>
                <th></th>
                <Header header="我通过的时间" style="min-width: 120px"></Header>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in filteredItems" :key="item._id" :id="item._id" class="text-center">
                <td class="align-middle">{{ item.name }}</td>
                <td class="align-middle">{{ item.brand }}</td>
                <td class="align-middle">{{ item.series }}</td>
                <td class="align-middle">{{ item.price }}</td>
                <td class="align-middle">{{ item.quantity }}</td>
                <td class="align-middle">{{ item.supplier }}</td>
                <td class="align-middle">{{ item.owner }}</td>
                <td class="align-middle">{{ item.purchaser }}</td>
                <td class="align-middle">{{ item.procedure }}</td>
                <td class="align-middle">
                  <router-link :to="{ name: 'ItemDetails', params: {id: item._id} }" class="btn btn-success btn-sm">
                    详情
                  </router-link>
                </td>
                <td v-if="item.procedureTimestamp != null" class="align-middle">
                  <a v-for="role in getUser.role.split('/')">
                    {{ getFormattedDate(item.procedureTimestamp[role]) }}
                  </a>
                </td>
                <td v-else></td>
                <b-popover :target="item._id" placement="top" triggers="hover">
                  {{ item.projectTitle }}
                </b-popover>
              </tr>
            </tbody>
          </table>

          <Pagination
          :array="sortedItems"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @setCurrentPage="setCurrentPage"
          @incrementCurrentPage="currentPage++"
          @minusCurrentPage="currentPage--">
        </Pagination>
      </div>
      <Empty v-else-if="!loading && sortedItems.length == 0"></Empty>
    </div>
  </div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';
import util from './util';
import moment from 'moment';

export default {
  data() {
    return {
      items: [],
      currentSort: 'uploadTime',
      currentSortDir: 'desc'
    }
  },
  mixins: [
    util, formatTime, sortUtil, storeUtil, loaderUtil
  ],
  created() {
    this.getItems();
  },
  computed : {
    sortedItems() {
      return this.sortByProcedureTimestamp(this.items);
    },
    filteredItems() {
      return this.filterForPagination(this.sortedItems);
    }
  },
  methods: {
    getItems() {
      this.axios.get(`redis/lrange/itemProcedure`).then(response => {
        var procedure = this.inProcedureArray(response.data[0]);
        this.axios.post(`items/${this.getLocation}/queryAll/timeline`, {
          procedure: { $in: procedure }
        }).then(response => {
          this.items = response.data;
          this.getProjectTitle();
        });
      });
    },
    getProjectTitle() {
      for (var item of this.items) {
        this.axios.post(`projects/${this.getLocation}/query`, {
          _id: item.projectId
        }).then(response => {
          item.projectTitle = response.data.title;
          this.$forceUpdate();
        });
      }
      this.loading = false;
    }
  }
}
</script>
