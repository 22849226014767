<template>
<div>
  <a class="trigger" v-for="filter in filters">
    {{ filter }} <i class="fas fa-times" @click="removeKeyword(filter)"></i>
  </a>
  <input class="trigger" placeholder="搜索" type="text" v-model="keyword" v-on:keyup.enter="addKeyword(keyword)">
</div>
</template>

<script>
export default {
  props: [
    'initFilters'
  ],
  data() {
    return {
      filters: !this.initFilters ? [] : this.initFilters,
      keyword: ''
    }
  },
  watch: {
    filters(newFilters) {
      this.$emit('updateData', newFilters);
    }
  },
  methods: {
    addKeyword(keyword) {
      this.filters.push(keyword);
      this.keyword = '';
    },
    removeKeyword(keyword) {
      var index = this.filters.indexOf(keyword);
      this.filters.splice(index, 1);
    }
  }
}
</script>
