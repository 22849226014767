export default {
  data() {
    return {
      projects: [],
      allPeople: []
    }
  },
  created: function() {
    this.fetchOngoingProjects();
    this.fetchAllPeople();
  },
  methods: {
    fetchOngoingProjects() {
      this.axios.post(`projects/${this.getLocation}/queryAll`, {
        'people.name': this.getUser.name,
        status: '进行中'
      }).then((response) => {
        if (response.data != null) {
          this.projects = response.data;
        }
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchAllPeople() {
      this.axios.post(`people/${this.getLocation}/aggregate`, [
        { $match:
          { name: { $ne: this.getUser.name } }
        }
      ]).then(response => {
        this.allPeople = response.data;
      });
    }
  }
}
