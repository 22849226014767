<template>
<ReimbursementListTemplate :status="3" :tableList="['projectTitle', 'amount', 'filename', 'method', 'completeTime']">
</ReimbursementListTemplate>
<!-- <div>
  <div style="display:inline-block;">
    <select v-model="selectedYear">
      <option disabled selected value>-----年-----</option>
      <option v-for="year in [2019,2020,2021]" :key="year">{{ year }}</option>
    </select>
    <label>年</label>
  </div>
  <div style="display:inline-block;">
    <select v-model="selectedMonth" @change="filterByDate()" id="selectedMonth">
      <option disabled selected value>-----月-----</option>
      <option v-for="month in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="month">{{ month }}</option>
    </select>
    <label>月</label>
  </div>
</div> -->
</template>

<script>
import moment from 'moment';
import ReimbursementListTemplate from './ReimbursementListTemplate';

export default {
  data() {
    return {
      selectedYear: null,
      selectedMonth: null
    }
  },
  components: {
    ReimbursementListTemplate
  },
  methods: {
    filterByDate() {
      var selectedStartYearMonth = moment(`${this.selectedYear}-${this.selectedMonth}-01`);
      var selectedEndYearMonth = moment(`${this.selectedYear}-${this.selectedMonth}-01`).add(1, 'months');
      this.reimbursements = this.allReimbursements.filter(r =>
        this.filterDate(r.completeTime, selectedStartYearMonth, selectedEndYearMonth)
      );
    },
    filterDate(time, selectedStartYearMonth, selectedEndYearMonth) {
      return time != null && moment(time) >= selectedStartYearMonth && moment(time) < selectedEndYearMonth;
    }
  }
}
</script>
