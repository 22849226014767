<template>
  <div class="container">
    <div class="card">

      <FileLoading :showGifImg="showGifImg"></FileLoading>
      <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

      <TheNavPills :status='2'></TheNavPills>

      <div class="card-body">

        <b-row>
          <b-col>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
              <p class="form-check-label">模糊查询</p>
            </div>
          </b-col>
          <b>合同金额:</b>
          <b-col v-on:keyup.enter="filterContract()">
            <input type="number" class="form-control" placeholder="合同金额最低值" v-model="lowerAmount">
          </b-col>
          ~
          <b-col v-on:keyup.enter="filterContract()">
            <input type="number" class="form-control" placeholder="合同金额最高值" v-model="upperAmount">
          </b-col>
        </b-row>
        <Search @updateData='updateData'></Search>

        <br />
        <p><b v-show="contracts.length > 0">共{{ contracts.length }}份合同</b></p>
        <br />

        <rise-loader :loading="loading" class="text-center"></rise-loader>

        <div v-if="!loading && contracts.length > 0" style='overflow-x:auto'>
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th class="text-center align-middle" style="min-width: 60px">序号</th>
                <Header header="项目名称" @sort="sort('projectTitle')"></Header>
                <Header header="责任人" @sort="sort('owner')"></Header>
                <Header header="类别" @sort="sort('category')"></Header>
                <Header header="合同名称" @sort="sort('filename')"></Header>
                <Header header="上传时间" @sort="sort('uploadTime')"></Header>
                <Header header="合同金额" @sort="sort('amount')"></Header>
                <th style="min-width: 100px"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(contract, index) in sortedContracts" :key="contract._id" ref="contracts" class="text-center">
                <td><b>{{ index + 1 }}</b></td>
                <td v-if="currentSort != 'projectTitle' || projectCount[contract.projectTitle] == 1" class="align-middle">
                  <span :style="[contract.projectTitle == '无' ? {'color': 'red'} : {}]">
                    {{ contract.projectTitle }}
                  </span>
                </td>
                <td v-else-if="index % pageSize == 0 || (index - 1 >= 0 && sortedContracts[index - 1].projectTitle != contract.projectTitle)"
                  class="align-middle"
                  :rowspan="projectCount[contract.projectTitle] >= pageSize - index % pageSize
                  ? pageSize - index % pageSize
                  : projectCount[contract.projectTitle]">
                  <span :style="[contract.projectTitle == '无' ? {'color': 'red'} : {}]">
                    {{ contract.projectTitle }}
                  </span>
                </td>


                <td class="align-middle">{{ contract.owner }}</td>
                <td class="align-middle">{{ contract.category }}</td>
                <td class="align-middle">
                  <a v-if="!contract.stampedFileId && !contract.stampedFilename" href="#" @click="viewFile(contract.fileId, contract.filename)">
                    {{ contract.filename }}
                  </a>
                  <a v-else href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">
                    {{ contract.stampedFilename }}
                  </a>
                </td>
                <td class="align-middle">{{ getYearMonthDate(contract.uploadTime) }}</td>
                <td class="align-middle">{{ contract.amount }}</td>

                <td v-if="contract.category != '中标通知书'" class="align-middle">
                  <router-link class="btn btn-success btn-sm" :to="{name: 'ContractInfo', params: { id: contract._id }}" @click.native="setContractIndex(index)">详情</router-link>
                </td>
                <td v-else></td>
              </tr>
            </tbody>
          </table>

          <Pagination
          :array="contracts"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @setCurrentPage="setCurrentPage"
          @incrementCurrentPage="currentPage++"
          @minusCurrentPage="currentPage--">
        </Pagination>
      </div>

      <Empty v-else-if="!loading && contracts.length == 0"></Empty>
    </div>
  </div>
</div>
</template>

<script>
import ContractListUtil from './ContractListUtil';
import TheNavPills from './TheNavPills';

export default {
  data() {
    return {
      pageSize: 15
    }
  },
  components: {
    TheNavPills
  },
  mixins: [
    ContractListUtil
  ],
  methods: {
    fetchContracts() {
      var query = {
        $where: 'this.amount == this.receiptAmount && this.amount == this.paidAmount'
      };
      if (!this.authLevel(2)) {
        query.owner = this.getUser.name;
      }

      this.axios.post(`overall/${this.getLocation}/contracts/project/title`, query).then((response) => {
        this.contracts = response.data;
        this.allContracts = response.data;
        this.loading = false;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
