import fileIO from '@/js/fileIO';
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import loaderUtil from '@/js/loaderUtil';
import countUtil from '@/js/countUtil';
import Search from '../SearchTemplate.vue';

export default {
  data() {
    return {
      contracts: [],
      allContracts: [],
      currentSort: 'projectTitle',
      filters: [],
      searchLogicOr: false,
      lowerAmount: 0,
      upperAmount: 99999999
    }
  },
  mixins: [
    fileIO, formatTime, storeUtil, sortUtil, loaderUtil, countUtil
  ],
  components: {
    Search
  },
  created: function() {
    this.fetchContracts();
  },
  updated() {
    var contractPosition = this.$cookies.get('contractPosition');
    if (contractPosition != null) {
      if (contractPosition.filters != null) {
        this.updateData(contractPosition.filters);
      }
      this.currentPage = contractPosition.page;
      var row = this.$refs.contracts[contractPosition.index];
      if (row != null) {
        row.scrollIntoView();
      }
      this.$cookies.remove('contractPosition');
    }
  },
  computed : {
    sortedContracts() {
      return this.sortArray(this.contracts);
    },
    projectCount() {
      return this.contractCount(this.sortedContracts, 'projectTitle');
    }
  },
  methods: {
    updateData(filters) {
      this.filters = filters;
      this.currentPage = 1;
      if (filters.length == 0) {
        this.contracts = this.allContracts;
      } else {
        this.contracts = !this.searchLogicOr ? this.filterKeywordsAnd(this.allContracts, filters) : this.filterKeywords(this.allContracts, filters);
      }
    },
    setContractIndex(index) {
      var contractPosition = {
        page: this.currentPage,
        index: index
      };
      if (this.filters.length > 0) {
        contractPosition.filters = this.filters;
      }
      this.$cookies.set('contractPosition', contractPosition);
    },
    filterContract() {
      this.contracts = this.allContracts.filter(contract => {
        return contract.amount >= this.lowerAmount && contract.amount <= this.upperAmount;
      });
    }
  }
}
