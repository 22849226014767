<template>
  <div v-bind:class="[files.length == 0 ? 'drop warning' : 'drop']" class="display-inline align-center" @drop.prevent="setFilesInFileUploadBox" @dragover.prevent>
    <label v-if="!required">{{ label }}</label>
    <rlabel v-else :label="label"></rlabel>
    <br>
    <p v-for="(note, index) in notes">
      {{ generateStars(index) }} {{ note }}
      <br>
    </p>

    <input v-if="supportMultiFiles" type="file" class="custom-file-input" @change="setFilesInFileUploadBox" multiple>
    <input v-else type="file" class="custom-file-input" @change="setFilesInFileUploadBox">

    <div v-for="f in files" :key="f._id">
      <a href="#" @click="preview(f)">{{ f.name }}</a>
    </div>

    <div v-if="filenameCheck">
      <div v-if="showCheckDuplicatedFilenameMsg">
        文件名查重中，请稍后<rise-loader :loading="showCheckDuplicatedFilenameMsg" class="text-center"></rise-loader>
      </div>
      <div v-else-if="similarFilenames.length > 0">
        <b style="color: red">*请检查是否重复上传文件，或者换个文件名再试试吧<br>
          **当前文件名与以下文件名重合率过高</b>
          <div v-for="fname in similarFilenames">
            <a>{{ fname }}</a>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import fileIO from '@/js/fileIO';
  import loaderUtil from '@/js/loaderUtil';

  export default {
    props: [
      'label',
      'notes',
      'required',
      'supportMultiFiles',
      'filenameCheck'
    ],
    mixins: [
      fileIO, loaderUtil
    ],
    methods: {
      async setFilesInFileUploadBox(e) {
        if (this.filenameCheck) {
          await this.onFileChange(e);
        } else {
          await this.onFileChangeWithoutCheck(e);
        }
        this.$emit('setFiles', this.files);
      },
      generateStars(index) {
        var i = 0;
        var stars = '*';
        while (i < index) {
          stars += '*';
          i++;
        }
        return stars;
      }
    }
  }
  </script>
