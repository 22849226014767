<template>
  <div class="card" id="bonus">
    <div class="card-header">
      <h3>奖金</h3>
    </div>
    <div class="card-header">
    </div>
    <div class="card-body">
      <div>
        <table class="table table-hover text-center">
          <thead>
            <tr>
              <th class="align-middle" style="min-width:80px">人员</th>
              <th class="align-middle">奖金总金额</th>
              <th class="align-middle">已领取奖金金额</th>
              <th class="align-middle">是否有奖金在审批</th>
              <th style="min-width:60px"></th>
              <th style="min-width:60px"></th>
              <th></th>
            </tr>
          </thead>
          <tbody v-for="bonus in bonusList" :key="bonus._id">
            <tr>
              <td>
                <select class="form-control" v-model="bonus.owner" v-if="authLevel(2)" required>
                  <option disabled selected value>-----请选择-----</option>
                  <option v-for="person in project.people" :key="person._id" :value="person.name">{{ person.name }}</option>
                </select>
                <a v-else>{{ bonus.owner }}</a>
              </td>
              <td>
                <input type="number" class="form-control" v-model="bonus.amount" v-if="authLevel(2)" required>
                <a v-else>{{ bonus.amount }}</a>
              </td>
              <td>
                {{ bonus.paidAmount }}
              </td>
              <td>
                {{ bonus.lock ? '是' : '否' }}
              </td>
              <td>
                <b-button size="sm" variant="primary" v-if="authLevel(2)" @click="saveBonus(bonus)">保存</b-button>
              </td>
              <td>
                <b-button size="sm" variant="warning" @click="showApplyModal(bonus)" :disabled="bonus.lock || bonus.amount - bonus.paidAmount == 0">领取</b-button>
              </td>
              <td>
                <b-button size="sm" variant="info" @click="showBonusInfoModal(bonus)">详情</b-button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>

    <BonusInfo :bonus="bonusInfo" v-if="bonusInfo"></BonusInfo>

    <b-modal id="applyModal" centered
    ok-title="确认"
    cancel-title="取消"
    @ok="startBonusPaymentProcedure()">
    <b-list-group>
      <b-list-group-item>奖金总金额: {{ targetBonus.amount }}</b-list-group-item>
      <b-list-group-item>已领取奖金: {{ targetBonus.paidAmount }}</b-list-group-item>
      <b-list-group-item>
        本次领取奖金:
        <input type="number" class="form-control" v-model="bonusPayment.amount" required>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</div>
</template>

<script>
import PersonalBonus from './PersonalBonus';
import BonusInfo from './BonusInfo';
import storeUtil from '@/js/storeUtil';

export default {
  props: [
    'project'
  ],
  data() {
    return {
      bonusList: [],
      targetBonus: {},
      bonusPayment: {},
      bonusInfo: {}
    }
  },
  mixins: [
    storeUtil
  ],
  components: {
    PersonalBonus, BonusInfo
  },
  created() {
    this.fetchBonus();
  },
  methods: {
    fetchBonus() {
      this.axios.post(`bonus/queryAll`, {
        projectId: this.project._id
      }).then(response => {
        this.bonusList = response.data;

        if (this.authLevel(2)) {
          var i = this.bonusList.length;
          var j = this.project.people.length;
          while (i < j) {
            this.bonusList.push({});
            i++;
          }
        }
      });
    },
    saveBonus(bonus) {
      if(!bonus._id) {
        bonus.projectId = this.project._id;
        bonus.paidAmount = 0;
        bonus.lock = false;
        this.axios.post(`bonus/add`, bonus).then(response => {
          alert(response.data);
          this.$forceUpdate();
        });
      } else {
        // TODO: to check
        this.axios.post(`bonus/update/${bonus._id}`, bonus).then(response => {
          alert(response.data);
          this.$forceUpdate();
        });
      }
    },
    showApplyModal(bonus) {
      this.$bvModal.show('applyModal');
      this.targetBonus = bonus;
    },
    showBonusInfoModal(bonus) {
      this.$bvModal.show('infoModal');
      this.bonusInfo = bonus;
    },
    startBonusPaymentProcedure() {
      if (this.bonusPayment.amount > this.targetBonus.amount - this.targetBonus.paidAmount) {
        alert('超出领取奖金范围');
        return;
      }
      this.targetBonus.lock = true;

      this.bonusPayment.bonusId = this.targetBonus._id;
      this.bonusPayment.owner = this.targetBonus.owner;
      this.bonusPayment.procedure = this.targetBonus.owner;
      this.axios.post(`bonusPayment/add`, this.bonusPayment).then(response => {
        this.bonusPayment._id = response.data._id;
        this.axios.post(`bonus/update/${this.targetBonus._id}`, this.targetBonus).then(() => {
          this.axios.post(`bonusPayment/update/procedure/${this.bonusPayment._id}`, {}).then(response => {
            alert(response.data);
          });
        });
      });
    }
  }
}
</script>
