<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>用车申请</h3>
      </div>

      <div class="card-body">
        <form v-on:submit.prevent="addCarProcedure()">
          <div class="form-group">
            <rlabel label="项目名称"></rlabel>
            <select class="form-control" v-model="car.projectId" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="project in projects" :key="project._id" :value="project._id">
                {{ project.customer }} - {{ project.title }}
              </option>
              <option :value="null">日常用车</option>
            </select>
          </div>

          <div class="form-row">
            <div class="form-check form-check-inline col-md-3">
              <input class="form-check-input" type="radio" value="宝马" v-model="car.brand" required="required">
              <label class="form-check-label">
                <img src="@/assets/bmw.png" style="width: 38%" />
              </label>
            </div>
            <div class="form-check form-check-inline col-md-3">
              <input class="form-check-input" type="radio" value="奥迪" v-model="car.brand" required="required">
              <label class="form-check-label">
                <img src="@/assets/audi.jpeg" style="width: 50%" />
              </label>
            </div>
            <div class="form-check form-check-inline col-md-3">
              <input class="form-check-input" type="radio" value="蔚来" v-model="car.brand" required="required">
              <img src="@/assets/nio.jpg" style="width: 50%" />
            </div>
          </div>

          <div class="form-row" id="dateAlert">
            <div class="form-group col-md-3">
              <rlabel label="开始日期"></rlabel>
              <b-form-datepicker v-model="startDate" locale="zh" calendar-width="450px" :date-disabled-fn="disableDatesBeforeToday"></b-form-datepicker>
            </div>
            <div class="form-group col-md-4">
              <rlabel label="开始时间"></rlabel>
              <b-form-timepicker v-model="startTime" locale="zh" menu-class="w-100" no-close-button>
              </b-form-timepicker>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <rlabel label="结束日期"></rlabel>
              <b-form-datepicker v-model="endDate" locale="zh" calendar-width="450px" :date-disabled-fn="disableDatesBeforeToday"></b-form-datepicker>
            </div>
            <div class="form-group col-md-4">
              <rlabel label="结束时间"></rlabel>
              <b-form-timepicker v-model="endTime" locale="zh" menu-class="w-100" no-close-button>
              </b-form-timepicker>
            </div>
          </div>

          <div v-if="showDateAlert && !startDate" class="alert alert-danger">
            开始日期还未填写
          </div>
          <div v-else-if="showDateAlert && !endDate" class="alert alert-danger">
            结束日期还未填写
          </div>
          <div v-else-if="showDateAlert" class="alert alert-danger">
            开始时间大于结束时间, 请重新填写
          </div>


          <div class="form-group">
            <label>目的地<i class="fa fa-asterisk fa-custom" aria-hidden="true"></i>(如有多个目的地, 请用英文的逗号(,)分割):</label>
            <input type="text" class="form-control" v-model="car.destinations">
          </div>

          <div class="form-group">
            <rlabel label="说明"></rlabel>
            <textarea type="text" rows="3" class="form-control" v-model="car.note"></textarea>
          </div>

          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
          </div>
          <pacman-loader :loading="waiting"></pacman-loader>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil.js';
import formatTime from '@/js/formatTime.js';
import loaderUtil from '@/js/loaderUtil.js';
import projectUtil from '@/js/projectUtil';
import moment from 'moment';

export default {
  data() {
    return {
      projects: [],
      projectTitle: '',
      car: {},
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      showDateAlert: false
    }
  },
  mixins:[
    storeUtil, formatTime, loaderUtil, projectUtil
  ],
  methods: {
    addCarProcedure() {
      this.waiting = true;
      if(!this.startDate || !this.endDate) {
        this.showDateAlert = true;
        document.getElementById('dateAlert').scrollIntoView();
        this.waiting = false;
        return;
      }

      this.car.startTime = this.formatDateTimePicker(this.startDate, this.startTime);
      this.car.endTime = this.formatDateTimePicker(this.endDate, this.endTime);

      if (this.car.startTime >= this.car.endTime) {
        this.showDateAlert = true;
        document.getElementById('dateAlert').scrollIntoView();
        this.waiting = false;
        return;
      }
      this.car.user = this.getUser.name;
      this.car.uploadTime = moment();
      this.car.procedure = this.getUser.name;

      this.axios.post(`cars/${this.getLocation}/add`, this.car).then(response => {
        this.axios.post(`cars/${this.getLocation}/update/procedure/${response.data._id}`, {}).then(response => {
          alert(response.data);
          this.$router.push({name: 'CarIndex'});
          this.waiting = false;
        });
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
