<template>
  <div class="card" id="completedStamps">
    <div class="card-header">
      <h3>我审批的盖章</h3>
      <b>* 此处显示近三个月来审批的盖章。</b>
    </div>
    <div class="card-body">
      <rise-loader :loading="loading" class="text-center"></rise-loader>
      <div v-if="!loading && sortedStamps.length > 0" style="overflow-x: auto">
        <table class="table table-hover table-sm">
          <thead>
            <tr class="text-center">
              <th class="align-middle" style="min-width:90px">责任人</th>
              <th class="align-middle" style="min-width:100px">用印类型</th>
              <th class="align-middle" style="min-width:100px">用印公司</th>
              <th class="align-middle" style="min-width:100px">用印文件</th>
              <th class="align-middle" style="min-width:100px">关联项目</th>
              <th class="align-middle" style="min-width:100px">说明</th>
              <Header header="我通过的时间" style="min-width: 120px"></Header>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="stamp in filteredStamps" :key="stamp._id" class="text-center">
              <td class="align-middle">{{ stamp.owner }}</td>
              <td class="align-middle">{{ stamp.category }}</td>
              <td class="align-middle">{{ stamp.company }}</td>
              <td class="align-middle"><a href="#" @click="viewFile(stamp.fileId, stamp.filename)">{{ stamp.filename }}</a></td>
              <td class="align-middle">{{ stamp.projectTitle }}</td>
              <td class="align-middle" v-html="!stamp.note ? '' : stamp.note.replace(/\n/, '<br /><br />')"></td>

              <td v-if="stamp.procedureTimestamp != null" class="align-middle">
                <a v-for="role in getUser.role.split('/')">
                  {{ getFormattedDate(stamp.procedureTimestamp[role]) }}
                </a>
              </td>
              <td v-else></td>

              <td class="align-middle" v-if="stamp.procedureNote != null && stamp.procedureNote != ''">
                <ProcedureNote
                :id="stamp._id.toString() + 'ProcedureNote'"
                :note="stamp.procedureNote">
              </ProcedureNote>
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>

      <Pagination
      :array="sortedStamps"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @setCurrentPage="setCurrentPage"
      @incrementCurrentPage="currentPage++"
      @minusCurrentPage="currentPage--">
    </Pagination>
  </div>
  <div v-else-if="!loading && sortedStamps.length == 0">
    <Empty></Empty>
  </div>
</div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import util from './util.js';
import loaderUtil from '@/js/loaderUtil';
import moment from 'moment';

export default {
  data() {
    return {
      stamps: []
    }
  },
  mixins: [
    util, formatTime, sortUtil, storeUtil, loaderUtil
  ],
  created() {
    this.getStamps();
  },
  computed : {
    sortedStamps() {
      return this.sortByProcedureTimestamp(this.stamps);
    },
    filteredStamps() {
      return this.filterForPagination(this.sortedStamps);
    }
  },
  methods: {
    getStamps() {
      this.axios.get(`redis/lrange/stampProcedure`).then(response => {
        var procedure = this.inProcedureArray(response.data[0]);
        this.axios.post(`stamps/${this.getLocation}/queryAll/timeline`, {
          procedure: {$in: procedure}
        }).then((response) => {
          this.stamps = response.data.filter((row) => {
            return moment(row.uploadTime) > moment().add(-3, 'months');
          });
          this.loading = false;
        });
      });
    }
  }
}
</script>
