<template>
  <div class="container">
  <div class="card">
    <div class="card-header">
      <h3>发布通知</h3>
    </div>
    <div class="card-body">
      <form @submit.prevent="addAnnouncement()">
        <div class="form-group">
          <rlabel label="标题"></rlabel>
          <input type="text" class="form-control" v-model="announcement.title" required="required">
        </div>
        <div class="form-group">
          <rlabel label="内容"></rlabel>
          <textarea type="text" class="form-control" v-model="announcement.desc" row="5" required="required" />
        </div>
        <div class="form-row" id="dateAlert">
          <div class="form-group col-md-4">
            <rlabel label="开始日期"></rlabel>
            <b-form-datepicker v-model="startTime" locale="zh" calendar-width="450px"></b-form-datepicker>
          </div>
          <div class="form-group col-md-4">
            <rlabel label="结束日期"></rlabel>
            <b-form-datepicker v-model="endTime" locale="zh" calendar-width="450px"></b-form-datepicker>
          </div>
        </div>

        <div v-if="showDateAlert && !announcement.startTime" class="alert alert-danger">
          开始日期还未填写
        </div>
        <div v-else-if="showDateAlert && !announcement.endTime" class="alert alert-danger">
          结束日期还未填写
        </div>
        <div v-else-if="showDateAlert" class="alert alert-danger">
          开始时间大于结束时间, 请重新填写
        </div>

        <div class="form-group">
          <input type="submit" class="btn btn-primary" value="发布">
        </div>
      </form>
    </div>
  </div>
  </div>
</template>

<script>
import storeUtil from '../js/storeUtil.js';
import formatTime from '../js/formatTime.js';

export default {
  data() {
    return {
      announcement: {},
      showDateAlert: false,
      startTime: '',
      endTime: ''
    }
  },
  mixins: [ storeUtil, formatTime ],
  methods: {
    addAnnouncement() {
      if (this.announcement.startTime > this.announcement.endTime) {
        this.showDateAlert = true;
        document.getElementById('dateAlert').scrollIntoView();
        return;
      }
      this.announcement.startTime = this.startTime;
      this.announcement.endTime = this.endTime;

      this.axios.post(`announcements/${this.getLocation}/add`, this.announcement).then((response) => {
        alert(response.data);
        this.$router.push({name: 'Home'});
      }).catch(err => {
        alert(err.response.data);
      });
    }
  }
}
</script>
