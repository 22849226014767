<template>
  <ReimbursementListTemplate :status="2" :tableList="['projectTitle', 'amount', 'procedure', 'method', 'editButton', 'uploadButton', 'deleteButton']">
  </ReimbursementListTemplate>
</template>

<script>
import ReimbursementListTemplate from './ReimbursementListTemplate';

export default {
  components: {
    ReimbursementListTemplate
  }
}
</script>
