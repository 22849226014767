import storeUtil from '@/js/storeUtil';
import queryUtil from '@/js/queryUtil';

export default {
  mixins: [
    storeUtil, queryUtil
  ],
  methods: {
    fetchUnlinkedProjectContracts(name) {
      return new Promise((resolve, reject) => {
        this.axios.post(`overall/${this.getLocation}/contracts/project/title`, this.unlinkedProjectContractsQuery(name)).then((response) => {
          var results = response.data.filter(row => {
            return row.projectTitle == '无';
          });
          resolve(results);
        });
      });
    },
    fetchContractsWithoutStamp(name) {
      return new Promise((resolve, reject) => {
        this.axios.post(`contracts/${this.getLocation}/queryAll`, this.contractsWithoutStampQuery(name)).then(response => {
          resolve(response.data);
        });
      });
    },
    fetchUnlinkedItems(name) {
      return new Promise((resolve, reject) => {
        this.axios.post(`items/${this.getLocation}/queryAll`, this.unlinkedItemsQuery(name)).then(response => {
          resolve(response.data);
        });
      });
    },
    fetchIncompletedItems(name) {
      return new Promise((resolve, reject) => {
        this.axios.post(`items/${this.getLocation}/queryAll`, this.incompletedItemsQuery(name)).then(response => {
          var results = response.data;

          results.forEach(incompletedItem => {
            this.axios.post(`projects/${this.getLocation}/query`, {
              _id: incompletedItem.projectId
            }).then(response => {
              incompletedItem.projectTitle = response.data.title;
            });
          });

          resolve(results);
        });
      });
    },
    fetchReimbursementsWithNoReceipts(name) {
      return new Promise((resolve, reject) => {
        this.axios.post(`reimbursements/${this.getLocation}/queryAll`, this.reimbursementsWithNoReceiptsQuery(name)).then(response => {
          resolve(response.data);
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
      });
    },
    messageCount(name) {
      return new Promise((resolve, reject) => {
        this.axios.post(`overall/${this.getLocation}/message`, {name: name}).then(response => {
          resolve(response.data.count);
        }).catch(() => {
          // this.$router.push({name: 'ErrorPage'});
        });
      });
    },
    notificationCount(name) {
      return new Promise((resolve, reject) => {
        this.axios.post(`notifications/${this.getLocation}/count`, {'user': name}).then(response => {
          resolve(response.data.count);
        }).catch(() => {
          // this.$router.push({name: 'ErrorPage'});
        });
      });
    },
  }
}
