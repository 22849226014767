<template>
  <table class="table table-hover">
    <tbody>
      <tr>
        <th scope="row" style="min-width: 100px">名称</th>
        <td>{{ item.name }}</td>
      </tr>
      <tr>
        <th scope="row" style="min-width: 100px">品牌</th>
        <td>{{ item.brand }}</td>
      </tr>
      <tr>
        <th scope="row" style="min-width: 100px">型号</th>
        <td>{{ item.series }}</td>
      </tr>
      <tr>
        <th scope="row" style="min-width: 100px">到货日期</th>
        <td>{{ getYearMonthDate(item.date) }}</td>
      </tr>
      <tr>
        <th scope="row" style="min-width: 100px">供应商</th>
        <td>{{ item.supplier }}</td>
      </tr>
      <tr>
        <th scope="row" style="min-width: 100px">价格</th>
        <td>{{ item.price }}</td>
      </tr>
      <tr>
        <th scope="row" style="min-width: 100px">数量</th>
        <td>{{ item.quantity }}</td>
      </tr>
      <tr v-if="!isOriginal">
        <th scope="row" style="min-width: 100px">参数相似度</th>
        <td>{{ item.paramSimilarity}}%</td>
      </tr>
      <tr v-if="isOriginal">
        <th scope="row" style="min-width: 100px">参数</th>
        <td v-html="!highlightedParam ? item.parameters : highlightedParam.replace(/\n/, '<br /><br />')"></td>
      </tr>
      <tr v-else>
        <th scope="row" style="min-width: 100px">参数</th>
        <td v-html="!item.parameters ? '' : item.parameters.replace(/\n/, '<br /><br />')"></td>
      </tr>
      <tr>
        <th scope="row" style="min-width: 100px">备注</th>
        <td v-html="!item.note ? '' : item.note.replace(/\n/, '<br /><br />')"></td>
      </tr>
      <tr v-if="!isOriginal">
        <th scope="row" style="min-width: 100px">反馈</th>
        <td>
          <i class="fa fa-thumbs-up" aria-hidden="true" v-if="!feedback" @click="feedback = true">有用</i>
          <i class="fa fa-thumbs-up" aria-hidden="true" v-else>有用</i>

          <i class="fa fa-thumbs-down" aria-hidden="true" v-if="!feedback" @click="feedback = true">没用</i>
          <i class="fa fa-thumbs-down" aria-hidden="true" v-else>没用</i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import formatTime from '@/js/formatTime';

export default {
  props: [
    'item',
    'highlightedParam',
    'isOriginal'
  ],
  data() {
    return {
      feedback: false
    }
  },
  mixins: [
    formatTime
  ]
}
</script>
