<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <b>{{ translateModelName(commentModel.substr(0, commentModel.length - 1)) }}审批</b>
          <label v-if="commentUpdateOrReject == 'update'"><b>备注</b></label>
          <label v-else-if="commentUpdateOrReject == 'reject'"><b>驳回理由</b></label>

          <div>
            <textarea type="text" class="form-control" rows="3" required="required"
            v-bind:value="value"
            v-on:input="$emit('input', $event.target.value)" />

            <br />
            <DotLoader :loading="checking" size="1"></DotLoader>
            <br />

            <a v-for="p in nextProcedure" :key="p">
              <input class="radio" type="radio" required="required" @click="$emit('setNextProcedure', p)" name="nextProcedure">
              {{ p }}
            </a>
            <br />
            <button v-show="!checking"
            v-bind:class="commentUpdateOrReject == 'update' ? 'btn btn-primary btn-sm' : 'btn btn-danger btn-sm'"
            @click="$emit('updateProcedure')">
            {{ commentUpdateOrReject == 'update' ? '上传审批' : '驳回'}}
          </button>
          <button v-show="!checking" class="btn btn-light" @click="$emit('close')">取消</button>
        </div>

      </div>
    </div>
  </div>
</transition>
</template>

<script>
import translateUtil from '@/js/translateUtil';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      nextProcedure: [],
      checking: true
    }
  },
  props: [
    'commentModel',
    'commentUpdateOrReject',
    'currentProcedure',
    'value'
  ],
  mixins: [
    translateUtil, storeUtil, loaderUtil
  ],
  created() {
    this.checkNextProcedure();
  },
  methods: {
    checkNextProcedure() {
      if (!this.currentProcedure) {
        this.checking = false;
        return;
      }

      var procedureName;
      switch(this.commentModel) {
        case 'contracts':
        procedureName = 'sortedContractProcedure';
        break;
        case 'purchasingContracts':
        procedureName = 'sortedPurchasingContractProcedure';
        break;
        case 'payments':
        procedureName = 'sortedPaymentProcedure';
        break;
        case 'receipts':
        procedureName = 'sortedReceiptProcedure';
        break;
        case 'stamps':
        procedureName = 'sortedStampProcedure';
        break;
        case 'items':
        procedureName = 'sortedItemProcedure';
        break;
        case 'reimbursements':
        procedureName = 'sortedReimbursementProcedure';
        break;
        case 'cars':
        procedureName = 'sortedCarProcedure';
        break;
        default:
        this.checking = false;
        return;
      }

      this.axios.post(`redis/zscore/${procedureName}`, {
        value: this.currentProcedure
      }).then(response => {
        var currentScore = response.data[0];
        this.axios.post(`redis/zrangebyscore/${procedureName}`, {
          score: currentScore * 1 + 1
        }).then(response => {
          var nextProcedure = response.data[0];
          if (nextProcedure.length > 1) {
            this.nextProcedure = nextProcedure;
          }
          this.checking = false;
        });
      });
    }
  }
}
</script>
