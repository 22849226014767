<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>立项</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="addProject">
          <div class="form-group">
            <rlabel label="项目名称"></rlabel>
            <input type="text" class="form-control" v-model="project.title" required="required">
          </div>
          <div class="form-group">
            <rlabel label="甲方"></rlabel>
            <b-form-input list="customer-list" v-model="project.customer" required="required"></b-form-input>
            <b-form-datalist id="customer-list" :options="customerList"></b-form-datalist>
          </div>
          <div class="form-group">
            <rlabel label="项目类别"></rlabel>
            <select class="form-control" v-model="project.category" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="c in projectCategory" :key="c" :value="c">{{ c }}</option>
            </select>
          </div>

          <div class="form-group">
            <label>中标通知书</label>   (报价单、招标文件、中标通知书、销售合同四选一上传)
            <select class="form-control" v-model="bidFilename">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="notice in bidFiles" :key="notice._id" :value="notice.filename">
                {{ notice.filename }} - ￥{{ notice.amount }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>销售合同</label>   (报价单、招标文件、中标通知书、销售合同四选一上传)
            <select class="form-control" v-model="contractFilename">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="contract in projectContracts" :key="contract._id" :value="contract.filename">
                {{ contract.filename }} - ￥{{ contract.amount }}
              </option>
            </select>
          </div>

          <div v-show="bidFiles.length == 0 && projectContracts.length == 0" class="alert alert-warning">
            尚未有可选择的中标通知书或销售合同。请在“上传合同”中先上传中标通知书或销售合同，审批完成后，将在这里变为可选项。
          </div>

          <div class="form-group">
            <label>招标文件</label>   (报价单、招标文件、中标通知书、销售合同四选一上传)
            <FileUploadBox
            label="上传招标文件以立项"
            :notes="['支持拖拽; 重新选择或拖拽文件即可替换原先上传文件']"
            @setFiles="files = $event"
            :required="false"
            :supportMultiFiles="false"
            :filenameCheck="true">
          </FileUploadBox>
        </div>
        <br />
        <br />

        <ProjectPeopleTemplate :projectPeople="[]" @setProjectPeople="setProjectPeople"></ProjectPeopleTemplate>

        <div class="form-check">
          <input type="checkbox" class="form-check-input" v-model="project.noPurchase">
          <b class="form-check-label">此项目不包含采购</b>
        </div>

        <br />

        <div class="form-group">
          <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
        </div>
        <Uploading :waiting="waiting"></Uploading>

      </form>
    </div>
  </div>
</div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import peopleUtil from '@/js/peopleUtil';
import fileIO from '@/js/fileIO';
import loaderUtil from '@/js/loaderUtil';
import ProjectCreateUtil from './ProjectCreateUtil';
import ProjectPeopleTemplate from './ProjectPeopleTemplate';

export default {
  data() {
    return {
      project: {},
      projectContracts: [],
      contractFilename: '',
      bidFiles: [],
      bidFilename: ''
    }
  },
  created() {
    this.getProjectContracts();
    this.getBidFiles();
  },
  components: {
    ProjectPeopleTemplate
  },
  mixins: [
    storeUtil, peopleUtil, fileIO, loaderUtil, ProjectCreateUtil
  ],
  methods: {
    getProjectContracts() {
      this.axios.post(`overall/${this.getLocation}/contracts/projects/unlinked`, {
        owner: this.getUser.name
      }).then(response => {
        this.projectContracts = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getBidFiles() {
      this.axios.post(`overall/${this.getLocation}/bidFiles/projects/unlinked`, {
        owner: this.getUser.name
      }).then(response => {
        this.bidFiles = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    setProjectPeople(projectPeople) {
      this.project.people = projectPeople;
    },
    addProject() {
      this.waiting = true;
      this.project.status = (!this.contractFilename && !this.bidFilename) ? '机会' : '进行中';

      // If the project does not have a contract, use bid invitation
      if (this.files.length > 0) {
        this.handleFiles(this.addProjectWithoutContract, false);
        return;
      }

      this.axios.post(`contracts/${this.getLocation}/query`, {
        filename: this.bidFilename
      }).then((bidFileResponse) => {
        if (bidFileResponse.data) {
          this.project.bidFileId = bidFileResponse.data._id;
        }

        this.axios.post(`contracts/${this.getLocation}/query`, {
          filename: this.contractFilename
        }).then((contractResponse) => {
          if (contractResponse.data) {
            this.project.contractId = contractResponse.data._id;
          }
          this.axiosAddProject();
        });
      }).catch(() => {
        this.waiting = false;
        this.$router.push({name: 'ErrorPage'});
      });
    },
    addProjectWithoutContract(id, filename) {
      this.project.bidInvitationFileId = id;
      this.project.bidInvitationFilename = filename;
      this.axiosAddProject();
    },
    axiosAddProject() {
      console.log(this.project);
      this.axios.post(`projects/${this.getLocation}/add`, this.project).then((response) => {
        alert(response.data);
        this.waiting = false;
        this.$router.push({name: 'ProjectListOngoing'});
      }).catch(() => {
        this.waiting = false;
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
