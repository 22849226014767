export default {
  methods: {
    contractCount(contracts, field) {
      var count = new Object();
      for (var i = 0; i < contracts.length; i++) {
        var contract = contracts[i];
        count[contract[field]] = !count[contract[field]] ? 1 : count[contract[field]] + 1;
      }
      return count;
    }
  }
}
