<template>
  <th class="text-center align-middle"
  @click="$emit('sort')"
  :style="header.length == 2 ? 'min-width: 80px' :
          header.length == 3 ? 'min-width: 100px' :
          header.length == 4 ? 'min-width: 105px' :
          'min-width: 80px'">
  {{ header }} <i class="fa fa-sort" aria-hidden="true"></i>
</th>
</template>

<script>
export default {
  props: [
    'header'
  ]
}
</script>
