<template>
  <div class="container">

    <ul class="nav nav-pills">
      <li class="nav-item" @click="status = 1">
        <a class="nav-link" :class="status == 1 ? 'active' : ''">收款历史</a>
      </li>
      <li class="nav-item" @click="status = 2">
        <a class="nav-link" :class="status == 2 ? 'active' : ''">采购合同付款</a>
      </li>
      <li class="nav-item" @click="status = 3">
        <a class="nav-link" :class="status == 3 ? 'active' : ''">公司付款</a>
      </li>
      <li class="nav-item" @click="status = 4">
        <a class="nav-link" :class="status == 4 ? 'active' : ''">个人报销</a>
      </li>
    </ul>

    <br />

    <div class="card" v-if="status == 1">
      <div class="card-header">
        <h3>收款历史</h3>
        <button class="btn btn-primary" style="float: right;" @click="hideCard('receivePaymentHistory')">隐藏</button>
      </div>
      <div class="card-body">
        <PaymentFinance id="receivePaymentHistory" category="project"></PaymentFinance>
      </div>
    </div>

    <div class="card" v-if="status == 2">
      <div class="card-header">
        <h3>采购合同付款</h3>
        <button class="btn btn-primary" style="float: right;" @click="hideCard('purchasingPaymentHistory')">隐藏</button>
      </div>
      <div class="card-body">
        <PaymentFinance id="purchasingPaymentHistory" category="purchasing" @setPurchasingPaymentTotal="setPurchasingPaymentTotal"></PaymentFinance>
        <h5 style="text-align: right;">
          合计: {{ purchasingPaymentTotal }}
        </h5>
      </div>
    </div>

    <div class="card" v-if="status == 3">
      <div class="card-header">
        <h3>公司付款</h3>
        <button class="btn btn-primary" style="float: right;" @click="hideCard('companyReimbursement')">隐藏</button>
      </div>
      <div class="card-body">
        <br />
        <CompletedReimbursement
        id = "companyReimbursement"
        :matchQuery="companyMatchQuery"
        amountField="estimatedAmount"
        @setReimbursementTotal="setCompanyReimbursementTotal">
      </CompletedReimbursement>
      <h5 style="text-align: right;">
        合计: {{ companyReimbursementTotal }}
      </h5>
    </div>
  </div>

  <div class="card" v-if="status == 4">
    <div class="card-header">
      <h3>个人报销</h3>
      <button class="btn btn-primary" style="float: right;" @click="hideCard('individualReimbursement')">隐藏</button>
    </div>
    <div class="card-body">
      <CompletedReimbursement
      id="individualReimbursement"
      :matchQuery="individualMatchQuery"
      amountField="amount"
      @setReimbursementTotal="setIndividualReimbursementTotal">
    </CompletedReimbursement>
    <h5 style="text-align: right;">
      合计: {{ individualReimbursementTotal }}
    </h5>
  </div>
</div>
</div>
</template>

<script>
import CompletedReimbursement from '../CompletedReimbursementTemplate.vue';
import PaymentFinance from './PaymentFinanceTemplate.vue';
import layoutUtil from '@/js/layoutUtil';

export default {
  data() {
    return {
      status: 1,
      purchasingPaymentTotal: 0,
      companyMatchQuery: {},
      individualMatchQuery: {},
      companyReimbursementTotal: 0,
      individualReimbursementTotal: 0
    }
  },
  created() {
    this.companyMatchQuery = {
      method: 'company',
      procedure: '完成'
    };
    this.individualMatchQuery = {
      method: 'individual',
      procedure: '完成',
      fileId: { $exists: true },
      filename: { $exists: true }
    };
  },
  mixins: [layoutUtil],
  components: {
    CompletedReimbursement,
    PaymentFinance
  },
  methods: {
    setPurchasingPaymentTotal(total) {
      this.purchasingPaymentTotal = total.toFixed(2);
    },
    setCompanyReimbursementTotal(total) {
      this.companyReimbursementTotal = total.toFixed(2);
    },
    setIndividualReimbursementTotal(total) {
      this.individualReimbursementTotal = total.toFixed(2);
    }
  }
}
</script>
