<template>
  <div>
      <canvas id="pieChart"></canvas>

      <div>
        <div style="display:inline-block;">
          <select v-model="selectedYear">
            <option disabled selected value>-----年-----</option>
            <option v-for="year in yearArray" :key="year">{{ year }}</option>
          </select>
          <label>年</label>
        </div>
        <div style="display:inline-block;">
          <select v-model="selectedMonth">
            <option disabled selected value>-----月-----</option>
            <option v-for="month in [1,2,3,4,5,6,7,8,9,10,11,12]" :key="month">{{ month }}</option>
          </select>
          <label>月</label>
        </div>
        <button class="btn btn-primary" @click="filterByDate()">搜索</button>
        <button class="btn btn-info"
        @click="
        selectedYear=null, selectedMonth=null,
        completedReimbursements=allCompletedReimbursements, completedReimbursementsByCategory=[],
        createPie()
        ">
        还原
      </button>
      </div>

      <br />
      <RTemp
      :list="['projectTitle', 'amount', 'method', 'completeTime']"
      :reimbursements="completedReimbursementsByCategory"
      :pagination="false"
      :displayEmpty="false"
      @deleteReimbursement="">
    </RTemp>

    <hr>
    <h5>
      总计: {{ getTotal(this.completedReimbursementsByCategory, 'amount').toFixed(2) }}
    </h5>
    </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import accountingUtil from '@/js/accountingUtil';
import formatTime from '@/js/formatTime';
import Chart from 'chart.js';
import moment from 'moment';

export default {
  data() {
    return {
      clickedCategory: null,
      allCompletedReimbursements: [],
      completedReimbursements: [],
      completedReimbursementsByCategory: [],
      selectedYear: null,
      selectedMonth: null,
      yearArray: [],
      pieChart: null
    }
  },
  created() {
    this.fetchCompletedReimbursements();
    this.generateYearArray();
  },
  computed: {
    countByCategory() {
      return this.summaryReimbursementObject(this.completedReimbursements, 'category');
    }
  },
  mixins: [
    storeUtil, accountingUtil, formatTime
  ],
  methods: {
    fetchCompletedReimbursements() {
      var query = {
        fileId: { $exists: true, $ne: null },
        filename: { $exists: true },
        procedure: '完成'
      };
      var aggregation = [
        { $match: query },
        { $sort: { completeTime: -1 } }
      ];

      this.axios.post(`overall/${this.getLocation}/reimbursements/project/title`, aggregation).then((response) => {
        this.allCompletedReimbursements = response.data;
        this.completedReimbursements = response.data;
        this.createPie();
      });
    },
    generateYearArray() {
      var aggregation = [
        { $match: { completeTime: { $exists: true } } },
        { $project:{ year: { $year: '$completeTime' } } },
        { $group: {_id: '$year'} }
      ];
      this.axios.post(`reimbursements/${this.getLocation}/aggregate`, aggregation).then(response => {
        for (var i = 0; i < response.data.length; i++) {
          this.yearArray.push(response.data[i]._id);
        }
      });
    },
    filterByDate() {
      var selectedStartYearMonth = moment(`${this.selectedYear}-${this.selectedMonth}-01`);
      var selectedEndYearMonth = moment(`${this.selectedYear}-${this.selectedMonth}-01`).add(1, 'months');
      this.completedReimbursements = this.allCompletedReimbursements.filter(r =>
        this.filterDate(r.completeTime, selectedStartYearMonth, selectedEndYearMonth)
      );
      this.completedReimbursementsByCategory = this.completedReimbursements;
      this.createPie();
    },
    filterDate(time, selectedStartYearMonth, selectedEndYearMonth) {
      return time && moment(time) >= selectedStartYearMonth && moment(time) < selectedEndYearMonth;
    },
    createPie() {
      var backgroundColors = [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        '#F78181',
        '#FAAC58',
        '#F4FA58',
        '#F781F3',
        '#D358F7',
        '#0041C2',
        '#736AFF',
        '#38ACEC',
        '#7FFFD4',
        '#FFD801',
        '#F88017',
        '#F778A1',
        '#F52887',
        '#E6A9EC',
        '#E3E4FA'
      ];

      var labels = Object.keys(this.countByCategory);
      var counts = Object.values(this.countByCategory);

      if(this.pieChart != null) {
        this.pieChart.destroy();
      }

      var _this = this;
      var ctx = document.getElementById('pieChart');
      var pieChart = new Chart(ctx, {
        type: 'pie',
        data: {
          datasets: [{
            data: counts,
            backgroundColor: backgroundColors
          }],
          labels: labels
        },
        options: {
          animation: {
            animateScale: true,
            animateRotate: true
          },
          onClick: function(event) {
            var activePoint = pieChart.getElementsAtEvent(event)[0];
            if (activePoint) {
              var label = pieChart.data.labels[activePoint._index];
              _this.completedReimbursementsByCategory
              = _this.completedReimbursements.filter(r => r.category == label);
            }
          }
        }
      });
      this.pieChart = pieChart;
    }
  }
}
</script>
