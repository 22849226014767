<template>
  <b-modal
  id="reimbursement-summary"
  ok-title="确认"
  title="费用统计"
  @show="$bvModal.show('reimbursement-summary')"
  @hidden="$bvModal.hide('reimbursement-summary')"
  @ok="$bvModal.hide('reimbursement-summary')">
  <table class="table table-hover table-sm">
    <thead>
      <tr class="text-center">
        <th class="align-middle">序号</th>
        <th class="align-middle">人员</th>
        <th class="align-middle">费用合计</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(amount, person, index) in sumByPerson" class="text-center">
        <td class="align-middle"><b>{{ index + 1 }}</b></td>
        <td class="align-middle">{{ person }}</td>
        <td class="align-middle">{{ amount.toFixed(2) }}</td>
      </tr>
    </tbody>
  </table>
  <hr>
  <b>总计: {{ Object.values(sumByPerson).reduce((a, b) => a + b, 0).toFixed(2) }}</b>
</b-modal>
</template>

<script>
import formatTime from '@/js/formatTime';

export default {
  props: [
    'sumByPerson'
  ],
  mixins: [
    formatTime
  ]
}
</script>
