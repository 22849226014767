<template>
  <div class="container">
  <div class="card">
    <div class="card-header">
      <h3>搜索结果</h3>
    </div>
    <div class="card-body">
      共查询到<b>{{ count }}</b>条有关<b>{{ this.$route.params.query }}</b>的结果
      <div v-for="(model, modelName) in results">
        <div v-for="result in model">
          <li class="list-group-item list-group-item-light">
            <b>{{ translateModelName(modelName) }}</b>
            <hr>
            <span v-for="[key, value] in Object.entries(result)">
              {{ translateAttribute(key) }} - <span v-html="highlightQuery(value)"></span>;
            </span>

            <br>
          </li>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import translateUtil from '@/js/translateUtil.js';
import storeUtil from '@/js/storeUtil.js';

export default {
  data() {
    return {
      results: {},
      count: 0
    }
  },
  mixins: [translateUtil, storeUtil],
  watch: {
    '$route' (to, from) {
      this.clearData();
      this.fetchResults();
      // this.validSearch();
    }
  },
  created() {
    this.clearData();
    this.fetchResults();
    // this.validSearch();
  },
  methods: {
    validSearch() {
      this.axios.get(`people/${this.getLocation}/search/invalid/${this.$route.params.query}`).then((response) => {
        this.fetchResults();
      }).catch(err => {
        alert(err.response.data);
      });
    },
    fetchResults() {
      this.axios.get(`overall/${this.getLocation}/search/${this.$route.params.query}`).then((response) => {
        this.results = response.data.results;
        this.count = response.data.count;
      });
    },
    highlightQuery(words) {
      var iQuery = new RegExp(this.$route.params.query, "ig");
      if(!words) {
        return;
      }

      return words.toString().replace(iQuery, function(matchedTxt, a, b){
        return ('<span class=\'highlight\'>' + matchedTxt + '</span>');
      });
    },
    clearData() {
      this.results = {};
      this.count = 0;
    }
  }
}
</script>

<style>
.highlight {
  background-color: yellow;
}
</style>
