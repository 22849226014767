import sortUtil from '@/js/sortUtil';
import moment from 'moment';

export default {
  data() {
    return {
      overtime: [],
      vacation: [],
      overtimeHistory: [],
      vacationHistory: [],
      totalOvertime: 0,
      totalVacation: 0
    }
  },
  mixins: [
    sortUtil
  ],
  methods: {
    computeWorkingHoursByPerson(person) {
      this.overtimeHistory = this.filterTimeArrayHistory(person.overtime);
      this.vacationHistory = this.filterTimeArrayHistory(person.vacation);

      this.overtime = this.filterTimeArray(person.overtime);
      this.totalOvertime = this.computeTotalTime(this.overtime);

      this.vacation = this.filterTimeArray(person.vacation);
      this.totalVacation = this.computeTotalTime(this.vacation);
    },
    filterTimeArray(timeArray) {
      timeArray = !timeArray ? [] : timeArray;
      timeArray = timeArray.filter(time => {
        return moment(time.startTime).isSame(moment(), 'year')
      });
      timeArray = this.sortByStartTime(timeArray);
      return timeArray;
    },
    filterTimeArrayHistory(timeArray) {
      timeArray = !timeArray ? [] : timeArray;
      timeArray = timeArray.filter(time => {
        return moment(time.startTime).isBefore(moment(), 'year')
      });
      timeArray = this.sortByStartTime(timeArray);
      return timeArray;
    },
    computeTotalTime(timeArray) {
      var totalTime = 0;
      for (var time of timeArray) {
        if (time.procedure != '驳回') {
          var difference = moment(time.endTime).from(time.startTime, true);
          totalTime += Number(difference.split(' ')[0]);
        }
      }
      return totalTime;
    }
  }
}
