<template>
  <div class="container">
    <div class="card">

      <FileLoading :showGifImg="showGifImg"></FileLoading>
      <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

      <TheHeader status="opportunity"></TheHeader>

      <div class="card-body">
        <button class="btn btn-info" style="float: right;" @click="determineAllProjectStatus(projects)">
          更新项目状态
        </button>

        <div class="form-check">
          <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
          <p class="form-check-label">模糊查询</p>
        </div>
        <Search @updateData='updateData'></Search>

        <br />
        <b v-show="projects.length > 0">共{{ projects.length }}个机会</b>
        <br />

        <rise-loader :loading="loading" class="text-center"></rise-loader>

        <div v-if="!loading && projects.length > 0" style='overflow-x:auto'>
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <Header header="甲方" @sort="sort('customer')"></Header>
                <Header header="名称" @sort="sort('title')"></Header>
                <Header header="销售合同" @sort="sort('filename')"></Header>
                <th class="text-center align-middle" style="min-width: 210px">项目人员</th>
                <th style="min-width: 100px"></th>
                <th v-show="authLevel(2)"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(project, index) in sortedProjects" :key="project._id" ref="projects" class="text-center">
                <td class="align-middle">
                  <i class="fa fa-star" style="color: red;" v-if="isStarredProject(project._id)" @click="updateStarredProjects(project._id, 'delete')"></i>
                  <i class="fa fa-star" style="color: white;" v-else @click="updateStarredProjects(project._id, 'add')"></i>
                </td>
                <td v-if="isStarredProject(project._id) || currentSort != 'customer' || projectCustomerCount[project.customer] == 1" class="align-middle">
                  {{ project.customer }}
                </td>
                <td v-else-if="index % pageSize == 0 || (index - 1 >= 0 && sortedProjects[index - 1].customer != project.customer)"
                  class="align-middle"
                  :rowspan="projectCustomerCount[project.customer] >= pageSize - index % pageSize
                  ? pageSize - index % pageSize
                  : projectCustomerCount[project.customer]">
                  {{ project.customer }}
                </td>

                <td class="align-middle">{{ project.title }}</td>
                <td class="align-middle">
                  <a href="#" v-on:click="viewContractByContractId(project.contractId)">
                    {{ project.filename }}
                  </a>
                </td>
                <td class="align-middle">
                  <ul class="list-group" v-for="p in project.people" :key="p._id">
                    <li class="list-group-item">{{ p.name }} : {{p.role}}</li>
                  </ul>
                </td>


                <td class="align-middle">
                  <router-link :to="{name: 'ProjectInfo', params: { id: project._id }}" class="btn btn-success" @click.native="setProjectIndex(index)">
                    详情
                  </router-link>
                </td>

                <td class="align-middle" v-show="authLevel(2)">
                  <b-button-toolbar>
                    <b-dropdown text="更多操作">
                      <b-dropdown-item :to="{ name: 'ProjectEdit', params: { id: project._id } }" @click.native="setProjectIndex(index)">
                        编辑
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteProjectId=project._id, deleteProjectIndex=index, $bvModal.show('delete-project')">
                        删除
                      </b-dropdown-item>
                      <b-dropdown-item @click="unlinkContract(project._id)">
                        取消项目合同关联
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-button-toolbar>
                </td>
              </tr>
            </tbody>
          </table>

          <Pagination
          :array="projects"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @setCurrentPage="setCurrentPage"
          @incrementCurrentPage="currentPage++"
          @minusCurrentPage="currentPage--">
        </Pagination>
      </div>

      <Empty v-else-if="!loading && projects.length == 0"></Empty>
    </div>

<b-modal
id="delete-project"
ok-title="确认"
cancel-title="取消"
title="删除项目"
@show="$bvModal.hide('delete-project')"
@hidden="$bvModal.hide('delete-project')"
@ok="deleteProject(deleteProjectId, deleteProjectIndex), $bvModal.hide('delete-project')">
    你确定要删除这个项目吗？确认将删除项目，招标文件，销售合同，中标通知书，采购记录，采购合同，合同收付款，合同收开发票......
</b-modal>

</div>
</div>
</template>

<script>
import ProjectListUtil from './ProjectListUtil';
import ProjectStarredListUtil from './ProjectStarredListUtil';
import ProjectUpdateStatus from './ProjectUpdateStatus';

export default {
  data() {
    return{
      currentSort: 'customer',
      deleteProjectId: '',
      deleteProjectIndex: '',
      pageSize: 5,
      status: '机会',
      person: {}
    }
  },
  mixins: [
    ProjectListUtil, ProjectStarredListUtil, ProjectUpdateStatus
  ],
  methods: {
    deleteProject(id, index) {
      this.sortedProjects.splice(index, 1);
      this.axios.get(`overall/${this.getLocation}/delete/project/${id}`).then((response) => {
        alert(response.data);
        this.fetchProjects();
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    unlinkContract(projectId) {
      this.axios.get(`projects/${this.getLocation}/unlink/contract/${projectId}`).then(response => {
        alert(response.data);
      });
    }
  }
}
</script>
