<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>添加历史采购</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="addItem">
          <div class="form-group">
            <rlabel label="名称"></rlabel>
            <input type="text" class="form-control" v-model="item.name" required="required">
          </div>
          <div class="form-group">
            <rlabel label="品牌"></rlabel>
            <input type="text" class="form-control" v-model="item.brand" required="required">
          </div>
          <div class="form-group">
            <rlabel label="型号"></rlabel>
            <input type="text" class="form-control" v-model="item.series" required="required">
          </div>
          <div class="form-group">
            <rlabel label="数量"></rlabel>
            <input type="number" class="form-control" v-model="item.quantity" required="required">
          </div>
          <div class="form-group">
            <rlabel label="价格"></rlabel>
            <input type="number" class="form-control" v-model="item.price" step=".01" required="required">
          </div>
          <div class="form-group">
            <rlabel label="供应商"></rlabel>
            <input type="text" class="form-control" v-model="item.supplier" required="required">
          </div>
          <div class="form-group">
            <rlabel label="采购日期"></rlabel>
            <b-form-datepicker v-model="expectedArrvialDate" locale="zh"></b-form-datepicker>
          </div>
          <div class="form-group">
            <rlabel label="参数"></rlabel>
            <textarea type="text" class="form-control" rows="5" v-model="item.parameters" required="required"/>
          </div>
          <div class="form-group">
            <label><b>备注</b></label>
            <textarea type="text" class="form-control" rows="5" v-model="item.note"/>
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传">
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';
import moment from 'moment';

export default {
  data() {
    return {
      item: {},
      expectedArrvialDate: ''
    }
  },
  mixins: [
    formatTime, storeUtil
  ],
  methods: {
    addItem() {
      this.item.date = moment(this.expectedArrvialDate);
      this.axios.post(`items/${this.getLocation}/add`, this.item).then((response) => {
        alert(response.data);
        this.clearData();
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    clearData() {
      this.item = {};
      this.expectedArrvialDate = '';
    }
  }
}
</script>
