<template>
<div class="container">
  <h5>管理权限</h5>
  <p>
    此页面用于管理页面权限问题，1级权限可以查看所有页面，2级权限可以查看所有财务权限和管理员权限，3级权限可以查看所有财务权限，4级权限可以查看部分财务权限。
  </p>
  <div>
    <b>1级权限</b>
    <br />
    <a v-for="auth in authLevel1">
      <a class="trigger">{{ auth }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'authLevel1', auth)"></i></a>
    </a>
    <input class="trigger" type="text" v-model="newAuthLevel1" v-on:keyup.enter="modifyValueInRedis('add', 'authLevel1', newAuthLevel1)">
  </div>

  <div>
  <b>2级权限</b>
  <br />
  <a v-for="auth in authLevel2">
    <a class="trigger">{{ auth }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'authLevel2', auth)"></i></a>
  </a>
  <input class="trigger" type="text" v-model="newAuthLevel2" v-on:keyup.enter="modifyValueInRedis('add', 'authLevel2', newAuthLevel2)">
  </div>

  <div>
    <b>3级权限</b>
    <br />
    <a v-for="auth in authLevel3">
      <a class="trigger">{{ auth }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'authLevel3', auth)"></i></a>
    </a>
    <input class="trigger" type="text" v-model="newAuthLevel3" v-on:keyup.enter="modifyValueInRedis('add', 'authLevel3', newAuthLevel3)">
  </div>

<div>
  <b>4级权限</b>
  <br />
  <a v-for="auth in authLevel4">
    <a class="trigger">{{ auth }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'authLevel4', auth)"></i></a>
  </a>
  <input class="trigger" type="text" v-model="newAuthLevel4" v-on:keyup.enter="modifyValueInRedis('add', 'authLevel4', newAuthLevel4)">
</div>

</div>
</template>

<script>
export default {
  data() {
    return {
      authLevel1: [],
      authLevel2: [],
      authLevel3: [],
      authLevel4: [],
      newAuthLevel1: null,
      newAuthLevel2: null,
      newAuthLevel3: null,
      newAuthLevel4: null
    }
  },
  created() {
    this.fetchSet('authLevel1');
    this.fetchSet('authLevel2');
    this.fetchSet('authLevel3');
    this.fetchSet('authLevel4');
  },
  methods: {
    fetchSet(setName) {
      this.axios.get(`redis/members/${setName}`).then((response) => {
        if (response.data.length == 0) {
          return;
        }

        switch(setName[setName.length - 1]) {
          case '1':
          this.authLevel1 = response.data[0];
          this.newAuthLevel1 = null;
          break;
          case '2':
          this.authLevel2 = response.data[0];
          this.newAuthLevel2 = null;
          break;
          case '3':
          this.authLevel3 = response.data[0];
          this.newAuthLevel3 = null;
          break;
          case '4':
          this.authLevel4 = response.data[0];
          this.newAuthLevel4 = null;
          break;
        }
      });
    },
    modifyValueInRedis(addOrDelete, setName, value) {
      this.axios.post(`redis/${addOrDelete}/${setName}`, {
        value: value
      }).then(() => {
        this.fetchSet(setName);
      });
    }
  }
}
</script>
