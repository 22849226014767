<template>
  <button class="btn btn-outline-info" style="float: right;" @click="xlsxExport(tableId, filename)">
    下载   <i class="fas fa-download"></i>
  </button>
</template>

<script>
import fileIO from '@/js/fileIO';

export default {
  props: [
    'tableId',
    'filename'
  ],
  mixins: [
    fileIO
  ]
}
</script>
