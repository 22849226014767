<template>
  <div class="form-group" id="peopleAlert">
    <b-row>
      <b-col>
        <b>添加项目人员</b>
      </b-col>
      <b-col>
      </b-col>
      <b-col>
        <button type="button" v-on:click="addPersonToProject" class="btn btn-success">
          添加 +
        </button>
      </b-col>
    </b-row>

    <b-row>
      <b-col align="middle">
        人员
      </b-col>
      <b-col align="middle">
        角色
      </b-col>
      <b-col>
      </b-col>
    </b-row>
    <div v-for="(projectPerson, index) in projectPeople" :key="projectPerson._id">
      <b-row align-v="end">
        <b-col>
          <select class="form-control" v-model="projectPerson.name" required="required">
            <option disabled selected value>-----请选择-----</option>
            <option v-for="person in allPeople" :key="person._id" :value="person.name">{{ person.name }}</option>
          </select>
        </b-col>
        <b-col>
          <select class="form-control" v-model="projectPerson.role" required="required">
            <option disabled selected value>-----请选择-----</option>
            <option>项目负责人</option>
            <option>项目技术员</option>
            <option>项目助理</option>
            <option>采购员</option>
          </select>
        </b-col>
        <b-col>
          <button type="button" v-on:click="removePersonFromProject(index)" class="btn btn-danger">
            删除 -
          </button>
        </b-col>
      </b-row>
    </div>

    <div v-if="showPeopleAlert" class="alert alert-danger">
      请完整填写项目人员
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  data() {
    return {
      allPeople: [],
      showPeopleAlert: false
    }
  },
  props: [
    'projectPeople'
  ],
  mixins: [
    storeUtil
  ],
  created() {
    this.fetchAllPeople();
  },
  watch: {
    projectPeople(newPeople, oldPeople) {
      this.showPeopleAlert = false;
      for (var i = 0; i < oldPeople.length; i++) {
        if (!oldPeople[i].name || !oldPeople[i].role) {
          this.showPeopleAlert = true;
          return;
        }
      }
      this.fillId();
      this.$emit('setProjectPeople', oldPeople);
    }
  },
  methods: {
    fetchAllPeople() {
      this.axios.get(`people/${this.getLocation}`).then((response) => {
        this.allPeople = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    addPersonToProject() {
      this.projectPeople.push({});
      this.$forceUpdate();
    },
    removePersonFromProject(index) {
      this.projectPeople.splice(index, 1);
    },
    fillId() {
      for (let p of this.projectPeople) {
        if (p.name != "") {
          p._id = this.getIdByName(p.name);
        }
      }
    },
    getIdByName(name) {
      for (let p of this.allPeople) {
        if (p.name == name) {
          return p._id;
        }
      }
    }
  }
}
</script>
