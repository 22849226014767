<template>
  <div class="container">
  <div class="card">

    <div class="card-header">
      <h3>我的费用申请</h3>
      <br />
      <TheNavPills :status="status"></TheNavPills>
    </div>

    <div class="card-body">
      <rise-loader :loading="loading" class="text-center align-middle"></rise-loader>

      <div class="form-check">
        <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
        <p class="form-check-label">模糊查询</p>
      </div>
      <Search @updateData='updateData'></Search>
      <br />
      <p><b v-show="reimbursements.length > 0">共{{ reimbursements.length }}条费用</b></p>
      <br />

      <div v-if="!loading && reimbursements.length > 0" style='overflow-x:auto'>
        <ReimbursementTableTemplate
        :list="tableList"
        :reimbursements="reimbursements"
        :pagination="true"
        :displayEmpty="true"
        @deleteReimbursement="setDeleteReimbursement">
      </ReimbursementTableTemplate>
    </div>
    <Empty v-else-if="!loading && reimbursements.length == 0"></Empty>
  </div>

  <b-modal
  id="delete-reimbursement"
  ok-title="确认"
  cancel-title="取消"
  title="删除费用申请"
  @show="$bvModal.show('delete-reimbursement')"
  @hidden="$bvModal.hide('delete-reimbursement')"
  @ok="removeReimbursement(deleteReimbursementId, deleteReimbursementIndex), $bvModal.hide('delete-reimbursement')">
  你确定要删除这项费用申请吗？
  </b-modal>
</div>
</div>
</template>

<script>
import ReimbursementListUtil from './ReimbursementListUtil';
import ReimbursementTableTemplate from './ReimbursementTableTemplate';

export default {
  mixins: [
    ReimbursementListUtil
  ],
  props: [
    'status',
    'tableList'
  ],
  components: {
    ReimbursementTableTemplate
  },
  methods: {
    fetchReimbursements() {
      var query = {};

      if (this.status == 1) {
        query = {
          fileId: { $exists: true },
          fileId: { $ne: null },
          procedure: { $nin: [ '完成', '已退回' ] }
        };
      }
      if (this.status == 2) {
        query = {
          $or: [
            { fileId: { $exists: false } },
            { fileId: null }
          ]
        };
      }
      if (this.status == 3) {
        query = {
          $or : [
            {
              fileId: { $exists: true, $ne: null },
              procedure: { $in: [ '完成', '已退回' ] }
            },
            {
              fileId: { $exists: false },
              procedure: '已退回'
            }
          ]
        };
      }

      if (!this.authLevel(4)) {
        query.owner = this.getUser.name;
      }

      this.fetchReimbursementsWithQuery(query);
    }
  }
}
</script>
