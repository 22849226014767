<template>
  <div class="container">
    <FileLoading :showGifImg="showGifImg"></FileLoading>
    <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>
    <Uploading :waiting="waiting"></Uploading>
    <rise-loader :loading="loading" class="text-center"></rise-loader>

    <h1>消息中心</h1>
    <br/>

    <div v-show="!loading && contracts.length > 0" class="card">
      <div class="card-header">
        <h3>合同审批</h3>
      </div>
      <div class="card-body" style="overflow-x: auto">
        <table class="table table-hover table-sm">
          <thead>
            <tr class="text-center">
              <th class="align-middle" style="min-width:90px">责任人</th>
              <th class="align-middle" style="min-width:80px">类别</th>
              <th class="align-middle" style="min-width:100px">合同名称</th>
              <th class="align-middle" style="min-width:100px">上传时间</th>
              <th class="align-middle" style="min-width:100px">合同金额</th>
              <th style="width:60px"></th>
              <th style="width:60px"></th>
              <th style="width:60px"></th>
              <th style="width:60px"></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="contract in contracts" :key="contract._id" class="text-center">
              <td class="align-middle">{{ contract.owner }}</td>
              <td class="align-middle">{{ contract.category }}</td>
              <td class="align-middle">
                <a href="#" @click="viewFile(contract.fileId, contract.filename)">{{ contract.filename }}</a>
              </td>
              <td class="align-middle">{{ getFormattedDate(contract.uploadTime) }}</td>
              <td class="align-middle">{{ contract.amount }}</td>

              <td class="align-middle" v-if="contract.category != '中标通知书'">
                <router-link :to="{name: 'ContractInfo', params: { id: contract._id }}" class="btn btn-success btn-sm">详情</router-link>
              </td>
              <td v-else></td>

              <td class="align-middle" v-if="contract.category == '采购合同'">
                <router-link :to="{ name: 'ItemConfirmation', params: {contractId: contract._id} }" class="btn btn-success btn-sm">采购详情</router-link>
              </td>
              <td v-else></td>

              <td class="align-middle">
                <button class="btn btn-primary btn-sm" v-if="contract.category == '销售合同'" @click="commentWithNote(contract._id, 'contracts', 'update', contract.procedure)">
                  上传审批
                </button>
                <button class="btn btn-primary btn-sm" v-if="contract.category == '采购合同'" @click="commentWithNote(contract._id, 'purchasingContracts', 'update', contract.procedure)">
                  上传审批
                </button>
              </td>


              <td class="align-middle">
                <button class="btn btn-danger btn-sm" @click="commentWithNote(contract._id, 'contracts', 'reject')">驳回</button>
              </td>

              <td v-if="contract.procedureTimestamp != null" class="align-middle">
                <TimelineHover :timelineId="contract._id" :timestamps="contract.procedureTimestamp"></TimelineHover>
              </td>
              <td v-else></td>

              <td v-if="contract.procedureNote != null && contract.procedureNote != ''" class="align-middle">
                <ProcedureNote :id="contract._id.toString() + 'ProcedureNote'" :note="contract.procedureNote"></ProcedureNote>
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
    </div>

    <div v-show="!loading && payments.length > 0" class="card">
      <div class="card-header">
        <h3>付款审批</h3>
      </div>
      <div class="card-body" style="overflow-x: auto">
        <table class="table table-hover table-sm">
          <thead>
            <tr class="text-center">
              <th class="align-middle" style="min-width: 100px">项目名称</th>
              <th class="align-middle" style="min-width: 100px">盖章合同</th>
              <th class="align-middle" style="min-width: 100px">合同负责人</th>
              <th class="align-middle" style="min-width: 100px">付款金额</th>
              <th class="align-middle" style="min-width: 100px">付款方式</th>
              <th class="align-middle" style="min-width: 100px">付款公司</th>
              <th class="align-middle" style="min-width: 100px">付款日期</th>
              <th class="align-middle" style="min-width: 100px">付款信息</th>
              <th class="align-middle" style="min-width: 100px">备注</th>
              <th style="min-width: 60px"></th>
              <th style="min-width: 60px"></th>
              <th style="min-width: 60px"></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="payment in payments" :key="payment._id" class="text-center">
              <td class="align-middle">
                <a v-if="payment.projectTitle == '无'">{{ payment.projectTitle }}</a>
                <router-link v-else :to="{ name: 'ProjectInfo', params: { id: payment.projectId } }">{{ payment.projectTitle }}</router-link>
              </td>
              <td class="align-middle"><a href="#" @click="viewFile(payment.stampedFileId, payment.stampedFilename)">{{ payment.stampedFilename }}</a></td>
              <td class="align-middle">{{ payment.owner }}</td>
              <td class="align-middle">{{ payment.amount }}</td>
              <td class="align-middle">{{ payment.method }}</td>
              <td class="align-middle">{{ payment.company }}</td>
              <td class="align-middle">{{ getYearMonthDate(payment.date) }}</td>
              <td class="align-middle"><a href="#" @click="viewFile(payment.accountImgId, payment.accountImgFilename)">{{ payment.accountImgFilename }}</a></td>
              <td class="align-middle" v-html="!payment.note ? '' : payment.note.replace(/\n/, '<br /><br />')"></td>
              <td class="align-middle">
                <router-link :to="{name: 'ContractInfo', params: { id: payment.contractId }}" class="btn btn-success btn-sm">合同详情</router-link>
              </td>
              <td class="align-middle">
                <button class="btn btn-primary btn-sm" @click="commentWithNote(payment._id, 'payments', 'update', payment.procedure)">上传审批</button>
              </td>
              <td class="align-middle">
                <button class="btn btn-danger btn-sm" @click="commentWithNote(payment._id, 'payments', 'reject')">驳回</button>
              </td>

              <td v-if="payment.procedureTimestamp != null" class="align-middle">
                <TimelineHover :timelineId="payment._id" :timestamps="payment.procedureTimestamp"></TimelineHover>
              </td>
              <td v-else></td>

              <td v-if="payment.procedureNote != null && payment.procedureNote != ''" class="align-middle">
                <ProcedureNote :id="payment._id.toString() + 'ProcedureNote'" :note="payment.procedureNote"></ProcedureNote>
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
    </div>

    <div v-show="!loading && receipts.length > 0" class="card">
      <div class="card-header">
        <h3>开票审批</h3>
      </div>
      <div class="card-body" style="overflow-x: auto">
        <table class="table table-hover table-sm">
          <thead>
            <tr class="text-center">
              <th class="align-middle" style="min-width:90px">责任人</th>
              <th class="align-middle" style="min-width:100px">合同名称</th>
              <th class="align-middle" style="min-width:80px">此次开票金额</th>
              <th class="align-middle" style="min-width:80px">类别</th>
              <th class="align-middle" style="min-width:80px">税率</th>
              <th class="align-middle" style="min-width:100px">备注</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="receipt in receipts" :key="receipt._id" class="text-center">
              <td class="align-middle">{{ receipt.owner }}</td>
              <td class="align-middle"><a href="#" @click="viewFile(receipt.contractFileId, receipt.contractFilename)">{{ receipt.contractFilename }}</a></td>
              <td class="align-middle">{{ receipt.amount }}</td>
              <td class="align-middle">{{ receipt.category }}</td>
              <td class="align-middle">{{ receipt.taxPercentage }}</td>
              <td class="align-middle" v-html="!receipt.note ? '' : receipt.note.replace(/\n/, '<br /><br />')"></td>
              <td class="align-middle"><router-link :to="{ name: 'ContractInfo', params: {id: receipt.contractId} }" class="btn btn-success btn-sm">合同详情</router-link></td>

              <td class="align-middle" v-if="isLastReceiptProcedure(receipt)">
                <router-link :to="{ name: 'ReceiptProcedureComplete', params: {id: receipt._id} }" class="btn btn-primary btn-sm">上传发票</router-link>
              </td>
              <td class="align-middle" v-else>
                <button class="btn btn-primary btn-sm" @click="commentWithNote(receipt._id, 'receipts', 'update', receipt.procedure)">上传审批</button>
              </td>

              <td class="align-middle">
                <button class="btn btn-danger btn-sm" @click="commentWithNote(receipt._id, 'receipts', 'reject')">驳回</button>
              </td>

              <td v-if="receipt.procedureTimestamp != null" class="align-middle">
                <TimelineHover :timelineId="receipt._id" :timestamps="receipt.procedureTimestamp"></TimelineHover>
              </td>
              <td v-else></td>

              <td v-if="receipt.procedureNote != null && receipt.procedureNote != ''">
                <ProcedureNote :id="receipt._id.toString() + 'ProcedureNote'" :note="receipt.procedureNote"></ProcedureNote>
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
    </div>

    <div v-show="!loading && reimbursements.length > 0" class="card">
      <div class="card-header">
        <h3>费用审批</h3>
        <br />
        <button class="btn btn-info" @click="summary()">统计</button>
      </div>
      <div class="card-body" style="overflow-x: auto">
        <table class="table table-hover table-sm">
          <thead>
            <tr class="text-center">
              <th class="align-middle" style="min-width:100px">项目名称</th>
              <th class="align-middle" style="min-width:90px">责任人</th>
              <th class="align-middle" style="min-width:80px">发票</th>
              <th class="align-middle" style="min-width:100px">金额</th>
              <th class="align-middle" style="min-width:80px">类别</th>
              <th class="align-middle" style="min-width:100px">付款方式</th>
              <th class="align-middle" style="min-width:200px">说明</th>
              <th style="min-width:100px"></th>
              <th style="min-width:60px"></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="r in reimbursements" :key="r._id" class="text-center">
              <td class="align-middle">
                <a v-if="!r.projectId">日常开销</a>
                <router-link v-else :to="{ name: 'ProjectInfo', params: { id: r.projectId } }">{{ r.projectTitle }}</router-link>
              </td>
              <td class="align-middle">{{ r.owner }}</td>
              <td class="align-middle"><a href="#" @click="viewFile(r.fileId, r.filename)">{{ r.filename }}</a></td>
              <td class="align-middle">{{ r.amount }}</td>
              <td class="align-middle">{{ r.category }}</td>
              <td class="align-middle" v-if="r.method == 'company'" @click="viewFile(r.accountImgId, r.accountImgFilename)">
                {{ translateReimbursementMethod(r.method) }}
                <i class="fa fa-sticky-note" aria-hidden="true"></i>
              </td>
              <td class="align-middle" v-else>{{ translateReimbursementMethod(r.method) }}</td>
              <td class="align-middle" v-html="!r.note ? '' : r.note.replace(/\n/, '<br /><br />')"></td>
              <td class="align-middle" v-if="r.accountImgId != null || r.fileId != null">
                <button class="btn btn-primary btn-sm" @click="commentWithNote(r._id, 'reimbursements', 'update', r.procedure)">上传审批</button>
              </td>
              <td class="align-middle" v-else>
                <button class="btn btn-primary btn-sm" @click="commentWithNote(r._id, 'reimbursements', 'update')">上传审批</button>
              </td>
              <td class="align-middle">
                <button class="btn btn-danger btn-sm" @click="commentWithNote(r._id, 'reimbursements', 'reject')">驳回</button>
              </td>

              <td v-if="r.procedureTimestamp != null" class="align-middle">
                <TimelineHover :timelineId="r._id" :timestamps="r.procedureTimestamp"></TimelineHover>
              </td>
              <td v-else></td>

              <td class="align-middle" v-if="r.procedureNote != null && r.procedureNote != ''">
                <ProcedureNote
                :id="r._id.toString() + 'ProcedureNote'"
                :note="r.procedureNote">
              </ProcedureNote>
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
    </div>

    <SummaryModal
    :sumByPerson="sumByPerson"
    @confirm="$bvModal.hide('reimbursement-summary'), sumByPerson=new Object()">
  </SummaryModal>

<br />
</div>

<div v-show="!loading && items.length > 0" class="card">
  <div class="card-header">
    <h3>采购审批</h3>
  </div>
  <div class="card-body" style="overflow-x: auto">
    <table class="table table-hover table-sm">
      <thead>
        <tr class="text-center">
          <th class="align-middle" style="min-width:80px">名称</th>
          <th class="align-middle" style="min-width:80px">品牌</th>
          <th class="align-middle" style="min-width:80px">单价</th>
          <th class="align-middle" style="min-width:80px">数量</th>
          <th class="align-middle" style="min-width:100px">采购责任人</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item._id" class="text-center">
          <td class="align-middle">{{ item.name }}</td>
          <td class="align-middle">{{ item.brand }}</td>
          <td class="align-middle">{{ item.price }}</td>
          <td class="align-middle">{{ item.quantity }}</td>
          <td class="align-middle">{{ item.owner }}</td>
          <td class="align-middle">
            <router-link :to="{ name: 'ItemDetails', params: {id: item._id} }" class="btn btn-success btn-sm">详情</router-link>
          </td>
          <td class="align-middle">
            <router-link :to="{ name: 'ItemComparison', params: {id: item._id} }" class="btn btn-info btn-sm">比价</router-link>
          </td>
          <td class="align-middle">
            <button class="btn btn-primary btn-sm"  @click="commentWithNote(item._id, 'items', 'update', item.procedure)">上传审批</button>
          </td>
          <td class="align-middle">
            <button class="btn btn-danger btn-sm" @click="commentWithNote(item._id, 'items', 'reject')">驳回</button>
          </td>

          <td v-if="item.procedureTimestamp != null" class="align-middle">
            <TimelineHover :timelineId="item._id" :timestamps="item.procedureTimestamp"></TimelineHover>
          </td>
          <td v-else></td>

          <td class="align-middle" v-if="item.procedureNote != null && item.procedureNote != ''">
            <ProcedureNote
            :id="item._id.toString() + 'ProcedureNote'"
            :note="item.procedureNote">
          </ProcedureNote>
        </td>
        <td v-else></td>
      </tr>
    </tbody>
  </table>
</div>
<br />
</div>

<div v-show="!loading && stamps.length > 0" class="card">
  <div class="card-header">
    <h3>用印审批</h3>
  </div>
  <div class="card-body" style="overflow-x: auto">
    <table class="table table-hover table-sm">
      <thead>
        <tr class="text-center">
          <th class="align-middle" style="min-width:90px">责任人</th>
          <th class="align-middle" style="min-width:100px">用印类型</th>
          <th class="align-middle" style="min-width:100px">用印公司</th>
          <th class="align-middle" style="min-width: 100px">印章类型</th>
          <th class="align-middle" style="min-width:100px">用印文件</th>
          <th class="align-middle" style="min-width:100px">关联项目</th>
          <th class="align-middle" style="min-width:100px">说明</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="stamp in stamps" :key="stamp._id" class="text-center">
          <td class="align-middle">{{ stamp.owner }}</td>
          <td class="align-middle">{{ stamp.category }}</td>
          <td class="align-middle">{{ stamp.company }}</td>
          <td class="align-middle">{{ stamp.stampKind }}</td>
          <td class="align-middle"><a href="#" @click="viewFile(stamp.fileId, stamp.filename)">{{ stamp.filename }}</a></td>
          <td class="align-middle">{{ stamp.projectTitle }}</td>
          <td class="align-middle" v-html="!stamp.note ? '' : stamp.note.replace(/\n/, '<br /><br />')"></td>

          <!-- <td class="align-middle" v-if="isLastStampProcedure(stamp)">
            <router-link :to="{ name: 'StampProcedureComplete', params: {id: stamp._id} }" class="btn btn-primary btn-sm">上传盖章文件</router-link>
          </td>
          <td class="align-middle" v-else> -->
            <button class="btn btn-primary btn-sm"  @click="commentWithNote(stamp._id, 'stamps', 'update', stamp.procedure)">上传审批</button>
          <!-- </td> -->
          <td class="align-middle">
            <button class="btn btn-danger btn-sm" @click="commentWithNote(stamp._id, 'stamps', 'reject')">驳回</button>
          </td>

          <td v-if="stamp.procedureTimestamp != null" class="align-middle">
            <TimelineHover :timelineId="stamp._id" :timestamps="stamp.procedureTimestamp"></TimelineHover>
          </td>
          <td v-else></td>

          <td class="align-middle" v-if="stamp.procedureNote != null && stamp.procedureNote != ''">
            <ProcedureNote
            :id="stamp._id.toString() + 'ProcedureNote'"
            :note="stamp.procedureNote">
          </ProcedureNote>
        </td>
        <td v-else></td>
      </tr>
    </tbody>
  </table>
</div>
<br />
</div>

<div class="card" v-show="!loading && bonus.length > 0">
  <div class="card-header">
    <h3>奖金申领审批</h3>
  </div>

  <div class="card-body" style="overflow-x: auto">
    <table class="table table-hover table-sm">
      <thead>
        <tr class="text-center">
          <th class="align-middle" style="min-width: 80px">奖金领取人</th>
          <th class="align-middle" style="min-width: 80px">项目名称</th>
          <th class="align-middle" style="min-width: 80px">奖金总金额</th>
          <th class="align-middle" style="min-width: 80px">已领取奖金金额</th>
          <th class="align-middle" style="min-width: 80px">此次申领奖金金额</th>
          <th class="align-middle" style="min-width: 60px"></th>
          <th class="align-middle" style="min-width: 60px"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="b in bonus" :key="b._id" class="align-middle text-center">
          <td>{{ b.owner }}</td>
          <td>
            <router-link :to="{ name: 'ProjectInfo', params: { id: b.projectId } }">{{ b.projectTitle }}</router-link>
          </td>
          <td>{{ b.totalAmount }}</td>
          <td>{{ b.paidAmount }}</td>
          <td>{{ b.amount }}</td>
          <td>
            <b-button variant="primary" size="sm" @click="commentWithNote(b._id, 'bonus', 'update', b.procedure)">上传审批</b-button>
          </td>
          <td>
            <b-button variant="danger" size="sm" @click="commentWithNote(b._id, 'bonus', 'reject', b.procedure)">驳回</b-button>
          </td>
        </span>
      </tr>
    </tbody>
  </table>
</div>
</div>

<div class="card" v-show="!loading && overtime.length > 0">
  <div class="card-header">
    <h3>加班审批</h3>
  </div>

  <div class="card-body" style="overflow-x: auto">
    <table class="table table-hover table-sm">
      <thead>
        <tr class="text-center">
          <th class="align-middle" style="min-width: 80px">姓名</th>
          <th class="align-middle" style="min-width: 80px">开始时间</th>
          <th class="align-middle" style="min-width: 80px">结束时间</th>
          <th class="align-middle" style="min-width: 80px">备注</th>
          <th class="align-middle" style="min-width: 60px"></th>
          <th class="align-middle" style="min-width: 60px"></th>
        </tr>
      </thead>

      <tbody v-for="o in overtime" :key="o._id">
        <!-- <tr v-for="time in o.overtime" :key="time._id" class="text-center"> -->
        <td class="align-middle">{{ o.name }}</td>
        <td class="align-middle">{{ getFormattedDate(o.overtime.startTime) }}</td>
        <td class="align-middle">{{ getFormattedDate(o.overtime.endTime) }}</td>
        <td class="align-middle">{{ o.overtime.note }}</td>
        <td class="align-middle">
          <button class="btn btn-primary btn-sm"  @click="updateWorkingHourProcedure('overtime', o.overtime._id, o.name, 'update')">上传审批</button>
        </td>
        <td class="align-middle">
          <button class="btn btn-danger btn-sm" @click="updateWorkingHourProcedure('overtime', o.overtime._id, o.name, 'reject')">驳回</button>
        </td>
        <!-- </tr> -->
      </tbody>
    </table>
  </div>
</div>

<div class="card" v-show="!loading && vacation.length > 0">
  <div class="card-header">
    <h3>休假审批</h3>
  </div>

  <div class="card-body" style="overflow-x: auto">
    <table class="table table-hover table-sm">
      <thead>
        <tr class="text-center">
          <th class="align-middle" style="min-width: 80px">姓名</th>
          <th class="align-middle" style="min-width: 80px">开始时间</th>
          <th class="align-middle" style="min-width: 80px">结束时间</th>
          <th class="align-middle" style="min-width: 80px">备注</th>
          <th class="align-middle" style="min-width: 60px"></th>
          <th class="align-middle" style="min-width: 60px"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="v in vacation" :key="v.vacation._id" class="text-center">
          <td class="align-middle">{{ v.name }}</td>
          <td class="align-middle">{{ getFormattedDate(v.vacation.startTime) }}</td>
          <td class="align-middle">{{ getFormattedDate(v.vacation.endTime) }}</td>
          <td class="align-middle">{{ v.vacation.note }}</td>
          <td class="align-middle">
            <button class="btn btn-primary btn-sm"  @click="updateWorkingHourProcedure('vacation', v.vacation._id, v.name, 'update')">上传审批</button>
          </td>
          <td class="align-middle">
            <button class="btn btn-danger btn-sm" @click="updateWorkingHourProcedure('vacation', v.vacation._id, v.name, 'reject')">驳回</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<CommentBox v-if="showModal"
:commentModel="commentModel"
:commentUpdateOrReject="commentUpdateOrReject"
:currentProcedure="currentProcedure"
@updateProcedure="updateProcedure(), showModal=false"
@close="emptyCommentInfo()"
@setNextProcedure="setNextProcedure"
v-model="commentNote">
</CommentBox>

<br />
<br />

<div class="card" v-show="!loading && notifications.length > 0">
  <div class="card-header">
    <button class="btn btn-outline-info" style="float: right;" @click="updateAllNotifications()">消息已全部阅读</button>
  </div>

  <div class="card-body">
    <ul v-for="notification in notifications" :key="notification._id">
      <li v-bind:class="[{'list-group-item list-group-item-info': !notification.viewed}, {'list-group-item list-group-item-light': notification.viewed}]" @click="updateNotification(notification._id)">
        {{ getFormattedDate(notification.uploadTime) }} - {{ notification.msg }}
        <span v-if="notification.link">
          请及时前往<router-link :to="{ name: notification.link, params: {id: notification.linkId} }">这里</router-link>查看。
        </span>
      </li>
    </ul>
  </div>
  <br />
</div>

<div v-show=
"!loading && contracts.length == 0 && payments.length == 0 &&
receipts.length == 0 && reimbursements.length == 0 &&
notifications.length == 0 && items.length == 0 &&
stamps.length == 0 && cars.length == 0">
<h5 class="align-middle">全部处理完啦   <i class="far fa-smile-wink"></i></h5>
</div>

</div>
</template>

<script>
import fileIO from '@/js/fileIO';
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';
import translateUtil from '@/js/translateUtil';
import loaderUtil from '@/js/loaderUtil';
import accountingUtil from '@/js/accountingUtil';
import CommentBox from './CommentBox';
import SummaryModal from '@/components/reimbursement/SummaryModal';

export default {
  data() {
    return {
      contracts: [],
      payments: [],
      receipts: [],
      reimbursements: [],
      notifications: [],
      items: [],
      refItems: [],
      stamps: [],
      cars: [],
      bonus: [],
      overtime: [],
      vacation: [],
      commentId: '',
      commentNote: '',
      commentModel: '',
      commentUpdateOrReject: '',
      receiptProcedure: [],
      showModal: false,
      currentProcedure: '',
      nextProcedure: null,
      sumByPerson: new Object()
    }
  },
  mixins: [
    fileIO, formatTime, storeUtil, translateUtil, loaderUtil, accountingUtil
  ],
  components: {
    CommentBox, SummaryModal
  },
  created() {
    this.fetchReceiptProcedure();
    this.getMessages();
    this.getNotification();
  },
  methods: {
    getRoleQuery() {
      var query = {};
      if (this.getUser.role && this.getUser.role.indexOf('/') >= 0) {
        var inArray = this.getUser.role.split('/');
        query = {procedure: {$in: inArray}};
      } else {
        query.procedure = this.getUser.role;
      }
      return query;
    },
    getMessages() {
      ['contracts', 'items', 'stamps', 'cars'].forEach((collection) => {
        this.getCollections(collection);
      });
      this.getPayment();
      this.getReceipt();
      this.getReimbursements();
      this.getBonus();
      this.getOvertime();
      this.getVacation();
    },
    getPayment() {
      var query = this.getRoleQuery();
      this.axios.post(`overall/${this.getLocation}/payments/contract/project`, [
        { $match: query }
      ]).then((response) => {
        this.payments = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getReceipt() {
      var query = this.getRoleQuery();
      let uri = `receipts/${this.getLocation}/queryAll`;
      this.axios.post(uri, query).then((response) => {
        this.getReceiptContent(response.data);
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    async getReceiptContent(receipts) {
      for (var i = 0; i < receipts.length; i++) {
        var contractId = receipts[i].contractId;
        var response = await this.axios.post(`contracts/${this.getLocation}/query`, {_id: contractId});
        if (response.data == null) {
          continue;
        }
        receipts[i].owner = response.data.owner;
        if (!response.data.stampedFileId) {
          receipts[i].contractFileId = response.data.fileId;
          receipts[i].contractFilename = response.data.filename;
        } else {
          receipts[i].contractFileId = response.data.stampedFileId;
          receipts[i].contractFilename = response.data.stampedFilename;
        }
      }
      this.receipts = receipts;
    },
    getReimbursements() {
      this.axios.post(`overall/${this.getLocation}/reimbursements/project/title`, [
        { $match: this.getRoleQuery() }
      ]).then((response) => {
        this.reimbursements = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getBonus() {
      var query = this.getRoleQuery();
      this.axios.post(`bonusPayment/${this.getLocation}/withProject/queryAll`, query).then(response => {
        this.bonus = response.data;
      });
    },
    getOvertime() {
      var matchQuery = {
        'overtime.procedure': { $in: this.getUser.role.split('/') }
      };

      var projectQuery = {
        name: 1,
        overtime:
        {
          $filter: {
            input: "$overtime",
            as: "overtime",
            cond: {
              $in: [ '$$overtime.procedure', this.getUser.role.split('/') ]
            }
          }
        }
      };

      var query = [
        { $match: matchQuery },
        { $project: projectQuery },
        { $unwind: '$overtime' }
      ];

      this.axios.post(`people/${this.getLocation}/aggregate`, query).then(response => {
        this.overtime = response.data;
      });
    },
    getVacation() {
      var matchQuery = {
        'vacation.procedure': { $in: this.getUser.role.split('/') }
      };

      var projectQuery = {
        name: 1,
        vacation:
        {
          $filter: {
            input: "$vacation",
            as: "vacation",
            cond: {
              $in: [ '$$vacation.procedure', this.getUser.role.split('/') ]
            }
          }
        }
      };

      var query = [
        { $match: matchQuery },
        { $project: projectQuery },
        { $unwind: '$vacation' }
      ];

      this.axios.post(`people/${this.getLocation}/aggregate`, query).then(response => {
        this.vacation = response.data;
      });
    },
    getCollections(collection) {
      var query = this.getRoleQuery();
      let uri = `${collection}/${this.getLocation}/queryAll/timeline`;
      this.axios.post(uri, query).then((response) => {
        switch(collection) {
          case 'contracts':
          this.contracts = response.data;
          break;
          case 'items':
          this.items = response.data;
          break;
          case 'stamps':
          this.stamps = response.data;
          break;
          case 'cars':
          this.cars = response.data;
          break;
          default:
          this.$router.push({name: 'ErrorPage'});
        }
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getNotification() {
      this.axios.post(`notifications/${this.getLocation}/queryAll`, {
        'receivers.name': this.getUser.name
      }).then(response => {
        this.notifications = this.sortNotificationByTime(response.data);
        for (var i = 0; i < this.notifications.length; i++) {
          var note = this.notifications[i];
          for (var j = 0; j < note.receivers.length; j++) {
            if (note.receivers[j].name == this.getUser.name) {
              this.notifications[i].viewed = note.receivers[j].viewed;
              break;
            }
          }
        }
        this.loading = false;
      });
    },
    async purchasingContractItemDetails(contractId) {
      this.axios.post(`items/${this.getLocation}/queryAll`, {
        contractId: contractId,
        managerConfirmation: true
      }).then((response) => {
        if (response.data.length == 0) {
          this.$router.push({name: 'ErrorPage'});
        }
        this.$router.push({name: 'Edit', params: {id: response.data[0]._id}});
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    updateNotification(id) {
      this.axios.post(`notifications/${this.getLocation}/update/viewed/${id}`, {
        name: this.getUser.name
      }).then(() => {
        this.getNotification();
      });
    },
    updateAllNotifications() {
      this.axios.post(`notifications/${this.getLocation}/queryAll`, {
        receivers: {
          $elemMatch: {
            name: this.getUser.name,
            viewed: false
          }
        }
      }).then(response => {
        var unreadNotifications = response.data;
        unreadNotifications.forEach(notification => {
          this.axios.post(`notifications/${this.getLocation}/update/viewed/${notification._id}`, {
            name: this.getUser.name
          }).then(() => {
            this.getNotification();
          });
        });
      });
    },
    commentWithNote(id, model, updateOrReject, currentProcedure) {
      this.commentId = id;
      this.commentModel = model;
      this.commentUpdateOrReject = updateOrReject;
      this.showModal = true;
      if (currentProcedure != null) {
        this.currentProcedure = currentProcedure;
      }
    },
    setNextProcedure(nextProcedure) {
      this.nextProcedure = nextProcedure;
    },
    emptyCommentInfo() {
      this.commentId = '';
      this.commentModel = '';
      this.commentNote = '';
      this.commentUpdateOrReject = '';
      this.currentProcedure = '';
      this.nextProcedure = null;
      this.showModal = false;
    },
    async updateProcedure() {
      this.waiting = true;

      // Handle special cases
      switch(this.commentModel) {
        case 'bonus':
        this.updateBonusProcedure();
        return;
        case 'purchasingContracts': // Special case for contract
        this.commentModel = 'contracts';
      }

      let uri = `${this.commentModel}/${this.getLocation}/${this.commentUpdateOrReject}/procedure/${this.commentId}`;
      var response;
      switch(this.commentUpdateOrReject) {
        case 'update':
        try {
          response = await this.axios.post(uri, { procedureNote: this.commentNote, nextProcedure: this.nextProcedure });
        } catch(err) {
          alert(err.response.data);
        }
        break;
        case 'reject':
        try {
          response = await this.axios.post(uri, { reason: this.commentNote });
        } catch(err) {
          console.log(err);
          alert(err.response.data);
        }
      }

      alert(response.data);

      switch (this.commentModel) {
        case 'payments':
        this.getPayment();
        break;
        case 'receipts':
        this.getReceipt();
        break;
        case 'reimbursements':
        this.getReimbursements();
        break;
        case 'contracts':
        if (this.commentUpdateOrReject == 'reject') {
          await this.axios.get(`items/${this.getLocation}/contract/unlink/${this.commentId}`);
        }
        this.getCollections('contracts');
        break;
        default:
        this.getCollections(this.commentModel);
      }

      this.getNotification();
      this.emptyCommentInfo();
      this.waiting = false;
    },
    updateBonusProcedure() {
      let url = `bonusPayment/${this.commentUpdateOrReject}/procedure/${this.commentId}`;
      var post;
      switch(this.commentUpdateOrReject) {
        case 'update':
        post = { procedureNote: this.commentNote }
        break;
        case 'reject':
        post = { reason: this.commentNote }
        break;
      }
      this.axios.post(url, post).then(response => {
        alert(response.data);
        this.getBonus();
        this.emptyCommentInfo();
        this.waiting = false;
      });
    },
    updateBonusProcedure() {
      var url = `bonusPayment/${this.commentUpdateOrReject}/procedure/${this.commentId}`;
      var post;
      switch(this.commentUpdateOrReject) {
        case 'update':
        post = { bonusNote: this.commentNote }
        break;
        case 'reject':
        post = { reason: this.commentNote }
        break;
      }
      this.axios.post(url, post).then(response => {
        alert(response.data);
        this.getBonus();
        this.emptyCommentInfo();
        this.waiting = false;
      });
    },
    updateWorkingHourProcedure(workingHourType, id, name, updateOrReject) {
      let uri =`people/${this.getLocation}/${updateOrReject}/procedure/${workingHourType}/${name}`;
      this.axios.post(uri, {id: id}).then(response => {
        alert(response.data);
        switch(workingHourType) {
          case 'overtime':
          this.getOvertime();
          break;
          case 'vacation':
          this.getVacation();
          break;
        }
      });
    },
    sortNotificationByTime(notifications) {
      // In reverse time order
      return notifications.sort((a,b) => {
        let modifier = -1;
        if(a['uploadTime'] < b['uploadTime']) return -1 * modifier;
        if(a['uploadTime'] > b['uploadTime']) return 1 * modifier;
        return 0;
      });
    },
    fetchReceiptProcedure() {
      this.axios.get(`redis/lrange/receiptProcedure`).then((response) => {
        this.receiptProcedure = response.data[0];
      });
    },
    isLastReceiptProcedure(receipt) {
      var lastReceiptProcedure = this.receiptProcedure[this.receiptProcedure.length - 1];
      return this.getUser.role.indexOf(lastReceiptProcedure) >= 0 && receipt.procedure == lastReceiptProcedure;
    },
    // TODO:
    fetchStampProcedure() {
      // this.axios.get('redis/lrange/stamp')
    },
    isLastStampProcedure(stamp) {
      var lastReceiptProcedure = this.stampProcedure[this.receiptProcedure.length - 1];
      return this.getUser.role.indexOf(lastReceiptProcedure) >= 0 && receipt.procedure == lastReceiptProcedure;
    },
    summary() {
      this.sumByPerson = this.summaryReimbursementObject(this.reimbursements, 'owner');
      this.$bvModal.show('reimbursement-summary');
    }
  }
}
</script>
