<template>
  <div>
  <ReimbursementListTemplate :status="1" :tableList="['projectTitle', 'amount', 'filename', 'procedure', 'method', 'editButton', 'callbackButton', 'deleteButton']">
  </ReimbursementListTemplate>
</div>

<!-- <SummaryModal
:sumByPerson="sumByPerson"
@confirm="$bvModal.hide('reimbursement-summary'), sumByPerson=new Object()">
</SummaryModal> -->
</template>

<script>
import accountingUtil from '@/js/accountingUtil';
import SummaryModal from './SummaryModal';
import ReimbursementListTemplate from './ReimbursementListTemplate';

export default {
  data() {
    return {
      sumByPerson: new Object()
    }
  },
  components: {
    SummaryModal, ReimbursementListTemplate
  },
  mixins: [
    accountingUtil
  ],
  methods: {
    summary() {
      this.sumByPerson = this.summaryReimbursementObject(this.allReimbursements, 'owner');
      $bvModal.show('reimbursement-summary');
    }
  }
}
</script>
