import config from '../../config/IP';

export default {
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    getUser: function() {
      return this.$store.getters.getUser;
    },
    getAuthLevel: function() {
      return this.$store.getters.getAuthLevel;
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    isFinance: function() {
      return this.$store.getters.isFinance;
    },
    isManager() {
      return this.$store.getters.isManager;
    },
    isSales() {
      return this.$store.getters.isSales;
    },
    isContractManager: function() {
      return this.$store.getters.isContractManager;
    },
    isStampManager: function() {
      return this.$store.getters.isStampManager;
    },
    isCashier: function() {
      return this.$store.getters.isCashier;
    },
    isBoss: function() {
      return this.$store.getters.isBoss;
    },
    isDriver: function() {
      return this.$store.getters.isDriver;
    },
    isFileManager: function() {
      return this.$store.getters.isFileManager;
    },
    isReceiptManager: function() {
      return this.$store.getters.isReceiptManager;
    },
    isDBEngineer: function() {
      return this.$store.getters.isDBEngineer;
    },
    getLocation: function() {
      return this.$store.getters.getLocation;
    }
  },
  methods: {
    roleToProcedureCapital() {
      if (this.isAdmin) {
        return '管理员';
      } else if (this.isBoss) {
        return 'BOSS';
      } else if (this.isFinance) {
        return '会计';
      } else if (this.isCashier) {
        return '出纳';
      } else if (this.isDriver) {
        return '接待办主任';
      } else if (this.isContractManager) {
        return '合同审核员';
      } else if (this.isStampManager) {
        return '盖章人';
      }
    },
    authLevel(level, ...args) {
      // let uri =  `https://${config.IP}:${config.port}/redis/members/authLevel${level}`;
      // var authResponse = await this.axios.get(uri);
      // if (authResponse.data.length == 0) {
      //   return;
      // }

      // var authArray = authResponse.data[0];
        switch(level) {
          case 1:
          return this.isAdmin;
          case 2:
          return this.getAuthLevel <= 2;
          case 3:
          return this.getAuthLevel <= 3;
          case 4:
          return this.getAuthLevel <= 4;
          case 5:
          var argEvaluation = false;
          for (var arg of args) {
            argEvaluation = argEvaluation || arg;
          }
          return this.isBoss || this.isAdmin || argEvaluation;
          case 6:
          var argEvaluation = false;
          for (var arg of args) {
            argEvaluation = argEvaluation || arg;
          }
          return argEvaluation;
          default:
          return this.isLoggedIn;
        }
      // });
    }
  }
}
