import storeUtil from '@/js/storeUtil';
import accountingUtil from '@/js/accountingUtil';

export default {
  mixins: [
    storeUtil, accountingUtil
  ],
  methods: {
    async determineAllProjectStatus(projects) {
      this.waiting = true;
      for (var project of projects) {
        if (project.status == '机会') {
          if (project.contractId != null) {
            this.updateProjectStatus(project, '进行中');
          }
          continue;
        }

        var query = {};
        if (project.contractId != null) {
          query = {_id: project.contractId};
        } else {
          this.updateProjectStatus(project, '进行中');
          this.$forceUpdate();
          continue;
        }
        var r = await this.axios.post(`contracts/${this.getLocation}/query`, query);
        if (r.data.amount == r.data.paidAmount &&
          r.data.amount == r.data.receiptAmount &&
          (await this.determinePurchasingContracts(project._id) || project.noPurchase) &&
          this.isBonusCompleted(project.people, r.data.amount) &&
          project.relatedFiles.length > 0) {

            await this.updateProjectStatus(project, '历史项目');
            this.$forceUpdate();

        } else if (0.9 * r.data.amount <= r.data.paidAmount &&
            0.9 * r.data.amount <= r.data.receiptAmount &&
            (await this.determinePurchasingContracts(project._id) || project.noPurchase) &&
            // !this.isBonusCompleted(project.people, r.data.amount) &&
            // project.relatedFiles.length > 0 &&
            !(await this.hasIncompleteItems(project._id))) {

              await this.updateProjectStatus(project, '已完成');
              this.$forceUpdate();
        }
      }
      this.waiting = false;
      alert('项目状态更新完成');
    },
    async updateProjectStatus(project, status) {
      if (project.status == status)
        return;

      project.status = status;
      var response = await this.axios.post(`projects/${this.getLocation}/update/${project._id}`, project);
      this.$forceUpdate();
    },
    async determinePurchasingContracts(projectId) {
      var response = await this.axios.get(`overall/${this.getLocation}/contracts/purchasing/projects/${projectId}`);
      if (response.data != null) {
        var purchasingContracts = response.data;
        var totalPurchasingContractAmount = this.getTotalContractAmount(purchasingContracts, 'amount');
        var totalPurchasingContractPaidAmount = this.getTotalContractAmount(purchasingContracts, 'paidAmount');
        var totalPurchasingContractReceiptAmount = this.getTotalContractAmount(purchasingContracts, 'receiptAmount');
        return totalPurchasingContractAmount == totalPurchasingContractPaidAmount &&
        totalPurchasingContractAmount == totalPurchasingContractReceiptAmount &&
        totalPurchasingContractAmount > 0;
      } else {
        return false;
      }
    },
    isBonusCompleted(people, contractAmount) {
      var totalBonusPercentage = 0;
      var totalReceivedBonus = 0;
      for (var person of people) {
        totalBonusPercentage += person.bonusPercentage;
        totalReceivedBonus += person.receivedBonus;
      }
      return ((contractAmount * totalBonusPercentage / 1000).toFixed(2) == totalReceivedBonus.toFixed(2));
    },
    async hasIncompleteItems(projectId) {
      var response = await this.axios.post(`items/${this.getLocation}/queryAll`, {
        projectId: projectId,
        $or: [
          { contractId: null },
          { contractId: { $exists: false } }
        ]
      });
      var hasIncompleteItems = response.data.length > 0;
      return hasIncompleteItems;
    },
  }
}
