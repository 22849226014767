<template>
  <b-progress min="0" max="100" show-value show-progress animated>
    <b-progress-bar :value="actualPaymentPercentage(contract)" variant="primary"></b-progress-bar>
    <b-progress-bar :value="expectedPaymentPercentage(contract) - actualPaymentPercentage(contract) > 0 ? expectedPaymentPercentage(contract) - actualPaymentPercentage(contract) : 0" variant="danger"></b-progress-bar>
  </b-progress>
</template>

<script>
import accountingUtil from '@/js/accountingUtil';

export default {
  props: [
    'contract'
  ],
  mixins: [
    accountingUtil
  ]
}
</script>
