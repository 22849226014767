import RiseLoader from 'vue-spinner/src/RiseLoader.vue';
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import DotLoader from 'vue-spinner/src/DotLoader.vue';

export default {
  data() {
    return {
      loading: true,
      waiting: false
    }
  },
  components: {
    RiseLoader,
    PacmanLoader,
    DotLoader
  },
}
