<template>
  <b-container>
    <div class="card">
      <div class="card-header">
        <h3>问题详情</h3>
      </div>
      <div class="card-body">
        <center>
        <img src="@/assets/problemProcedure.png"style="width: 40%"></img>
      </center>
    <b-form>
      <b-row>
        <b-col>

            <b-form-group label="项目名称">
              <select v-if="!problem.projectId" class="form-control" v-model="projectId" required="required">
                <option disabled selected value>-----请选择-----</option>
                <option v-for="p in projects" :key="p._id" :value="p._id">{{ p.customer }} - {{ p.title }}</option>
              </select>
              <b-form-input v-else v-model="problem.projectId" disabled></b-form-input>
            </b-form-group>

          <b-form-group label="用户姓名">
            <b-form-input v-model="problem.user" disabled></b-form-input>
          </b-form-group>
      <b-form-group label="问题标题">
        <b-form-input v-model="problem.title" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="问题分类">
        <b-form-input v-model="problem.category" disabled></b-form-input>
      </b-form-group>

    </b-col>

      <b-col>
        <b-form-group label="用户单位">
          <b-form-input v-model="problem.customer" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="联系方式">
          <b-form-input v-model="problem.phoneNumber" disabled></b-form-input>
        </b-form-group>
      <b-form-group label="问题描述">
        <b-form-input v-model="problem.description" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="问题优先级">
        <select v-if="!problem.priority" class="form-control" v-model="priority" required="required">
          <option disabled selected value>-----请选择-----</option>
          <option v-for="p in priorities" :key="p" :value="p">{{ p }}</option>
        </select>
        <b-form-input v-else v-model="problem.priority" disabled></b-form-input>
      </b-form-group>
    </b-col>
  </b-row>

  <b-form-group label="备注">
    <b-form-textarea v-model="problem.notes" :placeholder="notePlaceholder" rows="6"></b-form-textarea>
  </b-form-group>

  <b-form-group label="解决方案">
    <b-form-textarea v-model="problem.solution" placeholder="请在此处填写解决方案与用户交流" rows="6"></b-form-textarea>
  </b-form-group>

  <b-button variant="primary" @click="updateProblemInfo()">保存</b-button>

    </b-form>
  </div>
</div>
  </b-container>

</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  data() {
    return {
      problem: {},
      projects: [],
      projectId: null,
      priority: null,
      priorities: ['低', '中', '高']
    }
  },
  mixins: [
    storeUtil
  ],
  created() {
    this.fetchProblem();
    this.fetchAllProjects();
  },
  computed: {
    notePlaceholder() {
      switch(this.problem.category) {
        case '项目进度问询':
        return '请电话咨询用户具体问题情况';
        case '设备安装调试':
        return '请电话咨询用户合适的设备安装调试时间，期望完成的时间，安装调试环境是否到位等问题以提前准备';
        case '设备故障报修':
        return '请电话咨询用户故障设备的类型、品牌、型号、序列号、故障时间、故障描述、故障次数、之前是否维修、期望上门维修还是远程协助';
        case '其他':
        return '请电话咨询用户具体问题情况';
      }
    }
  },
  methods: {
    fetchProblem() {
      var id = this.$route.params.id;
      this.axios.post(`problems/query`, {
        _id: id
      }).then(response => {
        this.problem = response.data;
      });
    },
    fetchAllProjects() {
      this.axios.post(`projects/${this.getLocation}/queryAll`, {}).then(response => {
        this.projects = response.data;
      });
    },
    updateProblemInfo() {
      if (this.projectId) {
        this.problem.projectId = this.projectId;
      }
      if (this.priority) {
        this.problem.priority = this.priority;
      }
      var id = this.$route.params.id;
      this.axios.post(`problems/update/${id}`, this.problem).then(response => {
        alert(response.data);
        this.$router.go(-1);
      });
    }
  }
}
</script>
