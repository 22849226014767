<template>
  <b-container>
    <div class="card">
      <div class="card-header">
        <h3>采购详情</h3>
        <DropdownOperations :item="item" style="float: right;"></DropdownOperations>
      </div>
      <div class="card-body">
    <b-row>
      <b-col>
      <b-card>
        <ul class="timeline">
          <li>
            <div v-if="phase >= itemProcedure.length + 3" class="timeline-badge success">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div v-else class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>
                采购记录已关联到<span v-if="item.contractId != null"><router-link :to="{ name: 'ContractInfo', params: {id: item.contractId} }">采购合同</router-link>
              </span><span v-else>采购合同</span>
            </h5>
            </div>
          </li>

          <li>
            <div v-if="phase >= itemProcedure.length + 2" class="timeline-badge success">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div v-else class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>采购审批已完成</h5>
            </div>
          </li>

          <li v-for="(status, index) in itemProcedure">
            <div v-if="phase >= itemProcedure.length - index - 1 + 2" class="timeline-badge success">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div v-else class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>采购审批已上传至{{ itemProcedure[itemProcedure.length - index - 1] }}
                <WhoIsWho v-show="itemProcedure[itemProcedure.length - index - 1] != '完成'"
                :id="itemProcedure[itemProcedure.length - index - 1]"
                :role="itemProcedure[itemProcedure.length - index - 1]">
                </WhoIsWho>
                <p>{{ procedureTimestamp == null ? '' : procedureTimestamp[itemProcedure[itemProcedure.length - index - 1]] != null ?
                  getFormattedDate(procedureTimestamp[itemProcedure[itemProcedure.length - index - 1]]) : '' }}
                </p>
              </h5>
            </div>
          </li>

          <li>
            <div v-if="phase >= 1" class="timeline-badge success">
              <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div v-else class="timeline-badge warning">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="timeline-title">
              <h5>采购审批已上传
                <p>{{ procedureTimestamp == null || procedureTimestamp[item.owner] == null ? '' : getFormattedDate(procedureTimestamp[item.owner]) }}</p>
              </h5>
            </div>
          </li>
        </ul>
      </b-card>
    </b-col>
    <b-col>
      <b-card>
        <table class="table table-hover">
          <tbody>
            <tr>
              <th scope="row">项目名称</th>
              <td>
                <router-link :to="{ name: 'ProjectInfo', params: { id: projectId } }">{{ projectTitle }}</router-link>
              </td>
            </tr>
            <tr>
              <th scope="row">名称</th>
              <td>{{ item.name }}</td>
            </tr>
            <tr>
              <th scope="row">单价</th>
              <td>{{ item.price }}</td>
            </tr>
            <tr>
              <th scope="row">数量</th>
              <td>{{ item.quantity }}</td>
            </tr>
            <tr>
              <th scope="row">到货日期</th>
              <td>{{ getYearMonthDate(item.date) }}</td>
            </tr>
            <tr>
              <th scope="row">品牌</th>
              <td>{{ item.brand }}</td>
            </tr>
            <tr>
              <th scope="row">型号</th>
              <td>{{ item.series }}</td>
            </tr>
            <tr>
              <th scope="row">供应商</th>
              <td>
                {{ item.supplier }}
                <SupplierPopover :key="item.supplier"></SupplierPopover>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-col>
    <b-col>
      <b-card>
      <table class="table table-hover">
        <tbody>
          <tr>
            <th scope="row">参数</th>
            <td v-html="!item.parameters ? '' : item.parameters.replace(/\n/, '<br /><br />')"></td>
          </tr>
          <tr>
            <th scope="row">备注</th>
            <td v-html="!item.note ? '' : item.note.replace(/\n/, '<br /><br />')"></td>
          </tr>
        </tbody>
      </table>
    </b-card>
    </b-col>
  </b-row>
  </div>
</div>
</b-container>
</template>

<script>
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';
import DropdownOperations from './DropdownOperations';

export default {
  data() {
    return {
      item: {},
      phase: '',
      projectTitle: '',
      projectId: '',
      itemProcedure: [],
      procedureTimestamp: {}
    }
  },
  mixins: [
    formatTime, storeUtil
  ],
  components: {
    DropdownOperations
  },
  created() {
    this.getItem();
    this.getPhase();
    this.getProcedure();
    this.fetchProcedureTimestamp();
  },
  methods: {
    getItem() {
      var itemId = this.$route.params.id;
      this.axios.post(`items/${this.getLocation}/queryAll`, {
        _id: itemId
      }).then((response) => {
        this.item = response.data[0];

        this.axios.post(`projects/${this.getLocation}/query`, {
          _id: this.item.projectId
        }).then((response) => {
          this.projectTitle = response.data.title;
          this.projectId = response.data._id;
        });
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getPhase() {
      this.axios.get(`items/${this.getLocation}/history/${this.$route.params.id}`).then((response) => {
        this.phase = response.data.phase;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getProcedure() {
      this.axios.get(`redis/zrange/sortedItemProcedure`).then((response) => {
        this.itemProcedure = response.data[0];
      });
    },
    fetchProcedureTimestamp() {
      this.axios.get(`redis/hgetall/${this.$route.params.id}`).then((response) => {
        this.procedureTimestamp = response.data[0];
      });
    },
  }
}
</script>
