<template>
  <td class="align-middle" @dblclick="clickHandler($event)">
    <a v-if="reimbursement.returnDate != null && !enableReturnDateEdit">
      {{ getYearMonthDate(reimbursement.returnDate) }}
    </a>
    <b-form-datepicker v-else v-model="reimbursement.returnDate" locale="zh" calendar-width="450px"></b-form-datepicker>
  </td>
</template>

<script>
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';

export default {
  props: [
    'reimbursement'
  ],
  mixins: [
    formatTime, storeUtil
  ],
  data() {
    return {
      enableReturnDateEdit: false
    }
  },
  watch: {
    'reimbursement.returnDate': {
      handler: function(after, before) {
        this.axios.post(`reimbursements/${this.getLocation}/update/${this.reimbursement._id}`, this.reimbursement).then(response => {
          alert(response.data);
          this.enableReturnDateEdit = false;
        });
      }
    }
  },
  methods: {
    clickHandler(event) {
      this.enableReturnDateEdit = true;
    }
  }
}
</script>
