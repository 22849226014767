import TheNavPills from './TheNavPills.vue';

import sortUtil from '@/js/sortUtil';
import formatTime from '@/js/formatTime';
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import translateUtil from '@/js/translateUtil';
import projectUtil from '@/js/projectUtil';
import loaderUtil from '@/js/loaderUtil';
import accountingUtil from '@/js/accountingUtil';

export default {
  data() {
    return {
      reimbursements: [],
      allReimbursements: [],
      // currentSort: 'owner',
      deleteReimbursementId: '',
      deleteReimbursementIndex: '',
      searchLogicOr: false
    }
  },
  components: {
    TheNavPills
  },
  mixins: [
    formatTime, fileIO, storeUtil, translateUtil, projectUtil, loaderUtil, sortUtil, accountingUtil
  ],
  created() {
    this.fetchReimbursements();
  },
  // computed : {
  //   sortedReimbursements:function() {
  //     return this.sortArray(this.reimbursements);
  //   }
  // },
  methods: {
    fetchReimbursementsWithQuery(query) {
      this.axios.post(`overall/${this.getLocation}/reimbursements/project/title`, [
        { $match: query }
      ]).then((response) => {
        this.reimbursements = response.data;
        this.allReimbursements = response.data;
        this.loading = false;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    removeReimbursement(id, index) {
      this.reimbursements.splice(index, 1);
      this.axios.get(`reimbursements/${this.getLocation}/delete/${id}`).then(() => {
        alert('成功删除报销记录');
        this.fetchReimbursements();
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    updateData(filters) {
      this.currentPage = 1;
      if (filters.length == 0) {
        this.reimbursements = this.allReimbursements;
      } else {
        this.reimbursements = !this.searchLogicOr ?
        this.filterKeywordsAnd(this.allReimbursements, filters) :
        this.filterKeywords(this.allReimbursements, filters);
      }
    },
    setDeleteReimbursement(id, index) {
      this.deleteReimbursementId = id;
      this.deleteReimbursementIndex = index;
      this.$bvModal.show('delete-reimbursement');
    }
  }
}
