<template>
  <div class="container">

    <rise-loader :loading="loading" class="text-center"></rise-loader>

    <div class="card">
      <div class="card-header">
        <h5>管理付款账户信息</h5>
      </div>
      <div class="card-body">
        <div style="overflow-x:auto;">
        <table class="table table-hover">
          <thead>
            <tr class="text-center">
              <th>序号</th>
              <Header header="供应商" @sort="sort('supplier')"></Header>
              <th>付款账户信息</th>
              <th>付款账户信息使用次数</th>
              <th>付款次数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(payment, index) in sortedPayments" class="text-center">
              <td><b>{{ index + 1 }}</b></td>
              <td
              v-if="index == 0 || sortedPayments[index - 1]['supplier'] != payment.supplier"
              class="align-middle"
              :rowspan="supplierPaymentCount[payment.supplier]">
                {{ payment.supplier }}
              </td>

              <td>
                <a href="#" @click="viewFile(payment.accountImgId, payment.accountImgFilename)">
                  {{ payment.accountImgFilename }}
                </a>
              </td>
              <td>{{ payment.accCount }}</td>
              <td
              v-if="index == 0 || sortedPayments[index - 1]['supplier'] != payment.supplier"
              class="align-middle"
              :rowspan="supplierPaymentCount[payment.supplier]">
                {{ supplierPaymentCount[payment.supplier] }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import loaderUtil from '@/js/loaderUtil';
import fileIO from '@/js/fileIO';

export default {
  data() {
    return {
      payments: [],
      supplierPaymentCount: new Object(),
      currentSort: 'supplier'
    }
  },
  mixins: [
    storeUtil, sortUtil, loaderUtil, fileIO
  ],
  created() {
    this.fetchPayments();
  },
  computed: {
    sortedPayments() {
      return this.sortArrayWithoutPagination(this.payments);
    }
  },
  methods: {
    fetchPayments() {
      this.axios.post(`payments/${this.getLocation}/queryAll`, {
        procedure: '完成',
        accountImgId: { $exists: true },
        accountImgFilename: { $exists: true }
      }).then(async response => {
        var payments = response.data;

        for (var i = 0; i < payments.length; i++) {
          var accCountResponse = await this.axios.post(`payments/${this.getLocation}/queryAll`, {
            accountImgId: payments[i].accountImgId
          });
          payments[i].accCount = accCountResponse.data.length;

          var response = await this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
            _id: payments[i].contractId,
            category: '采购合同'
          });

          if (response.data.length > 1) {
            this.$route.push('ErrorPage');
          }
          var contract = response.data[0];

          payments[i].supplier = contract.supplier;
          this.supplierPaymentCount[contract.supplier]
          = !this.supplierPaymentCount[contract.supplier] ? 1 : this.supplierPaymentCount[contract.supplier] + 1;
        }

        this.payments = payments;
        console.log(this.payments.length);
        this.loading = false;
      });
    }
  }
}
</script>
