export default {
  data() {
    return {
      marginQuery: {
        category: { $regex: '.*金.*' },
        procedure: '完成',
        method: 'company',
        $or :
        [
          { amount: { $gt: 0 } },
          { amount: { $exists: false } }
        ],
        returnDate: { $exists: true }
      },
    }
  },
  methods: {
    contractAggregate(category) {
      return [
        {
          $match: {
            category: category,
            procedure: '完成'
          }
        },
        {
          $group: {
            _id: '$owner',
            totalAmount: { $sum: '$amount' },
            totalPaidAmount: { $sum: '$paidAmount' }
          }
        },
        {
          $addFields: {
            due: { $subtract: [ '$totalAmount', '$totalPaidAmount' ] }
          }
        },
        {
          $sort: {
            owner: 1
          }
        }
      ];
    },
    personalContractAggregate(category, name) {
      return [
        {
          $match: {
            category: category,
            procedure: '完成',
            owner: name
          }
        },
        {
          $group: {
            _id: null,
            totalAmount: { $sum: '$amount' },
            totalPaidAmount: { $sum: '$paidAmount' }
          }
        },
        {
          $addFields: {
            due: { $subtract: [ '$totalAmount', '$totalPaidAmount' ] }
          }
        }
      ];
    },
    contractQuery(category) {
      return {
        category: category,
        procedure: '完成',
        paymentMethod: { $exists: true },
        'paymentSchedule.schedule': { $exists: true },
        $where: 'this.amount > this.paidAmount'
      };
    },
    personalContractQuery(category, name) {
      var query = this.contractQuery(category);
      query.owner = name;
      return query;
    },
    personalMarginQuery(name) {
      var query = this.marginQuery;
      query.owner = name;
      return query;
    },
    unlinkedProjectContractsQuery(name) {
      return {
        procedure: '完成',
        owner: name,
        category: '销售合同'
      };
    },
    contractsWithoutStampQuery(name) {
      return {
        procedure: '完成',
        owner: name,
        stampedFileId: { $exists: false },
        category: { $ne: '中标通知书' }
      };
    },
    unlinkedItemsQuery(name) {
      return {
        procedure: '完成',
        purchaser: name,
        contractId: { $exists: false },
        price: { $ne: 0 }
      };
    },
    incompletedItemsQuery(name) {
      return {
        procedure: {
          $exists: false
        },
        $or: [
          {
            owner: name,
            enablePurchaserEdit: false,
            managerConfirmation: false
          },
          {
            purchaser: name,
            $or: [
              {
                enablePurchaserEdit: true,
                managerConfirmation: false
              },
              {
                managerConfirmation: true,
                price: { $exists: false }
              }
            ]
          }
        ],
      };
    },
    reimbursementsWithNoReceiptsQuery(name) {
      return {
        owner: name,
        $or: [
          {
            fileId: null,
            filename: null
          },
          {
            fileId: { $exists: false },
            filename: { $exists: false }
          }
        ],
        procedure: '完成'
      };
    },

  }
}
