<template>
  <div class="container">
    <div class="card">
      <FileLoading :showGifImg="showGifImg"></FileLoading>

      <div class="card-header">
        <h3>上传盖章合同</h3>
      </div>
      <div class="card-body">
        <div class="row">
        <div class="card" style="width:50%; overflow-x: auto">
        <table class="table table-hover">
          <tbody>
            <tr>
              <th scope="row" style="min-width: 130px">合同编号</th>
              <td>{{ contract._id }}</td>
            </tr>
            <tr>
              <th scope="row">合同类别</th>
              <td>{{ contract.category }}</td>
            </tr>
            <tr>
              <th scope="row">项目名称</th>
              <td v-if="contract.projectTitle != '无'">
                <router-link :to="{ name: 'ProjectInfo', params: { id: contract.projectId } }">{{ contract.projectTitle }}</router-link>
              </td>
              <td v-else>{{ contract.projectTitle }}</td>
            </tr>
            <tr>
              <th scope="row">甲方</th>
              <td>{{ contract.projectCustomer }}</td>
            </tr>
            <tr>
              <th scope="row">合同负责人</th>
              <td>{{ contract.owner }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card" style="width:50%; overflow-x: auto">
        <table class="table table-hover">
      <tbody>
        <tr>
          <th scope="row">合同金额</th>
          <td>{{ contract.amount }}</td>
        </tr>
        <tr>
          <th scope="row">质保期</th>
          <td>{{ contract.warranty > 0 ? contract.warranty : 0 }}年</td>
        </tr>
        <tr>
          <th scope="row">付款方式</th>
          <td>{{ contract.paymentMethod }}</td>
        </tr>
        <tr>
          <th scope="row">销售合同</th>
          <td><a href="#" v-on:click="viewFile(contract.fileId, contract.filename)">{{ contract.filename }}</a></td>
        </tr>
        <tr>
          <th scope="row">上传日期</th>
          <td>{{ getFormattedDate(contract.uploadTime) }}</td>

        </tr>
      </tbody>
    </table>
      </div>
    </div>

    <br /><br />

        <form enctype="multipart/form-data" v-on:submit.prevent="uploadContract">

          <FileUploadBox
          label="上传盖章合同"
          :notes="['支持拖拽; 重新选择或拖拽文件即可替换原先上传文件']"
          @setFiles="files = $event"
          :required="true"
          :supportMultiFiles="false"
          :filenameCheck="true">
          </FileUploadBox>

          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
          </div>
          <pacman-loader :loading="waiting"></pacman-loader>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';
import formatTime from '@/js/formatTime';

export default {
  data() {
    return {
      contract: {}
    }
  },
  mixins: [ fileIO, storeUtil, loaderUtil, formatTime ],
  created() {
    this.getContract();
  },
  methods: {
    getContract() {
      this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
        _id: this.$route.params.id,
        owner: this.getUser.name
      }).then(response => {
        if (!response.data) {
          alert('您无权编辑此页');
          this.$router.go(-1);
        }
        this.contract = response.data[0];
      }).catch(() => {
        alert('您无权编辑此页');
        this.$router.go(-1);
      });
    },
    uploadContract() {
      this.waiting = true;
      this.handleFiles(this.updateStampedContract, false);
    },
    updateStampedContract(id, filename) {
      this.contract.stampedFileId = id;
      this.contract.stampedFilename = filename;
      this.axios.post(`contracts/${this.getLocation}/update/${this.$route.params.id}`, this.contract).then(() => {
        this.waiting = false;
        this.$router.push({name: 'ContractListOngoing'});
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
