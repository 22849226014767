<template>
  <div id="app">
    <b-container>
      <b-navbar v-if="isLoggedIn" toggleable="lg" type="dark" variant="info">
        <b-navbar-brand to="/" v-if="getLocation == 'xian'">西安 SunHarv</b-navbar-brand>
        <b-navbar-brand to="/" v-if="getLocation == 'shanghai'">上海 SunHarv</b-navbar-brand>

          <b-navbar-nav>
            <b-nav-item-dropdown text="项目">
              <b-dropdown-item to="/project/create">立项</b-dropdown-item>
              <b-dropdown-item to="/project/list/ongoing">我的项目</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="合同">
              <b-dropdown-item to="/contract/upload">上传合同</b-dropdown-item>
              <b-dropdown-item to="/contract/list/ongoing">我的合同</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="采购">
              <b-dropdown-item to="/item/form">添加采购</b-dropdown-item>
              <b-dropdown-item to="/item/list/ongoing">我的</b-dropdown-item>
              <b-dropdown-item to="/item/quoting">询价</b-dropdown-item>
              <b-dropdown-item to="/item/query">查询</b-dropdown-item>
              <b-dropdown-item to="/item/add/history">添加历史采购</b-dropdown-item>
              <b-dropdown-item to="/supplier/list">供应商列表</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="财务" v-show="authLevel(4)">
              <b-dropdown-item to="/finance">应收应付</b-dropdown-item>
              <b-dropdown-item to="/finance/bar" v-show="authLevel(3)">财务一览</b-dropdown-item>
              <b-dropdown-item to="/update/payment/receipt">收到票款</b-dropdown-item>
              <b-dropdown-item to="/finance/payment/history">收付款历史</b-dropdown-item>
              <b-dropdown-item to="/reimbursement/margin">保证金管理</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="申请">
              <b-dropdown-item to="/reimbursement/form">上传费用</b-dropdown-item>
              <b-dropdown-item to="/reimbursement/list/individual">我的费用</b-dropdown-item>
              <b-dropdown-item to="/stamp/procedure">上传用印</b-dropdown-item>
              <b-dropdown-item to="/stamp/index">我的用印</b-dropdown-item>
              <b-dropdown-item to="/car/procedure">上传用车</b-dropdown-item>
              <b-dropdown-item to="/car/index">我的用车</b-dropdown-item>
              <b-dropdown-item to="/workingHour/overtime">加班</b-dropdown-item>
              <b-dropdown-item to="/workingHour/vacation">请假</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="共享文件">
              <b-dropdown-item to="/github">知识库</b-dropdown-item>
              <b-dropdown-item to="/sharedFiles/index">共享文件</b-dropdown-item>
              <b-dropdown-item to="/sharedFiles/upload">上传共享文件</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="用户">
              <b-dropdown-item to="/problem/list/incoming">新进用户问题</b-dropdown-item>
              <b-dropdown-item to="/problem/list/ongoing">我的用户问题</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown text="管理员权限" right v-show="authLevel(2)">
              <b-dropdown-item to="/person/index">人员列表</b-dropdown-item>
              <b-dropdown-item to="/announcement/create">发布通知</b-dropdown-item>
              <b-dropdown-item to="/manage/selection">管理选项</b-dropdown-item>
              <b-dropdown-item to="/manage/procedure">管理流程</b-dropdown-item>
              <b-dropdown-item to="/manage/payment/account">管理付款账户信息</b-dropdown-item>
              <b-dropdown-item to="/manage/invoice/info">管理开票信息</b-dropdown-item>
              <b-dropdown-item to="/manage/authlevel">管理权限</b-dropdown-item>
              <b-dropdown-item to="/manage/todo">管理</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item to="/message">
              消息
              <span v-if="computedMessageCount > 0">
                <i class="fas fa-bullhorn"></i>
                {{ computedMessageCount }}
              </span>
            </b-nav-item>
            <b-nav-item to="/procedure/completed">我审批的</b-nav-item>
            <b-nav-item to="/person/profile"><b-avatar variant="info"></b-avatar>我的</b-nav-item>
            <b-nav-item to="/manual">说明</b-nav-item>
            <b-nav-item v-on:click="logout()">退出登录</b-nav-item>
          </b-navbar-nav>

      </b-navbar>

      <b-navbar v-show="authLevel(2)" toggleable="lg" type="light" variant="info">
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
             <b-form-input size="sm" class="mr-sm-2" placeholder="搜索" v-model="query"></b-form-input>
             <router-link v-if="query != ''" :to="{ name: 'SearchOverall', params: {query: query} }" class="btn btn-light btn-sm">
               搜索   <i class="fas fa-search" aria-hidden="true"></i>
             </router-link>
           </b-nav-form>
        </b-navbar-nav>
      </b-navbar>
    </b-container>

    <transition name="fade">
      <div class="gap">
        <router-view></router-view>
      </div>
    </transition>
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import formatTime from './js/formatTime';
import storeUtil from './js/storeUtil';
import fileIO from './js/fileIO';
import todoUtil from './components/management/todoUtil';

export default {
  name: 'App',
  data() {
    return {
      msgCount: 0,
      notCount: 0,
      query: ''
    }
  },
  mixins: [
    formatTime, storeUtil, fileIO, todoUtil
  ],
  created() {
    window.addEventListener('beforeunload', this.logout);
  },
  computed : {
    computedMessageCount() {
      return this.msgCount + this.notCount;
    }
  },
  mounted() {
    // window.setInterval(() => {
    //   console.log(this.authStatus);
    //   console.log(this.isLoggedIn);
    //   if (!this.authStatus) {
    //     this.logout();
    //   }
    // }, 0.1 * 60 * 1000);

    window.setInterval(() => {
      if (this.isLoggedIn) {
        this.getCount();
      } else {
        this.logout();
      }
    }, 0.1 * 60 * 1000);
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    getCount() {
      Promise.all([
        this.messageCount(this.getUser.name),
        this.notificationCount(this.getUser.name)
      ]).then(results => {
        this.msgCount = results[0];
        this.notCount = results[1];
      }).catch(err => {
        // if (err.response.status == 401) {
          this.logout();
        // }
      });
    },
    search() {
      this.$router.push({name: 'SearchOverall', params: {query: this.query}});
      this.query = '';
    }
  }
}
</script>

/*<style>
/* Mobile phones (portrait and landscape) ---------- */
@media screen and (max-width: 767px){
    .container {
        max-width: 100%;
    }
}
/* Tablets and iPads (portrait and landscape) ---------- */
@media screen and (min-width: 768px) and (max-width: 1023px){
    .container {
        max-width: 768px;
    }
}
/* Low resolution desktops and laptops ---------- */
@media screen and (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}
/* High resolution desktops and laptops ---------- */
@media screen and (min-width: 1280px) and (max-width: 2560px) {
    .container {
        max-width: 2560px;
    }
}

/* iMac ---------- */
@media screen and (min-width: 2560px) {
  .container {
    max-width: 2340px;
  }
}
</style>*/
