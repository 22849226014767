<template>
  <div class="container">
    <div class="card">

      <div class="card-header">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a class="nav-link" @click="selection = 1" :class="selection == 1 ? 'active' : ''" href="#">本月一览</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="selection = 2" :class="selection == 2 ? 'active' : ''" href="#">公司总览</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="selection = 3" :class="selection == 3 ? 'active' : ''" href="#">个人总览</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" @click="selection = 4" :class="selection == 4 ? 'active' : ''" href="#">费用一览</a>
          </li>
        </ul>
      </div>

      <div v-show="selection == 1">
        <CurrentFinanceBar></CurrentFinanceBar>
      </div>
    </div>

    <div v-show="selection == 2">
      <canvas id="overallTimelineBar" width="100%"></canvas>
      <CTemp :contractDisplay="contractTimelineDisplay"></CTemp>
      <RTemp
      :list="['projectTitle', 'estimatedAmount', 'amount', 'filename', 'method', 'completeTime', 'returnDate']"
      :reimbursements="reimbursementTimelineDisplay"
      :pagination="false"
      @deleteReimbursement="">
    </RTemp>
    <br /><br /><br />
  </div>

  <div v-show="selection == 3">
    <select v-model="person">
      <option disabled selected value>-----请选择-----</option>
      <option v-for="person in people" :key="person" :value="person">{{ person }}</option>
    </select>

    <PersonalMonthFinanceBar :person="person"></PersonalMonthFinanceBar>
    <br /><br /><br />
  </div>

  <ReimbursementInsight v-show="selection == 4"></ReimbursementInsight>

</div>
</div>
</template>

<script>
import Chart from 'chart.js';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import moment from 'moment';
import accountingUtil from '@/js/accountingUtil';
import formatTime from '@/js/formatTime';
import translateUtil from '@/js/translateUtil';
import queryUtil from '@/js/queryUtil';
import monthlyFinanceUtil from './monthlyFinanceUtil';
import ReimbursementInsight from '@/components/reimbursement/ReimbursementInsight';
import PersonalMonthFinanceBar from './PersonalMonthFinanceBar';
import CurrentFinanceBar from './CurrentFinanceBar';

export default {
  data() {
    return {
      people: [],
      person: '',
      selection: 1
    }
  },
  watch: {
    'selection': {
      handler: function(after, before) {
        switch(after) {
          case 1:
          this.createBar();
          break;
          case 2:
          this.createOverallTimelineBar();
          break;
          case 3:
          this.peopleWithIncompleteContracts();
          break;
          case 4:
          break;
        }
      }
    }
  },
  mixins: [
    sortUtil, storeUtil, accountingUtil, formatTime, translateUtil, queryUtil, monthlyFinanceUtil
  ],
  components: {
    ReimbursementInsight, PersonalMonthFinanceBar, CurrentFinanceBar
  },
  methods: {
    createOverallTimelineBar() {
      Promise.all([
        this.monthlyDueReceive(this.contractQuery('销售合同')),
        this.monthlyDuePay(this.contractQuery('采购合同')),
        this.monthlyDueReturn(this.marginQuery)
      ]).then(results => {
        this.createTimelineBar(results, 'overallTimelineBar');
      });
    },
    peopleWithIncompleteContracts() {
      this.axios.post(`contracts/${this.getLocation}/distinct`, {
        field: 'owner',
        query: {
          $where: `this.amount > this.receiptAmount || this.amount > this.paidAmount`,
          category: { $ne: '中标通知书' }
        }
      }).then(response => {
        this.people = response.data;
      });
    },
  }
}
</script>
