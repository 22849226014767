<template>
  <div>
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{disabled: currentPage * 1 - 1 == 0}">
        <a class="page-link" @click="$emit('minusCurrentPage')">
          <i class="fas fa-angle-double-left"></i>
        </a>
      </li>

      <li class="page-item" :class="{active: currentPage * 1 == 1}" style="display: inline-block">
        <a class="page-link" @click="$emit('setCurrentPage', 1)">
          1
        </a>
      </li>

      <li class="page-item" style="display: inline-block" v-show="displayedPageArray[0] > 2">
        <a class="page-link">...</a>
      </li>

      <li
      class="page-item"
      :class="{active: currentPage * 1 == pageNumber}"
      v-for="pageNumber in displayedPageArray"
      :key="pageNumber"
      style="display: inline-block">
        <a class="page-link" @click="$emit('setCurrentPage', pageNumber)">
          {{ pageNumber }}
        </a>
      </li>

      <li class="page-item" style="display: inline-block" v-show="displayedPageArray[displayedPageArray.length - 1] < totalPageNum - 1">
        <a class="page-link">...</a>
      </li>

      <li class="page-item" :class="{active: currentPage * 1 == totalPageNum * 1}" style="display: inline-block" v-show="totalPageNum > 1">
        <a class="page-link" @click="$emit('setCurrentPage', totalPageNum)">
          {{ totalPageNum }}
        </a>
      </li>

      <li class="page-item" :class="{disabled: currentPage * 1 + 1 > totalPageNum * 1}">
        <a class="page-link" @click="$emit('incrementCurrentPage')">
          <i class="fas fa-angle-double-right"></i>
        </a>
      </li>

      <li class="page-item" style="margin-left: 20px">
        <input class="pageInput" type="number" min="1" :max="totalPageNum" v-model="searchPageNum" v-on:keyup.enter="$emit('setCurrentPage', searchPageNum), searchPageNum = ''">
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  data() {
    return {
      searchPageNum: ''
    }
  },
  props: [
    'array',
    'currentPage',
    'pageSize'
  ],
  computed: {
    totalPageNum() {
      return Math.ceil(this.array.length / this.pageSize);
    },
    displayedPageArray() {
      // Be careful about numbers with different types - string and number
      var range = 2;
      var array = [];
      var cur = this.currentPage * 1 - range;
      while (cur <= this.currentPage * 1 + range) {
        if (cur > 1 && cur < this.totalPageNum * 1) {
          array.push(cur);
        }
        cur++;
      }
      return array;
    }
  }
}
</script>

<style>
.pageInput {
  padding: 2px 10px;
  border-radius: 10px;
  display: inline-block;
  margin: 2px 2px;
}
</style>
