<template>
  <b-modal ref="my-modal" hide-footer>
    <div class="text-center">
      <h5>已第{{ viewFileAttempt }}次为您尝试打开文件</h5>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: [
    'viewFileAttempt'
  ],
  watch: {
    viewFileAttempt(newVal) {
      if (newVal > 0) {
        this.showModal();
      }
      if (newVal == 0) {
        this.hideModal();
      }
    }
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    }
  }
}
</script>
