<template>
  <Person title="修改" :id="$route.params.id"></Person>
</template>

<script>
import storeUtil from '@/js/storeUtil.js';
import Person from './PersonTemplate';

export default {
  mixins: [
    storeUtil
  ],
  components: {
    Person
  }
}
</script>
