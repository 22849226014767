<template>
  <div class="card" id="completedReimbursements">
    <div class="card-header">
      <h3>我审批的费用</h3>
      <b>* 此处显示近三个月来审批的费用。</b>
    </div>
    <div class="card-body">
      <rise-loader :loading="loading" class="text-center"></rise-loader>
      <div v-if="!loading && sortedReimbursements.length > 0" style="overflow-x: auto">
        <table class="table table-hover table-sm">
          <thead>
            <tr class="text-center">
              <th class="align-middle" style="min-width:100px">项目名称</th>
              <th class="align-middle" style="min-width:90px">责任人</th>
              <th class="align-middle" style="min-width:80px">发票</th>
              <th class="align-middle" style="min-width:100px">预估金额</th>
              <th class="align-middle" style="min-width:100px">实报金额</th>
              <th class="align-middle" style="min-width:80px">类别</th>
              <th class="align-middle" style="min-width:100px">付款方式</th>
              <th class="align-middle" style="min-width:200px">说明</th>
              <Header header="我通过的时间" style="min-width: 120px"></Header>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="r in filteredReimbursements" :key="r._id" class="text-center">
              <td class="align-middle" style="width: 10%">{{ !r.projectId ? '日常开销' : r.projectTitle }}</td>
              <td class="align-middle">{{ r.owner }}</td>
              <td class="align-middle"><a href="#" @click="viewFile(r.fileId, r.filename)">{{ r.filename }}</a></td>
              <td class="align-middle">{{ r.estimatedAmount }}</td>
              <td class="align-middle">{{ r.amount }}</td>
              <td class="align-middle">{{ r.category }}</td>
              <td class="align-middle" v-if="r.method == 'company'" @click="viewFile(r.accountImgId, r.accountImgFilename)">
                {{ translateReimbursementMethod(r.method) }}
                <i class="fa fa-sticky-note" aria-hidden="true"></i>
              </td>
              <td class="align-middle" v-else>{{ translateReimbursementMethod(r.method) }}</td>
              <td class="align-middle" v-html="!r.note ? '' : r.note.replace(/\n/, '<br /><br />')"></td>

              <td v-if="r.procedureTimestamp != null" class="align-middle">
                <a v-for="role in getUser.role.split('/')">
                  <span v-if="r.procedureTimestamp[role] != null">
                    {{ getFormattedDate(r.procedureTimestamp[role]) }}
                  </span>
                  <span v-else-if="r.procedureTimestamp[role+'初审'] != null">
                    {{ getFormattedDate(r.procedureTimestamp[role+'初审']) }}
                  </span>
                </a>
              </td>
              <td v-else></td>

              <td class="align-middle" v-if="r.procedureNote != null && r.procedureNote != ''">
                <ProcedureNote
                :id="r._id.toString() + 'ProcedureNote'"
                :note="r.procedureNote">
              </ProcedureNote>
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>

      <Pagination
      :array="sortedReimbursements"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @setCurrentPage="setCurrentPage"
      @incrementCurrentPage="currentPage++"
      @minusCurrentPage="currentPage--">
    </Pagination>
  </div>
  <div v-else-if="!loading && sortedReimbursements.length == 0">
    <Empty></Empty>
  </div>
</div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import translateUtil from '@/js/translateUtil';
import loaderUtil from '@/js/loaderUtil';
import util from './util';
import moment from 'moment';

export default {
  data() {
    return {
      reimbursements: []
    }
  },
  mixins: [
    util, formatTime, sortUtil, storeUtil, translateUtil, loaderUtil
  ],
  created() {
    this.getReimbursements();
  },
  computed : {
    sortedReimbursements() {
      return this.sortByProcedureTimestamp(this.reimbursements);
    },
    filteredReimbursements() {
      return this.filterForPagination(this.sortedReimbursements);
    }
  },
  methods: {
    getReimbursements() {
      this.axios.get(`redis/lrange/reimbursementProcedure`).then(response => {
        var procedure = this.inProcedureArray(response.data[0]);
        this.axios.post(`overall/${this.getLocation}/reimbursements/project/title`, [
          { $match:
            {
              procedure: { $in: procedure }
            }
          }
        ]).then((response) => {
          this.reimbursements = response.data.filter(row => {
            var time = this.determineProcedureTimestamp(row.procedureTimestamp, this.getUser.role);
            return time != null && moment(time) > moment().add(-3, 'months');
          });
          this.loading = false;
        });
      });
    }
  }
}
</script>
