import storeUtil from '@/js/storeUtil';

export default {
  data() {
    return {
      customerList: [],
      projectCategory: []
    }
  },
  mixins: [
    storeUtil
  ],
  created() {
    this.fetchCustomerList();
    this.fetchProjectCategory();
  },
  methods: {
    fetchCustomerList() {
      this.axios.post(`projects/${this.getLocation}/distinct`, {
        field: 'customer'
      }).then((response) => {
        this.customerList = response.data;
      });
    },
    fetchProjectCategory() {
      this.axios.get(`redis/members/projectCategory`).then(response => {
        this.projectCategory = response.data[0];
      });
    },
  }
}
