import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import NProgress from 'nprogress';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import 'vue-event-calendar/dist/style.css';
import vueEventCalendar from 'vue-event-calendar';

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
VueCookies.set('theme','default');
VueCookies.set('hover-time','1s');

Vue.use(BootstrapVue);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}; // Prevent duplicated nevigation problem
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(vueEventCalendar, {
    locale: 'zh',
    color: '#82CAFA',
    weekStartOn: 1
});

import '@/assets/css/timeline.css';
import '@/assets/css/trigger.css';
import '@/assets/css/star.css';
import '@/assets/css/modal.css';
import '@/assets/css/dragAndDrop.css';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/nprogress/nprogress.css';

import '../node_modules/fontawesome-free-5.10.1-web/css/all.css';

import draggable from "vuedraggable";

import ErrorPage from '@/components/ErrorPage.vue';
import Home from '@/components/Home.vue';
import SearchOverall from '@/components/SearchOverall.vue';

import Header from '@/components/Header.vue';
import Pagination from '@/components/Pagination.vue';
import TimelineHover from '@/components/TimelineHoverTemplate.vue';
import ProcedureNote from '@/components/ProcedureNoteTemplate.vue';
import FileLoading from '@/components/FileLoading.vue';
import FileAttempt from '@/components/FileAttempt.vue';
import Uploading from '@/components/Uploading.vue';
import Empty from '@/components/Empty.vue';
import rlabel from '@/components/rlabel.vue';
import WhoIsWho from '@/components/WhoIsWho.vue';
import SupplierPopover from '@/components/supplier/SupplierPopover.vue';
import Search from '@/components/SearchTemplate.vue';
import FileUploadBox from '@/components/FileUploadBox.vue';
import Download from '@/components/Download.vue';
import ReimbursementTableTemplate from '@/components/reimbursement/ReimbursementTableTemplate.vue';
import ContractListTemplate from '@/components/contract/ContractListTemplate.vue';
import Todo from '@/components/project/Todo.vue';

Vue.component('Header', Header);
Vue.component('Pagination', Pagination);
Vue.component('TimelineHover', TimelineHover);
Vue.component('ProcedureNote', ProcedureNote);
Vue.component('FileLoading', FileLoading);
Vue.component('FileAttempt', FileAttempt);
Vue.component('Uploading', Uploading);
Vue.component('Empty', Empty);
Vue.component('rlabel', rlabel);
Vue.component('WhoIsWho', WhoIsWho);
Vue.component('SupplierPopover', SupplierPopover);
Vue.component('Search', Search);
Vue.component('FileUploadBox', FileUploadBox);
Vue.component('Download', Download);
Vue.component('RTemp', ReimbursementTableTemplate);
Vue.component('CTemp', ContractListTemplate);
Vue.component('todo', Todo);
Vue.component('draggable', draggable);

import App from './App.vue';
import Manual from '@/components/Manual.vue';
import ManageSelection from '@/components/management/ManageSelection.vue';
import ManageProcedure from '@/components/management/ManageProcedure.vue';
import ManageAuthLevel from '@/components/management/ManageAuthLevel.vue';
import UploadSharedFiles from '@/components/sharedFiles/UploadSharedFiles.vue';
import ManageTodo from '@/components/management/ManageTodo.vue';
import ManagePaymentAccount from '@/components/management/ManagePaymentAccount.vue';
import ManageInvoiceInfo from '@/components/management/ManageInvoiceInfo.vue';
import SharedFiles from '@/components/sharedFiles/SharedFiles.vue';
import SharedFilesHistory from '@/components/sharedFiles/SharedFilesHistory.vue';

import Overtime from '@/components/workingHour/Overtime.vue';
import Vacation from '@/components/workingHour/Vacation.vue';
import WorkingHourInfo from '@/components/workingHour/WorkingHourInfo.vue';

import MyCompletedProcedure from '@/components/completedProcedure/MyCompletedProcedure.vue';

import ItemForm from '@/components/item/ItemForm.vue';
import ItemListOngoing from '@/components/item/ItemListOngoing.vue';
import ItemListComplete from '@/components/item/ItemListComplete.vue';
import ItemQuery from '@/components/item/ItemQuery.vue';
import ItemQuoting from '@/components/item/ItemQuoting.vue';
import ItemDetails from '@/components/item/ItemDetails.vue';
import ItemConfirmation from '@/components/item/ItemConfirmation.vue';
import ItemExcelImport from '@/components/item/ItemExcelImport.vue';
import ItemComparison from '@/components/item/ItemComparison.vue';
import ItemAddHistory from '@/components/item/ItemAddHistory.vue';

import SupplierForm from '@/components/supplier/SupplierForm.vue';
import SupplierList from '@/components/supplier/SupplierList.vue';

import CreatePerson from '@/components/person/CreatePerson.vue';
import IndexPerson from '@/components/person/IndexPerson.vue';
import EditPerson from '@/components/person/EditPerson.vue';
import Login from '@/components/Login.vue';
import Profile from '@/components/person/Profile.vue';

import ProjectCreate from '@/components/project/ProjectCreate.vue';
import ProjectEdit from '@/components/project/ProjectEdit.vue';
import ProjectInfo from '@/components/project/ProjectInfo.vue';
import ProjectListOngoing from '@/components/project/ProjectListOngoing.vue';
import ProjectListComplete from '@/components/project/ProjectListComplete.vue';
import ProjectListHistory from '@/components/project/ProjectListHistory.vue';
import ProjectListOpportunity from '@/components/project/ProjectListOpportunity.vue';
import ProjectListOverall from '@/components/project/ProjectListOverall.vue';
import UploadProjectFiles from '@/components/project/UploadProjectFiles.vue';
import FileManagement from '@/components/project/FileManagement.vue';

import ProblemInfo from '@/components/problem/ProblemInfo.vue';
import ProblemListIncoming from '@/components/problem/ProblemListIncoming';
import ProblemListOngoing from '@/components/problem/ProblemListOngoing';

import ContractUpload from '@/components/contract/ContractUpload.vue';
import ContractListOngoing from '@/components/contract/ContractListOngoing.vue';
import ContractListHistory from '@/components/contract/ContractListHistory.vue';
import ContractListInvalid from '@/components/contract/ContractListInvalid.vue';
import ContractEdit from '@/components/contract/ContractEdit.vue';
import ContractInfo from '@/components/contract/ContractInfo.vue';

import Finance from '@/components/finance/Finance.vue';
import FinanceBar from '@/components/finance/FinanceBar.vue';
import UpdatePaymentReceipt from '@/components/finance/UpdatePaymentReceipt.vue';
import PaymentHistory from '@/components/finance/PaymentHistory.vue';
import Message from '@/components/Message.vue';

import ReimbursementForm from '@/components/reimbursement/ReimbursementForm.vue';
import ReimbursementListCompany from '@/components/reimbursement/ReimbursementListCompany.vue';
import ReimbursementListIndividual from '@/components/reimbursement/ReimbursementListIndividual.vue';
import ReimbursementListComplete from '@/components/reimbursement/ReimbursementListComplete.vue';
import ReimbursementInsight from '@/components/reimbursement/ReimbursementInsight.vue';
import ManageMargin from '@/components/reimbursement/ManageMargin.vue';
// import EditReimbursement from '@/components/reimbursement/EditReimbursement.vue';

import ReceivePayment from '@/components/payment/ReceivePayment.vue';
import PaymentProcedure from '@/components/payment/PaymentProcedure.vue';

import ReceiveReceipt from '@/components/receipt/ReceiveReceipt.vue';
import ReceiptProcedure from '@/components/receipt/ReceiptProcedure.vue';
import ReceiptProcedureComplete from '@/components/receipt/ReceiptProcedureComplete.vue';

import StampProcedure from '@/components/stamp/StampProcedure.vue';
import StampIndex from '@/components/stamp/StampIndex.vue';
import StampIndexComplete from '@/components/stamp/StampIndexComplete.vue';

import CarProcedure from '@/components/car/CarProcedure.vue';
import CarIndex from '@/components/car/CarIndex.vue';

import Announcement from '@/components/Announcement.vue';

Vue.config.productionTip = false;

const routes = [
  {
    path: '/github',
    beforeEnter() {
      location.href = 'https://jwt0t.github.io/techNotes'
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'Home',
    path: '/',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Manual',
    path: '/manual',
    component: Manual,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Overtime',
    path: '/workingHour/overtime',
    component: Overtime,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Vacation',
    path: '/workingHour/vacation',
    component: Vacation,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'WorkingHourInfo',
    path: '/workingHour/info/:personId',
    component: WorkingHourInfo,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'MyCompletedProcedure',
    path: '/procedure/completed',
    component: MyCompletedProcedure,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'SearchOverall',
    path: '/search/:query',
    component: SearchOverall,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'ManageSelection',
    path: '/manage/selection',
    component: ManageSelection,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'ManageProcedure',
    path: '/manage/procedure',
    component: ManageProcedure,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'ManageAuthLevel',
    path: '/manage/authlevel',
    component: ManageAuthLevel,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'UploadSharedFiles',
    path: '/sharedFiles/upload',
    component: UploadSharedFiles,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ManageTodo',
    path: '/manage/todo',
    component: ManageTodo,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'ManagePaymentAccount',
    path: '/manage/payment/account',
    component: ManagePaymentAccount,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'ManageInvoiceInfo',
    path: '/manage/invoice/info',
    component: ManageInvoiceInfo,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'SharedFiles',
    path: '/sharedFiles/index',
    component: SharedFiles,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'SharedFilesHistory',
    path: '/sharedFiles/history',
    component: SharedFilesHistory,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemForm',
    path: '/item/form/:id',
    component: ItemForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemForm',
    path: '/item/form',
    component: ItemForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemListOngoing',
    path: '/item/list/ongoing',
    component: ItemListOngoing,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemListComplete',
    path: '/item/list/complete',
    component: ItemListComplete,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemComparison',
    path: '/item/comparison/:id',
    component: ItemComparison,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemAddHistory',
    path: '/item/add/history',
    component: ItemAddHistory,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemQuery',
    path: '/item/query',
    component: ItemQuery,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemQuoting',
    path: '/item/quoting',
    component: ItemQuoting,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemConfirmation',
    path: '/item/confirmation/:contractId',
    component: ItemConfirmation,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemDetails',
    path: '/item/details/:id',
    component: ItemDetails,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ItemExcelImport',
    path: '/item/excel/import',
    component: ItemExcelImport,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'SupplierForm',
    path: '/supplier/form',
    component: SupplierForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'SupplierForm',
    path: '/supplier/form/:id',
    component: SupplierForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'SupplierList',
    path: '/supplier/list',
    component: SupplierList,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'CreatePerson',
    path: '/person/create',
    component: CreatePerson,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'IndexPerson',
    path: '/person/index',
    component: IndexPerson,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'EditPerson',
    path: '/person/edit/:id',
    component: EditPerson,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: Login
  },
  {
    name: 'Profile',
    path: '/person/profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProjectCreate',
    path: '/project/create',
    component: ProjectCreate,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProjectListOngoing',
    path: '/project/list/ongoing',
    component: ProjectListOngoing,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProjectListComplete',
    path: '/project/list/complete',
    component: ProjectListComplete,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProjectListHistory',
    path: '/project/list/history',
    component: ProjectListHistory,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProjectListOpportunity',
    path: '/project/list/opportunity',
    component: ProjectListOpportunity,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProjectEdit',
    path: '/project/edit/:id',
    component: ProjectEdit,
    meta:{
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'ProjectListOverall',
    path: '/project/list/overall/:status',
    component: ProjectListOverall,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'UploadProjectFiles',
    path: '/project/upload/files/:id',
    component: UploadProjectFiles,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'FileManagement',
    path: '/project/file/management',
    component: FileManagement,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProblemInfo',
    path: '/problem/info/:id',
    component: ProblemInfo,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProblemListIncoming',
    path: '/problem/list/incoming',
    component: ProblemListIncoming,
    meta: {
      requiresAuth: true,
      authLevel2: true

    }
  },
  {
    name: 'ProblemListOngoing',
    path: '/problem/list/ongoing',
    component: ProblemListOngoing,
    meta: {
      requiresAuth: true,
      authLevel2: true

    }
  },
  {
    name: 'ContractUpload',
    path: '/contract/upload',
    component: ContractUpload,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ContractListOngoing',
    path: '/contract/list/ongoing',
    component: ContractListOngoing,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ContractListHistory',
    path: '/contract/list/history',
    component: ContractListHistory,
    meta: {
      authLevel2: true,
      requiresAuth: true
    }
  },
  {
    name: 'ContractListInvalid',
    path: '/contract/list/invalid',
    component: ContractListInvalid,
    meta: {
      authLevel2: true,
      requiresAuth: true
    }
  },
  {
    name: 'ContractEdit',
    path: '/contract/edit/:id',
    component: ContractEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ContractInfo',
    path: '/contract/info/:id',
    component: ContractInfo,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ProjectInfo',
    path: '/project/info/:id',
    component: ProjectInfo,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Finance',
    path: '/finance',
    component: Finance,
    meta: {
      requiresAuth: true,
      authLevel4: true
    }
  },
  {
    name: 'FinanceBar',
    path: '/finance/bar',
    component: FinanceBar,
    meta: {
      requiresAuth: true,
      authLevel3: true
    }
  },
  {
    name: 'UpdatePaymentReceipt',
    path: '/update/payment/receipt',
    component: UpdatePaymentReceipt,
    meta: {
      requiresAuth: true,
      authLevel4: true
    }
  },
  {
    name: 'PaymentHistory',
    path: '/finance/payment/history',
    component: PaymentHistory,
    meta: {
      requiresAuth: true,
      authLevel4: true
    }
  },
  {
    name: 'Message',
    path: '/message',
    component: Message,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'PaymentProcedure',
    path: '/payment/procedure',
    component: PaymentProcedure,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ReimbursementForm',
    path: '/reimbursement/form',
    component: ReimbursementForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ReimbursementForm',
    path: '/reimbursement/form/:id',
    component: ReimbursementForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ReimbursementListCompany',
    path: '/reimbursement/list/company',
    component: ReimbursementListCompany,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ReimbursementListIndividual',
    path: '/reimbursement/list/individual',
    component: ReimbursementListIndividual,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ReimbursementListComplete',
    path: '/reimbursement/list/complete',
    component: ReimbursementListComplete,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ReimbursementInsight',
    path: '/reimbursement/insight',
    component: ReimbursementInsight,
    meta: {
      requiresAuth: true,
      authLevel4: true
    }
  },
  {
    name: 'ManageMargin',
    path: '/reimbursement/margin',
    component: ManageMargin,
    meta: {
      requiresAuth: true,
      authLevel4: true
    }
  },
  {
    name: 'ReceivePayment',
    path: '/payment/receive',
    component: ReceivePayment,
    meta: {
      requiresAuth: true,
      authLevel4: true
    }
  },
  {
    name: 'ReceiveReceipt',
    path: '/receipt/receive',
    component: ReceiveReceipt,
    meta: {
      requiresAuth: true,
      authLevel4: true
    }
  },
  {
    name: 'ReceiptProcedure',
    path: '/receipt/procedure',
    component: ReceiptProcedure,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'ReceiptProcedureComplete',
    path: '/receipt/procedure/complete',
    component: ReceiptProcedureComplete,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'StampProcedure',
    path: '/stamp/procedure',
    component: StampProcedure,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'StampIndex',
    path: '/stamp/index',
    component: StampIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'StampIndexComplete',
    path: '/stamp/index/complete',
    component: StampIndexComplete,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'CarProcedure',
    path: '/car/procedure',
    component: CarProcedure,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'CarIndex',
    path: '/car/index',
    component: CarIndex,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Announcement',
    path: '/announcement/create',
    component: Announcement,
    meta: {
      requiresAuth: true,
      authLevel2: true
    }
  },
  {
    name: 'ErrorPage',
    path: '/error',
    component: ErrorPage
  }
];

const router = new VueRouter({ mode: 'history', routes: routes, scrollBehaviour(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  } else {
    return { x: 0, y: 0 }
  }
}
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authLevel2)) {
    if (store.getters.getAuthLevel == 2) {
      next()
      return
    }
    alert('抱歉，您暂无权限查看此页面')
    next(from)
  } else {
    next()
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authLevel3)) {
    if (store.getters.getAuthLevel <= 3) {
      next()
      return
    }
    alert('抱歉，您暂无权限查看此页面')
    next(from)
  } else {
    next()
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authLevel4)) {
    if (store.getters.getAuthLevel <= 4) {
      next()
      return
    }
    alert('抱歉，您暂无权限查看此页面')
    next(from)
  } else {
    next()
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
});

router.afterEach(() => {
  NProgress.done()
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
