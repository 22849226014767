<template>
  <a v-if="contract.projectTitle == '无'">{{ contract.projectTitle }}</a>
  <span v-else :style="[contract.projectTitle == '无' ? {'color': 'red'} : {}]">
    <router-link :to="{ name: 'ProjectInfo', params: { id: contract.projectId } }">
      {{ contract.projectTitle }}
    </router-link>
  </span>
</template>

<script>
export default {
  props: [
    'contract'
  ]
}
</script>
