<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <router-link :to="{ name: 'ProjectListOpportunity' }" class="nav-link" :class="status == 'opportunity' ? 'active' : ''">机会</router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'ProjectListOngoing' }" class="nav-link" :class="status == 'ongoing' ? 'active' : ''">进行中</router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'ProjectListComplete' }" class="nav-link" :class="status == 'complete' ? 'active' : ''">已完成</router-link>
    </li>
    <li class="nav-item" v-if="authLevel(2)">
      <router-link :to="{ name: 'ProjectListHistory' }" class="nav-link" :class="status == 'history' ? 'active' : ''">历史项目</router-link>
    </li>
  </ul>
</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  props: [
    'status',
  ],
  mixins: [
    storeUtil
  ]
}
</script>
