<template>
  <div class="card-body">
    <div v-if="array != null && array.length > 0" style='overflow-x:auto'>
      <table class="table table-hover table-bordered text-center">
        <thead>
          <tr>
            <th class="align-middle">开始时间</th>
            <th class="align-middle">结束时间</th>
            <th class="align-middle">时长</th>
            <th class="align-middle">备注</th>
            <th class="align-middle">审批进度</th>
            <th class="align-middle"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="element in array">
            <td class="align-middle">{{ getFormattedDate(element.startTime) }}</td>
            <td class="align-middle">{{ getFormattedDate(element.endTime) }}</td>
            <td class="align-middle">{{ timeDiff(element.startTime, element.endTime) }}</td>
            <td class="align-middle">{{ element.note }}</td>
            <td class="align-middle">
              {{ element.procedure }}
              <WhoIsWho :id="element._id" :role="element.procedure" v-if="element.procedure != '完成' && element.procedure != '驳回'"></WhoIsWho>
            </td>
            <td class="align-middle"><button class="btn btn-danger" @click="$emit('deleteWorkingHour', element._id)">删除</button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <Empty v-else></Empty>
  </div>
</template>

<script>
import formatTime from '@/js/formatTime';

export default {
  props: [
    'array'
  ],
  mixins:[
    formatTime
  ]
}
</script>
