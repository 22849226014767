<template>
  <div class="container">
    <div class="card">
    <div class="card-header">
      <h3>上传模板</h3>
    </div>
    <div class="card-body">
      <form v-on:submit.prevent="uploadTemplate">

        <FileUploadBox
        v-if="!this.$route.params.id"
        label="上传文件"
        :notes="['支持拖拽; 支持同时上传多个文件; 重新选择或拖拽文件即可替换原先上传文件', '文件名务必与文件内容相符']"
        @setFiles="files = $event"
        :required="true"
        :supportMultiFiles="true"
        :filenameCheck="false">
      </FileUploadBox>

      <FileUploadBox
      v-else
      label="上传文件"
      :notes="['支持拖拽; 支持同时上传多个文件; 重新选择或拖拽文件即可替换原先上传文件', '文件名务必与文件内容相符']"
      @setFiles="files = $event"
      :required="false"
      :supportMultiFiles="true"
      :filenameCheck="false">
    </FileUploadBox>

        <div class="form-group">
          <rlabel label="文件类别"></rlabel>
          <a v-if="!$route.params.id">
            <b-form-input list="template-category-list" v-model="template.category"></b-form-input>
            <b-form-datalist id="template-category-list" :options="templateCategoryList"></b-form-datalist>
          </a>
          <a v-else>{{ template.category }}</a>
          <div v-if="showCategoryLengthAlert" class="alert alert-danger">
            自定义文件类别不超过10个字符
          </div>
        </div>
          <div class="form-group">
            <label>备注</label>
            <textarea type="text" class="form-control" rows="5" v-model="template.note"/>
          </div>

        <div class="form-group">
          <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
        </div>
        <Uploading :waiting="waiting"></Uploading>

      </form>
    </div>
  </div>
  </div>
</template>

<script>
import fileIO from '@/js/fileIO';
import loaderUtil from '@/js/loaderUtil';
import storeUtil from '@/js/storeUtil';
import moment from 'moment';

export default {
  data() {
    return {
      template: {},
      templateCategoryList: []
    }
  },
  mixins: [
    fileIO, loaderUtil, storeUtil
  ],
  created() {
    !this.$route.params.id ? this.fetchDistinctTemplateCategory() : this.fetchTemplate();
  },
  computed: {
    showCategoryLengthAlert() {
      return this.template != null && this.template.category != null && this.template.category.length > 10;
    }
  },
  methods: {
    fetchDistinctTemplateCategory() {
      this.axios.post(`template/distinct`, {
        field: 'category'
      }).then(response => {
        this.templateCategoryList = response.data;
      });
    },
    fetchTemplate() {
      this.axios.post(`template/query`, {
        _id: this.$route.params.id
      }).then(response => {
        this.template = response.data;
      });
    },
    uploadTemplate() {
      if (this.showCategoryLengthAlert)
        return;

      this.waiting = true;
      this.handleFiles(this.addTemplate, true);
    },
    addTemplate(id, filename) {
      if (id != null & filename != null) {
        this.template.fileId = id;
        this.template.filename = filename;
      }
      this.template.owner = this.getUser.name;
      this.template.uploadTime = moment();
      this.template.downloadTimes = 0;

      if (this.$route.params.id != null) {
        this.template._id = null;
        this.template.previousVersion = this.$route.params.id;
      }

      this.axios.post(`template/add`, this.template).then(response => {
        alert(response.data);
        this.waiting = false;
        this.$router.push({name: 'SharedFiles'});
      }).catch(err => {
        alert(err.response.data);
      });
    }
  }
}
</script>
