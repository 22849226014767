<template>
<span>
<i class="fa fa-question-circle" aria-hidden="true" :id="id" @click="show = true"></i>

<b-popover :target="id" placement="right" triggers="hover" v-show="show && person != null">
    {{ person.name }}
</b-popover>
</span>
</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  props: [
    'id',
    'role'
  ],
  data() {
    return {
      person: {},
      show: false
    }
  },
  mixins: [
    storeUtil
  ],
  created() {
    this.axios.post(`people/${this.getLocation}/query`, {
      role: {
        $regex: `.*${this.role}.*`
      }
    }).then(response => {
      this.person = response.data;
    });
  }
}
</script>
