<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>开票申请</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="addReceipt">
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">合同编号</th>
                <td>{{ contract._id }}</td>
              </tr>
              <tr>
                <th scope="row">盖章合同</th>
                <td><a href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">{{ contract.stampedFilename }}</a></td>
              </tr>
              <tr>
                <th scope="row">合同总金额</th>
                <td>{{ contract.amount }}</td>
              </tr>
              <tr>
                <th scope="row">已付金额</th>
                <td>{{ contract.receiptAmount }}</td>
              </tr>
              <tr>
                <th scope="row">供应商</th>
                <td>{{ customer }}</td>
              </tr>
            </tbody>
          </table>

          <div class="form-group" id="priceAlert">
            <rlabel label="此次开票金额"></rlabel>
            <input type="number" step=".01" class="form-control" v-model="receipt.amount" :placeholder="contract.amount - contract.receiptAmount" required="required">
            <div v-if="showPriceAlert" class="alert alert-danger">
              开票金额应在0~{{ contract.amount - contract.receiptAmount }}之间
            </div>
          </div>
          <b-row>
            <b-col>
              <div class="form-group">
                <rlabel label="发票类型"></rlabel>
                <select class="form-control" v-model="receipt.category" required="required">
                  <option disabled selected value>-----请选择-----</option>
                  <option>专票</option>
                  <option>普票</option>
                </select>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <rlabel label="税率"></rlabel>
                <select class="form-control" v-model="receipt.taxPercentage" required="required">
                  <option disabled selected value>-----请选择-----</option>
                  <option v-for="tax in taxPercentages" :key="tax">{{ tax }}</option>
                </select>
              </div>
            </b-col>
          </b-row>
          <div class="form-group">
            <label>备注</label>
            <textarea type="text" class="form-control" v-model="receipt.note" rows="5"/>
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="开票申请" :disabled="waiting">
          </div>
          <Uploading :waiting="waiting"></Uploading>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      contract: {},
      receipt: {},
      taxPercentages: [],
      customer: ''
    }
  },
  mixins: [storeUtil, loaderUtil],
  created: function() {
    this.fetchContract();
    this.getTaxPercentageSelection();
  },
  computed : {
    showPriceAlert: function() {
      return (this.receipt.amount <= 0 || this.receipt.amount > this.contract.amount - this.contract.receiptAmount);
    }
  },
  methods: {
    fetchContract() {
      this.axios.post(`contracts/${this.getLocation}/query`, {
        _id: this.$route.params.id
      }).then(response => {
        if (this.getUser.name == response.data.owner) {
          this.contract = response.data;

          this.axios.post(`projects/${this.getLocation}/query`, {
            contractId: this.$route.params.id
          }).then(response => {
            this.customer = response.data.customer;
            this.$forceUpdate();
          });
        } else {
          alert('您暂无权限查看此页');
          this.$router.go(-1);
        }
      }).catch(() => {
        alert('您暂无权限查看此页');
        this.$router.go(-1);
      });
    },
    addReceipt() {
      this.waiting = true;
      if (this.showPriceAlert) {
          document.getElementById('priceAlert').scrollIntoView();
          this.waiting = false;
          return;
      }

      this.receipt.procedure = this.contract.owner;
      this.receipt.contractId = this.contract._id;

      this.axios.post(`receipts/${this.getLocation}/add`, this.receipt).then((response) => {
        this.axios.post(`receipts/${this.getLocation}/update/procedure/${response.data._id}`, {}).then((response) => {
          this.waiting = false;
          alert(response.data);
          this.$router.push({name: 'ContractInfo', params: {id: this.receipt.contractId}});
        });
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getTaxPercentageSelection() {
      this.axios.get(`redis/members/taxPercentage`).then((response) => {
        this.taxPercentages = response.data[0];
      });
    }
  }
}
</script>
>
