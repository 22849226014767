<template>
  <div class="container">
  <div class="card">
    <div class="card-header">
      <h3>与我有关的采购</h3>
      <br />
      <ul class="nav nav-pills">
        <li class="nav-item">
          <router-link :to="{ name: 'ItemListOngoing'}" class="nav-link">未完成</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'ItemListComplete'}" class="nav-link active">已完成</router-link>
        </li>
      </ul>
    </div>
    <ItemListTemplate :query="query" :columns="['projectTitle', 'comparisonButton']"></ItemListTemplate>
</div>
</div>
</template>

<script>
import ItemListTemplate from './ItemListTemplate';
import storeUtil from '@/js/storeUtil';

export default {
  data() {
    return {
        query: {}
    }
  },
  components: {
    ItemListTemplate
  },
  mixins: [
    storeUtil
  ],
  created() {
    this.query = {
      contractId: { $exists: true }
    };
    if (!this.authLevel(2)) {
      this.query.owner = this.getUser.name;
    }
    //     this.axios.post(`overall/${this.getLocation}/items/project/complete`, query).then(async (response) => {
  }
}
</script>
