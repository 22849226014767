<template>
  <Person title="添加"></Person>
</template>

<script>
import Person from './PersonTemplate';

export default {
  components: {
    Person
  }
}
</script>
