<template>
    <div>
      <canvas id="personalTimelineBar" width="100%"></canvas>
      <CTemp :contractDisplay="contractTimelineDisplay"></CTemp>
      <RTemp
      :list="['projectTitle', 'estimatedAmount', 'amount', 'filename', 'method', 'completeTime', 'returnDate']"
      :reimbursements="reimbursementTimelineDisplay"
      :pagination="false"
      @deleteReimbursement="">
    </RTemp>

    </div>
</template>

<script>
import Chart from 'chart.js';
import accountingUtil from '@/js/accountingUtil';
import queryUtil from '@/js/queryUtil';
import sortUtil from '@/js/sortUtil';
import formatTime from '@/js/formatTime';
import monthlyFinanceUtil from './monthlyFinanceUtil';
import moment from 'moment';

export default {
  props: [
    'person'
  ],
  created() {
    if (this.person != null) {
      this.createPersonalTimelineBar();
    }
  },
  watch: {
    'person': {
      handler: function(after, before) {
        this.createPersonalTimelineBar();
      }
    }
  },
  mixins: [
    accountingUtil, queryUtil, sortUtil, formatTime, monthlyFinanceUtil
  ],
  methods: {
    createPersonalTimelineBar() {
      if (this.personalTimelineBar != null)
      this.personalTimelineBar.destroy();

      this.monthlyFinance = new Object();
      this.contractTimelineDisplay = [];
      this.reimbursementTimelineDisplay = [];

      Promise.all([
        this.monthlyDueReceive(this.personalContractQuery('销售合同', this.person)),
        this.monthlyDuePay(this.personalContractQuery('采购合同', this.person)),
        this.monthlyDueReturn(this.personalMarginQuery(this.person))
      ]).then(results => {
        this.createTimelineBar(results, 'personalTimelineBar');
      });
    },

  }
}
</script>
