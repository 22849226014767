import moment from 'moment';

export default {
  methods: {
    getTotal(mongooseObj, field) {
      var array = JSON.parse(JSON.stringify(mongooseObj));
      var total = 0;
      for (let element of array) {
        if (typeof element[field] === 'number') {
          total += element[field];
        }
      }
      return total > 0 ? total.toFixed(2) : total;
    },
    getTotalContractAmount(contracts, field) {
      var total = 0;
      for (let c of contracts) {
        if (c != null && c[field] != null) {
          total += c[field];
        }
      }
      // return total.toFixed(2);
      return total > 0 ? total.toFixed(2) : total;
    },
    getReimbursementTotal(reimbursements) {
      var total = 0;
      for (let r of reimbursements) {
        if (r != null) {
          if (r.amount != null) {
            total += r.amount;
          } else if (r.estimatedAmount != null) {
            total += r.estimatedAmount;
          }
        }
      }
      return total;
    },
    summaryReimbursementObject(reimbursements, field) {
      var summary = new Object();
      for (var r of reimbursements) {
        summary[r[field]] = !summary[r[field]] ? 0 : summary[r[field]];
        if (!r.amount) {
          summary[r[field]] += r.estimatedAmount;
        } else {
          summary[r[field]] += r.amount;
        }
      }
      return summary;
    },
    computePercentage(numerator, denominator) {
      if (!numerator || !denominator) {
        return 0;
      }
      return Math.round(numerator / denominator * 100);
    },
    actualPaymentPercentage(contract) {
      return this.computePercentage(contract.paidAmount, contract.amount);
    },
    actualReceiptPercentage(contract) {
      return this.computePercentage(contract.receiptAmount, contract.amount);
    },
    expectedPaymentPercentage(contract, time) {
      if (!contract.paymentSchedule)
      return;

      if(!contract.paymentSchedule.schedule)
      return;

      if (!time)
      time = moment();

      var index = 0;
      while(index < contract.paymentSchedule.schedule.length) {
        if (time.isBefore(contract.paymentSchedule.schedule[index])) {
          break;
        } else {
          index++;
        }
      }
      var paymentPercentage = contract.paymentMethod.split('-').slice(0, index).reduce(function(a, b) {
        return a * 1 + b * 1;
      }, 0);
      return paymentPercentage;
    },
    expectedMonthlyPaymentPercentage(contract, startOfMonth, endOfMonth) {
      if (!contract.paymentSchedule.schedule)
      return 0;

      var index = 0;
      while (index < contract.paymentSchedule.schedule.length) {
        if (moment(contract.paymentSchedule.schedule[index]).isBetween(startOfMonth, endOfMonth)) {
          break;
        } else {
          index++;
        }
      }

      if (index == contract.paymentSchedule.schedule.length)
      return 0;

      return contract.paymentMethod.split('-')[index];
    }
  }
}
