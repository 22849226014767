<template>
  <b-container fluid>
    <h3>管理待办事宜</h3>
  <div style="overflow-x:auto;">
      <table class="table table-hover header-fixed">
        <thead>
          <tr class="text-center">
            <th class="align-middle">序号</th>
            <th class="align-middle">姓名</th>
            <th class="align-middle">邮箱</th>
            <th class="align-middle">角色</th>
            <th class="align-middle">销售合同待关联到项目</th>
            <th class="align-middle">上传盖章合同</th>
            <th class="align-middle">采购记录待关联到采购合同</th>
            <th class="align-middle">待完成采购记录</th>
            <th class="align-middle">费用申请待上传发票</th>
            <th class="align-middle">待审批</th>
            <th class="align-middle">消息</th>
            <th class="align-middle">上次登录时间</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(person, index) in people" :key="person._id" class="text-center">
            <td class="align-middle"><b>{{ index + 1 }}</b></td>
            <td class="align-middle">{{ person.name }}</td>
            <td class="align-middle">{{ person.email }}</td>
            <td class="align-middle">{{ person.role }}</td>
            <td class="align-middle">{{ todoList[person.name]['unlinkedProjectContracts'] }}</td>
            <td class="align-middle">{{ todoList[person.name]['contractsWithoutStamp'] }}</td>
            <td class="align-middle">{{ todoList[person.name]['unlinkedItems'] }}</td>
            <td class="align-middle">{{ todoList[person.name]['incompletedItems'] }}</td>
            <td class="align-middle">{{ todoList[person.name]['reimbursementsWithNoReceipts'] }}</td>
            <td class="align-middle">{{ todoList[person.name]['messages'] }}</td>
            <td class="align-middle">{{ todoList[person.name]['notifications'] }}</td>
            <td class="align-middle">{{ getFormattedDate(lastLogin[person._id]) }}</td>
          </tr>
        </tbody>
      </table>
  </div>
</b-container>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import queryUtil from '@/js/queryUtil';
import todoUtil from '@/components/management/todoUtil';
import formatTime from '@/js/formatTime';

export default {
  data() {
    return {
      people: [],
      todoList: new Object(),
      lastLogin: {}
    }
  },
  created() {
    this.fetchPeople();
  },
  mixins: [
    storeUtil, queryUtil, formatTime, todoUtil
  ],
  methods: {
    fetchPeople() {
      this.axios.get(`people/${this.getLocation}`).then(response => {
          this.people = response.data;
          this.fetchLastLogin();

          this.people.forEach(person => {
            this.todoList[person.name] = {};
            this.fetchAllTodos(person.name);
          });
      }).catch(err => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchAllTodos(name) {
      Promise.all([
        this.fetchUnlinkedProjectContracts(name),
        this.fetchContractsWithoutStamp(name),
        this.fetchUnlinkedItems(name),
        this.fetchIncompletedItems(name),
        this.fetchReimbursementsWithNoReceipts(name),
        this.messageCount(name),
        this.notificationCount(name)
      ]).then(results => {
        this.todoList[name]['unlinkedProjectContracts'] = results[0].length;
        this.todoList[name]['contractsWithoutStamp'] = results[1].length;
        this.todoList[name]['unlinkedItems'] = results[2].length;
        this.todoList[name]['incompletedItems'] = results[3].length;
        this.todoList[name]['reimbursementsWithNoReceipts'] = results[4].length;
        this.todoList[name]['messages'] = results[5];
        this.todoList[name]['notifications'] = results[6];
        this.$forceUpdate();
      }).catch(err => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchLastLogin() {
      this.axios.post(`redis/hgetall/lastLogin`).then(response => {
        this.lastLogin = response.data[0];
      });
    }
  }
}
</script>

<style scoped>
div {
  display: inline-block;
  height: 800px;
  overflow: auto
}

table th {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  background-color: white
  /*min-width: 100px;*/
}

table {
  border-collapse: collapse;
}
</style>
