<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>确认采购记录</h3>
      </div>

      <div class="card-body">

        <div class="row">
          <div style="width:50%">
            <table class="table table-hover">
              <tbody>
                <tr>
                  <th scope="row">项目名称</th>
                  <td>{{ projectTitle }}</td>
                </tr>
                <tr>
                  <th scope="row">名称</th>
                  <td>{{ item.name }}</td>
                </tr>
                <tr>
                  <th scope="row">品牌</th>
                  <td>{{ item.brand }}</td>
                </tr>
                <tr>
                  <th scope="row">型号</th>
                  <td>{{ item.series }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style="width:50%">
            <table class="table table-hover">
              <tbody>
                <tr>
                  <th scope="row">单价</th>
                  <td>{{ item.price }}</td>
                </tr>
                <tr>
                  <th scope="row">数量</th>
                  <td>{{ item.quantity }}</td>
                </tr>
                <tr>
                  <th scope="row">供应商</th>
                  <td>{{ item.supplier }}</td>
                </tr>
                <tr>
                  <th scope="row">到货日期</th>
                  <td>{{ getYearMonthDate(item.date) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <table class="table table-hover">
          <tbody>
            <tr>
              <th scope="row" style="width: 25%;">参数</th>
              <td>{{ item.parameters }}</td>
            </tr>
            <tr>
              <th scope="row">备注</th>
              <td>{{ item.note }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="items.length > 0">
        <nav>
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{disabled: currentPage - 1 == 0}">
              <a class="page-link" @click="currentPage--, setItem(items[currentPage - 1])">
                <i class="fas fa-angle-double-left"></i>
              </a>
            </li>
            <li class="page-item" :class="{active: currentPage == index + 1}" v-for="(item, index) in items" :key="item._id" style="display: inline-block">
              <a class="page-link" href="#" @click="setItem(item), currentPage = index + 1">
                {{ index + 1 }}
              </a>
            </li>
            <li class="page-item" :class="{disabled: currentPage + 1 > items.length}">
              <a class="page-link" @click="currentPage++, setItem(items[currentPage - 1])">
                <i class="fas fa-angle-double-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>

    </div>
  </div>
</template>

<script>
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';

export default {
  data() {
    return {
      items: [],
      item: {},
      projectTitle: ''
    }
  },
  mixins: [
    formatTime, storeUtil, sortUtil
  ],
  created(){
    this.getItems();
  },
  methods: {
    getItems() {
      if(this.$route.params.contractId) {
        this.axios.post(`items/${this.getLocation}/queryAll`, {
          contractId: this.$route.params.contractId
        }).then(response => {
          if (response.data.length == 0) {
            this.$router.push({name:'ErrorPage'});
          }
          this.items = response.data;
          this.setItem(this.items[0]);
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
      }
      // else if (this.$route.params.id) {
      //   this.axios.post(uri, {_id: this.$route.params.id}).then((response) => {
      //     this.setItem(response.data[0]);
      //   });
      // }
    },
    setItem(item) {
      this.item = item;
      this.axios.post(`projects/${this.getLocation}/query`, {
        _id: this.item.projectId
      }).then((response) => {
        this.projectTitle = response.data.title;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
