<template>
  <div>
    <FileLoading :showGifImg="showGifImg"></FileLoading>
    <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

  <div class="card" id="completedPayments">
    <div class="card-header">
      <h3>我审批的采购合同付款</h3>
      <b>* 此处显示近三个月来审批的采购合同付款。</b>
    </div>
    <div class="card-body">
      <rise-loader :loading="loading" class="text-center"></rise-loader>
      <div v-if="!loading && sortedPayments.length > 0" style="overflow-x: auto">
        <table class="table table-hover table-sm">
          <thead>
            <tr class="text-center">
              <Header header="项目名称" @sort="sort('projectTitle')"></Header>
              <Header header="盖章合同" @sort="sort('stampedFilename')"></Header>
              <Header header="合同负责人" @sort="sort('owner')"></Header>
              <Header header="付款金额" @sort="sort('amount')"></Header>
              <Header header="付款方式" @sort="sort('method')"></Header>
              <Header header="付款公司" @sort="sort('company')"></Header>
              <Header header="付款日期" @sort="sort('date')"></Header>
              <Header header="付款信息" @sort="sort('accountImgFilename')"></Header>
              <th class="align-middle" style="min-width: 100px">备注</th>
              <th style="min-width: 60px"></th>
              <Header header="我通过的时间" style="min-width: 120px"></Header>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="payment in filteredPayments" :key="payment._id" class="text-center">
              <td class="align-middle">{{ payment.projectTitle }}</td>
              <td class="align-middle"><a href="#" @click="viewFile(payment.stampedFileId, payment.stampedFilename)">{{ payment.stampedFilename }}</a></td>
              <td class="align-middle">{{ payment.owner }}</td>
              <td class="align-middle">{{ payment.amount }}</td>
              <td class="align-middle">{{ payment.method }}</td>
              <td class="align-middle">{{ payment.company }}</td>
              <td class="align-middle">{{ getYearMonthDate(payment.date) }}</td>
              <td class="align-middle"><a href="#" @click="viewFile(payment.accountImgId, payment.accountImgFilename)">{{ payment.accountImgFilename }}</a></td>
              <td class="align-middle" v-html="!payment.note ? '' : payment.note.replace(/\n/, '<br /><br />')"></td>
              <td class="align-middle">
                <router-link :to="{name: 'ContractInfo', params: { id: payment.contractId }}" class="btn btn-success btn-sm">合同详情</router-link>
              </td>
              <td v-if="payment.procedureTimestamp != null" class="align-middle">
                <a v-for="role in getUser.role.split('/')">
                  {{ getFormattedDate(payment.procedureTimestamp[role]) }}
                </a>
              </td>
              <td v-else></td>

              <td v-if="payment.procedureNote != null && payment.procedureNote != ''" class="align-middle">
                <ProcedureNote :id="payment._id.toString() + 'ProcedureNote'" :note="payment.procedureNote"></ProcedureNote>
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>

        <Pagination
        :array="sortedPayments"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @setCurrentPage="setCurrentPage"
        @incrementCurrentPage="currentPage++"
        @minusCurrentPage="currentPage--">
      </Pagination>
    </div>
    <div v-else-if="!loading && sortedPayments.length == 0">
      <Empty></Empty>
    </div>
  </div>
</div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import util from './util';
import loaderUtil from '@/js/loaderUtil';
import fileIO from '@/js/fileIO';
import moment from 'moment';

export default {
  data() {
    return {
      payments: [],
      currentSort: 'date',
      currentSortDir: 'desc'
    }
  },
  mixins: [
    util, formatTime, sortUtil, storeUtil, loaderUtil, fileIO
  ],
  created() {
    this.getPayments();
  },
  computed : {
    sortedPayments() {
      return this.sortByProcedureTimestamp(this.payments);
    },
    filteredPayments() {
      return this.filterForPagination(this.sortedPayments);
    }
  },
  methods: {
    getPayments() {
      this.axios.get(`redis/lrange/paymentProcedure`).then(response => {
        var procedure = this.inProcedureArray(response.data[0]);
        this.axios.post(`overall/${this.getLocation}/payments/contract/project`, [
          { $match:
            {
              procedure: { $in: procedure }
            }
          }
        ]).then(response => {
          this.payments = response.data;
          this.loading = false;
        });
      });
    }
  }
}
</script>
