<template>
<label><b>{{ label }}<i class="fa fa-asterisk fa-custom" aria-hidden="true"></i></b></label>
</template>

<script>
export default {
  props: [
    'label'
  ]
}
</script>
