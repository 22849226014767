<template>
  <b-container fluid>

    <br />
    <h1>{{ project.title }}</h1>

    <b-card no-body>
      <b-tabs pills card vertical nav-wrapper-class="w-20">



        <b-tab title="项目详情">
          <b-card>
            <div class="card-deck">
              <div class="card text-white bg-danger" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 5px;">
                <h2 class="text-center align-middle">{{ items.length }}</h2>
                <div class="card-body text-center align-middle">
                  <h5 class="card-text">项目共计采购需求</h5>
                  <br />
                  <h5 class="card-text">{{ incompletedItemsCount }}项采购尚未完成</h5>
                </div>
              </div>

              <div class="card text-white bg-info" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 5px;">
                <h2 class="text-center align-middle">
                  ￥{{ projectContract.amount != null ?  projectContract.amount.toFixed(2) * 1 + getTotalContractAmount(additionalContracts, 'amount') * 1 : projectContract.amount }}
                </h2>
                <div class="card-body text-center align-middle">
                  <h5 class="card-text">应收项目款</h5>
                  <br />
                  <h5 class="card-text">
                    已收回￥{{ projectContract.paidAmount != null ? projectContract.paidAmount.toFixed(2) * 1 + getTotalContractAmount(additionalContracts, 'paidAmount') * 1 : projectContract.paidAmount }}项目款
                  </h5>
                </div>
              </div>

              <div class="card text-white bg-warning" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 5px;">
                <h2 class="text-center align-middle">
                  ￥{{ getTotalContractAmount(purchasingContracts, 'amount') }}
                </h2>
                <div class="card-body text-center align-middle">
                  <h5 class="card-text">应付采购款</h5>
                  <br />
                  <h5 class="card-text">
                    已付出￥{{ getTotalContractAmount(purchasingContracts, 'paidAmount') }}采购款
                  </h5>
                </div>
              </div>

              <div class="card text-white bg-secondary" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 5px;">
                <h2 class="text-center align-middle">
                  ￥{{ getReimbursementTotal(reimbursements) != null ? getReimbursementTotal(reimbursements).toFixed(2) : getReimbursementTotal(reimbursements) }}
                </h2>
                <div class="card-body text-center align-middle">
                  <h5 class="card-text">报销</h5>
                  <br />
                  <h5 class="card-text">共计{{ reimbursements.length }}笔报销款项</h5>
                </div>
              </div>
            </div>

            <table class="table">
              <tbody>
                <tr>
                  <th scope="row">项目编号</th>
                  <td>{{ project._id }}</td>
                </tr>
                <tr>
                  <th scope="row">项目名称</th>
                  <td>{{ project.title }}</td>
                </tr>
                <tr>
                  <th scope="row">甲方</th>
                  <td>{{ project.customer }}</td>
                </tr>
                <tr>
                  <th scope="row">项目类别</th>
                  <td>{{ project.category }}</td>
                </tr>
                <tr>
                  <th scope="row">
                    {{ projectContract.category == '中标通知书' ? '中标通知书' : projectContract.category == '销售合同' ? '销售合同' : '招标文件/报价单'}}
                  </th>
                  <td>
                    <a href="#" v-on:click="viewFile(projectContract.fileId, projectContract.filename)">{{ projectContract.filename }}</a>
                  </td>
                </tr>
                <tr v-if="projectContract.category != '销售合同' && projectContract.category != '中标通知书'">
                  <th scope="row">中标通知书</th>
                  <td>
                    <select class="form-control" v-model="project.bidFilename">
                      <option disabled selected value>-----请选择-----</option>
                      <option v-for="bidFile in bidFiles" :key="bidFile._id" :value="bidFile.filename">{{ bidFile.filename }}</option>
                    </select>
                    <button class="btn btn-primary" @click="updateBidFile()" :disabled="!project.bidFilename">上传</button>
                  </td>
                </tr>
                <tr v-if="projectContract.category != '销售合同'">
                  <th scope="row">销售合同</th>
                  <td>
                    <select class="form-control" v-model="contractFilename">
                      <option disabled selected value>-----请选择-----</option>
                      <option v-for="contract in projectContracts" :key="contract._id" :value="contract.filename">{{ contract.filename }}</option>
                    </select>
                    <button class="btn btn-primary" @click="updateProjectContract()" :disabled="!contractFilename">上传</button>
                  </td>
                </tr>
                <tr v-if="projectContract.category == '销售合同' && project.status == '进行中'">
                  <th scope="row">添加增项合同</th>
                  <td>
                    <select class="form-control" v-model="additionalContractId">
                      <option disabled selected value>-----请选择-----</option>
                      <option v-for="contract in projectContracts" :key="contract._id" :value="contract._id">{{ contract.filename }}</option>
                    </select>
                    <button class="btn btn-primary" @click="addAddtionalContract()" :disabled="!additionalContractId">上传</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">项目人员</th>
                  <td>
                    <div v-for="p in project.people" :key="p._id">
                      {{ p.name }} : {{p.role}}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">项目状态</th>
                  <td>
                    {{ project.status }}
                    <b-button-toolbar style="float: right">
                      <b-dropdown text="移动项目到..." dropright>
                        <b-dropdown-item-button @click="changeProjectStatus(project, '进行中')" :disabled="project.status == '进行中'">
                          进行中
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click="changeProjectStatus(project, '已完成')" :disabled="project.status == '已完成'">
                          已完成
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click="changeProjectStatus(project, '历史项目')" :disabled="project.status == '历史项目'">
                          历史项目
                        </b-dropdown-item-button>
                      </b-dropdown>
                    </b-button-toolbar>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-tab>



        <b-tab title="看板">
          <b-card>
            <div class="card-header">
              <h3>自定义看板</h3>
            </div>
            <ProjectTodos :projectId="$route.params.id"></ProjectTodos>
          </b-card>
        </b-tab>



        <b-tab>
          <template #title>
            各类合同汇总<b-spinner type="grow" small v-if="contractSummaryAlert()"></b-spinner>
          </template>
          <b-card>
            <div class="card-header">
              <h3>各类合同汇总</h3>
            </div>
            <b-card-text>
              <div v-if="(projectContract.category != null && projectContract.category != '中标通知书') || purchasingContracts.length > 0" style='overflow-x:auto'>
                <table class="table table-hover">
                  <thead>
                    <tr class="text-center">
                      <th class="align-middle" style="min-width: 100px">合同类型</th>
                      <th class="align-middle" style="min-width: 100px">合同名称</th>
                      <th class="align-middle" style="min-width: 100px">合同金额</th>
                      <th class="align-middle" style="min-width: 100px">合同审批进度</th>
                      <th class="align-middle" style="min-width: 120px">收/付款进度</th>
                      <th class="align-middle" style="min-width: 100px">发票进度</th>
                      <th style="min-width: 100px"></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr class="text-center" v-if="projectContract.category != null && projectContract.category != '中标通知书'">
                      <td class="align-middle">{{ projectContract.category }}</td>

                      <td class="align-middle"><a href="#" v-on:click="viewFile(projectContract.fileId, projectContract.filename)">{{ projectContract.filename }}</a></td>
                      <td class="align-middle">
                        {{ projectContract.amount }}
                      </td>
                      <td class="align-middle">{{ projectContract.procedure }}</td>
                      <td class="align-middle">
                        <PaymentProgressBar :contract="projectContract"></PaymentProgressBar>
                      </td>
                      <td class="align-middle">
                        <ReceiptProgressBar :contract="projectContract"></ReceiptProgressBar>
                      </td>
                      <td class="align-middle">
                        <router-link :to="{ name: 'ContractInfo', params: {id: projectContract._id} }" class="btn btn-success">详情</router-link>
                      </td>
                    </tr>

                    <tr class="text-center" v-for="additionalContract in additionalContracts" :key="additionalContract._id">
                      <td class="align-middle">{{ additionalContract.category }}</td>
                      <td class="align-middle">
                        <a href="#" v-on:click="viewFile(additionalContract.fileId, additionalContract.filename)">{{ additionalContract.filename }}</a>
                      </td>
                      <td class="align-middle">
                        {{ additionalContract.amount }}
                      </td>
                      <td class="align-middle">{{ additionalContract.procedure }}</td>
                      <td class="align-middle">
                        <PaymentProgressBar :contract="additionalContract"></PaymentProgressBar>
                      </td>
                      <td class="align-middle">
                        <ReceiptProgressBar :contract="additionalContract"></ReceiptProgressBar>
                      </td>
                      <td class="align-middle">
                        <router-link :to="{ name: 'ContractInfo', params: {id: additionalContract._id} }" class="btn btn-success">详情</router-link>
                      </td>
                    </tr>

                    <tr v-for="pContract in purchasingContracts" :key="pContract._id" class="text-center">
                      <td class="align-middle">{{ pContract.category }}</td>
                      <td class="align-middle">
                        <a v-if="!pContract.stampedFileId && !pContract.stampedFilename" href="#" v-on:click="viewFile(pContract.fileId, pContract.filename)">
                          {{ pContract.filename }}
                        </a>
                        <a v-else href="#" @click="viewFile(pContract.stampedFileId, pContract.stampedFilename)">
                          {{ pContract.stampedFilename }}
                        </a>
                      </td>
                      <td class="align-middle">
                        {{ pContract.amount }}
                      </td>
                      <td class="align-middle">{{ pContract.procedure }}</td>
                      <td class="align-middle">
                        <PaymentProgressBar :contract="pContract"></PaymentProgressBar>
                      </td>
                      <td class="align-middle">
                        <ReceiptProgressBar :contract="pContract"></ReceiptProgressBar>
                      </td>
                      <td class="align-middle">
                        <router-link :to="{ name: 'ContractInfo', params: {id: pContract._id} }" class="btn btn-success">详情</router-link>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <Empty v-else></Empty>
            </b-card-text>
          </b-card>
        </b-tab>



        <b-tab title="相关文件">
          <b-card title="相关文件">

            <b-button variant="outline-primary" style="float: right">
              <router-link :to="{ name: 'UploadProjectFiles' }">上传项目文件</router-link>
            </b-button>

            <b-card-text>
              <table class="table table-hover">
                <thead>
                  <tr class="text-center">
                    <th class="align-middle" style="min-width: 100px">文件名称</th>
                    <th class="align-middle" style="min-width: 100px">文件类型</th>
                    <th style="min-width: 100px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center" v-if="project.bidFileId != null && project.bidFilename != null">
                    <td class="align-middle">
                      <a href="#" @click="viewFile(project.bidFileId, project.bidFilename)">{{ project.bidFilename }}</a>
                    </td>
                    <td class="align-middle">中标通知书</td>
                    <td class="align-middle">
                      <b-button variant="primary" size="sm" @click="showDeleteFileModal(project.bidFileId)">删除</b-button>
                    </td>
                  </tr>
                  <tr class="text-center" v-if="project.bidInvitationFileId != null && project.bidInvitationFilename != null">
                    <td class="align-middle">
                      <a href="#" @click="viewFile(project.bidInvitationFileId, project.bidInvitationFilename)">{{ project.bidInvitationFilename }}</a>
                    </td>
                    <td class="align-middle">招标文件</td>
                    <td class="align-middle">
                      <b-button variant="primary" size="sm" @click="showDeleteFileModal(project.bidInvitationFileId)">删除</b-button>
                    </td>
                  </tr>
                  <tr class="text-center"
                  v-if="stampedFiles.length > 0"
                  v-for="(stampedFile, index) in stampedFiles"
                  :key="stampedFile._id">
                  <td class="align-middle">
                    <a href="#" @click="viewFile(stampedFile.fileId, stampedFile.filename)">{{ stampedFile.filename }}</a>
                  </td>
                  <td class="align-middle">
                    {{ stampedFile.category }}
                  </td>
                  <td></td>
                </tr>
                <tr class="text-center" v-if="project.relatedFiles != null && project.relatedFiles.length > 0" v-for="file in project.relatedFiles" :key="file._id">
                  <td class="align-middle">
                    <a href="#" @click="viewFile(file._id, file.filename)">{{ file.filename }}</a>
                  </td>
                  <td class="align-middle">{{ file.category }}</td>
                  <td class="align-middle">
                    <b-button variant="primary" size="sm" @click="showDeleteFileModal(file._id, index)">删除</b-button>
                  </td>
                </tr>
              </tbody>
            </table>

            <Empty
            v-if="!project.bidInvitationFileId &&
            !project.bidInvitationFilename &&
            !project.bidFileId &&
            stampedFiles.length == 0 &&
            !project.relatedFiles">
          </Empty>

          <b-modal
          id="delete-related-file"
          ok-title="确认"
          cancel-title="取消"
          title="删除文件"
          @show="$bvModal.hide('delete-related-file')"
          @hidden="$bvModal.hide('delete-related-file')"
          @ok="deleteRelatedProjectFile(deleteFileId, deleteFileIndex), $bvModal.hide('delete-related-file')">
          你确定要删除这个文件吗？
        </b-modal>

      </b-card-text>
    </b-card>
  </b-tab>



  <b-tab>
    <template #title>
      采购<b-spinner type="grow" small v-if="purchasingAlert"></b-spinner>
    </template>

    <b-card-text>
      <div v-if="!project.noPurchase">
        <div class="card" v-if="items.length > 0" id="purchasing">
          <div class="card-header">
            <h3>采购</h3>
          </div>
          <ItemListTemplate :query="itemQuery" :columns="['procedure', 'editButton', 'comparisonButton', 'moreButtons']"></ItemListTemplate>
        </div>
      </div>
    </b-card-text>
  </b-tab>

<b-tab>
  <template #title>
    用户问题<b-spinner type="grow" small v-if="problems.length > 0"></b-spinner>
  </template>
  <b-card-group deck v-for="problem in problems" :key="problem._id">
    <ProblemCard :problem="problem"></ProblemCard>
  </b-card-group>
</b-tab>



<b-tab title="报销">
  <b-card-text>
    <div class="card" id="reimbursements">
      <div class="card-header">
        <h3>报销</h3>
      </div>
      <div class="card-body" style="overflow-x:auto">
        <RTemp
        :list="['estimatedAmount', 'amount', 'filename', 'method', 'completeTime']"
        :reimbursements="reimbursements"
        :pagination="false"
        @deleteReimbursement="">
      </RTemp>
    </div>
  </div>
</b-card-text>
</b-tab>



<b-tab title="奖金" v-if="project.people != null">
  <b-card-text>
    <ProjectBonus :project="project"></ProjectBonus>
  </b-card-text>
</b-tab>



</b-tabs>
</b-card>
<FileLoading :showGifImg="showGifImg"></FileLoading>
<FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

</b-container>
</template>

<script>
import fileIO from '@/js/fileIO';
import procedureUtil from '@/js/procedureUtil';
import storeUtil from '@/js/storeUtil';
import peopleUtil from '@/js/peopleUtil';
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import accountingUtil from '@/js/accountingUtil';
import PaymentProgressBar from '@/components/PaymentProgressBar';
import ReceiptProgressBar from '@/components/ReceiptProgressBar';
import Folder from '@/components/FolderTemplate';
import FolderFile from '@/components/FolderFileTemplate';
import ItemListTemplate from '@/components/item/ItemListTemplate';
import ProjectBonus from '@/components/project/ProjectBonus';
import ProjectTodos from '@/components/project/ProjectTodos';
// import ItemListUtil from '@/components/item/ItemListUtil';
import ProblemCard from '@/components/problem/ProblemCard';
import moment from 'moment';

export default {
  data() {
    return {
      project: {},
      projectContract: {},
      contractFilename: '',
      projectContracts: [],
      bidFiles: [],
      itemQuery: {},
      items: [],
      purchasingAlert: false,
      incompletedItemsCount: 0,
      purchasingContracts: [],
      reimbursements: [],
      currentSort: 'name',
      showWarningModal: false,
      category: '',
      stampedFiles: [],
      additionalContractId: '',
      additionalContracts: [],
      deleteFileId: '', // delete related files
      deleteFileIndex: '', // delete related files
      todos: [],
      // todoDate: new Date()
      problems: []
    }
  },
  mixins: [
    fileIO, procedureUtil, storeUtil, peopleUtil, formatTime, sortUtil, accountingUtil
  ],
  components: {
    PaymentProgressBar, ReceiptProgressBar, Folder, FolderFile, ProjectBonus, ItemListTemplate, ProblemCard, ProjectTodos
  },
  created() {
    this.fetchProject();
    this.fetchPurchasingContracts();
    this.fetchProblems();
    this.fetchReimbursements();
  },
  methods: {
    fetchProject() {
      var id = this.$route.params.id;
      this.axios.post(`projects/${this.getLocation}/query`, {
        _id: id
      }).then(async (response) => {
        this.project = response.data;

        // this.fetchTodos();
        this.fetchStampedFiles();

        if (this.fetchProjectRole(this.project.people) == '' && !this.authLevel(2)) {
          this.logout();
        } else {
          // 中标通知书和销售合同二选一填写
          if (this.project.contractId != null) {
            this.fetchProjectContract(this.project.contractId);
          } else if (this.project.bidFileId != null) {
            this.fetchProjectContract(this.project.bidFileId);
            // this.fetchProjectBid(this.project.bidFileId);
          } else if (this.project.bidInvitationFileId != null && !this.project.bidFileId && !this.project.contractId) {
            // The project is in the preparation stage
            this.projectContract.fileId = this.project.bidInvitationFileId;
            this.projectContract.filename = this.project.bidInvitationFilename;
            this.getBidFiles();
          } else {
            this.getBidFiles();
          }
          this.getProjectContracts();

          this.itemQuery = { projectId: this.project._id };
          await this.fetchItems();

          if (this.project.additionalContracts) {
            await this.fetchAdditionalContracts();
          }
        }
      }).catch(err => {
        console.log(err);
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchProjectContract(id) {
      this.axios.post(`contracts/${this.getLocation}/query`, {
        _id: id
      }).then((response) => {
        if (response.data != null) {
          this.projectContract = response.data;
        }
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getBidFiles() {
      //TODO: to test
      this.axios.post(`overall/${this.getLocation}/bidFiles/projects/unlinked`, {
        owner: this.getUser.name
      }).then((response) => {
        this.bidFiles = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    getProjectContracts() {
      this.axios.post(`overall/${this.getLocation}/contracts/projects/unlinked`, {
        owner: this.getUser.name
      }).then((response) => {
        // this.projectContracts = response.data;
        for (var result of response.data) {
          var includes = false;
          for (var aContract in this.project.additionalContracts) {
            if (aContract._id == result._id) {
              includes = true;
              break;
            }
          }
          if (!includes) {
            this.projectContracts.push(result);
          }
        }
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    async fetchItems(id) {
      this.axios.post(`items/${this.getLocation}/queryAll`, this.itemQuery).then(response => {
        this.items = response.data;

        var totalCount = 0;
        for (let item of this.items) {
          if (item.contractId == null) {
            totalCount++;
          }
        }
        this.purchasingAlert = totalCount > 0;
        this.incompletedItemsCount = totalCount;
      });
    },
    fetchStampedFiles() {
      this.axios.post(`stamps/${this.getLocation}/queryAll`, {
        projectId: this.project._id
      }).then(response => {
        this.stampedFiles = response.data;
      }).catch(err => {
        console.log(err);
        alert(err.response.data);
      });
    },
    fetchPurchasingContracts() {
      var projectId = this.$route.params.id;
      this.axios.get(`overall/${this.getLocation}/contracts/purchasing/projects/${projectId}`).then((response) => {
        if (response.data != null) {
          this.purchasingContracts = response.data;
        }
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchProblems() {
      this.axios.post(`problems/queryAll`, {
        projectId: this.$route.params.id
      }).then(response => {
        this.problems = response.data;
      });
    },
    fetchReimbursements() {
      this.axios.post(`reimbursements/${this.getLocation}/queryAll`,
        {
          projectId: this.$route.params.id,
          $or: [
            { procedure: '完成' },
            { fileId: { $exists: true } }
          ]
        }).then((response) => {
          this.reimbursements = response.data;
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
      },
      updateProjectContract() {
        this.axios.post(`contracts/${this.getLocation}/query`, {
          filename: this.contractFilename
        }).then((response) => {
          var projectContract = response.data;
          // To prevent the case that the user wants to update the project contract directly after the bid
          if (this.projectContract.category != null && projectContract.amount != this.projectContract.amount) {
            alert('中标通知书金额与销售合同金额不符，请重新选择');
            return;
          }
          this.project.contractId = projectContract._id;
          this.axios.post(`projects/${this.getLocation}/update/${this.project._id}`, this.project).then(() => {
            this.projectContract = projectContract;
          });
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
      },
      updateBidFile() {
        this.axios.post(`contracts/${this.getLocation}/query`, {
          filename: this.project.bidFilename,
          category: '中标通知书'
        }).then((response) => {
          var projectContract = response.data;
          this.project.bidFileId = projectContract.fileId;

          this.axios.post(`projects/${this.getLocation}/update/${this.project._id}`, this.project).then(() => {
            this.projectContract = projectContract;
          });
        }).catch(err => {
          console.log(err);
          this.$router.push({name: 'ErrorPage'});
        });
      },
      logout() {
        this.$store.dispatch("logout").then(() => {
          this.$router.push("/login");
        });
      },
      // updateData(filters) {
      //   this.currentPage = 1;
      //   if (filters.length == 0) {
      //     this.items = this.allItems;
      //   } else {
      //     this.items = this.filterKeywordsAnd(this.allItems, filters);
      //   }
      // },
      uniqueFieldValue(jsonArray, field) {
        var uniqueValue = [];
        for (var array of jsonArray) {
          if (uniqueValue.indexOf(array[field]) == -1) {
            uniqueValue.push(array[field]);
          }
        }
        return uniqueValue;
      },
      // uploadBonusPercentage() {
      //   for (let person of this.project.people) {
      //     person.bonusLock = !person.bonusLock ? false : person.bonusLock;
      //     person.appliedBonus = !person.appliedBonus ? 0 : person.appliedBonus;
      //     person.receivedBonus = !person.receivedBonus ? 0 : person.receivedBonus;
      //     person.bonusNote = !person.bonusNote ? '' : person.bonusNote;
      //   }
      //   // this.axios.post(`projects/${this.getLocation}/update/people/${this.project._id}`, {
      //   this.axios.post(`projects/${this.getLocation}/update/${this.project._id}`, {
      //     people: this.project.people
      //   }).then(() => {
      //     alert('项目人员奖金情况已上传');
      //     this.enableBonusEdit = false;
      //   });
      // },
      // bonusToApply(person) {
      //   return (person.bonusPercentage * this.projectContract.amount / 1000).toFixed(2) - person.receivedBonus;
      // },
      addAddtionalContract() {
        this.axios.post(`contracts/${this.getLocation}/query`, {
          _id: this.additionalContractId
        }).then((response) => {
          var additionalContract = response.data;
          this.project.additionalContracts.push(additionalContract._id);
          this.axios.post(`projects/${this.getLocation}/update/${this.project._id}`, this.project).then(response => {
            alert(response.data);
            this.$forceUpdate();
          });
        });
      },
      async fetchAdditionalContracts() {
        for (var i = 0; i < this.project.additionalContracts.length; i++) {
          var id = this.project.additionalContracts[i];
          var response = await this.axios.post(`contracts/${this.getLocation}/query`, {_id: id});
          this.additionalContracts.push(response.data);
        }
      },
      contractSummaryAlert() {
        if (this.expectedPaymentPercentage(this.projectContract) - this.actualPaymentPercentage(this.projectContract) > 0) {
          return true;
        }
        for (var contract of this.purchasingContracts) {
          if (this.actualPaymentPercentage(contract) - this.actualReceiptPercentage(contract) > 0) {
            return true;
          }
        }
        return false;
      },
      changeProjectStatus(project, status) {
        project.status = status;
        this.axios.post(`projects/${this.getLocation}/update/${project._id}`, project).then(response => {
          alert(response.data);
        });
      },
      showDeleteFileModal(fileId, fileIndex) {
        this.deleteFileId = fileId;
        if (fileIndex != '') {
          this.deleteFileIndex = fileIndex;
        }
        this.$bvModal.show('delete-related-file');
      },
      deleteRelatedProjectFile(fileId, fileIndex) {
        if (fileIndex == '') {
          if (fileId == this.project.bidFileId) {
            this.project.bidFileId = null;
            this.project.bidFilename = null;
          } else if (fileId == this.project.bidInvitationFileId) {
            this.project.bidInvitationFileId = null;
            this.project.bidInvitationFilename = null;
          } else {
            // ERROR
          }
          this.axios.post(`projects/${this.getLocation}/update/${this.project._id}`, this.project).then(response => {
            alert(response.data);
            this.$forceUpdate();
          });
        } else {
          // delete related files
          this.axios.post(`projects/${this.getLocation}/delete/file/related/${this.project._id}`, {
            fileId: fileId
          }).then((response) => {
            alert(response.data);
            if (fileIndex != '') {
              this.project.relatedFiles.splice(fileIndex, 1);
            }
            this.$forceUpdate();
          });
        }
      },
      // addTodoId(id) {
      //   this.axios.post(`projects/${this.getLocation}/update/todos/${this.project._id}`, {todoId: id}).then(response => {
      //     alert(response.data);
      //   });
      // }
    }
  }
  </script>
