export default {
  data() {
    return {
      red(transparency) {
        return `rgb(255, 99, 132, ${transparency})`
      },
      green(transparency) {
        return `rgb(75, 192, 192, ${transparency})`
      },
      yellow(transparency) {
        return `rgb(255, 205, 86, ${transparency})`
      }
    }
  },
  methods: {
    randomColor() {
      var colorList = ['info', 'primary', 'success', 'warning', 'danger', 'secondary'];
      var randomIndex = Math.floor(Math.random() * Math.floor(colorList.length));
      return colorList[randomIndex];
    },
    generateColorArray(length) {
      var result = [];
      var colorList = [this.red(0.5), this.green(0.5), this.yellow(0.5)];
      for (var i = 0; i < length; i++) {
        result[i] = colorList[i % colorList.length];
      }
      return result;
    }
  }
}
