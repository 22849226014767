<template>
  <div class="container">
    <div class="card">

      <FileLoading :showGifImg="showGifImg"></FileLoading>
      <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

      <div class="card-header">
        <h3>我的用印申请</h3>
        <br />
        <ul class="nav nav-pills">
          <li class="nav-item">
            <router-link :to="{ name: 'StampIndex'}" class="nav-link active">未完成</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'StampIndexComplete'}" class="nav-link">已完成</router-link>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
          <p class="form-check-label">模糊查询</p>
        </div>
        <Search @updateData='updateData'></Search>
        <br />
        <p><b v-show="stamps.length > 0">共{{ stamps.length }}条未完成的用印</b></p>
        <br />

        <rise-loader :loading="loading" class="align-middle"></rise-loader>

        <div v-if="!loading && stamps.length > 0" style='overflow-x:auto'>
          <table class="table table-hover">
            <thead>
              <tr>
                <Header header="责任人" @sort="sort('owner')"></Header>
                <Header header="用印类型" @sort="sort('category')"></Header>
                <Header header="印章类型" @sort="sort('stampKind')"></Header>
                <Header header="用印公司" @sort="sort('company')"></Header>
                <Header header="用印文件" @sort="sort('filename')"></Header>
                <Header header="关联项目" @sort="sort('projectTitle')"></Header>
                <th class="text-center align-middle" style="min-width: 200px">说明</th>
                <Header header="审批进度" @sort="sort('procedure')"></Header>
                <th></th>
                <th></th>
                <th style="min-width: 80px"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="stamp in sortedStamps" :key="stamp._id" class="text-center">
                <td class="align-middle">{{ stamp.owner }}</td>
                <td class="align-middle">{{ stamp.category }}</td>
                <td class="align-middle">{{ stamp.stampKind }}</td>
                <td class="align-middle">{{ stamp.company }}</td>
                <td class="align-middle"><a href="#" v-on:click="viewFile(stamp.fileId, stamp.filename)">{{ stamp.filename }}</a></td>
                <td class="align-middle">{{ stamp.projectTitle }}</td>
                <td class="text-left align-middle">{{ stamp.note }}</td>
                <td class="align-middle">
                  {{ stamp.procedure }}
                  <WhoIsWho :id="stamp._id.toString() + 'WhoIsWho'" :role="stamp.procedure" v-if="stamp.procedure != null && stamp.procedure != '完成' && stamp.procedure != stamp.owner"></WhoIsWho>
                </td>

                <td class="align-middle" v-if="stamp.procedureTimestamp != null">
                  <TimelineHover :timelineId="stamp._id" :timestamps="stamp.procedureTimestamp"></TimelineHover>
                </td>
                <td v-else></td>

                <td class="align-middle" v-if="stamp.procedureNote != null && stamp.procedureNote != ''">
                  <ProcedureNote :id="stamp._id.toString() + 'ProcedureNote'" :note="stamp.procedureNote"></ProcedureNote>
                </td>
                <td v-else></td>

                <td class="align-middle">
                  <button class="btn btn-danger btn-sm" @click="deleteStamp(stamp._id)">
                    删除
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <Pagination
          :array="stamps"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @setCurrentPage="setCurrentPage"
          @incrementCurrentPage="currentPage++"
          @minusCurrentPage="currentPage--">
        </Pagination>
      </div>

        <Empty v-else-if="!loading && stamps.length == 0"></Empty>
    </div>
  </div>
</div>
</template>

<script>
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      stamps: [],
      allStamps: [],
      pageSize: 5,
      searchLogicOr: false
    }
  },
  mixins: [fileIO, storeUtil, sortUtil, loaderUtil],
  created() {
    this.fetchStamps();
  },
  computed: {
    sortedStamps:function() {
      return this.sortArray(this.stamps);
    }
  },
  methods: {
    fetchStamps() {
      var query = {procedure: {$ne: '完成'}};
      if (!this.authLevel(2)) {
        query.owner = this.getUser.name;
      }

      this.axios.post(`stamps/${this.getLocation}/queryAll/timeline`, query).then(response => {
        this.stamps = response.data;
        this.allStamps = response.data;
        this.loading = false;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    updateData(filters) {
      this.currentPage = 1;
      if (filters.length == 0) {
        this.stamps = this.allStamps;
      } else {
        if (!this.searchLogicOr) {
          this.stamps = this.filterKeywordsAnd(this.allStamps, filters);
        } else {
          this.stamps = this.filterKeywords(this.allStamps, filters);
        }
      }
    },
    deleteStamp(id) {
      this.axios.get(`stamps/${this.getLocation}/delete/${id}`).then(response => {
        alert(response.data);
        this.fetchStamps();
      });
    }
  }
}
</script>
