<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>上传   {{ projectTitle }}   项目相关文件</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="uploadProjectFiles">
          <!-- <div class="form-group">
            <rlabel label="项目名称"></rlabel>
            <select class="form-control" v-model="projectId" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="p in allProjects" :key="p._id" :value="p._id">{{ p.customer }} - {{ p.title }}</option>
            </select>
          </div>
          <div v-show="allProjects.length  == 0" class="alert alert-warning">
            尚未有可选择的项目名称。请确认你是某项目的项目成员，以添加项目相关文件。
          </div> -->

          <div class="form-group">
            <rlabel label="文件类别"></rlabel>
            <select class="form-control" v-model="category" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="c in projectFileCategories" :key="c" :value="c">{{ c }}</option>
            </select>
          </div>

            <FileUploadBox
            label="上传文件"
            :notes="['支持拖拽; 支持同时上传多个文件; 重新选择或拖拽文件即可替换原先上传文件', '文件名务必与文件内容相符']"
            @setFiles="files = $event"
            :required="true"
            :supportMultiFiles="true"
            :filenameCheck="false">
            </FileUploadBox>

            <br />
            <br />

            <div class="form-group">
              <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
            </div>
            <Uploading :waiting="waiting"></Uploading>

          </form>
        </div>
      </div>
    </div>
  </template>

  <script>
  import fileIO from '@/js/fileIO';
  import storeUtil from '@/js/storeUtil';
  import loaderUtil from  '@/js/loaderUtil';

  export default {
    data() {
      return {
        // allProjects: [],
        // projectId: '',
        projectTitle: '',
        category: '',
        fileUploadCompletion: 0,
        projectFileCategories: []
      }
    },
    created() {
      // var query = {
      //   'status': { $ne: '历史项目' }
      // };
      // if (!this.authLevel(2)) {
      //   query['people.name'] = this.getUser.name;
      // }

      // Get project title for header
      this.axios.post(`projects/${this.getLocation}/query`, {
        _id: this.$route.params.id
      }).then(response => {
        this.projectTitle = response.data.title;
      });
      // Fetch project file categories from redis
      this.axios.get(`redis/members/projectFileCategories`).then((response) => {
        this.projectFileCategories = response.data[0];
      });
    },
    mixins: [
      fileIO, storeUtil, loaderUtil
    ],
    methods: {
      uploadProjectFiles() {
        this.waiting = true;
        this.handleFiles(this.addFileToProject, true);
      },
      addFileToProject(id, filename) {
        this.axios.post(`projects/${this.getLocation}/upload/file/${this.$route.params.id}`, {
          _id: id,
          filename: filename,
          category: this.category
        }).then(() => {
          this.fileUploadCompletion++;
          if (this.fileUploadCompletion == this.files.length) {
            alert('全部文件已上传');
            this.clearData();
            this.waiting = false;
          }
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
      },
      clearData() {
        // this.projectId = '';
        this.category = '';
        this.files = [];
        this.fileUploadCompletion = 0;
        this.$router.go(-1);
      }
    }
  }
  </script>
