<template>
  <tr>
    <td class="align-middle">{{ person.name }}</td>
    <td class="align-middle" v-if="enableBonusEdit"><input type="number" class="form-control" v-model="person.bonusPercentage" step="0.001" placeholder="‰"></td>
    <td class="align-middle" v-else>{{ !person.bonusPercentage ? 0 : person.bonusPercentage.toFixed(3) }}</td>
    <td class="align-middle" v-if="enableBonusEdit"><input type="number" class="form-control" v-model="bonus"></td>
    <td class="align-middle" v-else>{{ bonus }}</td>
    <td class="align-middle">{{ person.receivedBonus }}</td>

    <td class="align-middle" v-if="person.name == getUser.name">
      <div class="from-group">
        <input type="number" class="form-control" v-model="person.appliedBonus" :readonly="bonusReadOnlyLock()">
      </div>
    </td>
    <td v-else></td>

    <td class="align-middle">{{ person.bonusProcedure }}</td>

    <td class="align-middle" v-if="person.name == getUser.name">
      <button class="btn btn-primary btn-sm" @click="startBonusProcedure()" :disabled="bonusReadOnlyLock()">申请领取</button>
    </td>
    <td v-else></td>

    <td class="align-middle" v-if="person.bonusNote != null && person.bonusNote.length > 0">
      <ProcedureNote :id="person._id" :note="person.bonusNote"></ProcedureNote>
    </td>
    <td v-else></td>
  </tr>
</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  props: [
    'person',
    'projectContractAmount',
    'enableBonusEdit',
    'project'
  ],
  mixins: [
    storeUtil
  ],
  data() {
    return {
      bonus: 0
    }
  },
  created() {
    this.setBonus();
  },
  watch: {
    'bonus': {
      handler: function(after, before) {
        this.generateBonusPercentage();
      }
    },
    'person.bonusPercentage': {
      handler: function(after, before) {
        this.setBonus();
      }
    }
  },
  methods: {
    generateBonusPercentage() {
      this.person.bonusPercentage = (this.bonus / this.projectContractAmount * 1000);
    },
    generateBonus() {
      return this.person.bonusPercentage != null ? (this.person.bonusPercentage * this.projectContractAmount / 1000).toFixed(2) : 0;
    },
    setBonus() {
      this.bonus = this.generateBonus();
    },
    bonusReadOnlyLock() {
      return this.person.bonusLock || isNaN(this.bonusToApply(this.person));
    },
    bonusToApply() {
      return this.generateBonus() - this.person.receivedBonus;
    },
    startBonusProcedure() {
      if (this.person.appliedBonus < 0 || this.person.appliedBonus > this.bonusToApply()) {
        alert(`奖金申领金额应在￥0~￥${this.person.appliedBonus > this.bonusToApply()}之间`);
        return;
      }

      this.person.bonusProcedure = this.person.name;
      this.person.bonusLock = true;
      this.axios.post(`projects/${this.getLocation}/update/people/${this.project._id}`, {
        people: this.project.people
      }).then(() => {
        this.axios.post(`projects/${this.getLocation}/update/procedure/bonus/${this.project._id}`, {
          name: this.person.name
        }).then(response => {
          alert(response.data);
          this.$forceUpdate();
        });
      });
    },
  }
}
</script>
