export default {
  created() {
    this.fetchPersonInfo();
  },
  computed: {
    sortedProjects() {
      if (!this.person.starredProjects)
        return this.filterForPagination(this.projects);


      var starredProjects = this.projects.filter(project => {
        return this.person.starredProjects.includes(project._id);
      });
      var unstarredProjects = this.projects.filter(project => {
        return !this.person.starredProjects.includes(project._id);
      });
      var sortedUnstarredProjects = this.sortArrayWithoutPagination(unstarredProjects);
      var projects = starredProjects.concat(sortedUnstarredProjects);
      return this.filterForPagination(projects);
    },
  },
  methods: {
    fetchPersonInfo() {
      this.axios.post(`people/${this.getLocation}/query`, {
        name: this.getUser.name
      }).then(response => {
        this.person = response.data;

        if (!this.person.starredProjects)
        this.person.starredProjects = [];

      });
    },
    updateStarredProjects(projectId, action) {
      if (action == 'add') {
        this.person.starredProjects.push(projectId);
      } else if (action == 'delete') {
        this.person.starredProjects.splice(this.person.starredProjects.indexOf(projectId), 1);
      }

      this.axios.post(`people/${this.getLocation}/update/${this.person._id}`, this.person).then(response => {
        this.$forceUpdate();
      });
    },
    isStarredProject(projectId) {
      return this.person.starredProjects != null && this.person.starredProjects.includes(projectId);
    }
  }
}
