<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>添加供应商</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="submit">
          <b-row>
            <b-col>
              <div class="form-group">
                <rlabel label="公司名称"></rlabel>
                <b-form-input list="customer-list" v-model="supplier.name" required="required"></b-form-input>
                <b-form-datalist id="customer-list" :options="supplierList"></b-form-datalist>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <rlabel label="标签"></rlabel>
                <b-form-tags input-id="tags-basic" v-model="supplier.tags"></b-form-tags>
              </div>
            </b-col>
          </b-row>

          <div class="form-group">
            <b-row>
              <b-col>
                <rlabel label="联系人"></rlabel>
              </b-col>
              <b-col></b-col>
              <b-col></b-col>
              <b-col>
                <b-button @click="addContact()" variant="success">
                  添加 +
                </b-button>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col align="middle"><rlabel label="姓名"></rlabel></b-col>
              <b-col align="middle"><rlabel label="电话"></rlabel></b-col>
              <b-col align="middle"><b>微信ID</b></b-col>
              <b-col align="middle"><b>邮箱</b></b-col>
              <b-col align="middle"><b>职务</b></b-col>
              <b-col></b-col>
            </b-row>
            <div v-for="(contact, index) in contacts">
              <b-row>
                <b-col>
                  <input type="text" class="form-control" v-model="contact.name" required>
                </b-col>
                <b-col>
                  <input type="text" class="form-control" v-model="contact.phoneNumber" required>
                  <div v-show="!checkPhoneNumberValidity(contact.phoneNumber, false)" class="alert alert-danger">
                    电话号码有误
                  </div>
                </b-col>
                <b-col>
                  <input type="text" class="form-control" v-model="contact.wechatID">
                </b-col>
                <b-col>
                  <input type="text" class="form-control" v-model="contact.email">
                  <div v-show="!checkEmailValidity(contact.email, true)" class="alert alert-danger">
                    邮箱地址有误
                  </div>
                </b-col>
                <b-col>
                  <input type="text" class="form-control" v-model="contact.role">
                </b-col>
                <b-col>
                  <b-button @click="removeContact(index)" variant="danger">
                    删除 -
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>

          <b-form-group label="其他信息">
            <b-form-textarea
            v-model="supplier.note"
            placeholder="其他信息"
            rows="10"
            max-rows="10"
            ></b-form-textarea>
          </b-form-group>

          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="添加">
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import checkFormValidity from '@/js/checkFormValidity';

export default {
  data() {
    return {
      supplier: {},
      supplierList: [],
      contacts: []
    }
  },
  mixins: [
    storeUtil, checkFormValidity
  ],
  created() {
    if (this.$route.params.id != null) {
      this.getExistingSupplier();
    }
    this.getExistingSupplierList();
  },
  methods: {
    getExistingSupplier() {
      this.axios.post(`suppliers/query`, {
        _id: this.$route.params.id
      }).then(response => {
        this.supplier = response.data;
        this.contacts = this.supplier.contacts;
      });
    },
    getExistingSupplierList() {
      this.axios.post(`items/${this.getLocation}/distinct`, {
        field: 'supplier'
      }).then(response => {
        this.supplierList = response.data;
      });
    },
    addContact() {
      this.contacts.push({});
    },
    removeContact(index) {
      this.contacts.splice(index, 1);
    },
    submit() {
      for (var contact of this.contacts) {
        if (!this.checkPhoneNumberValidity(contact.phoneNumber, true) || !this.checkEmailValidity(contact.email, false)) {
          alert('请正确填写联系人信息后再添加供应商');
          return;
        }
      }
      this.supplier.contacts = this.contacts;

      var url = '';
      var msg = '';
      if (!this.$route.params.id) {
        url = `suppliers/add`;
        msg = '成功添加供应商';
      } else {
        url = `suppliers/update/${this.$route.params.id}`;
        msg = '供应商信息已更新';
      }
      this.axios.post(url, this.supplier).then(response => {
        alert(msg);
        this.$router.go(-1);
      }).then(err => {
        console.log(err);
      });
    }
  }
}
</script>
