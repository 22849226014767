export default {
  methods: {
    hideCard(divName) {
      var x = document.getElementById(divName);
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    }
  }
}
