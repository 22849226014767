<template>
  <div class="container">

    <br />

    <div class="card-deck">
      <div v-for="mark in personalizedBookmark" class="card text-white" :class="'bg-' + randomColor()" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 5px;">
        <i class="fa fa-times" aria-hidden="true" @click="removePersonalizedBookmark(mark)"></i>
        <div class="card-body">
          <h5 class="card-title text-center">
            <router-link :to="{ name: bookmarks[mark] }" class="text-white">
              {{ mark }}
            </router-link>
          </h5>
        </div>
      </div>

      <div class="card border-info text-center" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 5px;">
        <div class="card-body">
          <div v-if="!addNewBookmark" class="card-title">
            <i class="fa fa-plus fa-2x text-info" aria-hidden="true" @click="addNewBookmark=true"></i>
          </div>
          <div v-else class="card-title">
            <i class="fa fa-backspace" style="float: left;" @click="addNewBookmark=false"></i>
            <a v-for="mark in Object.keys(bookmarks)">
              <div class="trigger" @click="addPersonalizedBookmark(mark), addNewBookmark=false"><b>{{ mark }}</b></div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div v-if="weather != null">
      <b>
        {{ greeting }}! 今天是{{ today }}
        <br />
        天气   {{ weather.forecasts[0].dayWeather }}
        {{ weather.forecasts[0].dayWeather == weather.forecasts[0].nightWeather ? '' : `~ ${weather.forecasts[0].nightWeather}` }}
        <i class="fa fa-thermometer-full" aria-hidden="true"></i>   {{ weather.forecasts[0].nightTemp }} ~ {{ weather.forecasts[0].dayTemp }}度
      </b>
    </div>

    <br />

    <!-- <div class="card text-white bg-info border-info" style="padding: 20px;">
      <h4><i class="fa fa-list-ul" aria-hidden="true"></i>   待办事宜</h4>

      <b-row>

        <b-col>
          <div v-if="unlinkedProjectContracts.length > 0">
            <br />
            <div>
              <h5 style="display: inline-block;" @click="hideCard('unlinkedProjectContracts')">
                销售合同待关联到项目   <i class="fas fa-bullhorn"></i> {{unlinkedProjectContracts.length}}
              </h5>
            </div>

            <br />

            <div id="unlinkedProjectContracts" style="display: block;">
              <b-list-group v-for="c in unlinkedProjectContracts" :key="c._id">
                <b-list-group-item variant="info">
                  {{ c.filename }}, 合同金额: ￥{{ c.amount }}
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>

          <div v-if="contractsWithoutStamp.length > 0">
            <br />
            <div>
              <h5 style="display: inline-block;" @click="hideCard('contractsWithoutStamp')">
                上传盖章合同   <i class="fas fa-bullhorn"></i> {{contractsWithoutStamp.length}}
              </h5>
            </div>

            <br />

            <div id="contractsWithoutStamp" style="display: block;">
              <b-list-group v-for="c in contractsWithoutStamp" :key="c._id">
                <router-link :to="{name: 'ContractEdit', params: { id: c._id }}">
                  <b-list-group-item variant="info">
                    {{ c.filename }}, 合同金额: ￥{{ c.amount }}
                  </b-list-group-item>
                </router-link>
              </b-list-group>
            </div>
          </div>

          <div v-if="unlinkedItems.length > 0">
            <br />
            <div>
              <h5 style="display: inline-block;" @click="hideCard('unlinkedItems')">
                采购记录待关联到采购合同   <i class="fas fa-bullhorn"></i> {{unlinkedItems.length}}
              </h5>
            </div>

            <br />

            <div id="unlinkedItems" style="display: block;">
              <b-list-group v-for="item in unlinkedItems" :key="item._id">
                <b-list-group-item variant="info">
                  {{ item.name }} - {{ item.quantity }} - ￥{{ item.price }} - {{ item.supplier }}
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </b-col>

        <b-col>
          <div v-if="incompletedItems.length > 0">
            <br />

            <div>
              <h5 style="display: inline-block;" @click="hideCard('incompletedItems')">
                待完成采购记录   <i class="fas fa-bullhorn"></i> {{incompletedItems.length}}
              </h5>
            </div>

            <br />

            <div id="incompletedItems" style="display: block;">
              <b-list-group v-for="item in incompletedItems" :key="item._id">
                <router-link :to="{ name: 'ItemForm', params: {id: item._id} }">
                  <b-list-group-item variant="info">
                    <b>项目名称</b>: {{ item.projectTitle }}
                    <br />
                    <b>采购名称</b>: {{ item.name }}
                    <br />
                    <b>数量</b>: {{ item.quantity }}
                    <br />
                    <b>供应商</b>: {{ item.supplier }}
                    <br />
                    <b>采购负责人</b>: {{ item.owner }}
                    <br />
                    <b>采购员</b>: {{ item.purchaser }}
                  </b-list-group-item>
                </router-link>
              </b-list-group>
            </div>
          </div>

          <div v-if="reimbursementsWithNoReceipts.length > 0">
            <br />
            <div>
              <h5 style="display: inline-block;" @click="hideCard('reimbursementsWithNoReceipts')">
                费用申请待上传发票   <i class="fas fa-bullhorn"></i> {{reimbursementsWithNoReceipts.length}}
              </h5>
            </div>

            <br />

            <div id="reimbursementsWithNoReceipts" style="display: block;">
              <b-list-group v-for="r in reimbursementsWithNoReceipts" :key="r._id">
                <router-link :to="{ name: 'ReimbursementForm', params: { id: r._id } }">
                  <b-list-group-item variant="info">
                    {{ r.note }}, 金额: ￥{{ r.estimatedAmount }}
                  </b-list-group-item>
                </router-link>
              </b-list-group>
            </div>
          </div>

        </b-col>
      </b-row>
    </div> -->

    <br />

    <b-row>
      <!-- <b-col>
        <canvas id="projectCategory" width="100%" height="50"></canvas>
      </b-col> -->
      <b-col>
        <canvas id="horizontalBar" width="100%" height="50"></canvas>
        <CTemp :contractDisplay="contractDisplay"></CTemp>
        <RTemp
        :list="['projectTitle', 'estimatedAmount', 'amount', 'filename', 'method', 'completeTime', 'returnDate']"
        :reimbursements="reimbursementDisplay"
        :pagination="false"
        @deleteReimbursement="">
      </RTemp>
    </b-col>
    <b-col>
      <PersonalMonthFinanceBar :person="getUser.name"></PersonalMonthFinanceBar>
    </b-col>
  </b-row>

  <br />

  <!-- <div class="card">
    <div class="card-header">
      <h5>系统更新说明</h5>
    </div>
    <div class="card-body" v-html="updateNotes">
    </div>
  </div>

  <br /><br /><br /> -->

  <!-- <vue-event-calendar :events="allEvents"></vue-event-calendar> -->
</div>
</template>

<script>
import formatTime from '@/js/formatTime.js';
import storeUtil from '@/js/storeUtil.js';
import sortUtil from '@/js/sortUtil.js';
import loaderUtil from '@/js/loaderUtil.js';
import layoutUtil from '@/js/layoutUtil.js';
import accountingUtil from '@/js/accountingUtil';
import queryUtil from '@/js/queryUtil';
import locatePosition from '@/js/locatePosition.js';
import monthlyFinanceUtil from '@/components/finance/monthlyFinanceUtil';
import todoUtil from '@/components/management/todoUtil';
import moment from 'moment';
import MapLoader from '@/js/AMap.js';
import PersonalMonthFinanceBar from '@/components/finance/PersonalMonthFinanceBar';
import colorUtil from '@/js/colorUtil';

export default {
  data () {
    return {
      weather: null,
      map: null,
      allEvents: [],
      bookmarks: [],
      personalizedBookmark: [],
      addNewBookmark: false,
      unlinkedProjectContracts: [],
      contractsWithoutStamp: [],
      unlinkedItems: [],
      incompletedItems: [],
      reimbursementsWithNoReceipts: [],
      updateNotes: '',
      toReceive: 0,
      toPay: 0,
      margin: 0,
      personalFinance: new Object(),
      monthlyFinance: new Object(),
      contractDisplay: [],
      reimbursementDisplay: [],
      projectCategoryPie: null
    }
  },
  mixins: [
    formatTime, storeUtil, sortUtil, loaderUtil, layoutUtil, locatePosition,
    accountingUtil, monthlyFinanceUtil, queryUtil, todoUtil, colorUtil
  ],
  components: {
    PersonalMonthFinanceBar
  },
  created() {
    this.readUpdateNotes();

    this.fetchPurchasingEvents();
    this.fetchCars();
    this.fetchAnnouncements();

    this.fetchAllBookmarks();
    this.fetchPersonalizedBookmark();

    this.fetchAllTodos();

    this.createBar();
    this.createProjectCategoryPie();
  },
  mounted () {
    var _this = this;
    MapLoader().then(AMap => {
      _this.map = new AMap.Map('mapContainer', {
        resizeEnable: true,
        zoom: 13
      });
      AMap.event.addListener(_this.map, 'complete', this.getWeather());
      // AMap.event.addListener(_this.map, 'complete', this.addToolBar());
      AMap.event.addListener(_this.map, 'complete');
    }, e => {
      console.log('地图加载失败', e);
    });
  },
  methods: {
    getWeather() {
      var _this = this;
      this.map.plugin('AMap.CitySearch', function() {
        var citysearch = new AMap.CitySearch();
        citysearch.getLocalCity(function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            if (result && result.city && result.bounds) {
              var cityinfo = result.city;
              AMap.plugin('AMap.Weather', function() {
                var weather = new AMap.Weather();
                weather.getForecast(cityinfo, function(err, data) {
                  _this.weather = data;
                });
              });
            }
          }
        });
      });
    },
    readUpdateNotes() {
      this.axios.post('files/readLocalFile', {
        path: '/opt/sunharv/updateNotes.txt'
      }).then(response => {
        this.updateNotes = response.data;
      });
    },
    fetchPurchasingEvents() {
      var monthBefore = moment().add(-1, 'months').toDate();
      this.axios.post(`overall/${this.getLocation}/items/project`,
        {
          name: this.getUser.name
        }).then(response => {
          var items = response.data;
          for (var item of items) {
            var date = new Date(item.date);
            if (date.getTime() > monthBefore.getTime()) {
              this.allEvents.push({
                title: `采购`,
                date: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
                desc: `${item.quantity}个${item.name}`
              });
            }
          }
          this.sortEventsByDate(this.allEvents);
        });
      },
      fetchCars() {
        var query = {
          startTime: {
            $gte: new Date()
          }
        };
        if (this.getUser.role != '接待办主任') {
          query.user = this.getUser.name;
        }

        this.axios.post(`cars/${this.getLocation}/queryAll`, query).then(response => {
          var cars = response.data;
          for (var i = 0; i < cars.length; i++) {
            var car = cars[i];
            var startTime = moment(car.startTime);
            var endTime = moment(car.endTime);
            for (var d = startTime; d.isSameOrBefore(endTime, 'day'); d.add(1, 'd')) {
              this.allEvents.push({
                title: `外出`,
                date: `${d.get('year')}/${d.get('month') + 1}/${d.get('date')}`,
                desc: `开${car.brand}去${car.destinations}，${car.note}`
              });
            }
          }
        });
      },
      fetchAnnouncements() {
        this.axios.post(`announcements/${this.getLocation}/queryAll`,
          {
            startTime: {
              $lte: new Date()
            },
            endTime: {
              $gte: new Date()
            }
          }).then(response => {
            var announcements = response.data;
            for (var i = 0; i < announcements.length; i++) {
              var announcement = announcements[i];
              var startTime = moment(new Date());
              var endTime = moment(announcement.endTime);
              for (var d = startTime; d.isSameOrBefore(endTime, 'day'); d.add(1, 'd')) {
                this.allEvents.push({
                  title: announcement.title,
                  date: `${d.get('year')}/${d.get('month') + 1}/${d.get('date')}`,
                  desc: announcement.desc
                });
              }
            }
          });
        },
        fetchAllBookmarks() {
          this.axios.get(`redis/hgetall/bookmarks`).then(response => {
            var bookmarks = response.data[0];
            if (!this.authLevel(4)) {
              delete bookmarks['应收应付'];
              delete bookmarks['收付款历史'];
              delete bookmarks['费用一览'];
              delete bookmarks['收到票款'];
            }
            if (!this.authLevel(3)) {
              delete bookmarks['费用一览'];
            }
            if (!this.authLevel(5, this.isFileManager)) {
              delete bookmarks['相关文件管理'];
            }
            if (!this.authLevel(6, this.isFileManager, this.isDBEngineer)) {
              delete bookmarks['上传项目文件'];
            }
            this.bookmarks = bookmarks;
          });
        },
        fetchPersonalizedBookmark() {
          this.axios.get(`redis/zrange/${this.getUser._id}`).then(response => {
            this.personalizedBookmark = response.data[0];
          });
        },
        addPersonalizedBookmark(mark) {
          this.axios.post(`redis/zadd/${this.getUser._id}`, {
            score: this.personalizedBookmark.length + 1,
            value: mark
          }).then(response => {
            this.fetchPersonalizedBookmark();
          });
        },
        removePersonalizedBookmark(mark) {
          this.axios.post(`redis/zrem/${this.getUser._id}`, {value: mark}).then(response => {
            this.fetchPersonalizedBookmark();
          });
        },
        fetchAllTodos() {
          Promise.all([
            this.fetchUnlinkedProjectContracts(this.getUser.name),
            this.fetchContractsWithoutStamp(this.getUser.name),
            this.fetchUnlinkedItems(this.getUser.name),
            this.fetchIncompletedItems(this.getUser.name),
            this.fetchReimbursementsWithNoReceipts(this.getUser.name)
          ]).then(results => {
            this.unlinkedProjectContracts = results[0];
            this.contractsWithoutStamp = results[1];
            this.unlinkedItems = results[2];
            this.incompletedItems = results[3];
            this.reimbursementsWithNoReceipts = results[4];
          }).catch(err => {
            console.log(err);
          });
        },
        generateToReceive() {
          return new Promise((resolve, reject) => {
            this.axios.post(`contracts/${this.getLocation}/aggregate`, this.personalContractAggregate('销售合同', this.getUser.name)).then(response => {
              this.personalFinance.toReceive = response.data[0].due;
              resolve();
            });
          });
        },
        generateToPay() {
          return new Promise((resolve, reject) => {
            this.axios.post(`contracts/${this.getLocation}/aggregate`, this.personalContractAggregate('采购合同', this.getUser.name)).then(response => {
              this.personalFinance.toPay = response.data[0].due;
              resolve();
            });
          });
        },
        generateMargin() {
          return new Promise((resolve, reject) => {
            this.axios.post(`reimbursements/${this.getLocation}/aggregate`, [
              { $match: this.personalMarginQuery(this.getUser.name) },
              {
                $group: {
                  _id: null,
                  totalMargin: { $sum: '$estimatedAmount' }
                }
              }
            ]).then(response => {
              this.personalFinance['保证金'] = !response.data[0] ? 0 : response.data[0].totalMargin;
              this.axios.post(`overall/${this.getLocation}/reimbursements/project/title`, [
                { $match: this.personalMarginQuery(this.getUser.name) }
              ]).then(response => {
                resolve(response.data);
              });
            });
          });
        },
        generateMonthMargin() {
          return new Promise((resolve, reject) => {
            this.axios.post(`reimbursements/${this.getLocation}/queryAll`, this.personalMarginQuery(this.getUser.name)).then(response => {
              for (var r of response.data) {
                if (moment(r.returnDate).isBefore(moment().endOf('month'))) {
                  this.personalFinance['本月保证金'] = !this.personalFinance['本月保证金'] ? 0 : this.personalFinance['本月保证金'];
                  this.personalFinance['本月保证金'] += r.estimatedAmount;
                }
              }

              resolve();
            });
          });
        },
        dueMargin() {
          return new Promise((resolve, reject) => {
            this.axios.post(`reimbursements/${this.getLocation}/queryAll`, this.personalMarginQuery(this.getUser.name)).then(response => {
              for (var r of response.data) {
                if (moment(r.returnDate).isBefore(moment())) {
                  this.personalFinance['到期保证金'] = !this.personalFinance['到期保证金'] ? 0 : this.personalFinance['到期保证金'];
                  this.personalFinance['到期保证金'] += r.estimatedAmount;
                }
              }
              resolve();
            });
          });
        },
        dueReceive() {
          return new Promise((resolve, reject) => {
            this.axios.post(`contracts/${this.getLocation}/queryAll`, this.personalContractQuery('销售合同', this.getUser.name)).then(response => {
              resolve([this.dueCalculation(response.data, '到期应收', moment()), this.dueCalculation(response.data, '本月应收', moment().endOf('month'))]);
            });
          });
        },
        duePay() {
          return new Promise((resolve, reject) => {
            this.axios.post(`contracts/${this.getLocation}/queryAll`, this.personalContractQuery('采购合同', this.getUser.name)).then(response => {
              resolve([this.dueCalculation(response.data, '到期应付', moment()), this.dueCalculation(response.data, '本月应付', moment().endOf('month'))]);
            });
          });
        },
        dueCalculation(contracts, key, time) {
          var dueAmount = 0;
          var relatedContracts = [];
          for (var contract of contracts) {
            var percentage = this.expectedPaymentPercentage(contract, time) - this.actualPaymentPercentage(contract);
            if (percentage > 0) {
              dueAmount += percentage / 100 * contract.amount;
              relatedContracts.push(contract);
            }
          }
          this.personalFinance[key] = dueAmount;
          return relatedContracts;
        },
        createBar() {
          Promise.all([
            this.generateToReceive(),
            this.dueReceive(),
            this.generateToPay(),
            this.duePay(),
            this.generateMargin(),
            this.generateMonthMargin(),
            this.dueMargin()
          ]).then(results => {
            console.log(this.personalFinance);
            var _this = this;
            var ctx = document.getElementById('horizontalBar');
            var bar = new Chart(ctx, {
              type: 'horizontalBar',
              data: {
                datasets: [
                  {
                    data: [
                      this.personalFinance.toReceive,
                      this.personalFinance['到期应收'],
                      this.personalFinance['本月应收'],
                      this.personalFinance.toPay,
                      this.personalFinance['到期应付'],
                      this.personalFinance['本月应付'],
                      this.personalFinance['保证金'],
                      this.personalFinance['到期保证金'],
                      this.personalFinance['本月保证金']
                    ],
                    label: '个人应收应付汇总',
                    backgroundColor: [
                      this.red(0.2), this.red(0.5), this.red(0.8),
                      this.green(0.2), this.green(0.5), this.green(0.8),
                      this.yellow(0.2), this.yellow(0.5), this.yellow(0.8)
                    ],
                    borderColor:
                    [
                      this.red(1), this.red(1), this.red(1),
                      this.green(1), this.green(1), this.green(1),
                      this.yellow(1), this.yellow(1), this.yellow(1)
                    ],
                    borderWidth: 1
                  }
                ],
                labels: ['应收', '到期应收', '本月应收', '应付', '到期应付', '本月应付', '保证金', '到期保证金', '本月保证金']
              },
              options: {
                animation: {
                  animateScale: true,
                  animateRotate: true
                },
                onClick: function(event) {
                  var element = bar.getElementAtEvent(event)[0];
                  if (!element)
                  return;

                  _this.contractDisplay = [];
                  _this.reimbursementDisplay = [];

                  switch(element._model.label) {
                    case '应收':
                    break;
                    case '到期应收':
                    _this.contractDisplay = results[1][0];
                    break;
                    case '本月应收':
                    _this.contractDisplay = results[1][1];
                    break;
                    case '应付':
                    break;
                    case '到期应付':
                    _this.contractDisplay = results[3][0];
                    break;
                    case '本月应付':
                    _this.contractDisplay = results[3][1];
                    break;
                    case '保证金':
                    _this.reimbursementDisplay = results[4];
                    break;
                    case '到期保证金':
                    _this.reimbursementDisplay = results[4].filter(row => {
                      return moment(row.returnDate).isBefore(moment());
                    });
                    break;
                    case '本月保证金':
                    _this.reimbursementDisplay = results[4].filter(row => {
                      return moment(row.returnDate).isBefore(moment().endOf('month'));
                    });
                    break;
                  }

                }
              }
            });
            this.bar = bar;
          });
        },
        countProjectCategory() {
          return new Promise((resolve, reject) => {
            this.axios.post(`projects/${this.getLocation}/aggregate`, [{
              $group: {
                _id: '$category',
                'count': { $sum: 1 }
              }
            }]).then(response => {
              resolve(response.data);
            });
          });
        },
        createProjectCategoryPie() {
          Promise.all([
            this.countProjectCategory()
          ]).then(results => {
            var projectCategoryResult = results[0];
            var projectCategory = [];
            var projectCategoryCount = [];
            for (var i = 0; i < projectCategoryResult.length; i++) {
              projectCategory.push(projectCategoryResult[i]._id);
              projectCategoryCount.push(projectCategoryResult[i].count);
            }
            var colors = this.generateColorArray(projectCategoryResult.length);

            var ctx = document.getElementById('projectCategory');
            var pie = new Chart(ctx, {
              type: 'pie',
              data: {
                labels: projectCategory,
                datasets: [{
                  data: projectCategoryCount,
                  backgroundColor: colors
                }]
              }
            });
            this.projectCategoryPie = pie;
        });
      }
    }
  }
  </script>
