<template>
  <div>
    <div :id="id">
      <i class="fa fa-comment" aria-hidden="true"></i>
    </div>
    <b-popover :target="id" boundary="window" placement="right" triggers="hover">
      <a v-html="!note ? '' : note.replace(/；/, '<br />')"></a>
    </b-popover>
</div>
</template>

<script>
export default {
  props: [
    'id', 'note'
  ]
}
</script>
