<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>{{ title }}人员</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="submit">
            <div class="form-group">
              <rlabel label="姓名"></rlabel>
              <input type="text" class="form-control" v-model="person.name" required="required">
            </div>
            <div class="form-group">
              <rlabel label="邮箱"></rlabel>
              <input type="email" class="form-control" v-model="person.email" required="required">
            </div>
            <div class="form-group">
              <rlabel label="手机"></rlabel>
              <input class="form-control" v-model="person.phoneNumber" required="required">
            </div>

          <div class="form-group">
            <b-row>
              <b-col>
                <rlabel label="角色"></rlabel>
              </b-col>
              <b-col>
                <button type="button" v-on:click="addRole()" class="btn btn-success">
                  添加 +
                </button>
              </b-col>
            </b-row>
            <div v-for="(r, index) in roles">
              <b-row>
                <b-col>
                  <select class="form-control" v-model="roles[index]">
                    <option disabled selected value>-----请选择-----</option>
                    <option v-for="role in personRole" :value="role">{{ role }}</option>
                  </select>
                </b-col>
                <b-col>
                  <b-button @click="removeRole(index)" variant="danger">
                    删除 -
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="form-group">
              <rlabel label="是否加入年假管理？"></rlabel>
              <div>
                <input type="radio" class="radio" v-model="person.monitorWorkingHour" value="true" required="required">是
                <input type="radio" class="radio" v-model="person.monitorWorkingHour" value="false" required="required">否
              </div>
            </div>
          </div>

          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传">
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  props: [
    'title',
    'id'
  ],
  data() {
    return {
      person: {},
      roles: [],
      personRole: []
    }
  },
  mixins: [ storeUtil ],
  async created() {
    await this.fetchExistingPerson();
    this.fetchPersonRoleFromRedis();
  },
  methods: {
    async fetchExistingPerson() {
      if(this.title == '修改') {
        var response = await this.axios.post(`people/${this.getLocation}/query`, { _id: this.id });
        this.person = response.data;
        this.roles = this.person.role.split('/');
      }
    },
    fetchPersonRoleFromRedis() {
      this.axios.get(`redis/members/personRole`).then((response) => {
        this.personRole = response.data[0];
      });
    },
    addRolesToPerson() {
      var personRole = '';
      for (var i = 0; i < this.roles.length; i++) {
        personRole += this.roles[i];
        if (i + 1 < this.roles.length) {
          personRole += '/';
        }
      }
      this.person.role = personRole;
    },
    addRole() {
      this.roles.push('');
    },
    removeRole(index) {
      this.roles.splice(index, 1);
    },
    submit() {
      this.addRolesToPerson();
      var url = `people/${this.getLocation}/`;
      switch(this.title) {
        case '添加':
        url += 'add';
        break;
        case '修改':
        url += `update/${this.person._id}`;
        break;
      }

      this.axios.post(url, this.person).then(response => {
        alert(response.data);
        this.$router.push({name: 'IndexPerson'});
      }).catch(err => {
        console.log(err);
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
