export default {
  methods: {
    checkEmailValidity(email, required) {
      if (!required && (!email || email == '')) {
        return true;
      }
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    },
    checkPhoneNumberValidity(phoneNumber, required) {
      if (!required && (!phoneNumber || phoneNumber == '')) {
        return true;
      }
      const regex = /^[0-9]{11}$/;
      return regex.test(String(phoneNumber));
    },
    checkIsNumber(numStr) {
      return !isNaN(numStr);
    },
    checkContractPaymentMethodValidity(contractPaymentMethod) {
      if (!contractPaymentMethod)
      return true;

      var paymentMethodArray = contractPaymentMethod.split('-');
      var sum = paymentMethodArray.reduce((a, b) => a * 1 + b * 1, 0);
      return sum != 100;
    },
  }
}
