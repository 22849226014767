<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>新进用户问题</h3>
      </div>
      <div class="card-body">
        <ProblemListTemplate :query="query"></ProblemListTemplate>
      </div>
    </div>
  </div>
</template>

<script>
import ProblemListTemplate from './ProblemListTemplate';

export default {
  data() {
    return {
      query: {
        projectId: null
      }
    }
  },
  components: {
    ProblemListTemplate
  }
}
</script>
