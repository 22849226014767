<template>
<div>
  <div class="btn btn-outline-info" style="float: right;" @click="downloadZip(tickedTemplates)">批量下载 <i class="far fa-download"></i></div>

  <table class="table table-hover table-sm">
    <thead>
      <tr class="text-center">
        <th><input type="checkbox" @click="tickedTemplates = templates"></th>
        <th class="align-middle" style="min-width: 80px">序号</th>
        <Header header="文件名称" @sort="sort('projectTitle')"></Header>
        <Header header="上传时间" @sort="sort('owner')"></Header>
        <Header header="文件内容描述"></Header>
        <Header header="下载次数"></Header>
        <th style="min-width: 80px"></th>
        <th v-if="authLevel(2)" style="min-width: 80px"></th>
        <th style="min-width: 80px"></th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(template, index) in templates" :key="template._id" class="text-center">
        <td><input type="checkbox" :value="template" v-model="tickedTemplates"></td>
        <td><b>{{ index + 1 }}</b></td>
        <td class="align-middle" @click="incrementDownloadTimes(template)">
          <a href="#" @click="viewFile(template.fileId, template.filename)">
            {{ template.filename }}
          </a>
        </td>
        <td class="align-middle">{{ getYearMonthDate(template.uploadTime) }}</td>
        <td class="align-middle" v-html="!template.note ? '' : template.note.replace(/\n/, '<br />')"></td>
        <td class="align-middle">{{ !template.downloadTimes ? 0 : template.downloadTimes }}</td>
        <td>
          <router-link :to="{ name: 'UploadSharedFiles', params: { id: template._id } }" class="btn btn-primary">编辑</router-link>
        </td>
        <td v-if="authLevel(2)"><button class="btn btn-danger" @click="deleteTemplate(template._id)">删除</button></td>
        <td class="align-middle">
          <router-link :to="{ name: 'SharedFilesHistory', params: { id: template._id } }" class="btn btn-primary" v-if="template.previousVersion != null">
            历史版本
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>

<script>
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import formatTime from '@/js/formatTime';

export default {
  data() {
    return {
      tickedTemplates: []
    }
  },
  props: [
    'templates'
  ],
  mixins: [
    fileIO, storeUtil, formatTime
  ],
  methods: {
    incrementDownloadTimes(template) {
      template.downloadTimes = !template.downloadTimes ? 1 : template.downloadTimes + 1;
      this.axios.post(`template/update/${template._id}`, template).then(response => {
        alert(response.data);
        this.$forceUpdate();
      }).catch(err => {
        console.log(err);
      });
    },
    deleteTemplate(id) {
      this.axios.get(`template/delete/${id}`).then(response => {
        alert(response.data);
        this.$forceUpdate();
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>
