<template>
  <div>
    <FileLoading :showGifImg="showGifImg"></FileLoading>
    <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

    <div class="card" id="completedContracts">
      <div class="card-header">
        <h3>我审批的合同</h3>
        <b>* 此处显示近三个月来审批的合同。</b>
      </div>
      <div class="card-body">
        <rise-loader :loading="loading" class="text-center"></rise-loader>
        <div v-if="!loading && sortedContracts.length > 0" style="overflow-x: auto">
          <table class="table table-hover table-sm">
            <thead>
              <tr class="text-center">
                <Header header="责任人" @sort="sort('owner')"></Header>
                <Header header="类别" @sort="sort('category')"></Header>
                <Header header="合同名称" @sort="sort('filename')"></Header>
                <Header header="上传时间" @sort="sort('uploadTime')"></Header>
                <Header header="合同金额" @sort="sort('amount')"></Header>
                <th style="width:60px"></th>
                <th style="width:60px"></th>
                <th class="align-middle" style="min-width: 120px">我通过的时间</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="contract in filteredContracts" :key="contract._id" class="text-center">
                <td class="align-middle">{{ contract.owner }}</td>
                <td class="align-middle">{{ contract.category }}</td>
                <td class="align-middle">
                  <a href="#" @click="viewFile(contract.fileId, contract.filename)">{{ contract.filename }}</a>
                </td>
                <td class="align-middle">{{ getFormattedDate(contract.uploadTime) }}</td>
                <td class="align-middle">{{ contract.amount }}</td>

                <td class="align-middle" v-if="contract.category != '中标通知书'">
                  <router-link :to="{name: 'ContractInfo', params: { id: contract._id }}" class="btn btn-success btn-sm">详情</router-link>
                </td>
                <td class="align-middle" v-else></td>

                <td class="align-middle" v-if="contract.category == '采购合同'">
                  <router-link :to="{ name: 'ItemConfirmation', params: {contractId: contract._id} }" class="btn btn-success btn-sm">采购详情</router-link>
                </td>
                <td class="align-middle" v-else></td>

                <td v-if="contract.procedureTimestamp != null" class="align-middle">
                  <a v-for="role in getUser.role.split('/')">
                    {{ getFormattedDate(contract.procedureTimestamp[role]) }}
                  </a>
                </td>
                <td v-else></td>

                <td v-if="contract.procedureNote != null && contract.procedureNote != ''" class="align-middle">
                  <ProcedureNote :id="contract._id.toString() + 'ProcedureNote'" :note="contract.procedureNote"></ProcedureNote>
                </td>
                <td v-else></td>
              </tr>
            </tbody>
          </table>

          <Pagination
          :array="sortedContracts"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @setCurrentPage="setCurrentPage"
          @incrementCurrentPage="currentPage++"
          @minusCurrentPage="currentPage--">
        </Pagination>
      </div>
      <div v-else-if="!loading && sortedContracts.length== 0">
        <Empty></Empty>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';
import fileIO from '@/js/fileIO';
import util from './util';
import moment from 'moment';

export default {
  data() {
    return {
      contracts: [],
      currentSort: 'uploadTime',
      currentSortDir: 'desc'
    }
  },
  mixins: [
    util, formatTime, sortUtil, storeUtil, loaderUtil, fileIO
  ],
  created() {
    this.getContracts();
  },
  computed : {
    sortedContracts() {
      return this.sortByProcedureTimestamp(this.contracts);
    },
    filteredContracts() {
      return this.filterForPagination(this.sortedContracts);
    }
  },
  methods: {
    getContracts() {
      var procedureName = 'contractProcedure';
      var baseRedisUri = `redis/lrange/`;
      this.axios.get(`${baseRedisUri}${procedureName}`).then(response => {
        var projectProcedure = this.inProcedureArray(response.data[0]);
        procedureName = 'purchasingContractProcedure';
        this.axios.get(`${baseRedisUri}${procedureName}`).then(response => {
          var purchasingProcedure = this.inProcedureArray(response.data[0]);
          var procedure = projectProcedure.concat(purchasingProcedure);
          let uri = `contracts/${this.getLocation}/queryAll/timeline`;
          this.axios.post(uri, {procedure: {$in: procedure}}).then((response) => {
            this.contracts = response.data;
            this.loading = false;
          });

        });
      });
    }
  }
}
</script>
