<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>上传发票</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="uploadReceipt">
          <div class="form-group">
            <label>合同:</label>
            <a v-if="!contract.stampedFileId && !contract.stampedFilename" href="#" @click="viewFile(contract.fileId, contract.filename)">
              {{ contract.filename }}
            </a>
            <a v-else href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">
              {{ contract.stampedFilename }}
            </a>
          </div>
          <div class="form-group">
            <label>合同总金额:</label>
            {{contract.amount}}
          </div>
          <div class="form-group">
            <label>已开票金额:</label>
            {{ contract.receiptAmount }}
          </div>
          <div class="form-group">
            <label>此次开票金额:</label>
            {{ receipt.amount }}
          </div>
          <div class="form-group">
            <label>备注:</label>
            {{ receipt.note }}
          </div>

          <FileUploadBox
          label="上传发票"
          :notes="['支持拖拽; 重新选择或拖拽文件即可替换原先上传文件']"
          @setFiles="files = $event"
          :required="true"
          :supportMultiFiles="false"
          :filenameCheck="false">
          </FileUploadBox>

          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传发票" :disabled="waiting">
          </div>
          <Uploading :waiting="waiting"></Uploading>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      contract: {},
      receipt: {}
    }
  },
  mixins: [fileIO, storeUtil, loaderUtil],
  created: function() {
    this.fetchReceipt();
  },
  methods: {
    fetchReceipt() {
        this.axios.post(`receipts/${this.getLocation}/query`, {
          _id: this.$route.params.id
        }).then((response) => {
          this.receipt = response.data;
          this.fetchContract(this.receipt.contractId);
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
    },
    fetchContract(contractId) {
        this.axios.post(`contracts/${this.getLocation}/query`, {
          _id: contractId
        }).then((response) => {
          this.contract = response.data;
        }).catch(() => {
          this.$router.push({name: 'ErrorPage'});
        });
    },
    uploadReceipt() {
      this.waiting = true;
      this.handleFiles(this.updateReceiptAndContract, false);
    },
    updateReceiptAndContract(id, filename) {
      this.receipt.fileId = id;
      this.receipt.filename = filename;
      if (this.receipt.date == null) {
        this.receipt.date = moment();
      }

      var receiptId = this.$route.params.id;
      this.axios.post(`receipts/${this.getLocation}/update/${receiptId}`, this.receipt).then(() => {
        this.axios.post(`receipts/${this.getLocation}/update/procedure/${receiptId}`, {}).then((response) => {
          alert(response.data);
          this.$router.push({name: 'Message'});
          this.waiting = false;
        });
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
