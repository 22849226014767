<template>
  <div class="container">
    <div class="card" id="workingHour">
      <div class="card-header">
        <h3>{{ person.name }}的工时一览</h3>
      </div>

      <div class="card-body">
        <b>{{ year }}年度，你共加班{{ totalOvertime }}小时，休假{{ totalVacation }}小时，剩余调休时间{{ workingHours }}小时。</b>

        <br /><br /><br />

        {{ year }}年度年假时长: <input type="number" min="0" v-model="annualLeave" v-on:keyup.enter="updateAnnualLeave()">小时

        <div class="card-header">
          <h5>加班详情</h5>
        </div>
        <WorkingHourTemplate :array="overtime" @deleteWorkingHour="deleteWorkingHour($event, 'overtime')"></WorkingHourTemplate>
        <br />
        <div class="card-header">
          <h5>休假详情</h5>
        </div>
        <WorkingHourTemplate :array="vacation" @deleteWorkingHour="deleteWorkingHour($event, 'vacation')"></WorkingHourTemplate>

        <div class="card">
          <div class="card-header">
            <h5>历史</h5>
            <button class="btn btn-primary" style="float: right;" @click="hideCard('workingHourHistory')">隐藏</button>
          </div>
          <div class="card-body" id="workingHourHistory" style="display: none;">
            <div class="card-header">
              <h5>历史加班详情</h5>
            </div>
            <WorkingHourTemplate :array="overtimeHistory"></WorkingHourTemplate>

            <div class="card-header">
              <h5>历史休假详情</h5>
            </div>
            <WorkingHourTemplate :array="vacationHistory"></WorkingHourTemplate>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import formatTime from '@/js/formatTime';
import WorkingHourTemplate from './WorkingHourTemplate';
import moment from 'moment';
import util from './util';
import layoutUtil from '@/js/layoutUtil';

export default {
  data() {
    return {
      person: {},
      year: moment().year(),
      annualLeave: 0
    }
  },
  mixins: [
    storeUtil, sortUtil, util, formatTime, layoutUtil
  ],
  components: {
    WorkingHourTemplate
  },
  computed: {
    workingHours() {
      return this.annualLeave + this.totalOvertime - this.totalVacation;
    }
  },
  created() {
    this.axios.post(`people/${this.getLocation}/query`, {
      _id: this.$route.params.personId
    }).then(response => {
      this.person = response.data;
      this.annualLeave = !this.person.annualLeave ? 0 : this.person.annualLeave;
      this.computeWorkingHoursByPerson(this.person);
    });
  },
  methods: {
    updateAnnualLeave() {
      this.person.annualLeave = this.annualLeave;
      this.axios.post(`people/${this.getLocation}/update/${this.person._id}`, this.person).then(response => {
        alert(response.data);
      });
    },
    deleteWorkingHour(elementId, category) {
      // category is either overtime or vacation
      if (category != 'overtime' && category != 'vacation') {
        alert('无法删除');
        return;
      }

      this.axios.post(`people/${this.getLocation}/delete/${category}`, {
        name: this.person.name,
        id: elementId
      }).then(response => {
        alert(response.data);
        this.$forceUpdate();
      });
    }
  }
}
</script>
