<template>
  <b-progress min="0" max="100" show-value show-progress animated>
    <b-progress-bar :value="actualReceiptPercentage(contract)" variant="primary"></b-progress-bar>
    <b-progress-bar v-if="contract.category == '采购合同'" :value="actualPaymentPercentage(contract) - actualReceiptPercentage(contract) > 0 ? actualPaymentPercentage(contract) - actualReceiptPercentage(contract) : 0" variant="danger"></b-progress-bar>
  </b-progress>
</template>

<script>
import accountingUtil from '@/js/accountingUtil';

export default {
  props: [
    'contract'
  ],
  mixins: [
    accountingUtil
  ]
}
</script>
