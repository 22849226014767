<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>我的用车申请</h3>
        <br />
        <ul class="nav nav-pills">
          <li class="nav-item">
            <span class="nav-link" :class="{active: !isCompleted}" @click="isCompleted=false">未完成</span>
          </li>
          <li class="nav-item">
            <span class="nav-link" :class="{active: isCompleted}" @click="isCompleted=true">已完成</span>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <div v-if="sortedCars.length > 0" style='overflow-x:auto'>
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <Header header="用车人" @sort="sort('user')"></Header>
                <Header header="项目名称" @sort="sort('projectTitle')"></Header>
                <Header header="车辆" @sort="sort('brand')"></Header>
                <Header header="开始时间" @sort="sort('startTime')"></Header>
                <Header header="结束时间" @sort="sort('endTime')"></Header>
                <th class="text-center align-middle">目的地</th>
                <th class="text-center align-middle">说明</th>
                <Header header="审批进度" @sort="sort('procedure')"></Header>
                <th v-show="!isCompleted"></th>
                <th class="text-center align-middle"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(car, index) in filteredCars" :key="car._id">
                <td class="text-center align-middle">{{ car.user }}</td>
                <td class="text-center align-middle">{{ car.projectTitle }}</td>
                <td class="text-center align-middle" style="width: 10%">
                  <img src="@/assets/bmw.png" style="width: 90%" v-if="car.brand == '宝马'" />
                  <img src="@/assets/audi.jpeg" style="width: 100%" v-else-if="car.brand == '奥迪'" />
                  <img src="@/assets/nio.jpg" style="width: 100%" v-else-if="car.brand == '蔚来'" />
                </td>
                <td class="text-center align-middle">{{ getFormattedDate(car.startTime) }}</td>
                <td class="text-center align-middle">{{ getFormattedDate(car.endTime) }}</td>
                <td class="text-center align-middle">{{ car.destinations }}</td>
                <td class="align-middle">{{ car.note }}</td>
                <td class="text-center align-middle">
                  {{ car.procedure }}
                  <WhoIsWho :id="car._id" :role="car.procedure" v-if="car.procedure != '完成' && car.procedure != car.user"></WhoIsWho>
                </td>
                <td class="text-center align-middle" v-show="!isCompleted">
                  <button class="btn btn-danger btn-sm" @click="deleteCarProcedure(car._id, index)">删除</button>
                </td>
                <td v-if="car.procedureTimestamp != null" class="text-center align-middle">
                  <TimelineHover :timelineId="car._id" :timestamps="car.procedureTimestamp"></TimelineHover>
                </td>
                <td v-else></td>
              </tr>
            </tbody>
          </table>

          <Pagination
          :array="sortedCars"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @setCurrentPage="setCurrentPage"
          @incrementCurrentPage="currentPage++"
          @minusCurrentPage="currentPage--">
        </Pagination>
      </div>

      <div v-else>
        <h4 class="text-center align-middle">空空如也   <i class="far fa-flushed"></i></h4>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import formatTime from '@/js/formatTime';

export default {
  data() {
    return {
      cars: [],
      isCompleted: false,
      currentSort: 'endTime',
      pageSize: 5
    }
  },
  mixins: [storeUtil, sortUtil, formatTime],
  created() {
    this.fetchCars();
  },
  computed: {
    sortedCars() {
      this.setCurrentPage(1);
      this.currentSortDir = !this.isCompleted ? 'asc' : 'desc';

      return this.cars.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(!a[this.currentSort] && !b[this.currentSort]) return 0;
        if(!a[this.currentSort] && b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] && !b[this.currentSort]) return 1 * modifier;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter(row => {
        return !this.isCompleted ? moment(row.endTime).isAfter(moment()) : moment(row.endTime).isBefore(moment());
      });
    },
    filteredCars() {
      return this.sortArray(this.sortedCars);
    }
  },
  methods: {
    fetchCars() {
      this.axios.post(`redis/zrange/sortedCarProcedure`, {}).then(response => {
        var procedure = response.data[0];
        var query = {};
        var include = false;
        for (var role of this.getUser.role.split('/')) {
          if (procedure.includes(role)) {
            include = true;
            break;
          }
        }
        if (!include) {
          query.user = this.getUser.name;
        }

        this.axios.post(`overall/${this.getLocation}/cars/project/title`, query).then((response) => {
          this.cars = response.data;
        });
      });
    },
    deleteCarProcedure(id, index) {
      this.axios.get(`cars/${this.getLocation}/delete/${id}`).then((response) => {
        alert(response.data);

        this.cars.splice(index, 1);
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      })
    }
  }
}
</script>
