<template>
  <div class="circular"
  :style="{
    'background-image': `url(https://bing.biturl.top/?resolution=1920&format=image&index=0)`,
    'position': 'fixed',
    'top': '0px',
    'left': '0px',
    'width': '100%',
    'height': '100%',
    'background-size': 'cover'
  }">
    <br />
    <br />
    <br />

    <b-container class="bv-example-row bv-example-row-flex-cols">
      <div class="card" style="background-color: transparent; border-color: transparent">
        <div class="card-body">
          <b-row>
            <b-col>
            </b-col>
            <b-col>
          <form v-on:submit.prevent="login">
            <div class="form-group">
              <label><b class="text-white">用户名:</b></label>
              <input type="email" class="form-control" v-model="user.email" required="required" style="width: 20em">
            </div>
            <div class="form-group">
              <label><b class="text-white">密码:</b></label>
              <input type="password" class="form-control" v-model="user.password" autocomplete="on" required="required" style="width: 20em">
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="xian" v-model="location">
              <label class="form-check-label"><b class="text-white">西安</b></label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" value="shanghai" v-model="location">
              <label class="form-check-label"><b class="text-white">上海</b></label>
            </div>
            <br />
            <br />
            <div class="form-group">
              <input type="submit" class="btn btn-primary" value="登录">
            </div>
          </form>
        </b-col>
        <b-col>
        </b-col>
      </b-row>
        </div>
      </div>
  </b-container>
</div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      imageIndex: Math.ceil(Math.random() * 10),
      location: ''
    }
  },
  methods: {
    login() {
      if(!this.location) {
        alert('请选择所属地');
        return;
      }

      this.$store.dispatch("changeLocation", this.location).then(() => {
        this.$store.dispatch("login", this.user).then(() => {
          this.$router.push("/").catch(err => {});
        }).catch((err) => {
          alert(err);
        });
      });
    }
  }
}
</script>
