export default {
  methods: {
    thisLocation() {
      var _this = this;
      this.map.plugin('AMap.Geolocation', () => {
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: false,
          timeout: 10000,
          zoomToAccuracy: true,
          buttonPosition: 'RB',
          extensions: 'all'
        });
        _this.map.addControl(geolocation);
        geolocation.getCurrentPosition();
        console.log(geolocation);
        AMap.event.addListener(geolocation, 'complete', (data) => {
          console.log(data);
          // this.citycode = data.addressComponent.cityCode
          var locatedPosition = [data.position.lng, data.position.lat];
          var desiredPosition = [108.873763, 34.2613665];
          // 返回 p1 到 p2 间的地面距离，单位：米
          var distance = AMap.GeometryUtil.distance(locatedPosition, desiredPosition);
          console.log(distance);
          // this.thisPosition.message = data.formattedAddress
          // this.chosePosition = this.thisPosition
        });
        AMap.event.addListener(geolocation, 'error', (err, data) => {
          alert(`定位失败${JSON.stringify(err)}`);
          console.log(err, data);
        });
      });
    }
  }
}
