<template>
  <b-card class="mb-2">
    <b-card-title>{{ problem.title }}</b-card-title>
    <br />
    <b-card-sub-title>{{ problem.user }}</b-card-sub-title>
    <br />
    <b-card-sub-title>{{ problem.uploadTime[0] }}</b-card-sub-title>
    <br />
    <b-card-text>
    {{ problem.description }}
    </b-card-text>
    <router-link :to="{ name: 'ProblemInfo', params: { id: problem._id } }" class="btn btn-primary">详情</router-link>
  </b-card>
</template>

<script>
import ProblemInfo from '@/components/problem/ProblemInfo';

export default {
  props: [
    'problem'
  ],
  components: {
    ProblemInfo
  }
}
</script>
