import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import config from '../config/IP';
import moment from 'moment';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    location: '',
    authLevel: 5
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success';
      state.token = token;
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.authLevel = 6
    },
    setLocation(state, location) {
      state.location = location;
    },
    setAuthLevel(state, level) {
      state.authLevel = level;
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios.post(`https://${config.IP}:${config.port}/people/${this.state.location}/login`, user)
        .then(res => {
          const token = res.data.token;
          localStorage.setItem('token', token);
          axios.defaults.baseURL = `https://${config.IP}:${config.port}/`;
          axios.defaults.headers.common = {Authorization: `Bearer ${token}`};
          commit('auth_success', token, res.data.user);
          this.state.user = res.data.user;

          axios.post(`https://${config.IP}:${config.port}/redis/hset/lastLogin`, {
            key: this.state.user._id, value: moment()
          }).then(() => {

            var roleList = this.state.user.role.split('/');
            var baseRedisUri = `https://${config.IP}:${config.port}/redis/members/authLevel`;
            var level = 2;
            axios.get(`${baseRedisUri}${level}`).then(response => {
              for (var role of roleList) {
                if (response.data[0].includes(role)) {
                  commit('setAuthLevel', level);
                  resolve();
                  return;
                }
              }
              level = 3;
              axios.get(`${baseRedisUri}${level}`).then(response => {
                for (var role of roleList) {
                  if (response.data[0].includes(role)) {
                    commit('setAuthLevel', level);
                    resolve();
                    return;
                  }
                }
                level = 4;
                axios.get(`${baseRedisUri}${level}`).then(response => {
                  for (var role of roleList) {
                    if (response.data[0].includes(role)) {
                      commit('setAuthLevel', level);
                      resolve();
                      return;
                    }
                  }
                });
              });
            });

          });
          resolve(res);
        })
        .catch(err => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      })
    },
    changeLocation({ commit }, location) {
      return new Promise((resolve, reject) => {
        commit('setLocation', location);
        resolve();
      });
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    getUser: state => state.user,
    getAuthLevel: state => state.authLevel,
    isAdmin: state => state.user.role.indexOf('管理员') >= 0,
    isFinance: state => state.user.role && state.user.role.indexOf('会计') >= 0,
    // isCashier: state => state.user.role.indexOf('出纳') >= 0,
    // isContractManager: state => state.user.role.indexOf('合同审核员') >= 0,
    // isFileManager: state => state.user.role.indexOf('资料审核员') >= 0,
    // isStampManager: state => state.user.role && state.user.role.indexOf('盖章人') >= 0,
    // isDriver: state => state.user.role && state.user.role.indexOf('接待办主任') >= 0,
    // isReceiptManager: state => state.user.role && state.user.role.indexOf('开票人') >= 0,
    // isSales: state => state.user.role == '销售总监',
    // isDBEngineer: state => state.user.role.indexOf('数据库') >= 0,
    getLocation: state => state.location
  }
})
