<template>
  <div class="card" id="completedReceipts">
    <div class="card-header">
      <h3>我审批的开票</h3>
      <b>* 此处显示近三个月来审批的开票。</b>
    </div>
    <div class="card-body">
      <rise-loader :loading="loading" class="text-center"></rise-loader>
      <div v-if="!loading && sortedReceipts.length > 0" style="overflow-x: auto">
        <table class="table table-hover table-sm">
          <thead>
            <tr class="text-center">
              <Header header="项目名称" @sort="sort('projectTitle')"></Header>
              <Header header="盖章合同" @sort="sort('stampedFilename')"></Header>
              <Header header="合同负责人" @sort="sort('owner')"></Header>
              <Header header="开票金额" @sort="sort('amount')"></Header>
              <Header header="开票日期" @sort="sort('date')"></Header>
              <Header header="开票类别" @sort="sort('category')"></Header>
              <th class="align-middle" style="min-width: 100px">备注</th>
              <Header header="我通过的时间" style="min-width: 120px"></Header>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="receipt in filteredReceipts" :key="receipt._id" class="text-center">
              <td class="align-middle">{{ receipt.projectTitle }}</td>
              <td class="align-middle"><a href="#" @click="viewFile(receipt.stampedFileId, receipt.stampedFilename)">{{ receipt.stampedFilename }}</a></td>
              <td class="align-middle">{{ receipt.owner }}</td>
              <td class="align-middle">{{ receipt.amount }}</td>
              <td class="align-middle">{{ getYearMonthDate(receipt.date) }}</td>
              <td class="align-middle">{{ receipt.category }}</td>
              <td class="align-middle">{{ receipt.note }}</td>

              <td v-if="receipt.procedureTimestamp != null" class="align-middle">
                <a v-for="role in getUser.role.split('/')">
                  {{ getFormattedDate(receipt.procedureTimestamp[role]) }}
                </a>
              </td>
              <td v-else></td>

              <td v-if="receipt.procedureNote != null && receipt.procedureNote != ''" class="align-middle">
                <ProcedureNote :id="receipt._id.toString() + 'ProcedureNote'" :note="receipt.procedureNote"></ProcedureNote>
              </td>
              <td v-else></td>
            </tr>
          </tbody>
        </table>

        <Pagination
        :array="sortedReceipts"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @setCurrentPage="setCurrentPage"
        @incrementCurrentPage="currentPage++"
        @minusCurrentPage="currentPage--">
      </Pagination>
    </div>
    <div v-else-if="!loading && sortedReceipts.length == 0">
      <Empty></Empty>
    </div>
  </div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';
import util from './util';
import moment from 'moment';

export default {
  data() {
    return {
      receipts: [],
      currentSort: 'date',
      currentSortDir: 'desc'
    }
  },
  mixins: [
    util, formatTime, sortUtil, storeUtil, loaderUtil
  ],
  created() {
    this.getReceipts();
  },
  computed : {
    sortedReceipts() {
      return this.sortByProcedureTimestamp(this.receipts);
    },
    filteredReceipts() {
      return this.filterForPagination(this.sortedReceipts);
    }
  },
  methods: {
    getReceipts() {
      this.axios.get(`redis/lrange/receiptProcedure`).then(response => {
        var procedure = this.inProcedureArray(response.data[0]);
        this.axios.post(`receipts/${this.getLocation}/queryAll/timeline`, {
          procedure: { $in: procedure }
        }).then(response => {
          this.receipts = response.data;
          this.getContract();
        });
      });
    },
    getContract() {
      this.receipts.forEach((receipt, i) => {
        this.axios.post(`contracts/${this.getLocation}/query`, {
          _id: receipt.contractId
        }).then(response => {
          var contract = response.data;
          if (contract != null) {
            receipt.owner = contract.owner;
            receipt.stampedFileId = contract.stampedFileId;
            receipt.stampedFilename = contract.stampedFilename;
            this.axios.post(`projects/${this.getLocation}/query`, {
              contractId: contract._id
            }).then(projectResponse => {
              receipt.projectTitle = projectResponse.data.title;
              this.$forceUpdate();
            });
          }
        });
      });
      this.loading = false;
    }
  }
}
</script>
