<template>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <router-link :to="{ name: 'ReimbursementListIndividual'}" class="nav-link" :class="status == 1 ? 'active' : ''">个人垫付</router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'ReimbursementListCompany'}" class="nav-link" :class="status == 2 ? 'active' : ''">公司付款</router-link>
    </li>
    <li class="nav-item">
      <router-link :to="{ name: 'ReimbursementListComplete'}" class="nav-link" :class="status == 3 ? 'active' : ''">已完成</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: [
    'status'
  ]
}
</script>
