export default {
  methods: {
    translateProcedure(procedure) {
      switch (procedure) {
        case 'FINANCE':
        return '会计';
        case 'CASHIER':
        return '出纳';
        case 'ADMIN':
        return '管理员';
        case 'CONTRACT_MANAGER':
        return '合同审核员';
        case 'STAMP_MANAGER':
        return '盖章人';
        case 'COMPLETE':
        return '完成';
        case 'DRIVER':
        return '接待办主任';
        default:
        return procedure;
      }
    }
  }
}
