import fileIO from '@/js/fileIO';
import loaderUtil from '@/js/loaderUtil';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import accountingUtil from '@/js/accountingUtil';
import TheHeader from './TheHeader';

export default {
  data() {
    return {
      projects: [],
      allProjects: [],
      currentSort: 'customer',
      filters: [],
      searchLogicOr: false
    }
  },
  components: {
    TheHeader
  },
  mixins: [
    fileIO, loaderUtil, sortUtil, storeUtil, accountingUtil
  ],
  created() {
    this.fetchProjects();
  },
  updated() {
    var projectPosition = this.$cookies.get('projectPosition');
    if (projectPosition != null) {
      if (projectPosition.filters != null) {
        this.updateData(projectPosition.filters);
      }
      this.currentPage = projectPosition.page;
      var row = this.$refs.projects[projectPosition.index];
      if (row != null) {
        row.scrollIntoView();
      }
      this.$cookies.remove('projectPosition');
    }
  },
  computed : {
    sortedProjects() {
      return this.sortArray(this.projects);
    },
    projectCustomerCount() {
      var count = new Object();
      for (var project of this.sortedProjects) {
        count[project.customer] = !count[project.customer] ? 1 : count[project.customer] + 1;
      }
      return count;
    }
  },
  methods: {
    fetchProjects() {
      var query = {
        status: this.status
      };
      if (!this.authLevel(2)) {
        query['people.name'] = this.getUser.name;
      }
      this.axios.post(`projects/${this.getLocation}/queryAll`, query).then((response) => {
        this.projects = response.data;
        this.allProjects = response.data;
        this.computeFilenames();
        this.loading = false;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    computeFilenames() {
      this.projects.forEach(project => {
        if (project.contractId != null) {
          this.axios.post(`contracts/${this.getLocation}/query`, { _id: project.contractId }).then(response => {
            project.filename = response.data.filename;
            this.$forceUpdate();
          });
        }
      });
    },
    updateData(filters) {
      this.filters = filters;

      this.currentPage = 1;
      this.projects = filters.length == 0 ? this.allProjects :
      !this.searchLogicOr ? this.filterKeywordsAnd(this.allProjects, filters) : this.filterKeywords(this.allProjects, filters);
    },
    setProjectIndex(index) {
      var projectPosition = {
        page: this.currentPage,
        index: index
      };
      if (this.filters.length > 0) {
        projectPosition.filters = this.filters;
      }
      this.$cookies.set('projectPosition', projectPosition);
    },
    changeProjectStatus(project, status) {
      project.status = status;
      this.axios.post(`projects/${this.getLocation}/update/${project._id}`, project).then(response => {
        alert(response.data);
      });
    }
  }
}
