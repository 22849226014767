<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>询价</h3>
      </div>
      <div class="card-body">
          <div class="form-group">
            <rlabel label="项目名称"></rlabel>
            <select class="form-control" v-model="projectTitle" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="project in projects" :key="project._id" :value="project.title">
                {{ project.customer }} - {{ project.title }}
              </option>
            </select>
          </div>

          <br />

          <div class="card">
            <div class="form-group">
              <button class="btn btn-primary" @click="$bvModal.show('search-supplier')">添加</button>
            </div>
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <Header header="名称"></Header>
                  <Header header="地址"></Header>
                  <Header header="联系人姓名"></Header>
                  <Header header="联系人电话"></Header>
                  <Header header="联系人微信号"></Header>
                  <Header header="联系人邮箱"></Header>
                  <Header header="联系人职务"></Header>
                  <Header header="信誉"></Header>
                </tr>
              </thead>
              <tbody>
                <tr v-for="supplier in suppliers">
                  <td>{{ supplier.name }}</td>
                  <td>{{ supplier.location }}</td>
                  <td>{{ supplier.name }}</td>
                  <td>{{ supplier.phoneNumber }}</td>
                  <td>{{ supplier.wechatID }}</td>
                  <td>{{ supplier.email }}</td>
                  <td>{{ supplier.role }}</td>
                  <td>{{ supplier.credibility }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <br />

          <FileUploadBox
          label="上传EXCEL文件"
          :notes="[
          '支持拖拽; 重新选择或拖拽文件即可替换原先上传文件',
          '可导入的列名有——名称（必填）、品牌、型号、单价、数量（必填）、供应商、送货日期（必填）、参数（必填）和备注',
          '如采购条目中有必填项未填，系统将自动忽略此采购条目',
          '送货日期的格式为YYYY-MM-DD，例如2019-11-12表示2019年11月12日']"
          @setFiles="files = $event"
          :required="false"
          :supportMultiFiles="false"
          :filenameCheck="false">
        </FileUploadBox>

          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="批量导入" :disabled="waiting">
          </div>
          <Uploading :waiting="waiting"></Uploading>

      </div>
    </div>

    <b-modal
    id="search-supplier"
    ok-title="确认"
    cancel-title="取消"
    title="搜索供应商"
    @show="$bvModal.hide('search-supplier')"
    @hidden="$bvModal.hide('search-supplier')"
    @ok="$bvModal.hide('search-supplier')">
      <input type="text" class="trigger" placeholder="搜索" v-model="searchKey" /><i class="fa fa-search" @click="fetchSuppliers()"></i>

      <div class="card" v-if="suppliers.length > 0">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th></th>
              <Header header="名称"></Header>
              <Header header="地址"></Header>
              <Header header="联系人姓名"></Header>
              <Header header="联系人电话"></Header>
              <Header header="联系人微信号"></Header>
              <Header header="联系人邮箱"></Header>
              <Header header="联系人职务"></Header>
              <Header header="信誉"></Header>
            </tr>
          </thead>
          <tbody>
            <tr v-for="supplier in suppliers">
              <td class="align-middle"><input type="checkbox" :value="supplier" v-model="targetSupplier"></td>
              <td>{{ supplier.name }}</td>
              <td>{{ supplier.location }}</td>
              <td>{{ supplier.name }}</td>
              <td>{{ supplier.phoneNumber }}</td>
              <td>{{ supplier.wechatID }}</td>
              <td>{{ supplier.email }}</td>
              <td>{{ supplier.role }}</td>
              <td>{{ supplier.credibility }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Empty v-else></Empty>
    </b-modal>


  </div>
</template>

<script>
import formatTime from '@/js/formatTime';
import peopleUtil from '@/js/peopleUtil';
import storeUtil from '@/js/storeUtil';
import fileIO from '@/js/fileIO';
import projectUtil from '@/js/projectUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      projectTitle: '',
      suppliers: [],
      searchKey: '',
      targetSupplier: ''
    }
  },
  mixins: [
    formatTime, peopleUtil, storeUtil, fileIO, projectUtil, loaderUtil
  ],
  methods: {
    importItemsToDB() {
      this.waiting = true;
      if(this.files.length == 0) {
        this.waiting = false;
        return;
      }

      var filenameSplitByDot = this.files[0].name.split('.');
      var type = filenameSplitByDot[filenameSplitByDot.length - 1];
      if (type != 'xlsx' && type != 'xls') {
        alert('请通过EXCEL文件批量导入');
        this.files = [];
        this.waiting = false;
        return;
      }

      let formData = new FormData();
      let header = {
        'Content-Type': 'multipart/form-data'
      };

      formData.append('file', this.files[0]);
      formData.append('owner', this.getUser.name);
      this.axios.post(`projects/${this.getLocation}/query`,
        {
          title: this.projectTitle
        }
      ).then((response) => {
        if (!response.data) {
          this.$router.push({name: 'ErrorPage'});
          return;
        }
        formData.append('projectId', response.data._id);

        let uri = `items/${this.getLocation}/add/csv`;
        this.axios.post(uri, formData, { headers: header }).then((response) => {
          this.waiting = false;
          alert(response.data);
          this.$router.push({name: 'ItemListOngoing'});
        });

      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });

    }
  }
}
</script>
