export default {
  data() {
    return {
      currentSortDir: 'asc',
      pageSize: 10,
      currentPage: 1
    }
  },
  methods: {
    sortArray(array) {
      return this.filterForPagination(this.sortArrayWithoutPagination(array));
    },
    sortArrayWithoutPagination(array) {
      return array.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(!a[this.currentSort] && !b[this.currentSort]) return 0;
        if(!a[this.currentSort] && b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] && !b[this.currentSort]) return 1 * modifier;
        if((typeof a[this.currentSort] === 'number' && typeof b[this.currentSort] === 'number') ||
          (typeof a[this.currentSort] === 'boolean' && typeof b[this.currentSort] === 'boolean') ||
          (this.isNumber(a[this.currentSort]) && this.isNumber(b[this.currentSort]))) {
          if(a[this.currentSort] * 1 < b[this.currentSort] * 1) return -1 * modifier;
          if(a[this.currentSort] * 1 > b[this.currentSort] * 1) return 1 * modifier;
        } else {
          return a[this.currentSort].localeCompare(b[this.currentSort], 'zh-CN') * modifier;
        }
      });
    },
    filterForPagination(array) {
      return array.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) {
          return true;
        }
      });
    },
    sortDateWithHistory(dateArray) {
      return dateArray.sort((a, b) => {
        if (a == '历史')
        return -1;

        if (b == '历史')
        return 1;

        var aYear = a.split('-')[0];
        var bYear = b.split('-')[0];
        var aMonth = a.split('-')[1];
        var bMonth = b.split('-')[1];
        if (aYear != bYear) {
          return aYear - bYear;
        } else {
          return aMonth - bMonth;
        }
      });
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    sortEventsByDate(events) {
      return events.sort((a, b) => {
        var aDateArray = a.date.split('/');
        var aDate = new Date(aDateArray[0], aDateArray[1], aDateArray[2]);
        var bDateArray = b.date.split('/');
        var bDate = new Date(bDateArray[0], bDateArray[1], bDateArray[2]);
        if (aDate < bDate) return -1;
        if (aDate > bDate) return 1;
        if (aDate == bDate) return 0;
      });
    },
    filter(array, keyword) {
      var filteredArray = [];
      for (let element of array) {
        var arrayValues = Object.values(element);
        var filteredArrayValues = arrayValues.filter(arrayValue => {
          return arrayValue != null && typeof arrayValue === 'string' && arrayValue.indexOf(keyword) >= 0;
        });
        if (filteredArrayValues.length > 0) {
          filteredArray.push(element);
        }
      }
      return filteredArray;
    },
    filterKeywords(array, keywords) {
      var filteredArray = [];
      for (let element of array) {
        for (let keyword of keywords) {
          var filteredArrayValues = Object.values(element).filter(arrayValue => {
            return this.includedKeyword(arrayValue, keyword);
          });

          if (filteredArrayValues.length > 0) {
            filteredArray.push(element);
            break;
          }

        }
      }
      return filteredArray;
    },
    filterKeywordsAnd(array, keywords) {
      var filteredArray = [];
      for (let element of array) {
        var keywordBooleanTracker = true;
        for (var keyword of keywords) {
          var booleanTracker = false;
          for (let arrayValue of Object.values(element)) {
            var included = this.includedKeyword(arrayValue, keyword);
            booleanTracker = booleanTracker || included;
            if (included) {
              break;
            }
          }
          keywordBooleanTracker = keywordBooleanTracker && booleanTracker;
        }

        if (keywordBooleanTracker) {
          filteredArray.push(element);
        }
      }
      return filteredArray;
    },
    includedKeyword(arrayValue, keyword) {
      if (!arrayValue) {
        return false;
      }
      if (this.isIterableObj(arrayValue)) {
        for (var nestedArrayObject of arrayValue) {
          for (var nestedArrayValue of Object.values(nestedArrayObject)) {
            if (typeof nestedArrayValue === 'string' && nestedArrayValue.indexOf(keyword) >= 0) {
              return true;
            }
          }
        }
        return false;
      } else if (this.isNumber(arrayValue) && this.isNumber(keyword)) {
        return (arrayValue * 1) === (keyword * 1);
      } else {
        return arrayValue != null && typeof arrayValue === 'string' && arrayValue.indexOf(keyword) >= 0;
      }
    },
    isIterableObj(arrayValue) {
      return typeof arrayValue === 'object' && typeof arrayValue[Symbol.iterator] === 'function';
    },
    isNumber(value) {
      return !isNaN(+value);
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    sortByStartTime(array) {
      return array.sort((a, b) => {
        if (a.startTime > b.startTime) {
          return -1;
        } else if (a.startTime > b.startTime) {
          return 1;
        } else {
          if (a.endTime > b.endTime) return -1;
          if (a.endTime < b.endTime) return 1;
        }
      });
    }
  }
}
