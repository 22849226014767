export default {
  methods: {
    fetchProjectManager(projectPeople) {
      for (var i = 0; i < projectPeople.length; i++) {
        var projectPerson = projectPeople[i];
        if (projectPerson.role == '项目负责人') {
          return projectPerson.name;
        }
      }
      return '';
    },
    checkDuplicatePerson(projectPeople) {
      var uniqueProjectPeople = [];
      for (var i = 0; i < projectPeople.length; i++) {
        if (!uniqueProjectPeople.includes(projectPeople[i].name)) {
          uniqueProjectPeople.push(projectPeople[i].name);
        } else {
          return false;
        }
      }
      return true;
    },
    checkRoleDef(projectPeople, role) {
      for (var i = 0; i < projectPeople.length; i++) {
        if (projectPeople[i].role == role) {
          return true;
        }
      }
      return false;
    },
    fetchProjectRole(projectPeople) {
        for (var i = 0; i < projectPeople.length; i++) {
          var p = projectPeople[i];
          if (p.name == this.getUser.name) {
            return p.role;
          }
        }
        return '';
    },
    isProjectManager(projectPeople) {
      return this.fetchProjectRole(projectPeople) == '项目负责人';
    },
    isTechnician(projectPeople) {
      return this.fetchProjectRole(projectPeople).indexOf('技术') >= 0;
    },
    isAssistant(projectPeople) {
      return this.fetchProjectRole(projectPeople).indexOf('助理') >= 0;
    }
  }
}
