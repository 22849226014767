<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>编辑项目</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="editProject">
          <table class="table">
            <tbody>
              <tr>
                <th scope="row">项目编号</th>
                <td>{{ project._id }}</td>
              </tr>
              <tr>
                <th scope="row">项目名称</th>
                <td>{{ project.title }}</td>
              </tr>
              <tr>
                <th scope="row">甲方</th>
                <td>
                  <b-form-input list="customer-list" v-model="project.customer" required="required"></b-form-input>
                  <b-form-datalist id="customer-list" :options="customerList"></b-form-datalist>
                </td>
              </tr>
              <tr>
                <th scope="row">项目类别</th>
                <td>
                  <select class="form-control" v-model="project.category" required="required">
                    <option disabled selected value>-----请选择-----</option>
                    <option v-for="c in projectCategory" :key="c" :value="c">{{ c }}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>

          <ProjectPeopleTemplate :projectPeople="project.people" @setProjectPeople="setProjectPeople"></ProjectPeopleTemplate>

          <div class="form-check">
            <input type="checkbox" class="form-check-input" v-model="project.noPurchase">
            <b class="form-check-label">此项目不包含采购   (如勾选此项，则默认项目不需要采购员角色）</b>
          </div>


          <br />

          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="更新" :disabled="waiting">
          </div>
          <Uploading :waiting="waiting"></Uploading>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import peopleUtil from '@/js/peopleUtil';
import loaderUtil from  '@/js/loaderUtil';
import ProjectCreateUtil from './ProjectCreateUtil';
import ProjectPeopleTemplate from './ProjectPeopleTemplate';

export default {
  data() {
    return {
      project: {}
    }
  },
  created() {
    this.fetchProjectById();
  },
  components: {
    ProjectPeopleTemplate
  },
  mixins: [
    storeUtil, peopleUtil, loaderUtil, ProjectCreateUtil
  ],
  methods: {
    fetchProjectById() {
      this.axios.post(`projects/${this.getLocation}/query`, {
        _id: this.$route.params.id
      }).then((response) => {
        this.project = response.data;
        this.projectPeople = this.project.people;
      });
    },
    setProjectPeople(projectPeople) {
      this.project.people = projectPeople;
    },
    editProject() {
      // this.axios.post(`projects/${this.getLocation}/update/${this.$route.params.id}`, this.project).then(() => {
      //   this.axios.post(`projects/${this.getLocation}/update/people/${this.$route.params.id}`, this.project).then(response => {
      this.axios.post(`projects/${this.getLocation}/update/${this.$route.params.id}`, this.project).then(response => {
        alert(response.data);
        this.$router.push({name: 'ProjectListOngoing'});
      });
    }
  }
}
</script>
