<template>
  <div class="container">
    <div class="card">

      <FileLoading :showGifImg="showGifImg"></FileLoading>

      <div class="card-header">
        <h3>收到票款</h3>

        <ul class="nav nav-pills">
          <li class="nav-item">
            <a href="#" class="nav-link" @click="updateData(['采购合同'])">收到发票</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" @click="updateData(['销售合同'])">收到款项</a>
          </li>
        </ul>
      </div>

      <div class="card-header">
        <button class="btn btn-primary" @click="clickExpand = !clickExpand">展开</button>
        <Download tableId="updatePaymentReceipt" filename="应收票款"></Download>
      </div>

      <div class="card-body" style='overflow-x:auto'>

        <div class="form-check">
          <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
          <p class="form-check-label">模糊查询</p>
        </div>
        <Search @updateData='updateData' :initFilters="filters"></Search>

        <br />
        <b>共有{{ contracts.filter(row => row.category == '销售合同').length }}销售合同待收款和{{ contracts.filter(row => row.category == '采购合同').length }}采购合同待收票</b>
        <br />

        <table id="updatePaymentReceipt" class="table table-hover" v-show="contracts.length > 0">
          <thead>
            <tr class="text-center">
              <th class="align-middle" style="min-width: 60px">序号</th>
              <Header header='甲方' @sort="sort('projectCustomer')"></Header>
              <Header header="项目名称" @sort="sort('projectTitle')" ></Header>
              <th v-if="filters.length == 1 && filters.includes('销售合同')"></th>
              <th class="align-middle" style="min-width: 150px" v-else>供货方</th>
              <Header header="责任人" @sort="sort('owner')"></Header>
              <Header header="合同名称" @sort="sort('filename')"></Header>
              <Header header="合同金额" @sort="sort('amount')"></Header>
              <th class="align-middle">待收款/票金额</th>
              <th style="min-width: 100px"></th>
              <th style="min-width: 120px"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(contract, index) in desiredContracts" :key="contract._id" class="text-center" ref="updatePaymentReceiptContracts">
              <td class="align-middle"><b>{{ index + 1 }}</b></td>

              <td class="align-middle" v-if="currentSort != 'projectCustomer'">
                {{ contract.projectCustomer }}
              </td>
              <td class="align-middle" v-else-if="projectCustomerCount[contract.projectCustomer] == 1">
                {{ contract.projectCustomer }}
              </td>
              <td class="align-middle" v-else-if="index % pageSize == 0 || (index - 1 >= 0 && sortedContracts[index - 1].projectCustomer != contract.projectCustomer)"
                :rowspan="projectCustomerCount[contract.projectCustomer] >= pageSize - index % pageSize
                ? pageSize - index % pageSize
                : projectCustomerCount[contract.projectCustomer]">
                {{ contract.projectCustomer }}
              </td>

              <td class="align-middle" >
                <span :style="[contract.projectTitle == '无' ? {'color': 'red'} : {}]">
                  {{ contract.projectTitle }}
                </span>
              </td>
              <td v-if="filters.length == 1 && filters.includes('销售合同')"></td>
              <td class="align-middle" v-else>{{ contract.supplier }}</td>
              <td class="align-middle">{{ contract.owner }}</td>
              <td class="align-middle" >
                <a v-if="!contract.stampedFileId && !contract.stampedFilename" href="#" @click="viewFile(contract.fileId, contract.filename)">
                  {{ contract.filename }}
                </a>
                <a v-else href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">
                  {{ contract.stampedFilename }}
                </a>
              </td>
              <td class="align-middle">{{ contract.amount.toFixed(2) }}</td>
              <td class="align-middle" v-if="contract.category == '采购合同' && contract.receiptAmount < contract.amount">
                {{ (contract.amount - contract.receiptAmount).toFixed(2) }}
              </td>
              <td class="align-middle" v-else-if="contract.category == '销售合同' && contract.paidAmount < contract.amount">
                {{ (contract.amount - contract.paidAmount).toFixed(2) }}
              </td>
              <td v-else></td>


              <td class="align-middle" >
                <router-link :to="{ name: 'ContractInfo', params: {id: contract._id} }" class="btn btn-success" @click.native="setReceiveIndex(index)">详情</router-link>
              </td>


              <td class="align-middle"  v-if="contract.category == '采购合同' && contract.receiptAmount < contract.amount">
                <router-link :to="{ name: 'ReceiveReceipt', params: {id: contract._id} }" class="btn btn-primary" @click.native="setReceiveIndex(index)">收到发票</router-link>
              </td>
              <td class="align-middle"  v-else-if="contract.category == '销售合同' && contract.paidAmount < contract.amount">
                <router-link :to="{ name: 'ReceivePayment', params: {id: contract._id} }" class="btn btn-primary" @click.native="setReceiveIndex(index)">收到款项</router-link>
              </td>
            </tr>
          </tbody>
        </table>

        <Pagination v-if="!clickExpand"
        :array="contracts"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @setCurrentPage="setCurrentPage"
        @incrementCurrentPage="currentPage++"
        @minusCurrentPage="currentPage--">
      </Pagination>
    </div>
  </div>
</div>
</template>

<script>
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import countUtil from '@/js/countUtil';

export default {
  data() {
    return {
      contracts: [],
      allContracts: [],
      filters: [],
      currentSort: 'projectCustomer',
      searchLogicOr: false,
      clickExpand: false
    }
  },
  mixins: [
    fileIO, storeUtil, sortUtil, countUtil
  ],
  created() {
    var query = {
      procedure: '完成',
      status: {$ne: '作废'},
      $or: [
        {
          category: '销售合同',
          $where: 'this.amount > this.paidAmount'
        },
        {
          category: '采购合同',
          $where: 'this.amount > this.receiptAmount'
        }
      ]
    };
    this.fetchContracts(query);
  },
  updated() {
    try {
      var receivePosition = this.$cookies.get('receivePosition');
      if (receivePosition != null) {
        if (receivePosition.filters != null) {
          this.updateData(receivePosition.filters);
        }
        this.currentPage = receivePosition.page;
        // console.log(this.$refs);
        // var row = this.$refs.updatePaymentReceiptContracts[receivePosition.index];
        // row.scrollIntoView();
        this.$cookies.remove('receivePosition');
      }
    } catch (err) {
      console.log(err);
      this.$cookies.remove('receivePosition');
    }
  },
  computed: {
    projectCustomerCount() {
      return this.contractCount(this.desiredContracts, 'projectCustomer');
    },
    sortedContracts:function() {
      return this.sortArray(this.contracts);
    },
    desiredContracts() {
      return this.clickExpand ? this.contracts : this.sortedContracts;
    }
  },
  methods: {
    fetchContracts(query) {
      this.axios.post(`overall/${this.getLocation}/contracts/project/title`, query).then(response => {
        this.contracts = response.data;
        this.allContracts = this.contracts;
        this.fetchSuppliers();
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchSuppliers() {
      this.contracts.forEach(contract => {
        if (contract.category == '采购合同') {
          this.axios.post(`items/${this.getLocation}/queryAll`, {
            contractId: contract._id
          }).then(response => {
            var item = response.data[0];
            contract.supplier = item.supplier;
            this.$forceUpdate();
          });
        }
      });
    },
    updateData(filters) {
      this.filters = filters;
      this.currentPage = 1;
      if (filters.length == 0) {
        this.contracts = this.allContracts;
      } else {
        this.contracts = !this.searchLogicOr ? this.filterKeywordsAnd(this.allContracts, filters) : this.filterKeywords(this.allContracts, filters);
      }
    },
    setReceiveIndex(index) {
      var receivePosition = {
        page: this.currentPage,
        index: index
      };
      if (this.filters.length > 0) {
        receivePosition.filters = this.filters;
      }
      this.$cookies.set('receivePosition', receivePosition);
    }
  }
}
</script>
