<template>
<div>
  <FileLoading :showGifImg="showGifImg"></FileLoading>
  <ul class="align-middle" v-for="(file, index) in fileList" :key="file[fileIdField]" style="display: inline-block; text-align: center; list-style-type: none;">
    <li>
      <i class="fa fa-file fa-3x text-primary"></i>
      <!-- <i class="fa fa-times" @click="deleteFile(file[fileIdField], file[filenameField])" style="float: right;"></i> -->
      <br />
      <a href="#" @click="viewFile(file[fileIdField], file[filenameField])">{{ file[filenameField] }}</a>
    </li>
  </ul>
</div>
</template>

<script>
import fileIO from '@/js/fileIO';

export default {
  props: [
    'fileList',
    'fileIdField',
    'filenameField'
  ],
  mixins: [
    fileIO
  ]
}
</script>
