<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>项目文件管理</h3>
      </div>
      <div class="card-body">

        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#" @click="depth=1, project={}">项目</a></li>
            <li class="breadcrumb-item"><a href="#" @click="depth=2, category=''">{{ project.title }}</a></li>
            <li class="breadcrumb-item">{{ category }}</li>
          </ol>
        </nav>

        <div>
          <Folder v-if="depth == 1"
          :categorys="projects" :field="'title'"
          @clickFolder="project = $event, depth++">
        </Folder>

        <div v-if="depth == 2">
          <i class="fa fa-backspace fa-2x" @click="depth--, project={}"></i>
          <Folder v-if="project.relatedFiles != null && project.relatedFiles.length > 0"
            :categorys="uniqueFieldValue(project.relatedFiles, 'category')"
            @clickFolder="category = $event, depth++">
          </Folder>
          <Empty v-else></Empty>
        </div>

        <div v-if="depth == 3 && project.relatedFiles != null">
          <i class="fa fa-backspace fa-2x" @click="depth--, category=''"></i>
          <FolderFile :fileList="filteredFilesByCategory" :fileIdField="'_id'" :filenameField="'filename'" @deleteFile="deleteFile"></FolderFile>

        <b-modal
        id="delete-file"
        ok-title="确认"
        cancel-title="取消"
        title="删除文件"
        @show="$bvModal.hide('delete-file')"
        @hidden="$bvModal.hide('delete-file')"
        @ok="deleteRelatedProjectFile(deleteFileId, deleteFileIndex), $bvModal.hide('delete-file')">
            你确定要删除这个文件吗？
        </b-modal>
      </div>

    </div>
  </div>
</div>
</div>
</template>

<script>
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import Folder from '@/components/FolderTemplate';
import FolderFile from '@/components/FolderFileTemplate';

export default {
  data() {
    return {
      projects: [],
      project: {},
      category: '',
      depth: 1,
      deleteFileId: '',
      deleteFileIndex: ''
    }
  },
  components: {
    Folder, FolderFile
  },
  mixins: [
    fileIO, storeUtil
  ],
  created() {
    this.fetchAllProjects();
  },
  computed: {
    filteredFilesByCategory() {
      var filesByCategory = [];
      for (var file of this.project.relatedFiles) {
        if (file.category == this.category) {
          filesByCategory.push(file);
        }
      }
      return filesByCategory;
    }
  },
  methods: {
    fetchAllProjects() {
      this.axios.post(`projects/${this.getLocation}/queryAll`, {
        status: { $ne: '历史项目' }
      }).then((response) => {
        this.projects = response.data;
      });
    },
    deleteRelatedProjectFile(fileId, fileIndex) {
      this.axios.post(`projects/${this.getLocation}/delete/file/related/${this.project._id}`, {
        fileId: fileId
      }).then((response) => {
        alert(response.data);
        this.project.relatedFiles.splice(fileIndex, 1);
        this.$forceUpdate();
      });
    },
    uniqueFieldValue(jsonArray, field) {
      var uniqueValue = [];
      for (var array of jsonArray) {
        if (uniqueValue.indexOf(array[field]) == -1) {
          uniqueValue.push(array[field]);
        }
      }
      return uniqueValue;
    },
    deleteFile(fileId, fileIndex) {
      this.deleteFileId = fileId;
      this.deleteFileIndex = fileIndex;
      this.$bvModal.show('delete-file');
    }
  }
}
</script>
