<template>
  <div class="card-header">
    <h3>我的项目</h3>
    <br />
    <TheNavPills :status="status"></TheNavPills>
    <router-link
    v-if="status != 'opportunity'"
    :to="{ name: 'ProjectListOverall', params: { status: status } }"
    class="btn-primary btn"
    style="float: right;">
    详细版
  </router-link>
  </div>
</template>

<script>
import TheNavPills from './TheNavPills';

export default {
  props: [
    'status'
  ],
  components: {
    TheNavPills
  }
}
</script>
