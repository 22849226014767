<template>
  <b-container fluid>
    <Waiting :waiting="waiting"></Waiting>
    <div style="overflow-x:auto;" ref="content">
      <br />

      <button class="btn btn-info" :disabled="waiting" v-if="projectStatus != '历史项目'" @click="determineAllProjectStatus(projects)">
        更新项目状态
      </button>
      <Download tableId="overallTable" filename="项目一览"></Download>

      <br />

      <p><b>共{{ projects.length }}个{{ projectStatus }}{{ projectStatus != '历史项目' ? '的项目' : ''}}</b></p>

      <br />

      <table class="table table-hover table-sm header-fixed" id="overallTable">
        <thead>
          <tr class="text-center">
            <th rowspan="2"></th>
            <th style="min-width: 100px" rowspan="2" class="align-middle bg-primary">项目负责人</th>
            <th style="min-width: 60px" rowspan="2" class="align-middle bg-primary">序号</th>
            <th style="min-width: 100px" rowspan="2" class="align-middle bg-light">项目名称</th>
            <th style="min-width: 100px" colspan="3" class="align-middle bg-warning">回款情况</th>
            <th style="min-width: 100px" colspan="5" class="align-middle bg-light">采购情况</th>
            <th style="min-width: 100px" colspan="5" class="align-middle bg-info">资料</th>
            <th colspan="2" class="align-middle bg-light">奖金情况</th>
            <th style="min-width: 100px" rowspan="2" class="align-middle bg-primary">项目费用</th>
            <th style="min-width: 80px" rowspan="2" class="align-middle bg-warning" v-if="authLevel(2)">项目利润</th>
            <th style="min-width: 80px" rowspan="2" class="align-middle bg-warning" v-if="authLevel(2)">项目利润百分比</th>
            <th style="min-width: 80px" rowspan="2" class="align-middle bg-light">项目状态</th>
          </tr>
          <tr class="text-center">
            <th style="min-width: 80px" class="align-middle bg-warning">合同金额</th>
            <th style="min-width: 80px" class="align-middle bg-warning">回款进度</th>
            <th style="min-width: 80px" class="align-middle bg-warning">开票进度</th>
            <th style="min-width: 80px" class="align-middle bg-light">采购（项）</th>
            <th style="min-width: 80px" class="align-middle bg-light">已采（项）</th>
            <th style="min-width: 80px" class="align-middle bg-light">采购金额</th>
            <th style="min-width: 80px" class="align-middle bg-light">付款进度</th>
            <th style="min-width: 80px" class="align-middle bg-light">收票进度</th>
            <th style="min-width: 60px" class="align-middle bg-info">招投标文件</th>
            <th style="min-width: 80px" class="align-middle bg-info">签收单</th>
            <th style="min-width: 80px" class="align-middle bg-info">验收资料</th>
            <th style="min-width: 80px" class="align-middle bg-info">报价单</th>
            <th style="min-width: 80px" class="align-middle bg-info">其他</th>
            <th style="min-width: 60px" class="align-middle bg-light">全部奖金额</th>
            <th style="min-width: 60px" class="align-middle bg-light">奖金领取额</th>
          </tr>
        </thead>

        <tbody>
          <tr class="text-center" v-for="(project, index) in sortedProjects" :key="project._id">

            <td class="align-middle">
              <i class="fa fa-star" style="color: red;" v-if="person.starredProjects.includes(project._id)" @click="updateStarredProjects(project._id, 'delete')"></i>
              <i class="fa fa-star" style="color: white;" v-else @click="updateStarredProjects(project._id, 'add')"></i>
            </td>

            <td class="align-middle" v-if="isStarredProject(project._id)">{{ project.manager }}</td>
            <td class="align-middle"
            v-else-if="index == 0 || sortedProjects[index - 1].manager != project.manager" :rowspan="managerCount[project.manager]">
            {{ project.manager }}
          </td>
          <td class="align-middle"><b>{{ index + 1 }}</b></td>
          <td class="align-middle">
            <router-link :to="{ name: 'ProjectInfo', params: { id: project._id } }">
              {{ project.title }}
            </router-link>
          </td>
          <td class="align-middle">{{ project.projectContract != null ? project.projectContract.amount : '?' }}</td>
          <td class="align-middle">
            {{ project.projectContract != null ? computePercentage(project.projectContract.paidAmount, project.projectContract.amount) : 0 }}%
          </td>
          <td class="align-middle">
            {{ project.projectContract != null ? computePercentage(project.projectContract.receiptAmount, project.projectContract.amount) : 0 }}%
          </td>
          <td class="align-middle">{{ project.projectItemCount }}</td>
          <td class="align-middle">{{ project.projectCompletedItemCount }}</td>
          <td class="align-middle">{{ project.totalPurchasingContractAmount }}</td>
          <td class="align-middle">{{ computePercentage(project.totalPurchasingContractPaidAmount,project.totalPurchasingContractAmount) }}%</td>
          <td class="align-middle">{{ computePercentage(project.totalPurchasingContractReceiptAmount, project.totalPurchasingContractAmount) }}%</td>
          <td class="align-middle" v-if="project.checkRelatedFiles != null">
            <i class="fa fa-check" aria-hidden="true" v-if="project.checkRelatedFiles.indexOf('招投标文件') >= 0"></i>
          </td>
          <td v-else></td>
          <td class="align-middle" v-if="project.checkRelatedFiles != null">
            <i class="fa fa-check" aria-hidden="true" v-if="project.checkRelatedFiles.indexOf('签收单') >= 0"></i>
          </td>
          <td v-else></td>
          <td class="align-middle" v-if="project.checkRelatedFiles != null">
            <i class="fa fa-check" aria-hidden="true" v-if="project.checkRelatedFiles.indexOf('验收资料') >= 0"></i>
          </td>
          <td v-else></td>
          <td class="align-middle" v-if="project.checkRelatedFiles != null">
            <i class="fa fa-check" aria-hidden="true" v-if="project.checkRelatedFiles.indexOf('报价单') >= 0"></i>
          </td>
          <td v-else></td>
          <td class="align-middle" v-if="project.checkRelatedFiles != null">
            <i class="fa fa-check" aria-hidden="true" v-if="project.checkRelatedFiles.indexOf('其他') >= 0"></i>
          </td>
          <td v-else></td>
          <td class="align-middle">{{ project.totalBonus == null ? '?' : project.totalBonus.toFixed(2) }}</td>
          <td class="align-middle">{{ project.totalPaidBonus == null ? '?' : project.totalPaidBonus.toFixed(2) }}</td>

          <td class="align-middle">{{ !project.reimbursement ? 0 : project.reimbursement.toFixed(2) }}</td>
          <td class="align-middle" v-if="authLevel(2)">
            {{ computeProfit(project) }}
          </td>
          <td class="align-middle" v-if="authLevel(2)" :style="{'background-color': computeProfitPercentage(project) < 13 ? 'red' : ''}">
            {{ computeProfitPercentage(project) }}%
          </td>
          <td class="align-middle">{{ project.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</b-container>
</template>

<script>
import peopleUtil from '@/js/peopleUtil';
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import accountingUtil from '@/js/accountingUtil';
import formatTime from '@/js/formatTime';
import loaderUtil from '@/js/loaderUtil';
import fileIO from '@/js/fileIO';
import Waiting from '@/components/Waiting';
import ProjectUpdateStatus from './ProjectUpdateStatus';
import ProjectStarredListUtil from './ProjectStarredListUtil';

export default {
  props: [
    'projectStatus'
  ],
  data() {
    return {
      projects: [],
      managerCount: null,
      currentSort: 'manager',
    }
  },
  mixins: [
    peopleUtil, storeUtil, sortUtil, accountingUtil, formatTime, loaderUtil, fileIO, ProjectStarredListUtil, ProjectUpdateStatus
  ],
  components: {
    Waiting
  },
  created() {
    this.waiting = true;
    this.fetchAllProjects();
  },
  watch: {
    'projectStatus': function(val, oldVal) {
      this.fetchAllProjects();
    }
  },
  computed: {
    sortedProjects() {
      if (!this.getUser.starredProjects) {
        return this.projects;
      } else {
        var starredProjects = this.projects.filter(project => {
          return this.getUser.starredProjects.includes(project._id);
        });
        var unstarredProjects = this.projects.filter(project => {
          return !this.getUser.starredProjects.includes(project._id);
        });
        var sortedUnstarredProjects = this.sortArrayWithoutPagination(unstarredProjects);
        var projects = starredProjects.concat(sortedUnstarredProjects);
        return projects;
      }
    },
    query() {
      var q = { status: this.projectStatus };
      if (!this.authLevel(4)) {
        q['people.name'] = this.getUser.name;
      }
      return q;
    }
  },
  methods: {
    fetchAllProjects() {
      this.axios.post(`projects/${this.getLocation}/queryAll`, this.query).then(response => {
        this.projects = response.data;
        this.waiting = false;

        this.computeManagerCount();
        this.sortedProjects.forEach((project, index) => {
          this.fetchProjectContract(project, index);
          this.checkRelatedFileCategory(project, index);
          this.countItems(project._id, index);
          this.countPurchasingContracts(project._id, index);
          this.fetchReimbursment(project._id, index);
          this.fetchBonus(project._id, index);
        });
      });
    },
    computeManagerCount() {
      this.managerCount = new Object();
      for (var index = 0; index < this.projects.length; index++) {
        var project = this.projects[index];
        project.manager = this.fetchProjectManager(project.people);

        if (this.isStarredProject(project._id)) {
          continue;
        }
        this.managerCount[project.manager] = !this.managerCount[project.manager] ? 1 : this.managerCount[project.manager] + 1;
      }
    },
    fetchProjectContract(project, index) {
      var query = {};
      if (project.contractId != null) {
        query = {_id: project.contractId};
      } else if (project.bidFileId != null) {
        query = {_id: project.bidFileId};
      } else {
        return;
      }
      this.axios.post(`contracts/${this.getLocation}/query`, query).then(contractResponse => {
        if(contractResponse.data != null) {
          (this.sortedProjects)[index].projectContract = contractResponse.data;
          (this.sortedProjects)[index].paymentMethod = contractResponse.data.paymentMethod;
          if (contractResponse.data.paymentSchedule != null) {
            (this.sortedProjects)[index].paymentSchedule = contractResponse.data.paymentSchedule.schedule;
          }
          this.$forceUpdate();
        }
      });
    },
    checkRelatedFileCategory(project, index) {
      (this.sortedProjects)[index].checkRelatedFiles = [];
      project.relatedFiles.forEach(file => {
        (this.sortedProjects)[index].checkRelatedFiles.push(file.category);
        this.$forceUpdate();
      });
    },
    countItems(projectId, index) {
      this.axios.post(`items/${this.getLocation}/queryAll`, {
        projectId: projectId
      }).then(response => {
        (this.sortedProjects)[index].projectItemCount = response.data.length;
        this.$forceUpdate();
      });
      this.axios.post(`items/${this.getLocation}/queryAll`, {
        projectId: projectId,
        procedure: '完成',
        contractId: { $ne: null }
      }).then(response => {
        (this.sortedProjects)[index].projectCompletedItemCount = response.data.length;
        this.$forceUpdate();
      });
    },
    countPurchasingContracts(projectId, index) {
      this.axios.get(`overall/${this.getLocation}/contracts/purchasing/projects/${projectId}`).then((response) => {
        if (response.data != null) {
          var purchasingContracts = response.data;
          (this.sortedProjects)[index].totalPurchasingContractAmount = this.getTotalContractAmount(purchasingContracts, 'amount');
          (this.sortedProjects)[index].totalPurchasingContractPaidAmount = this.getTotalContractAmount(purchasingContracts, 'paidAmount');
          (this.sortedProjects)[index].totalPurchasingContractReceiptAmount = this.getTotalContractAmount(purchasingContracts, 'receiptAmount');
          this.$forceUpdate();
        }
      });
    },
    fetchReimbursment(projectId, index) {
      this.axios.post(`reimbursements/${this.getLocation}/queryAll`, {
        projectId: projectId
      }).then(response => {
        (this.sortedProjects)[index].reimbursement = this.getReimbursementTotal(response.data);
        this.$forceUpdate();
      });
    },
    fetchBonus(projectId, index) {
      this.axios.post(`bonus/queryAll`, {
        projectId: projectId
      }).then(response => {
        var bonus = response.data;
        var totalBonus = 0;
        var totalPaidBonus = 0;
        for (var i = 0; i < bonus.length; i++) {
          totalBonus += bonus[i].amount;
          totalPaidBonus += bonus[i].paidAmount;
        }

        (this.sortedProjects)[index].totalBonus = totalBonus;
        (this.sortedProjects)[index].totalPaidBonus = totalPaidBonus;
        this.$forceUpdate();
      });
    },
    computeProfit(project) {
      var gain = project.projectContract ? project.projectContract.amount : 0;
      var bonus = project.totalPaidBonus ? project.totalPaidBonus : 0;
      return (gain - project.totalPurchasingContractAmount - bonus - project.reimbursement).toFixed(2);
    },
    computeProfitPercentage(project) {
      var gain = project.projectContract != null ? project.projectContract.amount : 0;
      var profit = this.computeProfit(project);
      return gain == 0 ? '?' : (profit / gain * 100).toFixed(2);
    }
  }
}
</script>

<style scoped>
div {
  display: inline-block;
  height: 800px;
  overflow: auto
}

table th {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  /*min-width: 100px;*/
}

table {
  border-collapse: collapse;
}
</style>
