<template>
  <b-container fluid>
    <b-row>
      <b-col lg="2">

        <div v-b-toggle.sideTOC variant="info">
          <i class="fas fa-align-left"></i>
        </div>

        <b-collapse id="sideTOC" visible>
          <b-nav pills v-b-scrollspy:completedList class="flex-column">
            <b-nav-item href="#completedContracts">我审批的合同</b-nav-item>
            <b-nav-item href="#completedPayments">我审批的合同付款</b-nav-item>
            <b-nav-item href="#completedStamps">我审批的盖章</b-nav-item>
            <b-nav-item href="#completedReimbursements">我审批的费用</b-nav-item>
            <b-nav-item href="#completedItems">我审批的采购</b-nav-item>
            <b-nav-item href="#completedReceipts">我审批的开票</b-nav-item>
          </b-nav>
        </b-collapse>

      </b-col>

      <b-col lg="8">
        <div class="container" id="completedList" style="position:relative;overflow-y:scroll;height:700px">
          <Contract></Contract>
          <br /><br /><br />
          <Payment></Payment>
          <br /><br /><br />
          <Stamp></Stamp>
          <br /><br /><br />
          <Reimbursement></Reimbursement>
          <br /><br /><br />
          <Item></Item>
          <br /><br /><br />
          <Receipt></Receipt>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Contract from './Contract.vue';
import Payment from './Payment.vue';
import Stamp from './Stamp.vue';
import Reimbursement from './Reimbursement.vue';
import Item from './Item.vue';
import Receipt from './Receipt.vue';

export default {
  components: {
    Contract,
    Payment,
    Stamp,
    Reimbursement,
    Item,
    Receipt
  }
}
</script>
