<template>
  <img
  src="@/assets/loading_error.gif"
  style="{
    width: 150px;
    height: 150px;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    position:fixed;
    z-index: 100
  }"
  v-show="showGifImg"
  />
</template>

<script>
export default {
  props: [
    'showGifImg'
  ]
}
</script>
