<template>
  <div class="card">

    <FileLoading :showGifImg="showGifImg"></FileLoading>

    <rise-loader :loading="loading" class="text-center align-middle"></rise-loader>

    <table class="table table-hover" v-if="!loading">
      <thead>
        <tr class="text-center">
          <th class="align-middle" style="width: 60px">序号</th>
          <Header header="项目名称" @sort="sort('projectTitle')"></Header>
          <Header header="项目甲方" @sort="sort('projectCustomer')" v-show="category == 'project'"></Header>
          <Header header="供应商" @sort="sort('supplier')" v-show="category == 'purchasing'"></Header>
          <Header header="收款时间" @sort="sort('date')" v-show="category == 'project'"></Header>
          <Header header="计划付款时间" @sort="sort('date')" v-show="category == 'purchasing'"></Header>
          <th class="align-middle" v-show="category == 'purchasing'">实际付款时间</th>
          <Header header="金额" @sort="sort('amount')"></Header>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(p, index) in sortedPayments" :key="p._id" class="text-center">
          <td class="align-middle"><b>{{ index + 1 }}</b></td>
          <td class="align-middle">{{ p.projectTitle }}</td>
          <td class="align-middle" v-show="category == 'project'">{{ p.projectCustomer }}</td>
          <td class="align-middle" v-show="category == 'purchasing'">{{ p.supplier }}</td>
          <td class="align-middle">{{ getFormattedDate(p.date) }}</td>
          <td class="align-middle" v-if="category == 'purchasing' && p.procedureTimestamp != null">
            {{ getFormattedDate(Object.values(p.procedureTimestamp)[Object.values(p.procedureTimestamp).length - 1]) }}
          </td>
          <td v-else-if="category == 'purchasing'"></td>

          <td class="align-middle">{{ p.amount }}</td>
          <td class="align-middle" v-if="p.procedureTimestamp != null">
            <TimelineHover :timelineId="p._id" :timestamps="p.procedureTimestamp"></TimelineHover>
          </td>
          <td v-else></td>

          <td class="align-middle" v-if="(p.procedureNote != null && p.procedureNote != '') || (p.note != null && p.note != '')">
            <ProcedureNote v-if="(p.procedureNote != null && p.procedureNote != '') && (p.note != null && p.note != '')"
            :id="p._id.toString() + 'ProcedureNote'"
            :note="p.note + '\n' + p.procedureNote">
          </ProcedureNote>
          <ProcedureNote v-else-if="p.procedureNote == null || p.procedureNote == ''"
          :id="p._id.toString() + 'ProcedureNote'"
          :note="p.note">
          </ProcedureNote>
          <ProcedureNote v-else="p.note == null || p.note == ''"
          :id="p._id.toString() + 'ProcedureNote'"
          :note="p.procedureNote">
          </ProcedureNote>
        </td>
          <td v-else></td>

        </tr>
      </tbody>
    </table>

  <hr>
  <h5 style="text-align: right;">
    合计: {{ total }}
  </h5>

  </div>
</template>

<script>
import accountingUtil from '@/js/accountingUtil';
import loaderUtil from  '@/js/loaderUtil';
import sortUtil from '@/js/sortUtil';
import fileIO from '@/js/fileIO';
import formatTime from '@/js/formatTime';

export default {
  data() {
    return {
      payments: [],
      currentSort: 'date',
      currentSortDir: 'desc'
    }
  },
  props: [
    'category'
  ],
  mixins: [
    accountingUtil,
    fileIO,
    formatTime,
    loaderUtil,
    sortUtil
  ],
  computed: {
    sortedPayments() {
      return this.sortArrayWithoutPagination(this.payments);
    },
    total() {
      var calculation = this.getTotal(this.payments, 'amount');
      if (this.category == 'purchasing') {
        this.$emit('setPurchasingPaymentTotal', calculation);
      }
      return calculation;
    }
  },
  created() {
    this.fetchContractPayments();
  },
  watch: {
    category(newCategory) {
      this.payments = [];
      this.loading = true;
      this.fetchContractPayments();
    }
  },
  methods: {
    fetchContractPayments() {
      console.log('created');
      var query;
      switch(this.category) {
        case 'project':
        query = { $exists: false };
        break;
        case 'purchasing':
        query = '完成';
        break;
      }
      var paymentQuery = [
        { $match: { procedure: query } },
        { $sort: { date: -1 } }
      ];

      this.axios.post(`overall/${this.getLocation}/payments/contract/project`, paymentQuery).then((response) => {
        this.payments = response.data;
        this.loading = false;
      }).catch(err => {
        console.log(err.response.data);
      });
    }
  }
}
</script>
