<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>历史版本</h3>
      </div>
      <div class="card-body">
  <table class="table table-hover">
    <thead>
      <tr class="text-center">
        <th class="text-center align-middle">序号</th>
        <Header header="文件名称" @sort="sort('projectTitle')"></Header>
        <Header header="上传时间" @sort="sort('owner')"></Header>
        <th class="align-middle">文件内容描述</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(template, index) in sharedFilesHistory" :key="template._id" class="text-center">
        <td><b>{{ index + 1 }}</b></td>
        <td class="align-middle">
          <a href="#" @click="viewFile(template.fileId, template.filename)">
            {{ template.filename }}
          </a>
        </td>
        <td class="align-middle">{{ getYearMonthDate(template.uploadTime) }}</td>
        <td class="align-middle" v-html="!template.note ? '' : template.note.replace(/\n/, '<br />')"></td>
      </tr>
    </tbody>
  </table>
</div>
</div>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import storeUtil from '@/js/storeUtil';

export default {
  data() {
    return {
      sharedFilesHistory: []
    }
  },
  mixins: [
    formatTime, storeUtil
  ],
  created() {
    this.fetchSharedFilesHistory();
  },
  methods: {
    async fetchSharedFilesHistory() {
      var currentFile = await this.fetchFileAsync(this.$route.params.id);
      this.sharedFilesHistory.push(currentFile);
      var pointer = currentFile;

      while (pointer.previousVersion != null) {
        var file = await this.fetchFileAsync(pointer.previousVersion);
        this.sharedFilesHistory.push(file);
        pointer = file;
      }
    },
    async fetchFileAsync(id) {
      var response = await this.axios.post(`template/query`, { _id: id });
      return response.data;
    }
  }
}
</script>
