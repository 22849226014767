<template>
  <table class="table table-hover table-sm" v-if="problems.length > 0">
    <thead>
      <tr class="text-center">
        <th class="align-middle" style="min-width:60px">序号</th>
        <th class="align-middle" style="min-width:100px">系统项目名称</th>
        <th class="align-middle" style="min-width:100px">项目名称</th>
        <th class="align-middle" style="min-width:100px">项目用户单位</th>
        <th class="align-middle" style="min-width:100px">用户</th>
        <th class="align-middle" style="min-width:100px">联系方式</th>
        <th class="align-middle" style="min-width:100px">问题分类</th>
        <th class="align-middle" style="min-width:100px">问题标题</th>
        <th class="align-middle" style="min-width:100px">问题描述</th>
        <th class="align-middle" style="min-width:100px">事件优先级</th>
        <th style="min-width: 60px"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(problem, index) in problems" class="text-center">
        <td class="align-middle"><b>{{ index + 1 }}</b></td>
        <td class="align-middle">{{ problem.projectId }}</td>
        <td class="align-middle">{{ problem.projectName }}</td>
        <td class="align-middle">{{ problem.customer }}</td>
        <td class="align-middle">{{ problem.user }}</td>
        <td class="align-middle">{{ problem.phoneNumber }}</td>
        <td class="align-middle">{{ problem.category }}</td>
        <td class="align-middle">{{ problem.title }}</td>
        <td class="align-middle">{{ problem.description }}</td>
        <td class="align-middle">{{ problem.priority }}</td>
        <td class="align-middle">
          <router-link :to="{ name: 'ProblemInfo', params: { id: problem._id } }" class="btn btn-primary btn-sm">详情</router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: [
    'query'
  ],
  data() {
    return {
      problems: {}
    }
  },
  created() {
    this.axios.post(`problems/queryAll`, this.query).then(response => {
      this.problems = response.data;
    });
  }
}
</script>
