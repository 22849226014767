<template>
  <table class="table table-hover table-sm" v-if="contractDisplay.length > 0">
    <thead>
      <tr class="text-center">
        <th class="align-middle" style="min-width:60px">序号</th>
        <th class="align-middle" style="min-width:100px">项目名称</th>
        <th class="align-middle" style="min-width:90px">责任人</th>
        <th class="align-middle" style="min-width:80px">类别</th>
        <th class="align-middle" style="min-width:100px">合同名称</th>
        <th class="align-middle" style="min-width:100px">上传时间</th>
        <th class="align-middle" style="min-width:100px">合同金额</th>
        <th class="align-middle" style="min-width:100px">款项进度</th>
        <th class="align-middle" style="min-width:100px">发票进度</th>
        <th style="min-width: 60px"></th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(contract, index) in contractDisplay" :key="contract._id" class="text-center">
        <td class="align-middle"><b>{{ index + 1 }}</b></td>
        <td class="align-middle">{{ contract.projectTitle }}</td>
        <td class="align-middle">{{ contract.owner }}</td>
        <td class="align-middle">{{ contract.category }}</td>
        <td class="align-middle">
          <a v-if="!contract.stampedFileId && !contract.stampedFilename" href="#" @click="viewFile(contract.fileId, contract.filename)">
            {{ contract.filename }}
          </a>
          <a v-else href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">
            {{ contract.stampedFilename }}
          </a>
        </td>
        <td class="align-middle">{{ getYearMonthDate(contract.uploadTime) }}</td>
        <td class="align-middle">{{ contract.amount }}</td>

        <td class="align-middle">
          <PaymentProgressBar :contract="contract"></PaymentProgressBar>
        </td>

        <td class="align-middle">
          <ReceiptProgressBar :contract="contract"></ReceiptProgressBar>
        </td>

        <td class="align-middle" v-if="contract.category != '中标通知书'">
          <router-link class="btn btn-success btn-sm" :to="{name: 'ContractInfo', params: { id: contract._id }}">详情</router-link>
        </td>
        <td class="align-middle" v-else></td>

      </tr>
    </tbody>
  </table>
</template>

<script>
import fileIO from '@/js/fileIO';
import formatTime from '@/js/formatTime';
import moment from 'moment';
import PaymentProgressBar from '@/components/PaymentProgressBar';
import ReceiptProgressBar from '@/components/ReceiptProgressBar';

export default {
  props: [
    'contractDisplay'
  ],
  mixins: [
    fileIO, formatTime
  ],
  components: {
    PaymentProgressBar, ReceiptProgressBar
  }
}
</script>
