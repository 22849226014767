<template>
  <b-container fluid>

    <b-card>
      <b-tabs pills card vertical nav-wrapper-class="w-20">
        <b-tab title="个人资料">
          <b-card>
            <b-card-text>
              <table class="table">
                <tbody>
                  <tr>
                    <th scope="row">姓名</th>
                    <td>{{ getUser.name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">邮箱</th>
                    <td>{{ getUser.email }}</td>
                  </tr>
                  <tr>
                    <th scope="row">角色</th>
                    <td>{{ getUser.role }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card-text>
          </b-card>
        </b-tab>



        <b-tab title="奖金一览">
          <div class="card-deck">
            <div class="card" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 5px;" @click="showPastBonusDetails=!showPastBonusDetails">
              <h2 class="text-center align-middle">{{ totalBonus }}</h2>
              <div class="card-body text-center align-middle">
                <h5 class="card-text">已领取项目奖金</h5>
              </div>
            </div>
            <div class="card text-white bg-warning" style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); padding: 5px;" @click="showFutureBonusDetails=!showFutureBonusDetails">
              <h2 class="text-center align-middle">{{ paidBonus }}</h2>
              <div class="card-body text-center align-middle">
                <h5 class="card-text">待领取项目奖金</h5>
              </div>
            </div>
          </div>
        </b-tab>



        <b-tab title="工时一览">
          <div class="card-body">
            <b>{{ year }}年度，你共加班{{ totalOvertime }}小时，休假{{ totalVacation }}小时，剩余调休时间{{ workingHours }}小时。</b>

            <br />
            <br />
            <br />

            <div class="card-header">
              <h5>加班详情</h5>
            </div>
            <WorkingHourTemplate :array="overtime"></WorkingHourTemplate>

            <br />

            <div class="card-header">
              <h5>休假详情</h5>
            </div>
            <!-- <div class="card-body">
              <div v-if="vacation.length > 0">
                <table class="table table-hover table-bordered text-center">
                  <thead>
                    <tr>
                      <th>开始时间</th>
                      <th>结束时间</th>
                      <th>备注</th>
                      <th>审批进度</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="v in vacation">
                      <td>{{ getFormattedDate(v.startTime) }}</td>
                      <td>{{ getFormattedDate(v.endTime) }}</td>
                      <td>{{ v.note }}</td>
                      <td>
                        {{ v.procedure }}
                        <WhoIsWho :id="v._id" :role="v.procedure" v-if="v.procedure != '完成' && v.procedure != '驳回'"></WhoIsWho>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <h4 class="text-center align-middle">空空如也   <i class="far fa-flushed"></i></h4>
              </div>
            </div> -->
            <WorkingHourTemplate :array="vacation"></WorkingHourTemplate>
          </div>
        </b-tab>



        <b-tab title="修改密码">
          <div class="card-body">
            <form v-on:submit.prevent="changePassword">
              <div class="form-group">
                <label>旧密码:</label>
                <input type="password" class="form-control" v-model="oldPlainPassword" required="required" autocomplete="on">
              </div>
              <div v-if="showOldPwdAlert" class="alert alert-danger">
                您输入的旧密码有误，请重新输入
              </div>
              <div class="form-group">
                <label>新密码:</label>
                <input type="password" class="form-control" v-model="newPlainPassword" required="required" autocomplete="on">
              </div>
              <div class="form-group">
                <label>再次输入新密码:</label>
                <input type="password" class="form-control" v-model="newPlainPasswordAgain" required="required" autocomplete="on">
              </div>
              <div v-if="showNewPwdAlert" class="alert alert-danger">
                您输入两次密码不相符，请重新输入
              </div>
              <div class="form-group">
                <input type="submit" class="btn btn-primary" value="修改">
              </div>
            </form>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

  </b-container>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import formatTime from '@/js/formatTime';
import WorkingHourTemplate from '@/components/workingHour/WorkingHourTemplate';
import moment from 'moment';
import util from '@/components/workingHour/util';

export default {
  data() {
    return {
      showOldPwdAlert: false,
      showNewPwdAlert: false,
      oldPlainPassword: '',
      newPlainPassword: '',
      newPlainPasswordAgain: '',
      workingHours: 0,
      year: moment().year(),
      totalBonus: 0,
      paidBonus: 0,
      pastProjectBonus: [],
      futureProjectBonus: [],
      showPastBonusDetails: false,
      showFutureBonusDetails: false
    }
  },
  mixins: [
    storeUtil, formatTime, sortUtil, util
  ],
  components: {
    WorkingHourTemplate
  },
  created() {
    this.computeWorkingHours();
    this.fetchBonus();
  },
  methods: {
    changePassword() {
      this.showOldPwdAlert = false;
      this.showNewPwdAlert = false;

      if (this.newPlainPassword != this.newPlainPasswordAgain) {
        this.showNewPwdAlert = true;
        this.newPlainPasswordAgain = '';
        return;
      }
      this.axios.post(`people/${this.getLocation}/password`, {
        email: this.getUser.email,
        oldPlainPassword: this.oldPlainPassword,
        newPlainPassword: this.newPlainPassword
      }).then(() => {
        alert('成功修改密码');
        this.logout();
      }).catch(() => {
        this.showOldPwdAlert = true;
        this.oldPlainPassword = '';
      });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    clearData() {
      this.oldPlainPassword = '';
      this.newPlainPassword = '';
      this.newPlainPasswordAgain = '';
    },
    computeWorkingHours() {
      this.axios.post(`people/${this.getLocation}/query`, {
        name: this.getUser.name
      }).then(response => {
        this.computeWorkingHoursByPerson(response.data);
        this.workingHours = this.totalOvertime - this.totalVacation + !response.data.annualLeave ? 0 : response.data.annualLeave;
      });
    },
    fetchBonus() {
      this.axios.post(`bonus/queryAll`, {
        owner: this.getUser.name
      }).then(response => {
        var bonus = response.data;
        var totalBonus = 0;
        var paidBonus = 0;
        for (var i = 0; i < bonus.length; i++) {
          totalBonus += bonus.amount;
          paidBonus += bonus.paidAmount;
        }
        this.totalBonus = totalBonus;
        this.paidBonus = paidBonus;
      });
    }

  }
}
</script>
