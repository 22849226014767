<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>查询</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="queryItem">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>名称:</label>
              <input type="text" class="form-control" v-model="query.name">
            </div>
            <div class="form-group col-md-6">
              <label>品牌:</label>
              <input type="text" class="form-control" v-model="query.brand">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>型号:</label>
              <input type="text" class="form-control" v-model="query.series">
            </div>
            <div class="form-group col-md-6">
              <label>供应商:</label>
              <b-form-input list="supplier-list" v-model="query.supplier"></b-form-input>
              <b-form-datalist id="supplier-list" :options="supplierList"></b-form-datalist>
            </div>
          </div>
          <div class="form-row">
          <div class="form-group col-md-6">
            <label>单价:</label>
            <div class="form-row">
              <div class="form-group">
                <input type="number" step=".01" class="form-control" placeholder="最低单价" v-model="query.lowerBoundPrice">
              </div>
              -
              <div class="form-group">
                <input type="number" step=".01" class="form-control" placeholder="最高单价" v-model="query.upperBoundPrice">
              </div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label>数量:</label>
            <div class="form-row">
              <div class="form-group">
                <input type="number" class="form-control" placeholder="最低数量" v-model="query.lowerQuantity">
              </div>
              -
              <div class="form-group">
                <input type="number" class="form-control" placeholder="最高数量" v-model="query.upperQuantity">
              </div>
            </div>
          </div>
        </div>
          <div class="form-group">
            <label>到货日期:</label>
            <div class="form-row">
              <div class="form-group col-md-3 mb-3">
                <b-form-datepicker v-model="query.lowerBoundDate" locale="zh"></b-form-datepicker>
              </div>
              <div class="form-group col-md-3 mb-3">
                <b-form-datepicker v-model="query.upperBoundDate" locale="zh"></b-form-datepicker>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>参数:</label>
            <input type="text" class="form-control" v-model="query.parameters">
          </div>
          <div class="form-group">
            <label>备注:</label>
            <input type="text" class="form-control" v-model="query.note">
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="查询">
          </div>
        </form>
      </div>
    </div>

    <br />

    <ItemListTemplate :query="query" :columns="['comparisonButton']" :allData="true" v-if="clickedQuery"></ItemListTemplate>
    <button class="btn btn-outline-primary" v-if="items.length > 0 && clickedQuery" v-on:click="downloadItemsToCsv(items)" style="float: right;">下载   <i class="fas fa-download"></i></button>
</div>
</template>

<script>
import formatTime from '@/js/formatTime';
import sortUtil from '@/js/sortUtil';
import storeUtil from '@/js/storeUtil';
import fileIO from '@/js/fileIO';
import loaderUtil from '@/js/loaderUtil';
import ItemListTemplate from './ItemListTemplate';

export default {
  data() {
    return {
      query: {},
      items: [],
      projects: [],
      projectTitle: '',
      currentSort: 'name',
      clickedQuery: false,
      supplierList: []
    }
  },
  mixins: [
    formatTime, sortUtil, storeUtil, fileIO, loaderUtil
  ],
  components: {
    ItemListTemplate
  },
  created() {
    this.getProjects();
    this.fetchSupplierList();
  },
  computed: {
    sortedItems() {
      return this.sortArray(this.items);
    }
  },
  watch: {
    query(val) {
        this.clickedQuery = false;
    }
  },
  methods: {
    queryItem() {
      this.currentPage = 1;
      this.clickedQuery = true;

      // this.axios.post(`projects/${this.getLocation}/query`, {
      //   title: this.projectTitle
      // }).then((response) => {
      //   if (response.data != null) {
      //     this.query.projectId = response.data._id;
      //   }
      //   // this.axios.post(`items/query`, this.query).then((response) => {
      //   //   this.items = response.data;
      //   //   this.loading = false;
      //   //   this.clearData();
      //   // });
      // }).catch(() => {
      //   this.$router.push({name: 'ErrorPage'});
      // });
    },
    fetchSupplierList() {
      this.axios.post(`items/${this.getLocation}/distinct`, {
        field: 'supplier'
      }).then(response => {
        this.supplierList = response.data;
      });
    },
    getProjects() {
      this.axios.get(`projects/${this.getLocation}`).then((response) => {
        this.projects = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    clearData() {
      this.query = {};
    }
  }
}
</script>
