<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>应收账目</h3>
        <Download tableId="toBeReceivedPayment" filename="应收账目"></Download>
      </div>

      <div class="card-body">
        <b-alert show dismissible>
          <h5 class="alert-heading">说明</h5>
          <hr>
          <p>当满足以下条件时——</p>
          <ul>
            <li>销售合同已审批完成</li>
            <li>销售合同已关联到项目</li>
          </ul>
          <p>销售合同会在应收账目中显示。</p>
        </b-alert>
        <ContractFinance :contracts="projectContracts" key="toBeReceived" id="toBeReceived"></ContractFinance>
      </div>
    </div>

    <br />

    <div class="card">
      <div class="card-header">
        <h3>应付账目</h3>
        <Download tableId="toBePaidPayment" filename="应付账目"></Download>
      </div>

      <div class="card-body" id="toBePaidPayment">
        <b-alert show dismissible>
          <h5 class="alert-heading">说明</h5>
          <hr>
          <p>当满足以下条件时——</p>
          <ul>
            <li>采购合同已审批完成</li>
            <li>盖章合同已上传</li>
          </ul>
          <p>采购合同会在应付账目中显示。</p>
        </b-alert>
        <ContractFinance :contracts="purchasingContracts" key="toBePaid" id="toBePaid"></ContractFinance>
      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import layoutUtil from '@/js/layoutUtil';
import fileIO from '@/js/fileIO';
import accountingUtil from '@/js/accountingUtil';
import ContractFinance from './ContractFinanceTemplate';

export default {
  data() {
    return {
      projectContracts: [],
      purchasingContracts: [],
      currentSort: 'projectCustomer'
    }
  },
  mixins:[
    storeUtil, sortUtil, layoutUtil, fileIO, accountingUtil
  ],
  components: {
    ContractFinance
  },
  created() {
    if (this.authLevel(4)) {
      this.fetchProjectContracts();
      this.fetchPurchasingContracts();
    } else {
      alert('您暂无权限查看此页面');
    }
  },
  methods: {
    fetchProjectContracts() {
      this.axios.post(`overall/${this.getLocation}/contracts/project`, {
        category: '销售合同',
        procedure: '完成'
      }).then(async response => {
        var contracts = this.sortArrayWithoutPagination(response.data);
        this.projectContracts = await this.computeDueAmount(contracts);
      }).catch(err => {
        console.log(err);
        this.$router.push({name: 'ErrorPage'});
      });
    },
    fetchPurchasingContracts() {
      this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
        category: '采购合同',
        procedure: '完成',
        'stampedFileId': { $exists: true },
        'stampedFilename': { $exists: true },
        $where: 'this.amount > this.paidAmount'
      }).then(async response => {
        var contracts = this.sortArrayWithoutPagination(response.data);
        this.purchasingContracts = await this.computeDueAmount(contracts);
      }).catch(err => {
        console.log(err);
        this.$router.push({name: 'ErrorPage'});
      });
    },
    async computeDueAmount(contracts) {
      var indexToRemove = [];
      for (var i = 0; i < contracts.length; i++) {
        contracts[i].dueAmount = contracts[i].amount - contracts[i].paidAmount;

        if (contracts[i].category == '销售合同') {
          var response = await this.axios.post(`projects/${this.getLocation}/query`, {_id: contracts[i].projectId});
          var additionalContracts = await this.fetchAdditionalContracts(response.data);
          if (additionalContracts != null && additionalContracts.length > 0) {
            contracts[i].dueAmount += this.getTotalContractAmount(additionalContracts, 'amount') - this.getTotalContractAmount(additionalContracts, 'paidAmount');
          }
        }

        if (contracts[i].dueAmount == 0) {
          indexToRemove.push(i);
        }
      }

      for (var j = indexToRemove.length - 1; j >= 0; j--) {
        contracts.splice(indexToRemove[j], 1);
      }

      return contracts;
    },
    async fetchAdditionalContracts(project) {
      var additionalContracts = [];
      for (var i = 0; i < project.additionalContracts.length; i++) {
        var id = project.additionalContracts[i];
        var response = await this.axios.post(`contracts/${this.getLocation}/query`, {_id: id});
        additionalContracts.push(response.data);
      }
      return additionalContracts;
    },
  }
}
</script>
