<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>收到款项</h3>
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="addPaymentUpdateContract">
          <div class="form-group">
            <label>合同编号</label>
            {{ contract._id }}
          </div>
          <div class="form-group">
            <label>项目名称</label>
            {{ contract.projectTitle }}
          </div>
          <div class="form-group">
            <label>项目甲方</label>
            {{ contract.projectCustomer }}
          </div>
          <div class="form-group">
            <label>合同名称</label>
            <a href="#" v-on:click="viewFile(contract.fileId, contract.filename)">{{ contract.filename }}</a>
          </div>
          <div class="form-group">
            <label>合同总金额</label>
            {{ contract.amount }}
          </div>
          <div class="form-group">
            <label>已收金额</label>
            {{ contract.paidAmount }}
          </div>
          <div class="form-group" id="priceAlert">
            <rlabel label="收款金额"></rlabel>
            <input type="number" step=".01" class="form-control" v-model="payment.amount" required="required">
            <div v-if="showPriceAlert" class="alert alert-danger">
              回款金额应在0~{{ contract.amount - contract.paidAmount }}之间
            </div>
          </div>
          <div class="form-group">
            <label>备注</label>
            <textarea type="text" class="form-control" v-model="payment.note" rows="5"/>
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
          </div>

          <Uploading :waiting="waiting"></Uploading>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import loaderUtil from '@/js/loaderUtil';

export default {
  data() {
    return {
      contract: {},
      payment: {}
    }
  },
  mixins: [
    fileIO, storeUtil, loaderUtil
  ],
  created() {
    this.fetchContract();
  },
  computed : {
    showPriceAlert: function() {
      return (this.payment.amount <= 0 || this.payment.amount > this.contract.amount - this.contract.paidAmount);
    }
  },
  methods: {
    fetchContract() {
      if (this.authLevel(4)) {
        this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
          _id: this.$route.params.id
        }).then(response => {
            this.contract = response.data[0];
        }).catch(() => {
          alert('您暂无权限查看此页');
          this.$router.go(-1);
        });
      } else {
        alert('您暂无权限查看此页');
        this.$router.go(-1);
      }
    },
    addPaymentUpdateContract() {
      this.waiting = true;
      if (this.showPriceAlert) {
        document.getElementById('priceAlert').scrollIntoView();
        this.waiting = false;
        return;
      }

      this.payment.contractId = this.contract._id;
      this.payment.date = moment();
      this.axios.post(`payments/${this.getLocation}/add`, this.payment).then(response => {
          alert('成功添加收款记录');

          this.contract.paidAmount = Number(this.contract.paidAmount) + Number(this.payment.amount);
          this.axios.post(`contracts/${this.getLocation}/update/${this.contract._id}`, this.contract).then(() => {
              this.$router.push({name: 'ContractInfo', params: {id: this.$route.params.id}});
          }).catch(() => {
            alert(`无法更新合同收款`);
            this.errDeletePayment(response.data._id);
          });
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });

    },
    errDeletePayment(paymentId) {
      this.axios.get(`payments/${this.getLocation}/delete/${paymentId}`).then(response => {
        alert(response.data);
      });
    }
  }
}
</script>
