<template>
  <div class="container">
    <div class="card">

      <FileLoading :showGifImg="showGifImg"></FileLoading>
      <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

      <TheNavPills :status='3'></TheNavPills>

      <div class="card-body">

        <br />
        <p><b v-show="contracts.length > 0">共{{ contracts.length }}份合同</b></p>
        <br />

        <rise-loader :loading="loading" class="text-center"></rise-loader>

        <div v-if="!loading && contracts.length > 0" style='overflow-x:auto'>
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th class="text-center align-middle" style="min-width: 60px">序号</th>
                <Header header="责任人" @sort="sort('owner')"></Header>
                <Header header="类别" @sort="sort('category')"></Header>
                <Header header="合同名称" @sort="sort('filename')"></Header>
                <Header header="上传时间" @sort="sort('uploadTime')"></Header>
                <Header header="合同金额" @sort="sort('amount')"></Header>
                <th style="min-width: 100px"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(contract, index) in sortedContracts" :key="contract._id" ref="contracts" class="text-center">
                <td><b>{{ index + 1 }}</b></td>
                <td class="align-middle">{{ contract.owner }}</td>
                <td class="align-middle">{{ contract.category }}</td>
                <td class="align-middle">
                  <a v-if="!contract.stampedFileId && !contract.stampedFilename" href="#" @click="viewFile(contract.fileId, contract.filename)">
                    {{ contract.filename }}
                  </a>
                  <a v-else href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">
                    {{ contract.stampedFilename }}
                  </a>
                </td>
                <td class="align-middle">{{ getYearMonthDate(contract.uploadTime) }}</td>
                <td class="align-middle">{{ contract.amount }}</td>

                <td v-if="contract.category != '中标通知书'" class="align-middle">
                  <router-link class="btn btn-success btn-sm" :to="{name: 'ContractInfo', params: { id: contract._id }}" @click.native="setContractIndex(index)">详情</router-link>
                </td>
                <td v-else></td>
              </tr>
            </tbody>
          </table>

          <Pagination
          :array="contracts"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @setCurrentPage="setCurrentPage"
          @incrementCurrentPage="currentPage++"
          @minusCurrentPage="currentPage--">
        </Pagination>
      </div>

      <Empty v-else-if="!loading && contracts.length == 0"></Empty>
    </div>
  </div>
</div>
</template>

<script>
import ContractListUtil from './ContractListUtil';
import TheNavPills from './TheNavPills';

export default {
  data() {
    return {
      pageSize: 15
    }
  },
  components: {
    TheNavPills
  },
  mixins: [
    ContractListUtil
  ],
  methods: {
    fetchContracts() {
      this.axios.post(`contracts/${this.getLocation}/queryAll`, {status: '作废'}).then((response) => {
        this.contracts = response.data;
        this.loading = false;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
