<template>
  <div class="container">
  <div class="card">
    <div class="card-header">
      <h3>人员列表</h3>
      <router-link :to="{ name: 'CreatePerson' }" class="btn btn-info" style="float: right;">添加人员</router-link>
    </div>

    <div class="card-body" style="overflow-x:auto">
    <table class="table table-hover">
      <thead class="text-center">
        <tr>
          <th style="min-width: 80px">序号</th>
          <th class="align-middle">ID</th>
          <th class="align-middle" style="min-width: 100px">姓名</th>
          <th class="align-middle">邮箱</th>
          <th class="align-middle">角色</th>
          <th class="align-middle" style="min-width: 100px"></th>
          <th class="align-middle" style="min-width: 120px"></th>
          <th class="align-middle" style="min-width: 120px"></th>
          <th class="align-middle" style="min-width: 100px"></th>
        </tr>
      </thead>

      <tbody class="text-center">
        <tr v-for="(person, index) in people" :key="person._id">
          <td class="align-middle"><b>{{ index + 1 }}</b></td>
          <td class="align-middle">{{ person._id }}</td>
          <td class="align-middle">{{ person.name }}</td>
          <td class="align-middle">{{ person.email }}</td>
          <td class="align-middle">{{ person.role }}</td>
          <td class="align-middle"><router-link class="btn btn-primary" :to="{ name: 'EditPerson', params: { id: person._id } }">编辑</router-link></td>
          <td class="align-middle" v-if="person.monitorWorkingHour"><router-link class="btn btn-primary" :to="{ name: 'WorkingHourInfo', params: { personId: person._id } }">年假管理</router-link></td>
          <td v-else></td>
          <td class="align-middle"><button class="btn btn-success" @click="sendNewPwdEmail(person._id)">发送新密码邮件</button></td>
          <td class="align-middle"><button class="btn btn-danger"  @click="deletePerson(person._id, index)">删除</button></td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</div>
</template>

<script>
import storeUtil from '@/js/storeUtil.js';

export default {
  data() {
    return{
      people: []
    }
  },
  created: function() {
    this.fetchPeople();
  },
  mixins: [
    storeUtil
  ],
  methods: {
    fetchPeople() {
      this.axios.get(`people/${this.getLocation}`).then((response) => {
          this.people = response.data;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    sendNewPwdEmail(id) {
      this.axios.get(`people/${this.getLocation}/password/reset/${id}`).then((response) => {
        alert(response.data);
      }).catch((err) => {
        console.log(err);
        alert(err.response.data);
      });
    },
    deletePerson(id, index) {
      this.people.splice(index, 1);
      this.axios.get(`people/${this.getLocation}/delete/${id}`).then((response) => {
          alert(response.data);
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    }
  }
}
</script>
