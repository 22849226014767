<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>用印申请</h3>
      </div>

      <div class="card-body">
        <b-alert show dismissible>
          <h4 class="alert-heading">说明</h4>
          <hr>
          <p>请在此处上传<b>除合同外</b>的其他需要用印的文件。</p>
        </b-alert>
        <form v-on:submit.prevent="startStampProcedure()">
          <div class="form-group">
            <rlabel label="用印类型"></rlabel>
            <select class="form-control" v-model="stamp.category" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-for="c in stampCategory" :key="c" :value="c">{{ c }}</option>
            </select>
          </div>
          <div class="form-group">
            <rlabel label="印章类别"></rlabel>(按command⌘键可多选)
            <select class="form-control" v-model="selectedStampKind" required="required" multiple>
              <option disabled selected value>-----请选择-----</option>
              <option v-for="kind in stampKind" :key="kind" :value="kind">{{ kind }}</option>

            </select>
          </div>
          <div class="form-group">
            <rlabel label="用印公司"></rlabel>
            <select class="form-control" v-model="stamp.company" required="required">
              <option disabled selected value>-----请选择-----</option>
              <option v-if="this.getLocation == 'xian'">陕西晟航</option>
              <option v-if="this.getLocation == 'xian'">陕西双友</option>
              <option v-if="this.getLocation == 'xian'">陕西志诚</option>
              <option v-if="this.getLocation == 'shanghai'">上海晟航</option>
              <option v-if="this.getLocation == 'shanghai'">上海微宇</option>
            </select>
          </div>

            <FileUploadBox
            label="用印文件"
            :notes="['支持拖拽; 支持同时上传多个文件; 重新选择或拖拽文件即可替换原先上传文件']"
            @setFiles="files = $event"
            :required="true"
            :supportMultiFiles="true"
            :filenameCheck="true">
            </FileUploadBox>

            <div class="form-group">
              <label>关联项目</label>
              <select class="form-control" v-model="stamp.projectId">
                <option disabled selected value>-----请选择-----</option>
                <option v-for="p in projects" :key="p._id" :value="p._id">
                  {{ p.customer }} - {{ p.title }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <rlabel label="说明"></rlabel>
              <textarea type="text" class="form-control" rows="3" v-model="stamp.note" required="required"/>
            </div>

            <div class="form-group">
              <input type="submit" class="btn btn-primary" value="上传" :disabled="waiting">
            </div>
            <Uploading :waiting="waiting"></Uploading>

          </form>
        </div>

      </div>
    </div>
  </template>

  <script>
  import moment from 'moment';
  import storeUtil from '@/js/storeUtil';
  import fileIO from '@/js/fileIO';
  import loaderUtil from '@/js/loaderUtil';
  import projectUtil from '@/js/projectUtil';

  export default {
    data() {
      return {
        stamp: {},
        selectedStampKind: [],
        stampCategory: [],
        stampKind: []
      }
    },
    mixins: [
      storeUtil, fileIO, loaderUtil, projectUtil
    ],
    created() {
      this.fetchStampSetFromRedis();
    },
    methods: {
      startStampProcedure() {
        this.waiting = true;
        this.handleFiles(this.addStampProcedure, true);
      },
      addStampProcedure(id, filename) {
        this.stamp.stampKind = '';
        for (var i = 0; i < this.selectedStampKind.length; i++) {
          this.stamp.stampKind += this.selectedStampKind[i];
          if (i + 1 < this.selectedStampKind.length) {
            this.stamp.stampKind += ','
          }
        }

        this.stamp.fileId = id;
        this.stamp.filename = filename;
        this.stamp.owner = this.getUser.name;
        this.stamp.uploadTime = moment();
        this.stamp.procedure = this.getUser.name;


        this.axios.post(`stamps/${this.getLocation}/add`, this.stamp).then((response) => {
          var stamp = response.data;
          this.axios.post(`stamps/${this.getLocation}/update/procedure/${stamp._id}`, {}).then((response) => {
            alert(response.data);
            this.$router.push({name: 'StampIndex'});
            this.waiting = false;
          });
        });

      },
      fetchStampSetFromRedis() {
        this.axios.get(`redis/members/stampCategory`).then(response => {
          this.stampCategory = response.data[0];
        });

        this.axios.get(`redis/members/stampKind`).then(response => {
          this.stampKind = response.data[0];
        });

      }
    }
  }
  </script>
