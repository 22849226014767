export default {
  methods: {
    translateModelName(key) {
      switch(key) {
        case 'projectId':
        return '项目编号';
        case 'item':
        return '采购';
        case 'project':
        return '项目';
        case 'car':
        return '用车';
        case 'contract':
        return '合同';
        case 'payment':
        return '付款';
        case 'receipt':
        return '发票';
        case 'stamp':
        return '用印';
        case 'announcement':
        return '通知';
        case 'notification':
        return '消息';
        case 'reimbursement':
        return '费用';
        case 'price':
        return '价格';
        case 'date':
        return '到货日期';
        default:
        return '';
      }
    },
    translateReimbursementMethod(method) {
      switch(method) {
        case 'company':
        return '公司';
        case 'individual':
        return '个人';
        default:
        return '';
      }
    },
    translateAttribute(attribute) {
      switch(attribute) {
        case 'projectId':
        return '项目编号';
        case 'title':
        return '抬头';
        case 'desc':
        return '详情';
        case 'user':
        return '用车人';
        case 'brand':
        return '品牌';
        case 'destinations':
        return '目的地';
        case 'procedure':
        return '进度';
        case 'note':
        return '备注';
        case 'category':
        return '类别';
        case 'filename':
        return '文件名';
        case 'stampedFilename':
        return '盖章文件名';
        case 'paymentMethod':
        return '付款方式';
        case 'name':
        return '名称';
        case 'series':
        return '型号';
        case 'purchaserSeries':
        return '采购人提供的品牌';
        case 'supplier':
        return '供应商';
        case 'purchaserSupplier':
        return '采购人提供的供应商';
        case 'purchaser':
        return '采购人';
        case 'owner':
        return '负责人';
        case 'parameters':
        return '参数';
        case 'purchaserParameters':
        return '采购人提供的参数';
        case 'purchaserNote':
        return '采购人的备注';
        case 'link':
        return '关联';
        case 'company':
        return '公司';
        case 'accountImgFilename':
        return '付款账户截图文件名';
        case 'customer':
        return '甲方';
        case 'bidFilename':
        return '中标通知书文件名';
        case 'relatedFiles':
        return '相关文件';
        case 'method':
        return '付款方式';
        case 'stampKind':
        return '印章类别';
        case 'msg':
        return '消息';
        case 'quantity':
        return '数量';
        case 'date':
        return '日期';
        case 'procedureNote':
        return '审批备注';
        case 'price':
        return '价格';
        default:
        return attribute;
      }
    },
    translateMonth(num) {
      switch(num) {
        case 0:
        return '一';
        case 1:
        return '二';
        case 2:
        return '三';
        case 3:
        return '四';
        case 4:
        return '五';
        case 5:
        return '六';
        case 6:
        return '七';
        case 7:
        return '八';
        case 8:
        return '九';
        case 9:
        return '十';
        case 10:
        return '十一';
        case 11:
        return '十二';
      }
    }
  }
}
