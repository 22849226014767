<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <h3>与我有关的采购</h3>

      <br />

      <ul class="nav nav-pills">
        <li class="nav-item">
          <router-link :to="{ name: 'ItemListOngoing'}" class="nav-link active">未完成</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'ItemListComplete'}" class="nav-link">已完成</router-link>
        </li>
      </ul>
    </div>

    <ItemListTemplate :query="query" :columns="['projectTitle', 'procedure', 'editButton', 'comparisonButton', 'operationButton']"></ItemListTemplate>

</div>
</div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import ItemListTemplate from './ItemListTemplate';

export default {
  data() {
    return {
      query: {}
    }
  },
  components: {
    ItemListTemplate
  },
  mixins: [
    storeUtil
  ],
  created() {
    if (!this.authLevel(2)) {
      this.query = {
        $or: [
          // incompletedItems
          {
            procedure: { $ne: '完成' },
            owner: this.getUser.name
          },
          // unlinkedItems
          {
            procedure: '完成',
            contractId: null,
            owner: this.getUser.name
          }
        ]
      };
    } else {
      this.query = {
        owner: { $exists: true, $ne: null },
        $or: [
          { procedure: { $ne: '完成' } },
          { contractId: null }
        ]
      };
    }
  },
}
</script>
