<template>
<span>
<i class="fa fa-info-circle" aria-hidden="true" v-b-modal.modal></i>

<b-modal id="modal" v-if="supplier != null">
  <b-list-group>
    <b-list-group-item>
      <b>供应商名称</b><br />{{ supplier.name }}
    </b-list-group-item>
    <b-list-group-item>
      <b>供应商标签</b>
      <div v-for="tag in supplier.tags" :key="tag">
        <b-badge pill>{{ tag }}</b-badge>
      </div>
    </b-list-group-item>
    <b-list-group-item>
      <b>供应商联系人列表</b>
      <b-list-group v-for="contact in supplier.contacts">
        <b-list-group-item v-show="contact.name">
          <b>联系人</b><b-badge variant="info">{{ contact.name }}</b-badge>
        </b-list-group-item>
        <b-list-group-item v-show="contact.phoneNumber">
          <b>联系方式</b><b-badge variant="info">{{ contact.phoneNumber }}</b-badge>
        </b-list-group-item>
        <b-list-group-item v-show="contact.wechatID">
          <b>微信</b><b-badge variant="info">{{ contact.wechatID }}</b-badge>
        </b-list-group-item>
        <b-list-group-item v-show="contact.email">
          <b>邮箱</b><b-badge variant="info">{{ contact.email }}</b-badge>
        </b-list-group-item>
        <b-list-group-item v-show="contact.role">
          <b>职位</b><b-badge variant="info">{{ contact.role }}</b-badge>
        </b-list-group-item>
      </b-list-group>
    </b-list-group-item>
  </b-list-group>
</b-modal>

<b-modal id="modal" v-else>
  暂无信息
</b-modal>
</span>
</template>

<script>
export default {
  props: [
    'name'
  ],
  data() {
    return {
      supplier: null
    }
  },
  // computed: {
  //   supplier() {
  //     console.log(this.name.trim());
  //     this.axios.post(`suppliers/query`, { name: this.name.trim() }).then(response => {
  //       console.log(response.data);
  //       this.$forceUpdate();
  //       return response.data;
  //     });
  //   }
  // }
  async created() {
    this.supplier = (await this.axios.post(`suppliers/query`, { name: this.name.trim() })).data;
  }
}
</script>
