import formatTime from '@/js/formatTime';
import fileIO from '@/js/fileIO';
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import loaderUtil from '@/js/loaderUtil';
import peopleUtil from '@/js/peopleUtil';

export default {
  data() {
    return {
      items: [],
      allItems: [],
      currentSort: 'isEditable',
      currentSortDir: 'desc',
      filters: [],
      searchLogicOr: false
    }
  },
  mixins: [
    formatTime, fileIO, storeUtil, sortUtil, loaderUtil, peopleUtil
  ],
  created() {
    this.fetchItems();
  },
  computed : {
    sortedItems() {
      return this.sortArray(this.items);
    }
  },
  updated() {
    // var itemPosition = this.$cookies.get('itemPosition');
    // if (itemPosition != null) {
    //   if (itemPosition.filters != null) {
    //     this.updateData(itemPosition.filters);
    //   }
    //   this.currentPage = itemPosition.page;
    //   var row = this.$refs.items[itemPosition.index];
    //   row.scrollIntoView();
    //   this.$cookies.remove('itemPosition');
    // }
  },
  methods: {
    fetchItems() {
      this.axios.post(this.url, this.query).then(async response => {
        var items = response.data;

        for (var i = 0; i < items.length; i++) {
          items[i].isEditable = this.isEditableItem(items[i]);
          var response = await this.axios.post(`projects/${this.getLocation}/query`, { _id: items[i].projectId });

          if (!response.data)
          continue;

          items[i].projectTitle = response.data.title;
          items[i].projectId = response.data._id;
          items[i].projectCustomer = response.data.customer;
        }

        this.items = items;
        this.allItems = items;

        this.loading = false;
      });
    },
    updateData(filters) {
      this.filters = filters;
      this.currentPage = 1;
      if (filters.length == 0) {
        this.items = this.allItems;
      } else {
        if (!this.searchLogicOr) {
          this.items = this.filterKeywordsAnd(this.allItems, filters);
        } else {
          this.items = this.filterKeywords(this.allItems, filters);
        }
      }
    },
    // setItemIndex(index) {
    //   var itemPosition = {
    //     page: this.currentPage,
    //     index: index
    //   };
    //   if (this.filters.length > 0) {
    //     itemPosition.filters = this.filters;
    //   }
    //   this.$cookies.set('itemPosition', itemPosition);
    // },
    isEditableItem(item) {
      return item.owner == this.getUser.name && (!item.procedure || item.procedure == this.getUser.name);
      // !item.name || !item.brand || !item.series || !item.price ||
      // !item.quantity || !item.supplier || !item.owner || !item.date ||
      // !item.parameters;
    },
    isCallbackable(item) {
      if (this.getUser.name == item.owner || this.getUser.name == item.purchaser) {
        return item.procedure != null && item.procedure != item.owner && item.procedure != '完成';
      } else {
        return false;
      }
    }
  }
}
