<template>
  <div class="container">

    <div class="form-group">
      <h5>人员角色:</h5>
      <a v-for="role in personRole" :key="role">
        <a class="trigger">{{ role }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'personRole', role)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newPersonRole" v-on:keyup.enter="modifyValueInRedis('add', 'personRole', newPersonRole)">
    </div>

    <br />
    <br />

    <div class="form-group">
      <h5>项目类别:</h5>
      <a v-for="c in projectCategory" :key="c">
        <a class="trigger">{{ c }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'projectCategory', c)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newProjectCategory" v-on:keyup.enter="modifyValueInRedis('add', 'projectCategory', newProjectCategory)">
    </div>

    <br />
    <br />

    <div class="form-group">
      <h5>供应商类别:</h5>
      <a v-for="c in supplierCategory" :key="c">
        <a class="trigger">{{ c }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'supplierCategory', c)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newSupplierCategory" v-on:keyup.enter="modifyValueInRedis('add', 'supplierCategory', newSupplierCategory)">
    </div>

    <br />
    <br />

    <div class="form-group">
      <h5>费用类别:</h5>
      <a v-for="c in reimbursementCategory" :key="c">
        <a class="trigger">{{ c }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'reimbursementCategory', c)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newReimbursementCategory" v-on:keyup.enter="modifyValueInRedis('add', 'reimbursementCategory', newReimbursementCategory)">
    </div>

    <br />
    <br />

    <div class="form-group">
      <h5>用印类型:</h5>
      <a v-for="c in stampCategory" :key="c">
        <a class="trigger">{{ c }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'stampCategory', c)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newStampCategory" v-on:keyup.enter="modifyValueInRedis('add', 'stampCategory', newStampCategory)">
    </div>

    <br />
    <br />

    <div class="form-group">
      <h5>印章类型:</h5>
      <a v-for="kind in stampKind" :key="kind">
        <a class="trigger">{{ kind }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'stampKind', kind)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newStampKind" v-on:keyup.enter="modifyValueInRedis('add', 'stampKind', newStampKind)">
    </div>

    <br />
    <br />

    <div class="form-group">
      <h5>项目相关文件类别:</h5>
      <a v-for="category in projectFileCategories" :key="category">
        <a class="trigger">{{ category }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'projectFileCategories', category)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newProjectFileCategory" v-on:keyup.enter="modifyValueInRedis('add', 'projectFileCategories', newProjectFileCategory)">
    </div>

    <div class="form-group">
      <h5>合同付款方式:</h5>
      <a v-for="method in paymentMethods" :key="method">
        <a class="trigger">{{ method }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'paymentMethod', method)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newPaymentMethod" v-on:keyup.enter="modifyValueInRedis('add', 'paymentMethod', newPaymentMethod)">
    </div>

    <div class="form-group">
      <h5>开票税率:</h5>
      <a v-for="taxPercentage in taxPercentages" :key="taxPercentage">
        <a class="trigger">{{ taxPercentage }} <i class="fas fa-times"   @click="modifyValueInRedis('delete', 'taxPercentage', taxPercentage)"></i></a>
      </a>
      <input class="trigger" type="text" v-model="newTaxPercentage" v-on:keyup.enter="modifyValueInRedis('add', 'taxPercentage', newTaxPercentage)">
    </div>

  </div>
</template>

<script>
import config from '@/../config/IP';

export default {
  data() {
    return {
      projectCategory: [],
      newProjectCategory: null,
      supplierCategory: [],
      newSupplierCategory: null,
      reimbursementCategory: [],
      newReimbursementCategory: null,
      personRole: [],
      newPersonRole: null,
      stampCategory: [],
      newStampCategory: null,
      stampKind: [],
      newStampKind: null,
      projectFileCategories: [],
      newProjectFileCategory: null,
      paymentMethods: [],
      newPaymentMethod: null,
      taxPercentages: [],
      newTaxPercentage: null
    }
  },
  created() {
    this.fetchSet('projectCategory');
    this.fetchSet('supplierCategory');
    this.fetchSet('reimbursementCategory');
    this.fetchSet('personRole');
    this.fetchSet('stampCategory');
    this.fetchSet('stampKind');
    this.fetchSet('projectFileCategories');
    this.fetchSet('taxPercentage');
    this.fetchSet('paymentMethod');
  },
  methods: {
    fetchSet(setName) {
      this.axios.get(`redis/members/${setName}`).then((response) => {
        switch(setName) {
          case 'projectCategory':
          this.projectCategory = response.data[0];
          this.newProjectCategory = null;
          break;
          case 'supplierCategory':
          this.supplierCategory = response.data[0];
          this.newSupplierCategory = null;
          break;
          case 'reimbursementCategory':
          this.reimbursementCategory = response.data[0];
          this.newReimbursementCategory = null;
          break;
          case 'personRole':
          this.personRole = response.data[0];
          this.newPersonRole = null;
          break;
          case 'stampCategory':
          this.stampCategory = response.data[0];
          this.newStampCategory = null;
          break;
          case 'stampKind':
          this.stampKind = response.data[0];
          this.newStampKind = null;
          break;
          case 'projectFileCategories':
          this.projectFileCategories = response.data[0];
          this.newProjectFileCategory = null;
          break;
          case 'taxPercentage':
          this.taxPercentages = response.data[0];
          this.newTaxPercentage = null;
          break;
          case 'paymentMethod':
          this.paymentMethods = response.data[0];
          this.newPaymentMethod = null;
          break;
        }
      });
    },
    modifyValueInRedis(addOrDelete, setName, value) {
      this.axios.post(`redis/${addOrDelete}/${setName}`, {
        value: value
      }).then(() => {
        this.fetchSet(setName);
      });
    }
  }
}
</script>
