<template>
  <div class="container">
    <div class="card">
      <FileLoading :showGifImg="showGifImg"></FileLoading>
      <FileAttempt :viewFileAttempt="viewFileAttempt"></FileAttempt>

      <TheNavPills :status='1'></TheNavPills>

      <div class="card-body">
        <label><b>筛选</b></label>

        <br />

        <b-row>
          <b-col>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" v-model="searchLogicOr">
              <p class="form-check-label">模糊查询</p>
            </div>
          </b-col>
          <b-col>
            <input type="checkbox" class="form-check-input" v-model="attention">
            <p><i class="fa fa-exclamation-triangle" style="color: red"></i>求关注</p>
          </b-col>
          <b-col>
            <input type="checkbox" class="form-check-input" v-model="stampContract">
            <p>未上传盖章合同</p>
          </b-col>
          <b-col>
            <input type="checkbox" class="form-check-input" v-model="contractPayment">
            <p>未100%申请付款</p>
          </b-col>
          <b-col>
            <input type="checkbox" class="form-check-input" v-model="contractReceipt">
            <p>未100%申请开票</p>
          </b-col>
          <b>合同金额:</b>
          <b-col v-on:keyup.enter="filterContract()">
            <input type="number" class="form-control" placeholder="合同金额最低值" v-model="lowerAmount">
          </b-col>
          ~
          <b-col v-on:keyup.enter="filterContract()">
            <input type="number" class="form-control" placeholder="合同金额最高值" v-model="upperAmount">
          </b-col>
        </b-row>

        <Search @updateData='updateData'></Search>


        <hr>

        <p><b v-show="contracts.length > 0">共{{ contracts.length }}份合同</b></p>
        <br />

        <rise-loader :loading="loading" class="text-center"></rise-loader>

        <div v-if="!loading && contracts.length > 0" style='overflow-x:auto'>
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <Header header="" @sort="sort('lock')" style="min-width: 20px"></Header>
                <th class="text-center align-middle" style="min-width: 60px">序号</th>
                <Header header="项目名称" @sort="sort('projectTitle')"></Header>
                <Header header="责任人" @sort="sort('owner')"></Header>
                <Header header="类别" @sort="sort('category')"></Header>
                <Header header="合同名称" @sort="sort('filename')"></Header>
                <Header header="上传时间" @sort="sort('uploadTime')"></Header>
                <Header header="合同金额" @sort="sort('amount')"></Header>
                <Header header="审批进度" @sort="sort('procedure')"></Header>
                <Header header="付款进度" @sort="sort('paidAmount')"></Header>
                <Header header="发票进度" @sort="sort('receiptAmount')"></Header>
                <th style="min-width: 60px"></th>
                <th style="min-width: 100px"></th>
                <th style="min-width: 80px" v-show="authLevel(2)"></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(contract, index) in sortedContracts" :key="contract._id" ref="contracts" class="text-center">
                <td class="align-middle">
                  <i class="fa fa-lock" aria-hidden="true" v-if="contract.lock"></i>
                  <i class="fa fa-unlock" aria-hidden="true" v-else></i>
                </td>

                <td class="align-middle"><b>{{ index + 1 }}</b></td>

                <td class="align-middle" v-if="currentSort != 'projectTitle'">
                  <TheContractProjectTitle :contract="contract"></TheContractProjectTitle>
                </td>
                <td class="align-middle" v-else-if="projectCount[contract.projectTitle] == 1">
                  <TheContractProjectTitle :contract="contract"></TheContractProjectTitle>
                </td>
                <td class="align-middle" v-else-if="index % pageSize == 0 || (index - 1 >= 0 && sortedContracts[index - 1].projectTitle != contract.projectTitle)"
                  :rowspan="projectCount[contract.projectTitle] >= pageSize - index % pageSize
                  ? pageSize - index % pageSize
                  : projectCount[contract.projectTitle]">
                  <TheContractProjectTitle :contract="contract"></TheContractProjectTitle>
                </td>


                <td class="align-middle">{{ contract.owner }}</td>
                <td class="align-middle">{{ contract.category }}</td>
                <td class="align-middle">
                  <a v-if="!contract.stampedFileId && !contract.stampedFilename" href="#" @click="viewFile(contract.fileId, contract.filename)">
                    {{ contract.filename }}
                  </a>
                  <a v-else href="#" @click="viewFile(contract.stampedFileId, contract.stampedFilename)">
                    {{ contract.stampedFilename }}
                  </a>
                </td>
                <td class="align-middle">{{ getYearMonthDate(contract.uploadTime) }}</td>
                <td class="align-middle">{{ contract.amount }}</td>
                <td class="align-middle">
                  {{ contract.procedure }}
                  <WhoIsWho :id="contract._id" :role="contract.procedure" v-if="inProcedure(contract)"></WhoIsWho>
                </td>


                <td class="align-middle" v-if="contract.procedure == '完成' && contract.stampedFileId != null">
                  <PaymentProgressBar :contract="contract"></PaymentProgressBar>
                </td>
                <td class="align-middle" v-else></td>


                <td class="align-middle" v-if="contract.procedure == '完成' && contract.stampedFileId != null">
                  <ReceiptProgressBar :contract="contract"></ReceiptProgressBar>
                </td>
                <td class="align-middle" v-else></td>


                <td class="align-middle" v-if="contract.category != '中标通知书'">
                  <router-link class="btn btn-success btn-sm" :to="{name: 'ContractInfo', params: { id: contract._id }}" @click.native="setContractIndex(index)">详情</router-link>
                </td>
                <td class="align-middle" v-else></td>

                <!-- <td class="align-middle" v-if="getUser.name == contract.owner"> -->
                  <td class="align-middle">
                  <router-link :to="{name: 'ContractUpload', params: { id: contract._id }}" class="btn btn-primary btn-sm" v-if="contract.owner == contract.procedure" @click.native="setContractIndex(index)">编辑</router-link>
                  <a v-else-if="contract.category == '中标通知书'"></a>
                  <button class="btn btn-primary btn-sm" v-else-if="inProcedure(contract)" @click="callbackContract=contract, $bvModal.show('recall-contract')">撤回</button>
                  <router-link :to="{name: 'ContractEdit', params: { id: contract._id }}" class="btn btn-primary btn-sm" v-else-if="contract.procedure == '完成' && contract.stampedFileId == null" @click.native="setContractIndex(index)">上传盖章合同</router-link>
                  <router-link :to="{name: 'PaymentProcedure', params: { id: contract._id }}" class="btn btn-primary btn-sm" v-else-if="reachPaymentStatus(contract)" @click.native="setContractIndex(index)">付款申请</router-link>
                  <router-link :to="{name: 'ReceiptProcedure', params: { id: contract._id }}" class="btn btn-primary btn-sm" v-else-if="reachReceiptStatus(contract)" @click.native="setContractIndex(index)">开票申请</router-link>
                  <button class="btn btn-primary btn-sm" v-else-if="enableRecallPayment(contract)" @click="recallPayment(contract)">付款申请撤回</button>
                  <button class="btn btn-primary btn-sm" v-else-if="enableRecallReceipt(contract)" @click="recallReceipt(contract)">开票申请撤回</button>
                  <a v-else></a>
                  <span v-if="contract.procedure == '完成' && contract.stampedFileId == null && reachReceiptStatus(contract)">
                    <hr>
                    <router-link :to="{name: 'ReceiptProcedure', params: { id: contract._id }}" class="btn btn-primary btn-sm" @click.native="setContractIndex(index)">开票申请</router-link>
                  </span>
                </td>
                <!-- <td v-else></td> -->

                <td class="align-middle" v-if="authLevel(2)">
                  <b-button-toolbar>
                    <b-dropdown variant="danger" size="sm" text="更多操作">
                      <b-dropdown-item @click="invalidContract(contract)">
                        作废
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteContractCategory=contract.category, deleteContractId=contract._id, deleteContractIndex=index, $bvModal.show('delete-contract')">
                        删除
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteStampedFile(contract)">
                        仅删除盖章合同
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-button-toolbar>
                </td>
                <td v-else></td>

              </tr>
            </tbody>
          </table>

          <Pagination
          :array="contracts"
          :currentPage="currentPage"
          :pageSize="pageSize"
          @setCurrentPage="setCurrentPage"
          @incrementCurrentPage="currentPage++"
          @minusCurrentPage="currentPage--">
        </Pagination>
      </div>

      <Empty v-else-if="!loading && contracts.length == 0"></Empty>
    </div>

  <b-modal
  id="recall-contract"
  ok-title="确认"
  cancel-title="取消"
  title="撤回合同审批"
  @show="$bvModal.hide('recall-contract')"
  @hidden="$bvModal.hide('recall-contract')"
  @ok="callbackContractProcedure(callbackContract), $bvModal.hide('recall-contract')">
    你确定要撤回合同审批吗？确认后可重新编辑合同上传
  </b-modal>


<b-modal
id="delete-contract"
ok-title="确认"
cancel-title="取消"
title="删除合同"
@show="$bvModal.hide('delete-contract')"
@hidden="$bvModal.hide('delete-contract')"
@ok="deleteContract(deleteContractCategory, deleteContractId, deleteContractIndex), $bvModal.hide('delete-contract')">
  你确定要删除这个合同吗？确认将删除采购合同，合同收付款，合同收开发票，取关采购记录......
</b-modal>

</div>
</div>
</template>

<script>
import ContractListUtil from './ContractListUtil';
import accountingUtil from '@/js/accountingUtil';
import PaymentProgressBar from '@/components/PaymentProgressBar';
import ReceiptProgressBar from '@/components/ReceiptProgressBar';
import TheNavPills from './TheNavPills';
import TheContractProjectTitle from './TheContractProjectTitle';

export default {
  data() {
    return {
      deleteContractCategory: '',
      deleteContractId: '',
      deleteContractIndex: '',
      callbackContract: {},
      attention: false,
      stampContract: false,
      contractPayment: false,
      contractReceipt: false
    }
  },
  mixins: [
    ContractListUtil, accountingUtil
  ],
  components: {
    PaymentProgressBar, ReceiptProgressBar, TheNavPills, TheContractProjectTitle
  },
  watch: {
    attention(boolean) {
      if (boolean) {
        this.setCheckboxFalse('attention');
        this.contracts = this.allContracts.filter(contract => {
          return this.expectedPaymentPercentage(contract) - this.actualPaymentPercentage(contract) > 0
          || this.actualPaymentPercentage(contract) - this.actualReceiptPercentage(contract) > 0;
        });
      }
      this.displayAllContracts();
    },
    stampContract(boolean) {
      if (boolean) {
        this.setCheckboxFalse('stampContract');
        this.contracts = this.allContracts.filter(contract => {
          return contract.procedure == '完成' && contract.stampedFileId == null;
        });
      }
      this.displayAllContracts();
    },
    contractPayment(boolean) {
      if (boolean) {
        this.setCheckboxFalse('contractPayment');
        this.contracts = this.allContracts.filter(contract => {
          return this.reachPaymentStatus(contract);
        });
      }
      this.displayAllContracts();
    },
    contractReceipt(boolean) {
      if (boolean) {
        this.setCheckboxFalse('contractReceipt');
        this.contracts = this.allContracts.filter(contract => {
          return this.reachReceiptStatus(contract);
        });
      }
      this.displayAllContracts();
    }
  },
  methods: {
    fetchContracts() {
      var query = {
        // category: { $ne: '中标通知书' },
        $or: [
          { $where: `this.amount > 0 && (this.amount > this.receiptAmount || this.amount > this.paidAmount)` },
          { $where: `this.amount < 0 && (this.amount < this.receiptAmount || this.amount < this.paidAmount)` },
          { stampedFileId: null }
        ]
      };

      query.status = { $ne: '作废' };
      if (!this.authLevel(2)) {
        query.owner = this.getUser.name;
      }

      this.axios.post(`overall/${this.getLocation}/contracts/project/title`, query).then(response => {
        this.contracts = response.data;
        this.allContracts = response.data;
        this.loading = false;
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    reachPaymentStatus(contract) {
      return contract.category == '采购合同' && contract.procedure == '完成' &&
      contract.stampedFileId != null && contract.amount - contract.paidAmount > 0 &&
      !contract.lock && contract.projectTitle != '无';
    },
    reachReceiptStatus(contract) {
      return contract.category == '销售合同' && contract.procedure == '完成' &&
      contract.amount - contract.receiptAmount > 0 && !contract.lock && contract.projectTitle != '无';
    },
    enableRecallPayment(contract) {
      return contract.category == '采购合同' & contract.procedure == '完成' &&
      contract.stampedFileId != null && contract.amount - contract.paidAmount > 0 &&
      contract.lock && contract.projectTitle != '无';
    },
    enableRecallReceipt(contract) {
      return contract.category == '销售合同' && contract.procedure == '完成' &&
      contract.amount - contract.receiptAmount > 0 && contract.lock && contract.projectTitle != '无';
    },
    recallPayment(contract) {
      contract.lock = false;
      this.axios.post(`contracts/${this.getLocation}/update/${contract._id}`, contract).then(() => {
        this.axios.post(`payments/${this.getLocation}/query`, {
          contractId: contract._id,
          procedure: {$ne: '完成'}
        }).then(paymentResponse => {
          var payment = paymentResponse.data;
          this.axios.get(`payments/${this.getLocation}/delete/${payment._id}`).then(response => {
            alert(response.data);
          });
        });
      });
    },
    recallReceipt(contract) {
      contract.lock = false;
      this.axios.post(`contracts/${this.getLocation}/update/${contract._id}`, contract).then(() => {
        this.axios.post(`receipts/${this.getLocation}/query`, {
          contractId: contract._id,
          procedure: {$ne: '完成'}
        }).then(receiptResponse => {
          var receipt = receiptResponse.data;
          this.axios.get(`payments/${this.getLocation}/delete/${receipt._id}`).then(response => {
            alert(response.data);
          });
        });
      });
    },
    deleteContract(category, id, index) {
      if (category == '采购合同') {
        this.axios.get(`overall/${this.getLocation}/delete/contract/purchasing/${id}`).then((response) => {
          alert(response.data);
          this.contracts.splice(index, 1);
        });
      } else if (category == '销售合同' || category == '中标通知书') {
        this.axios.get(`overall/${this.getLocation}/delete/contract/project/unlink/${id}`).then((response) => {
          alert(response.data);
          this.contracts.splice(index, 1);
        }).catch(err => {
          alert(err.response.data);
        });
      }
    },
    callbackContractProcedure(callbackContract) {
      callbackContract.procedure = callbackContract.owner;
      this.axios.post(`contracts/${this.getLocation}/update/${callbackContract._id}`, callbackContract).then(() => {
        alert('已成功撤回合同审批，请编辑后重新上传');
      }).catch(() => {
        this.$router.push({name: 'ErrorPage'});
      });
    },
    inProcedure(contract) {
      return contract.procedure != contract.owner && contract.procedure != '完成';
    },
    deleteStampedFile(contract)  {
      var fileId = contract.stampedFileId;
      var filename = contract.stampedFilename;
      contract.stampedFileId = null;
      contract.stampedFilename = null;

      this.axios.post(`contracts/${this.getLocation}/update/${contract._id}`, contract).then(response => {
        this.axios.post(`files/delete`, {
          fileId: fileId,
          filename: filename
        }).then(response => {
          alert(response.data);
        });
      });
    },
    invalidContract(contract) {
      if (contract.category == '销售合同') {
        this.axios.post(`projects/${this.getLocation}/query/${contract._id}`, {contractId: contract._id}).then(response => {
          var project = response.data;
          Promise.all([
            this.axios.get(`contracts/${this.getLocation}/update/status/${contract._id}`),
            this.axios.get(`items/${this.getLocation}/contract/unlink/${contract._id}`),
            this.axios.get(`projects/${this.getLocation}/unlink/contract/${project._id}`)
          ]).then(results => {
            alert('成功作废合同并取消采购关联');
            this.$forceUpdate();
          });
        });
      } else {
        Promise.all([
          this.axios.get(`contracts/${this.getLocation}/update/status/${contract._id}`),
          this.axios.get(`items/${this.getLocation}/contract/unlink/${contract._id}`)
        ]).then(results => {
          alert('成功作废合同并取消采购关联');
          this.$forceUpdate();
        });
      }
    },
    setCheckboxFalse(trueBoolean) {
      this.currentPage = 1;
      switch(trueBoolean) {
        case 'attention':
        this.stampContract = false;
        this.contractPayment = false;
        this.contractReceipt = false;
        break;
        case 'stampContract':
        this.attention = false;
        this.contractPayment = false;
        this.contractReceipt = false;
        break;
        case 'contractPayment':
        this.attention = false;
        this.stampContract = false;
        this.contractReceipt = false;
        break;
        case 'contractReceipt':
        this.attention = false;
        this.stampContract = false;
        this.contractPayment = false;
        break;
      }
    },
    displayAllContracts() {
      if (!this.attention && !this.stampContract && !this.contractPayment && !this.contractReceipt)
      this.contracts = this.allContracts;
    }
  }
}
</script>
