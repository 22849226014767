<template>
  <div class="container">

    <rise-loader :loading="loading" class="text-center"></rise-loader>

    <div class="card">
      <div class="card-header">
        <h5>管理开票信息</h5>
      </div>
      <div class="card-body">
        <div style="overflow-x:auto;">
        <table class="table table-hover">
          <thead>
            <tr class="text-center">
              <th>序号</th>
              <Header header="甲方" @sort="sort('projectCustomer')"></Header>
              <th>开票备注</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(invoice, index) in sortedInvoice" class="text-center">
              <td><b>{{ index + 1 }}</b></td>
              <td>{{ invoice.projectCustomer }}</td>
              <td v-html="invoice.note.replace(/\n/, '<br /><br />')"></td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';
import sortUtil from '@/js/sortUtil';
import loaderUtil from '@/js/loaderUtil';
import fileIO from '@/js/fileIO';

export default {
  data() {
    return {
      invoices: [],
      currentSort: 'projectCustomer'
    }
  },
  mixins: [
    storeUtil, sortUtil, loaderUtil, fileIO
  ],
  created() {
    this.fetchInvoices();
  },
  computed: {
    sortedInvoice() {
      return this.sortArrayWithoutPagination(this.invoices);
    }
  },
  methods: {
    fetchInvoices() {
      this.axios.post(`receipts/${this.getLocation}/queryAll`, {
        procedure: '完成',
        note: { $regex: '.*\\d+.*', $regex: '银行' }
      }).then(async response => {
        var invoices = response.data;

        for (var i = 0; i < invoices.length; i++) {
          var response = await this.axios.post(`overall/${this.getLocation}/contracts/project/title`, {
            _id: invoices[i].contractId,
            category: '销售合同'
          });

          if (response.data.length > 1) {
            this.$route.push('ErrorPage');
          }
          var contract = response.data[0];

          invoices[i].projectCustomer = contract.projectCustomer;
        }

        this.invoices = invoices;
        this.loading = false;
      });
    }
  }
}
</script>
