<template>
  <div class="container">
    <div class="card" style="border-color: transparent">
    <div class="form-group">
      <h5>销售合同流程</h5>
      <p><b>{{ ongoingContractProcedure ? '有' : '无' }}</b>进行中的流程</p>
      <a v-for="(contract, index) in sortedContractProcedure" :key="index">
        <a class="trigger" v-if="index % 2 == 0">
          <b>{{ sortedContractProcedure[index + 1] }}</b>   {{ contract }}
          <i class="fas fa-times" @click="modifySortedSetValue('zrem', 'sortedContractProcedure', contract)"></i>
        </a>
        <i class="fa fa-arrow-right" aria-hidden="true" v-if="index % 2 == 0"></i>
      </a>
      <input class="trigger" type="text" style="width: 40px" v-model="nextSortedContractScore">
      <input class="trigger" type="text" v-model="nextSortedContractProcedure" v-on:keyup.enter="modifySortedSetValue('zadd', 'sortedContractProcedure', nextSortedContractProcedure, nextSortedContractScore)">
    </div>

    <hr>

    <div class="form-group">
      <h5>采购合同流程</h5>
      <a v-for="(contract, index) in sortedPurchasingContractProcedure" :key="index">
        <a class="trigger" v-if="index % 2 == 0">
          <b>{{ sortedPurchasingContractProcedure[index + 1] }}</b>   {{ contract }}
          <i class="fas fa-times" @click="modifySortedSetValue('zrem', 'sortedPurchasingContractProcedure', contract)"></i>
        </a>
        <i class="fa fa-arrow-right" aria-hidden="true" v-if="index % 2 == 0"></i>
      </a>
      <input class="trigger" type="text" style="width: 40px" v-model="nextSortedPurchasingContractScore">
      <input class="trigger" type="text" v-model="nextSortedPurchasingContractProcedure" v-on:keyup.enter="modifySortedSetValue('zadd', 'sortedPurchasingContractProcedure', nextSortedPurchasingContractProcedure, nextSortedPurchasingContractScore)">
    </div>

    <hr>

    <div class="form-group">
      <h5>采购合同付款流程 {{ checkOngoingProcedure('payment') }}</h5>
      <p><b>{{ ongoingPaymentProcedure ? '有' : '无' }}</b>进行中的流程</p>
      <a v-for="(payment, index) in sortedPaymentProcedure" :key="index">
        <a class="trigger" v-if="index % 2 == 0">
          <b>{{ sortedPaymentProcedure[index + 1] }}</b>   {{ payment }}
          <i class="fas fa-times" @click="modifySortedSetValue('zrem', 'sortedPaymentProcedure', payment)"></i>
        </a>
        <i class="fa fa-arrow-right" aria-hidden="true" v-if="index % 2 == 0"></i>
      </a>
      <input class="trigger" type="text" style="width: 40px" v-model="nextSortedPaymentScore">
      <input class="trigger" type="text" v-model="nextSortedPaymentProcedure" v-on:keyup.enter="modifySortedSetValue('zadd', 'sortedPaymentProcedure', nextSortedPaymentProcedure, nextSortedPaymentScore)">
    </div>

    <hr>

    <div class="form-group">
      <h5>销售合同开票流程 {{ checkOngoingProcedure('receipt') }}</h5>
      <p><b>{{ ongoingReceiptProcedure ? '有' : '无' }}</b>进行中的流程</p>
      <a v-for="(receipt, index) in sortedReceiptProcedure" :key="index">
        <a class="trigger" v-if="index % 2 == 0">
          <b>{{ sortedReceiptProcedure[index + 1] }}</b>   {{ receipt }}
          <i class="fas fa-times" @click="modifySortedSetValue('zrem', 'sortedReceiptProcedure', receipt)"></i>
        </a>
        <i class="fa fa-arrow-right" aria-hidden="true" v-if="index % 2 == 0"></i>
      </a>
      <input class="trigger" type="text" style="width: 40px" v-model="nextSortedReceiptScore">
      <input class="trigger" type="text" v-model="nextSortedReceiptProcedure" v-on:keyup.enter="modifySortedSetValue('zadd', 'sortedReceiptProcedure', nextSortedReceiptProcedure, nextSortedReceiptScore)">
    </div>

    <hr>

    <div class="form-group">
      <h5>采购审批流程</h5>
      <p><b>{{ ongoingItemProcedure ? '有' : '无' }}</b>进行中的流程</p>
      <a v-for="(item, index) in sortedItemProcedure" :key="index">
        <a class="trigger" v-if="index % 2 == 0">
          <b>{{ sortedItemProcedure[index + 1] }}</b>   {{ item }}
          <i class="fas fa-times" @click="modifySortedSetValue('zrem', 'sortedItemProcedure', item)"></i>
        </a>
        <i class="fa fa-arrow-right" aria-hidden="true" v-if="index % 2 == 0"></i>
      </a>
      <input class="trigger" type="text" style="width: 40px" v-model="nextSortedItemScore">
      <input class="trigger" type="text" v-model="nextSortedItemProcedure" v-on:keyup.enter="modifySortedSetValue('zadd', 'sortedItemProcedure', nextSortedItemProcedure, nextSortedItemScore)">
    </div>

    <hr>

    <div class="form-group">
      <h5>费用申请流程</h5>
      <p><b>{{ ongoinngReimbursementProcedure ? '有' : '无' }}</b>进行中的流程</p>
      <a v-for="(r, index) in sortedReimbursementProcedure" :key="index">
        <a class="trigger" v-if="index % 2 == 0">
          <b>{{ sortedReimbursementProcedure[index + 1] }}</b>   {{ r }}
          <i class="fas fa-times" @click="modifySortedSetValue('zrem', 'sortedReimbursementProcedure', r)"></i>
        </a>
        <i class="fa fa-arrow-right" aria-hidden="true" v-if="index % 2 == 0"></i>
      </a>
      <input class="trigger" type="text" style="width: 40px" v-model="nextSortedReimbursementScore">
      <input class="trigger" type="text" v-model="nextSortedReimbursementProcedure" v-on:keyup.enter="modifySortedSetValue('zadd', 'sortedReimbursementProcedure', nextSortedReimbursementProcedure, nextSortedReimbursementScore)">
    </div>

    <hr>

    <div class="form-group">
      <h5>用印申请流程</h5>
      <p><b>{{ ongoingStampProcedure ? '有' : '无' }}</b>进行中的流程</p>
      <a v-for="(stamp, index) in sortedStampProcedure" :key="index">
        <a class="trigger" v-if="index % 2 == 0">
          <b>{{ sortedStampProcedure[index + 1] }}</b>   {{ stamp }}
          <i class="fas fa-times" @click="modifySortedSetValue('zrem', 'sortedStampProcedure', stamp)"></i>
        </a>
        <i class="fa fa-arrow-right" aria-hidden="true" v-if="index % 2 == 0"></i>
      </a>
      <input class="trigger" type="text" style="width: 40px" v-model="nextSortedStampScore">
      <input class="trigger" type="text" v-model="nextSortedStampProcedure" v-on:keyup.enter="modifySortedSetValue('zadd', 'sortedStampProcedure', nextSortedStampProcedure, nextSortedStampScore)">
    </div>

    <hr>

    <div class="form-group">
      <h5>用车申请流程</h5>
      <a v-for="(car, index) in sortedCarProcedure" :key="index">
        <a class="trigger" v-if="index % 2 == 0">
          <b>{{ sortedCarProcedure[index + 1] }}</b>   {{ car }}
          <i class="fas fa-times" @click="modifySortedSetValue('zrem', 'sortedCarProcedure', car)"></i>
        </a>
        <i class="fa fa-arrow-right" aria-hidden="true" v-if="index % 2 == 0"></i>
      </a>
      <input class="trigger" type="text" style="width: 40px" v-model="nextSortedCarScore">
      <input class="trigger" type="text" v-model="nextSortedCarProcedure" v-on:keyup.enter="modifySortedSetValue('zadd', 'sortedCarProcedure', nextSortedCarProcedure, nextSortedCarScore)">
    </div>

    <hr>

    <div class="form-group">
      <h5>奖金申领流程:</h5>
      <a v-for="(bonus, index) in bonusProcedure" :key="index">
        <a class="trigger"><b>{{ index + 1 }}</b> {{ bonus }} <i class="fas fa-times"   @click="modifyValue('lrem', 'bonusProcedure', bonus)"></i></a>
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
      </a>
      <input class="trigger" type="text" v-model="nextBonusProcedure" v-on:keyup.enter="modifyValue('rpush', 'bonusProcedure', nextBonusProcedure)">
    </div>

    <div class="form-group">
      <h5>加班审批流程:</h5>
      <a v-for="(overtime, index) in overtimeProcedure" :key="index">
        <a class="trigger"><b>{{ index + 1 }}</b> {{ overtime }} <i class="fas fa-times"   @click="modifyValue('lrem', 'overtimeProcedure', overtime)"></i></a>
        <i class="fa fa-arrow-right" aria-hidden="true"></i>
      </a>
      <input class="trigger" type="text" v-model="nextOvertimeProcedure" v-on:keyup.enter="modifyValue('rpush', 'overtimeProcedure', nextOvertimeProcedure)">
    </div>
  </div>

  </div>
</template>

<script>
import storeUtil from '@/js/storeUtil';

export default {
  data() {
    return {
      // receiptProcedure: [],
      // nextReceiptProcedure: '',
      // itemProcedure: [],
      // nextItemProcedure: '',
      // carProcedure: [],
      // nextCarProcedure: '',
      bonusProcedure: [],
      nextBonusProcedure: '',
      overtimeProcedure: [],
      nextOvertimeProcedure: '',
      sortedContractProcedure: [],
      nextSortedContractScore: 1,
      nextSortedContractProcedure: '',
      sortedPurchasingContractProcedure: [],
      nextSortedPurchasingContractScore: 1,
      nextSortedPurchasingContractProcedure: '',
      sortedPaymentProcedure: [],
      nextSortedPaymentScore: 1,
      nextSortedPaymentProcedure: '',
      sortedReceiptProcedure: [],
      nextSortedReceiptScore: 1,
      nextSortedReceiptProcedure: '',
      sortedItemProcedure: [],
      nextSortedItemScore: 1,
      nextSortedItemProcedure: '',
      sortedStampProcedure: [],
      nextSortedStampScore: 1,
      nextSortedStampProcedure: '',
      sortedReimbursementProcedure: [],
      nextSortedReimbursementScore: 1,
      nextSortedReimbursementProcedure: '',
      sortedCarProcedure: [],
      nextSortedCarScore: 1,
      nextSortedCarProcedure: '',
      ongoingContractProcedure: false,
      ongoingPaymentProcedure: false,
      ongoingReceiptProcedure: false,
      ongoingItemProcedure: false,
      ongoingStampProcedure: false,
      ongoinngReimbursementProcedure: false,
      ongoingCarProcedure: false,
      ongoingBonusProcedure: false,
      ongoingOvertimeProcedure: false
    }
  },
  mixins: [ storeUtil ],
  created() {
    for (var listName of
      ['bonus', 'overtime']) {
      this.fetchList(listName + 'Procedure');
    }
    for (var sortedSetName of ['Stamp', 'Contract', 'PurchasingContract', 'Payment', 'Receipt', 'Item', 'Reimbursement', 'Car']) {
      this.fetchSortedSet(`sorted${sortedSetName}Procedure`);
    }
    for (var name of ['contract', 'payment', 'receipt', 'item', 'stamp', 'car', 'reimbursement']) {
      this.checkOngoingProcedure(name);
    }
  },
  methods: {
    fetchList(listName) {
      this.axios.get(`redis/lrange/${listName}`).then((response) => {
        switch(listName) {
          // case 'receiptProcedure':
          // this.receiptProcedure = response.data[0];
          // this.nextReceiptProcedure = '';
          // break;
          // case 'itemProcedure':
          // this.itemProcedure = response.data[0];
          // this.nextItemProcedure = '';
          // break;
          case 'bonusProcedure':
          this.bonusProcedure = response.data[0];
          this.nextBonusProcedure = '';
          break;
          case 'overtimeProcedure':
          this.overtimeProcedure = response.data[0];
          this.nextOvertimeProcedure = '';
          break;
        }
      });
    },
    fetchSortedSet(sortedSetName) {
      this.axios.get(`redis/zrangewithscores/${sortedSetName}`).then((response) => {
        switch(sortedSetName) {
          case 'sortedContractProcedure':
          this.sortedContractProcedure = response.data[0];
          this.nextSortedContractProcedure = '';
          break;
          case 'sortedPurchasingContractProcedure':
          this.sortedPurchasingContractProcedure = response.data[0];
          this.nextSortedPurchasingContractProcedure = '';
          break;
          case 'sortedPaymentProcedure':
          this.sortedPaymentProcedure = response.data[0];
          this.nextSortedPaymentProcedure = '';
          break;
          case 'sortedReceiptProcedure':
          this.sortedReceiptProcedure = response.data[0];
          this.nextSortedReceiptProcedure = '';
          break;
          case 'sortedStampProcedure':
          this.sortedStampProcedure = response.data[0];
          this.nextSortedStampProcedure = '';
          break;
          case 'sortedItemProcedure':
          this.sortedItemProcedure = response.data[0];
          this.nextSortedItemProcedure = '';
          break;
          case 'sortedReimbursementProcedure':
          this.sortedReimbursementProcedure = response.data[0];
          this.nextSortedReimbursementProcedure = '';
          break;
          case 'sortedCarProcedure':
          this.sortedCarProcedure = response.data[0];
          this.nextSortedCarProcedure = '';
          break;
        }
      });
    },
    modifyValue(operation, listName, value) {
      this.axios.post(`redis/${operation}/${listName}`, {
        value: value
      }).then(() => {
        this.fetchList(listName);
      });
    },
    modifySortedSetValue(operation, sortedSetName, value, score) {
      var query = { value: value };
      if (score !== undefined) {
        query.score = score;
      }
      this.axios.post(`redis/${operation}/${sortedSetName}`, query).then(response => {
        this.fetchSortedSet(sortedSetName);
      });
    },
    checkOngoingProcedure(model) {
      this.axios.post(`${model}s/${this.getLocation}/queryAll`, {
        procedure:
        {
          $ne: '完成',
          $exists: true
        }
      }).then(response => {
        var result = response.data.length > 0;
        switch(model) {
          case 'contract':
          this.ongoingContractProcedure = result;
          break;
          case 'payment':
          this.ongoingPaymentProcedure = result;
          break;
          case 'receipt':
          this.ongoingReceiptProcedure = result;
          break;
          case 'item':
          this.ongoingItemProcedure = result;
          break;
          case 'stamp':
          this.ongoingStampProcedure = result;
          break;
          case 'reimbursement':
          this.ongoinngReimbursementProcedure = result;
          break;
          case 'car':
          this.ongoingCarProcedure = result;
          break;
          case 'bonus':
          this.ongoingBonusProcedure = result;
          break;
          case 'overtime':
          this.ongoingOvertimeProcedure = result;
          break;
        }
      });
    }
  }
}
</script>
