<template>
  <b-card-text>
    <div style="text-align: right">
    <b-button variant="primary" @click="types.push('')">添加新看板</b-button>
  </div>
    <b-card-group deck>
      <div v-for="(type, index) in types" :key="index">
        <TodoCard :projectId="projectId" :type="type" :score="index"></TodoCard>
      </div>
    </b-card-group>
  </b-card-text>
</template>

<script>
import TodoCard from '@/components/project/TodoCard';

export default {
  props: [
    'projectId'
  ],
  data() {
    return {
      types: [],
      todos: []
    }
  },
  components: {
    TodoCard
  },
  created() {
    this.fetchTodoType();
    this.fetchTodos();
  },
  methods: {
    fetchTodoType() {
      this.axios.get(`redis/zrange/${this.projectId}TodoType`).then((response) => {
        this.types = response.data[0];
      });
    },
    fetchTodos() {
      this.axios.post(`todo/queryAll`, {
        projectId: this.projectId
      }).then(response => {
        this.todos = response.data;
      });
    }
  }
}
</script>
